import actionTypes from '../actionTypes/ProductSearchActionTypes';

const productSearchReducer = function productSearchReducer(state = [], action) {
    switch (action.type) {
        case actionTypes.PRODUCTSEARCH_GET_SUCCESS_BR:
        case actionTypes.PRODUCTSEARCH_GET_SUCCESS:
            return action.productSearchResults;
        case actionTypes.RESET_PRODUCTSEARCH_RESULTS_BR:
        case actionTypes.RESET_PRODUCTSEARCH_RESULTS:
            state = action.value;
            return state;
        default:
            return state;
    }
};

productSearchReducer.reducerName = 'productSearchResults';

export default productSearchReducer;
