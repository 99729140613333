import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
    getStoresWithBopisAvailability,
    getStoresByPageLink,
} from 'yoda-interfaces/lib/Order/StoreApi';
import { getLatLong } from 'yoda-interfaces/lib/Vendor/VendorApi';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import ScriptLoader from 'yoda-core-components/lib/helpers/ScriptLoader/ScriptLoader';
import FactorySaga from './FactorySaga';
import {
    FIND_STORES_API_URL,
    FIND_STORES_API_LIBRARY_PARAM_NAME,
    FIND_STORES_API_PLACE_LIBRARY,
    FIND_STORES_LINK,
    FIND_STORES_COUNT,
    X_CLIENT_SOURCE_SLIDER,
} from '../common/Constants';
import { selectFeatureFlags, selectPreferences } from '../selectors/ContextSelector';
import { getDisableBYDeliveryDateFlag } from '../selectors/byDeliveryDateSelector';
import {
    FIND_STORES_REQUEST,
    FIND_STORES_SUCCESS,
    FIND_STORES_ERROR,
    FIND_MORE_STORES_REQUEST,
    FIND_MORE_STORES_SUCCESS,
    FIND_MORE_STORES_ERROR,
    FIND_STORES_INVALID_INPUT,
} from '../actionTypes/FindStoresActionTypes';

/**
 * Load google library to access Geocoder to get latitude, longitude based on zipcode
 */
function loadGeocodeScript(preferences) {
    return new Promise((resolve, reject) => {
        if (!window.google || !window.google.maps) {
            const API_KEY = preferences.googleBrowserKey;
            const googleLibProps = {
                src: `${FIND_STORES_API_URL}${API_KEY}&${FIND_STORES_API_LIBRARY_PARAM_NAME}=${FIND_STORES_API_PLACE_LIBRARY}`,
                async: true,
                onSuccess: resolve,
                onError: reject,
            };
            ScriptLoader.load(googleLibProps);
        } else {
            resolve();
        }
    });
}

/** invoke google library to get lat long based on zipcode */
export function* getLatLongUsingGeocode(zipCode) {
    yield call(loadGeocodeScript, yield select(selectPreferences));
    // TODO: #TJ clean up enableGeocode logic once geocode goes 100%
    return yield call(getLatLong, { zipCode });
}

export function* FindStoresSaga(action) {
    try {
        const findStoresDetails = yield select((state) => state.findStoresDetails);
        const itemCount = Cookies.load('ItemCount');
        // Constructing payload for change store api call
        const { zipCode } = action.payload;
        // Payload resent by FactorySaga when token is expired
        const payload = action.payload.address ? action.payload : {};
        let isGeoLocationUsed = false;
        let isZipCode = false;
        const { enablePreSourcing = true } = yield select(selectFeatureFlags) || {};
        if (isEmpty(payload)) {
            // DA => To know whether user has used geo location to fetch stores list or not
            isGeoLocationUsed = true;
            payload.showAvailable = action.payload.showAvailable;
            payload.radius = action.payload.miles;
            payload.showCartItemAvailability =
                action.payload.showCartItemAvailability ||
                findStoresDetails.showCartItemAvailability;
            if (action.payload.pageSize) {
                payload.pageSize = action.payload.pageSize;
            }
            if (zipCode) {
                // Check if the zipCode is present then call google api to determine the lat, long from it
                isZipCode = !isNaN(zipCode);
                if (!__SERVER__) {
                    try {
                        payload.location = `${zipCode}`;
                    } catch (e) {
                        yield put({ type: FIND_STORES_ERROR });
                        return;
                    }
                }
                isGeoLocationUsed = false;
            } else {
                isZipCode = false;
                const SDPUStoreInfo = localStorage.getItem('SDPUStoreInfo');
                if (
                    (action.payload.skus && action.payload.skus.length > 0) ||
                    (SDPUStoreInfo && SDPUStoreInfo.length > 0) ||
                    payload.showCartItemAvailability ||
                    Number(itemCount) > 0
                ) {
                    // order-api when sku's selected or bopis item available in cart requires address
                    payload.address = `${action.payload.lat},${action.payload.lng}`;
                } else {
                    // bapi when sku's are not selected or bopis items not available in cart requires address
                    payload.latitude = `${action.payload.lat}`;
                    payload.longitude = `${action.payload.lng}`;
                }
            }
            if (action.payload.skus) {
                payload.skus = action.payload.skus;
            }
        }

        const findStoresAction = {};
        findStoresAction.payload = payload;
        findStoresAction.type = action.type;
        if (enablePreSourcing && action.payload.xClientSource) {
            findStoresAction.payload.xClientSource = action.payload.xClientSource;
        }
        let result;
        if (itemCount || action.payload.skus || action.payload.showCartItemAvailability) {
            const disableBYDeliveryDate = yield select(getDisableBYDeliveryDateFlag);
            if (!disableBYDeliveryDate) {
                findStoresAction.payload.xClientSource = X_CLIENT_SOURCE_SLIDER;
            }
            result = yield call(FactorySaga, getStoresWithBopisAvailability, findStoresAction);
        } else {
            const newResult = {};
            const data = yield call(getStoresWithBopisAvailability, findStoresAction);
            if (data.status === 200) {
                newResult.isSuccess = true;
            } else {
                newResult.isSuccess = false;
            }
            newResult.response = data;
            result = newResult;
        }

        const { response } = result;
        if (result.isSuccess) {
            const isLatLongAvailable = !!(payload.latitude && payload.longitude);
            const userLatLong = isLatLongAvailable
                ? `${payload.latitude.trim()}, ${payload.longitude.trim()}`
                : payload.address;
            const storesData = {};
            storesData.zipCode = zipCode;
            storesData.miles = payload.radius;
            storesData.stores = response.data;
            let searchTextZip = '';
            if (Array.isArray(response.data)) {
                searchTextZip = get(response.data, '[0].metaData.inputZipCode', '');
            } else if (Object.keys(response.data).length) {
                const { meta: { inputZipCode = '' } = {} } = response.data;
                searchTextZip = inputZipCode;
            }
            if (!isZipCode && searchTextZip) {
                storesData.inputZipCode = searchTextZip;
            }
            storesData.showAvailable = payload.showAvailable;
            storesData.count = response.headers.get(FIND_STORES_COUNT);
            storesData.link = response.headers.get(FIND_STORES_LINK);
            storesData.isGeoLocationUsed = isGeoLocationUsed; // <= DA-selectSrore Click Event Data attribute
            storesData.userLatLong = userLatLong;

            yield put({ type: FIND_STORES_SUCCESS, payload: storesData });
        } else {
            const errorCode = get(response, 'data[0].errorCode', '');
            const statusCode = get(response, 'status', null);
            // status code 500 - API call failed (fail over scenario)
            if (errorCode === 'LOCATION_NOT_FOUND' || statusCode === 500) {
                yield put({ type: FIND_STORES_INVALID_INPUT });
                return;
            }
            throw new Error(response.statusText);
        }
    } catch (error) {
        yield put({
            type: FIND_STORES_ERROR,
            error,
        });
    }
}

export function* FindStoresPaginationSaga(action) {
    try {
        // Constructing payload for change store api call
        const findMoreStoresAction = {};
        findMoreStoresAction.type = action.type;
        findMoreStoresAction.payload = {
            pageLink: action.payload.pageLink || action.payload.nextPageLink,
        };

        // Call the check store Api to get the stores list
        const result = yield call(FactorySaga, getStoresByPageLink, findMoreStoresAction);
        const { response } = result;
        if (result.isSuccess) {
            const payload = {
                stores: response.data,
                link: response.headers.get(FIND_STORES_LINK),
            };
            const isShipToStoreInfo = response.data && response.data.stores;
            if (isShipToStoreInfo) {
                const pages = response.data.page;
                const currentPageIndex = findIndex(pages, { selected: true });
                payload.stores = response.data.stores;
                payload.nextPageLink = pages[currentPageIndex + 1]
                    ? pages[currentPageIndex + 1].url
                    : '';
            }
            yield put({ type: FIND_MORE_STORES_SUCCESS, payload });
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        yield put({
            type: FIND_MORE_STORES_ERROR,
            error,
        });
    }
}

const watchFindStoresRequest = function* watchFindStoresRequest() {
    yield takeLatest(FIND_STORES_REQUEST, FindStoresSaga);
};
watchFindStoresRequest.sagaName = 'watchFindStoresRequest';

const watchFindMoreStoresRequest = function* watchFindMoreStoresRequest() {
    yield takeLatest(FIND_MORE_STORES_REQUEST, FindStoresPaginationSaga);
};
watchFindMoreStoresRequest.sagaName = 'watchFindMoreStoresRequest';

export { watchFindStoresRequest, watchFindMoreStoresRequest };
