import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import get from 'lodash/get';
import getWeeklyAdDetail from 'yoda-interfaces/lib/WeeklyAds/WeeklyAdsApi';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { selectPreferences } from '../selectors/ContextSelector';
import {
    GET_WEEKLY_AD_DETAILS,
    WEEKLY_AD_DETAILS_SUCCESS,
    WEEKLY_AD_DETAILS_FAILURE,
} from '../actionTypes/WeeklyAdsActionTypes';

function* weeklyAdMessageSaga(weeklyAdsStoreCode) {
    try {
        let weeklyAdsData = JSON.parse(LocalStorage.getData('weeklyAdsMessage', true));
        if (!weeklyAdsData) {
            const preferences = yield select(selectPreferences);
            // default duration to cache the flipp API is 24hr i,e 1440 min
            const weeklyAdsStorageDuration = get(preferences, 'weeklyAdsStorageDuration', 1440);
            const weeklyAdsResponse = yield call(getWeeklyAdDetail, weeklyAdsStoreCode);
            weeklyAdsData = (weeklyAdsResponse && get(weeklyAdsResponse, 'data', [])) || [];
            // weekly Ads information from flipp API will be cached based on config value weeklyAdsStorageDuration
            weeklyAdsData.length &&
                LocalStorage.setData(
                    'weeklyAdsMessage',
                    weeklyAdsData,
                    true,
                    '',
                    false,
                    weeklyAdsStorageDuration
                );
        }
        if (weeklyAdsData.length) {
            yield all([put({ type: WEEKLY_AD_DETAILS_SUCCESS, payload: weeklyAdsData })]);
        }
    } catch (error) {
        yield put({ type: WEEKLY_AD_DETAILS_FAILURE, error });
    }
}

const watchWeeklyAdsSaga = function* watchWeeklyAdsSaga() {
    yield takeLatest(GET_WEEKLY_AD_DETAILS, weeklyAdMessageSaga);
};

watchWeeklyAdsSaga.sagaName = 'watchWeeklyAdsSaga';

export default watchWeeklyAdsSaga;
