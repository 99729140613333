import { takeLatest, put, call, select } from 'redux-saga/effects';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { getL2Category } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import {
    IRIS_GLOBAL_NAVIGATION_GET_SUCCESS,
    IRIS_GLOBAL_NAVIGATION_GET_REQUEST,
} from '../actionTypes/DepartmentVisualNavigationActionTypes';
import { selectPreferences, selectFeatureFlags } from '../selectors/ContextSelector';
import CustomErrorLogger from '../helpers/CustomErrorLogger/CustomErrorLogger';
import getPageType from '../helpers/Utils/pageType';
import logApiErrors from '../helpers/Utils/logApiErrors';

const apiName = 'l2Navigation';

const fetchIrisCategoryLevel2 = (response) => {
    const newState = [];
    if (response) {
        _forEach(response, (value) => {
            let arrObj = {};
            const { linkText: name = '' } = value;
            const { link: { url: targetUrl = '' } = {} } = value;
            const { openInNewTab = false } = value;
            const targetWindow = openInNewTab ? 'NW' : 'SW';
            arrObj = {
                name,
                targetUrl,
                targetWindow,
            };
            newState.push(arrObj);
        });
    }
    return newState;
};
const fetchIrisSubNodes = (subNode = []) => {
    const newState = [];
    _forEach(subNode, (value) => {
        const { displayName: name = '', link: { url: links = '' } = {} } = value;
        newState.push({
            name,
            links,
        });
    });
    return newState;
};

const fetchInspirationalContent = (inspirationalContent = []) => {
    const newState = [];
    const inspirationalContentTrim = inspirationalContent.slice(0, 1);
    _forEach(inspirationalContentTrim, (value) => {
        const {
            headline = '',
            subHeadline = '',
            image: { url: imageUrl = '', altText = '' } = {},
            link: { url: links = '' } = {},
        } = value;
        newState.push({
            headline,
            subHeadline,
            imageUrl,
            altText,
            links,
        });
    });
    return newState;
};
const fetchIrisCategoryLevel1 = (response, enableColumnStyleFlyer) => {
    const categoryLevel1 = [];
    let maxColumn = 1;
    const columnPositions = [[], [], []];
    if (response) {
        _forEach(response, (value) => {
            let arrObj = {};
            const categories = fetchIrisCategoryLevel2(value.children) || [];
            const {
                linkText: key = '',
                link: { url: links = '' } = {},
                openInNewTab = false,
                columnPosition = '',
            } = value;
            const targetWindow = openInNewTab ? 'NW' : 'SW';
            const numericColumnPosition = columnPosition && parseInt(columnPosition, 10);
            maxColumn = maxColumn < numericColumnPosition ? numericColumnPosition : maxColumn;

            arrObj = {
                key,
                links,
                categories,
                targetWindow,
                numericColumnPosition,
            };
            categoryLevel1.push(arrObj);
        });
    }
    return {
        categoryLevel1,
        maxColumn,
        columnPositions,
    };
};

const trimDepartmentList = (deptArr, maxElement, columnCount) => {
    const maxColumnCount = columnCount || 16;
    _forEach(deptArr, (deptObj) => {
        let totalElemCount = 0;
        const trimmedArr = [];
        _forEach(deptObj.departments, (departmentObj) => {
            const categoryArr = _get(departmentObj, 'categories', []);
            let categoryCount = categoryArr.length;
            categoryCount = categoryCount > maxColumnCount ? maxColumnCount : categoryCount;
            totalElemCount = totalElemCount + categoryCount + 1;
            if (totalElemCount > maxElement) {
                const allowedCount = maxElement - (totalElemCount - categoryCount);
                categoryArr.splice(allowedCount - categoryCount);
                departmentObj.categories = categoryArr;
                trimmedArr.push(departmentObj);
                deptObj.departments = trimmedArr;
                return false;
            }
            trimmedArr.push(departmentObj);
            return true;
        });
    });
    return deptArr;
};

const fetchIrisDepartments = (response, elementCount, columnCount, enableColumnStyleFlyer) => {
    const deptObj = {
        departments: [],
        error: {},
    };

    if (response && response.data && response.data.contents) {
        _forEach(response.data.contents, (value) => {
            let arrObj = {};
            const { categoryLevel1: departments, maxColumn, columnPositions } =
                fetchIrisCategoryLevel1(value.children, enableColumnStyleFlyer) || {};
            const { linkText: title = '' } = value;
            const { image: { url: image = '' } = {} } = value;
            const { link: { url: links = '' } = {} } = value;
            const subDepartments = fetchIrisSubNodes(value.subNodes);
            const inspirationalContent = fetchInspirationalContent(value.inspirationalContent);
            arrObj = {
                title,
                image,
                links,
                departments,
                maxColumn,
                columnPositions,
                subDepartments,
                inspirationalContent,
            };
            deptObj.departments.push(arrObj);
        });
    }
    deptObj.error = logApiErrors(apiName, response, deptObj.departments);
    return deptObj;
};

function* IrisGlobalNavigationSaga() {
    try {
        // Fetching the feature flags and preferences.
        const featureFlags = yield select(selectFeatureFlags);
        const preferences = yield select(selectPreferences);
        const enableColumnStyleFlyer = _get(featureFlags, 'enableColumnStyleFlyer', false);
        let departments;
        let departmentObj = {};
        // We are storing the iris API response for the departments in the local Storage. Checking if the flag is enabled. If this is false, we will switch back to the existing functionality.
        if (featureFlags.isDepartmentAPILocalStore) {
            // Modifying the key as a part of switching between XM and iris
            // Getting the data which is cached and is in the local Storage. We need to pass the preferences object for this, which contains the cacheMinutes i.e. how long we need to store the object in localStorage, and flushTime - UTC milliseconds.
            const cachedData = JSON.parse(LocalStorage.getData('irisdepartmentCatIris', true));
            departments =
                !cachedData || cachedData?.createdTS || cachedData?.key === 'mobileMenu'
                    ? ''
                    : cachedData.val;

            // If nothing is returned, make the service call and store in the localStorage.
            if (_isEmpty(departments)) {
                departmentObj = fetchIrisDepartments(
                    yield call(getL2Category),
                    _get(preferences, 'singleFlyer.elementLimit', 52),
                    _get(preferences, 'singleFlyer.subCategoryMax', 16),
                    enableColumnStyleFlyer
                );
                departments = departmentObj.departments;
                const irisMenuStorageKey = { key: 'desktopMenu', val: departments };
                irisMenuStorageKey &&
                    LocalStorage.setData(
                        'irisdepartmentCatIris',
                        irisMenuStorageKey,
                        true,
                        '',
                        false,
                        preferences.cacheDepartmentAPI.cacheMins
                    );
            }
        } else {
            // This is the existing functionality. Call fetchCategories function.
            departmentObj = fetchIrisDepartments(
                yield call(getL2Category),
                _get(preferences, 'singleFlyer.elementLimit', 52),
                _get(preferences, 'singleFlyer.subCategoryMax', 16),
                enableColumnStyleFlyer
            );
            departments = departmentObj.departments;
        }

        yield put({
            type: IRIS_GLOBAL_NAVIGATION_GET_SUCCESS,
            departments,
        });

        if (!_isEmpty(departmentObj.error)) {
            const pageType = getPageType();
            const errorDetails = {
                errorType: departmentObj.error.errorType,
                errorDescription: departmentObj.error.errorDescription,
                pageType,
            };
            yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
        }
    } catch (error) {
        const pageType = getPageType();
        const errorDetails = {
            errorType: error.errorCode || 'l2 navigation error',
            errorDescription: error.errorMessage || 'Error while fetching l2 navigation categories',
            pageType,
        };
        yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
        yield put({ type: 'IRIS_GLOBAL_NAVIGATION_ERROR', error });
    }
}

const watchIrisGlobalNavigationRequest = function* watchIrisGlobalNavigationRequest() {
    yield takeLatest(IRIS_GLOBAL_NAVIGATION_GET_REQUEST, IrisGlobalNavigationSaga);
};

watchIrisGlobalNavigationRequest.sagaName = 'IrisGlobalNavigationSaga';

export default watchIrisGlobalNavigationRequest;
