export const FETCH_DATA_FOR_RECOMMENDATION = 'FETCH_DATA_FOR_RECOMMENDATION';
export const ADD_CERTONA_LOADER_TO_LIST = 'ADD_CERTONA_LOADER_TO_LIST';
export const ADD_PRODUCT_CARTRIDGE_SLOT = 'ADD_PRODUCT_CARTRIDGE_SLOT';
export const REMOVE_PRODUCT_CARTRIDGE_SLOT = 'REMOVE_PRODUCT_CARTRIDGE_SLOT';
export const FETCH_DATA_SAVE_FOR_LATER = 'FETCH_DATA_SAVE_FOR_LATER';
export const ADD_LOADER_TO_LIST = 'ADD_LOADER_TO_LIST';
export const FETCH_DATA_FROM_SAVED_ITEMS_ERROR = 'FETCH_DATA_FROM_SAVED_ITEMS_ERROR';
export const RESET_PRODUCT_CARTRIDGE_REDUCER = 'RESET_PRODUCT_CARTRIDGE_REDUCER';
export const CERTONA_OPTIONS = 'CERTONA_OPTIONS';
export const CLEAR_CERTONA_OPTIONS = 'CLEAR_CERTONA_OPTIONS';
export const ENSIGHTEN_ERROR_EVENT = 'ENSIGHTEN_ERROR_EVENT';
