import {
    SLIDER_CATEGORY_LOAD_REQUEST,
    SLIDER_HOVER_LOAD_REQUEST,
    IRIS_SLIDER_CATEGORY_LOAD_REQUEST,
    DISPLAY_HAMBURGER_SLIDER,
} from '../actionTypes/HamburgerActionTypes';

export const getCategories = () => ({
    type: SLIDER_CATEGORY_LOAD_REQUEST,
});

export const getHoverPanel = (nid) => ({
    type: SLIDER_HOVER_LOAD_REQUEST,
    nid,
});

export const getIrisCategories = (payload) => ({
    type: IRIS_SLIDER_CATEGORY_LOAD_REQUEST,
    payload,
});

export const displayHamburgerSlider = (payload) => ({
    type: DISPLAY_HAMBURGER_SLIDER,
    payload,
});

export default {
    getCategories,
    getHoverPanel,
    getIrisCategories,
    displayHamburgerSlider,
};
