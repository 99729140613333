import { BG_WHITE_SHOW, BG_WHITE_REMOVE } from '../actionTypes/BgWhiteActionType';

const showBgWhite = () => ({
    type: BG_WHITE_SHOW,
});

const removeBgWhite = () => ({
    type: BG_WHITE_REMOVE,
});

export default {
    showBgWhite,
    removeBgWhite,
};
