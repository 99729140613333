import { connect } from 'react-redux';

import {
    selectFeatureFlags,
    selectPreferences,
    selectReqHeaders,
} from '../../selectors/ContextSelector';

import WrappedComponent from './WrappedComponent';

function mapStateToProps(state) {
    return {
        featureFlags: selectFeatureFlags(state),
        preferences: selectPreferences(state),
        reqHeaders: selectReqHeaders(state),
    };
}

/**
 * A Connected Component to throttle child render based on feature flags stored in
 * featureFlags, Preferences Object, request header, request cookie.
 */
const ThrottleFeatureRender = connect(mapStateToProps)(WrappedComponent);

export default ThrottleFeatureRender;
