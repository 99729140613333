import {
    COLOR_SWATCHES_ONHOVER,
    BUNDLE_COLOR_SWATCHES_ON_HOVER,
} from '../actionTypes/ColorSwatchesActionTypes';

function ColorSwatchesReducer(state = {}, action) {
    const { payload } = action;
    switch (action.type) {
        case COLOR_SWATCHES_ONHOVER:
            return {
                hoverOption: payload.option,
                isSwatchesOnHover: payload.isSwatchesOnHover,
                updateProductImage: payload.updateProductImage,
            };
        case BUNDLE_COLOR_SWATCHES_ON_HOVER:
            return {
                primaryImage: payload.image,
                ppId: payload.ppId,
            };
        default:
            return state;
    }
}

// ColorSwatchesReducer.reducerName = 'colorSwatches';

export default ColorSwatchesReducer;
