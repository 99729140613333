import {
    FIND_STORES_PAGE_SUCCESS,
    FIND_MORE_STORES_PAGE_SUCCESS,
    FIND_STORES_BY_SERVICE,
    SET_SELECTED_SERVICES,
    STORES_BY_ID_SUCCESS,
    FIND_STORES_PAGE_RESET,
    SHOW_STORE_LOADER,
    HIDE_STORE_LOADER,
    SET_ZIP_CODE,
    STORES_ON_LOAD,
    FIND_STORES_PAGE_INVALID_INPUT,
    SET_SEARCH_OBJECT,
    FIND_STORES_TOGGLE_MAP,
    GET_FILTER_BY_SERVICES_SUCCESS,
    GET_FILTER_BY_SERVICES_FAILURE,
    SET_SERVICE_ERROR,
    CLEAR_SERVICE_ERROR,
} from '../actionTypes/FindAStorePageActionTypes';

const findAStorePageReducer = function findAStorePageReducer(
    state = {
        storeSelectedServices: [],
        count: 0,
        miles: 25,
        invalidInput: false,
        showLoader: false,
        invokeStoresOnLoad: false,
        filterServices: [],
    },
    action
) {
    const resetState = {
        invalidInput: false,
    };
    switch (action.type) {
        case FIND_STORES_PAGE_SUCCESS: {
            return { ...state, ...action.storeInfo, ...resetState };
        }
        case FIND_MORE_STORES_PAGE_SUCCESS: {
            const { nextPageLink, stores } = action.payload;
            return { ...state, ...resetState, stores: state.stores.concat(stores), nextPageLink };
        }
        case FIND_STORES_BY_SERVICE: {
            const { nextPageLink, stores, count } = action.payload;
            return { ...state, ...resetState, stores, nextPageLink, count };
        }
        case STORES_BY_ID_SUCCESS: {
            const { stores, count } = action.payload;
            return { ...state, ...resetState, stores: [...stores], count };
        }
        case SET_SELECTED_SERVICES: {
            return { ...state, storeSelectedServices: action.storeService };
        }
        case FIND_STORES_PAGE_INVALID_INPUT: {
            return { ...state, ...action.storeInfo, invalidInput: true };
        }
        case FIND_STORES_PAGE_RESET: {
            return {};
        }
        case SHOW_STORE_LOADER: {
            return { ...state, showLoader: true };
        }
        case HIDE_STORE_LOADER: {
            return { ...state, showLoader: false };
        }
        case SET_ZIP_CODE: {
            return { ...state, zipCode: action.zipCode };
        }
        case STORES_ON_LOAD: {
            return { ...state, invokeStoresOnLoad: true };
        }
        case SET_SEARCH_OBJECT: {
            // overwrite redux with searchObject to track params of last search
            return { ...state, ...action.payload.searchObject };
        }
        case FIND_STORES_TOGGLE_MAP: {
            const findStoresShowMap = !state.findStoresShowMap;
            return { ...state, findStoresShowMap };
        }
        case GET_FILTER_BY_SERVICES_SUCCESS: {
            return { ...state, filterServices: action.payload };
        }
        case GET_FILTER_BY_SERVICES_FAILURE: {
            return { ...state, filterServices: action.payload };
        }
        case SET_SERVICE_ERROR:
            return { ...state, customServiceError: true };
        case CLEAR_SERVICE_ERROR:
            return { ...state, customServiceError: false };
        default:
            return state;
    }
};

findAStorePageReducer.reducerName = 'findAStorePageInfo';

export default findAStorePageReducer;
