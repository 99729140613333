import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { updateCartStore } from 'yoda-interfaces/lib/Cart/CartApi';
import AnalyticsActionTypes from '../actionTypes/AnalyticsActionTypes';
import { ANALYTICS_SELECT_STORE_SUCCESS } from '../common/Constants';
import FactorySaga from './FactorySaga';
import storeActions from '../actions/OrderAction';
import { updateUserStoreLocationInfo } from '../actions/LocationServiceAction';
import * as actionTypes from '../actionTypes/FindStoresActionTypes';
import productPricingAction from '../actions/ProductPricingAction';
import { getFilteredSkuId } from '../selectors/ProductOptionsSelector';
import { selectFeatureFlags } from '../selectors/ContextSelector';

const { updateSelectedStoreAvailability } = productPricingAction;

export const findStoresDetails = (state) => state.findStoresDetails;
export const getDeliveryOptions = ({ productOffering }) =>
    get(productOffering, 'promise.skuDeliveryOptions[0]', {});

function* selectStoreSaga(action) {
    try {
        const updateAction = {};
        const featureFlags = yield select(selectFeatureFlags);
        const { enablePreSourcing = true, enableLocationService } = featureFlags || {};
        updateAction.payload = action.params.payload;
        if (enablePreSourcing) {
            updateAction.payload.xClientSource = 'SLIDER';
        }
        let result = '';
        let storeId = updateAction.payload.id;
        if (updateAction.payload.deliveryOption && !isEmpty(updateAction.payload.itemId)) {
            storeId = updateAction.payload.deliveryOption.storeId;
            result = yield call(FactorySaga, updateCartStore, updateAction);
        }
        const { response } = result;
        if (enableLocationService && result.isSuccess) {
            let shouldUpdateStore = true;
            const storeName = get(action, 'params.storeDetails.name', '');
            if (
                isEmpty(storeId) ||
                !updateAction.payload.city ||
                !updateAction.payload.state ||
                !updateAction.payload.street ||
                !updateAction.payload.zip ||
                isEmpty(storeName)
            ) {
                shouldUpdateStore = false;
            }
            if (shouldUpdateStore) {
                yield put(
                    updateUserStoreLocationInfo({
                        id: storeId,
                        city: updateAction.payload.city,
                        state: updateAction.payload.state,
                        street: updateAction.payload.street,
                        zip: updateAction.payload.zip,
                        name: storeName,
                    })
                );
            }
        }
        const storeDetails = yield select(findStoresDetails);
        const store = find(storeDetails.stores, { id: storeId }) || {};
        const selectedSkuId = yield select(getFilteredSkuId(action.params.payload.fromMiniPDP));
        const deliveryOptions = yield select(getDeliveryOptions);

        yield all([
            put({ type: actionTypes.PUT_SELECTED_STORE_SUCCESS, response }),
            // DA => Trigger 'selectStore' when use successfully change store for BOPIS item
            put({
                type: AnalyticsActionTypes.UPDATE_ANALYTICS_CLICK_EVENT,
                payload: ANALYTICS_SELECT_STORE_SUCCESS,
            }),
            !action.params.enableStoreModalAtc &&
                put(
                    updateSelectedStoreAvailability(
                        selectedSkuId,
                        store,
                        deliveryOptions,
                        action.params.payload.fromMiniPDP
                    )
                ),
        ]);

        // Blocking the header store update when SDPU store is updating.
        if (!action.params.blockHeaderStoreUpdate) {
            const selectActionCallBack = action.params.selectActionCallback;
            yield put(
                storeActions.getStoreByStoreId({
                    storeId: store.id,
                    getStoreCallback: selectActionCallBack,
                })
            );
        }
    } catch (error) {
        yield put(actionTypes.PUT_SELECTED_STORE_ERROR, error);
    }
}

const watchSelectStore = function* watchSelectStore() {
    yield takeLatest(actionTypes.PUT_SELECTED_STORE_REQUEST, selectStoreSaga);
};
watchSelectStore.sagaName = 'watchSelectStore';

export default watchSelectStore;
