import PropTypes from 'prop-types';
import React from 'react';
import FindAStorePageLazy from '../FindAStorePage/FindAStorePageLazy';
import {
    FIND_STORES_SELECT_A_STORE_PAGE_TITLE,
    FIND_STORES_CHANGE_MY_STORE_PAGE_TITLE,
} from '../../common/Constants';

const SelectaStore = ({ isGeoStore }) => (
    <FindAStorePageLazy
        theme="selectAStore"
        title={
            isGeoStore
                ? FIND_STORES_SELECT_A_STORE_PAGE_TITLE
                : FIND_STORES_CHANGE_MY_STORE_PAGE_TITLE
        }
    />
);

SelectaStore.defaultProps = {
    isGeoStore: false,
};

SelectaStore.propTypes = {
    isGeoStore: PropTypes.bool,
};

export default SelectaStore;
