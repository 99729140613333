import { call, put, takeLatest, select } from 'redux-saga/effects';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { updateStore, addStore } from 'yoda-interfaces/lib/Account/AccountApi';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import * as OrderActionTypes from '../actionTypes/OrderActionTypes';
import * as StoreActionTypes from '../actionTypes/StoreActionTypes';
import Storage from '../helpers/Storage/Storage';
import FactorySaga from './FactorySaga';
import User from '../helpers/User/User';
import { selectFeatureFlags } from '../selectors/ContextSelector';

const previousSelectedStore = (state) => state.selectedStore;

export function* getStoresSaga(action) {
    try {
        const payload = omit(action.payload, 'getStoreCallback');
        const storeState = yield select(previousSelectedStore);
        let selectedStore;
        const getStoreCallback = get(action, 'payload.getStoreCallback');
        const storeConfig = JSON.parse(LocalStorage.getData('defaultStore', true));
        selectedStore = JSON.parse(Cookies.load('userPreferredStore'));
        if (selectedStore.storeId) {
            selectedStore.id = selectedStore.storeId;
        }
        if (selectedStore.storeName) {
            selectedStore.name = selectedStore.storeName;
        }
        if (selectedStore.zipcode) {
            selectedStore.zip = selectedStore.zipcode;
        }

        // payload from checkout page should have a flag: shouldTriggerSelectStore = false
        // to avoid select store event being triggered in checkout page, which is not required
        if (
            typeof payload.shouldTriggerSelectStore === 'undefined' &&
            (!isEmpty(selectedStore) || !isEmpty(storeState))
        ) {
            yield put({
                type: OrderActionTypes.GET_STORE_BY_STORE_ID_SUCCESS,
                selectedStore,
                defaultStore: true,
            });
        }
        if (!isEmpty(storeState) && (selectedStore || storeConfig)) {
            const param = {
                type: OrderActionTypes.GET_STORE_BY_STORE_ID_SUCCESS_CHECKOUT,
                selectedStore: selectedStore || storeConfig,
                defaultStore: true,
            };
            yield put(param);
        }
        if (getStoreCallback) {
            yield call(getStoreCallback);
        }
    } catch (error) {
        yield put({ type: 'GET_STORES_GET_ERROR', error });
    }
}

function* setMyStoreSaga({ payload }) {
    try {
        if (payload) {
            // const storeFrom = Cookies.load('isGeoStore') || 'false';
            const selectedStore = yield select(previousSelectedStore);
            LocalStorage.setData('defaultStore', payload, true);
            Storage.save('userStore', payload.id, true);
            Cookies.save('geoLocStoreId', payload.id);
            Cookies.remove('isGeoStore');

            if (User.isUserLoggedIn()) {
                let isStoreInUserList = false;
                const sendData = {
                    payload: {
                        isDefault: true,
                    },
                };

                if (selectedStore.userStores.length) {
                    const arr = selectedStore.userStores.filter((elm) => elm.id === payload.id);
                    isStoreInUserList = !!arr.length;
                }
                const { enablePreSourcing = true } = yield select(selectFeatureFlags) || {};
                if (enablePreSourcing) {
                    sendData.payload.xClientSource = 'SLIDER';
                }

                if (isStoreInUserList) {
                    sendData.appendToPostData = { storeId: payload.id };
                    yield call(FactorySaga, updateStore, sendData);
                } else {
                    sendData.payload.id = payload.id;
                    const addStoreResp = yield call(FactorySaga, addStore, sendData);
                    // Error handling: when add store api call failed because store already exists in CAM profile
                    if (
                        addStoreResp.isSuccess === false &&
                        addStoreResp.response.data.errorCode === 'SRV_STORE_EXISTS'
                    ) {
                        yield call(FactorySaga, updateStore, {
                            payload: {
                                isDefault: true,
                                storeId: payload.id,
                            },
                        });
                    }
                }
            }

            // Storing the data to the common store which is used across microsites.
            yield put({
                type: OrderActionTypes.GET_STORE_BY_STORE_ID_SUCCESS,
                selectedStore: payload,
            });
        }
    } catch (error) {
        yield put({ type: 'SET_MY_STORE_ERROR', error });
    }
}

const watchGetStoresRequest = function* watchGetStoresRequest() {
    yield takeLatest(OrderActionTypes.GET_STORE_BY_STORE_ID_REQUEST, getStoresSaga);
};

watchGetStoresRequest.sagaName = 'watchGetStoresRequest';

export default watchGetStoresRequest;

const watchSetMyStore = function* watchSetMyStore() {
    yield takeLatest(StoreActionTypes.SET_MY_STORE, setMyStoreSaga);
};

watchSetMyStore.sagaName = 'watchSetMyStore';

export { watchSetMyStore };
