import {
    SAVEDITEMS_ADD_ITEM_SFL,
    SAVEDITEMS_REMOVE_ITEM_SFL,
    SAVEDITEMS_RESET_STATUS,
} from '../actionTypes/SavedItemActionTypes';

function SavedItemResponseReducer(state = [], action) {
    switch (action.type) {
        case SAVEDITEMS_ADD_ITEM_SFL: {
            return [...state, action.obj];
        }

        case SAVEDITEMS_REMOVE_ITEM_SFL: {
            return [...state, action.obj];
        }

        case SAVEDITEMS_RESET_STATUS: {
            return [...state.filter((obj) => obj.ppId !== action.ppId)];
        }

        default:
            return state;
    }
}

SavedItemResponseReducer.reducerName = 'savedItemStatus';
export default SavedItemResponseReducer;
