import get from 'lodash/get';
import trimIrisComponents from './trimIrisComponents';

const trimComponents = (response) => {
    const { components = [] } = response;
    if (components && components.length) {
        return { ...response, components: components.map((data) => trimIrisComponents(data)) };
    }
    return response;
};

const trimColumns = (response) => {
    const { columns = [] } = response;
    if (columns && columns.length) {
        return { ...response, columns: columns.map((data) => trimComponents(data)) };
    }
    return response;
};

const trimRows = (response) => {
    const { rows = [] } = response;
    if (rows && rows.length) {
        return { ...response, rows: rows.map((data) => trimColumns(data)) };
    }
    return response;
};

const trimIrisData = (response) => {
    response.content = trimRows(get(response, 'content', {}));
    return response;
};

export default trimIrisData;
