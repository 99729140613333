import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames/bind';
import Button from 'yoda-core-components/lib/components/Button/Button';
import TypeaheadInput from 'yoda-core-components/lib/components/TypeaheadInput/TypeaheadInput';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _take from 'lodash/take';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { fetchChannelInfo } from 'yoda-core-components/lib/helpers/Utils/Utils';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import isBloomReachAudience from '../../helpers/BloomReach/BloomReach';
import actions from '../../actions/ProductSearchAction';
import config from './ProductSearch.config';
import * as styles from './ProductSearch.css';
import RecentSearch from './RecentSearch';
import withReducerSaga from '../../hoc/ReducerRegistry/withReducerSaga';
import ProductSearchReducer from '../../reducers/ProductSearchReducer';
import ProductSearchSaga from '../../sagas/ProductSearchSaga';
import { showLazy } from '../../lazy/actions/LazyAction';
import selectLazy from '../../lazy/selectors/selectLazy';
import {
    selectFeatureFlags,
    selectIsNative,
    selectIsFragment,
} from '../../selectors/ContextSelector';
import productSearchFocusAction from '../../actions/ProductSearchFocusAction';

const cx = classNames.bind(styles);

const typeaheadEnable = 'typeaheadEnable';

const getAmount = (price) => price.split(',').join('').trim();

const searchPlaceholder = 'What can we help you find?';

// const searchOnFocusMobile = 'What are you looking for?';

export const renderPrice = (price, type) => {
    if (price) {
        price = price.split('$').join('');
        const prices = price.split('-');
        if (prices.length === 2) {
            const n = prices[1].trim().split(' ');
            /* istanbul ignore else */
            if (n.length === 2) {
                prices[1] = n[0];
                type = n[1];
            }
        } else {
            const n = prices[0].split(' ');
            if (n.length === 2) {
                prices[0] = n[0];
                type = n[1];
            }
        }

        const minPrice = getAmount(prices[0]).trim();
        /* istanbul ignore else */
        if (!Number(minPrice)) return null;

        const maxPrice = prices.length === 2 ? getAmount(prices[1]) : null;
        return {
            max: Number(maxPrice) || Number(minPrice) || 0,
            min: Number(minPrice) || 0,
            type: type || '',
            minPercentOff: 0,
            maxPercentOff: 0,
        };
    }

    return null;
};

const alterUrl = (url, linkPrefix) => {
    const prefixPath = linkPrefix ? `//${linkPrefix}` : '';
    /* istanbul ignore next */
    if (url.indexOf('http') === 0) {
        // if url is fully qualified return as is
        return url;
    }
    return `${prefixPath}${url}`;
};

export const getSearchURL = (navResults, params = {}, linkPrefix = '') => {
    let url;
    if (navResults.link) {
        url = navResults.link;
    } else {
        const desired = navResults.term.replace(/[^\w\s/+]/gi, '');
        url = `${config.link.sub}`;
    }

    const queryParams = _map(params, (value, key) => `${key}=${value}`).join('&');
    const alteredUrl = alterUrl(url, linkPrefix);
    const separator = url && url.indexOf('?') !== -1 ? '&' : '?';
    return !queryParams.length ? `${alteredUrl}` : `${alteredUrl}${separator}${queryParams}`;
};

export const navigateTo = (navResult, datasourceType, linkPrefix) => {
    const param = {};

    if (navResult.testVersion) {
        param.testVersion = navResult.testVersion;
    }

    // Recent Search/Keyword Search Scenario :
    // Link will not be available so constructing the URL using search term
    if (!navResult.link) {
        /* istanbul ignore next */
        const searchKey = 'searchTerm';
        param[searchKey] = encodeURIComponent(navResult.term).replace(/%20/g, '+');

        // Searchtype query param
        if (datasourceType) {
            param.searchType = encodeURIComponent(datasourceType).replace(/%20/g, '+');
        }
    }

    /* istanbul ignore else */
    if (navResult.predictiveDetail) {
        param.predictiveDetail = navResult.predictiveDetail;
    }

    window.location.assign(getSearchURL(navResult, param, linkPrefix));
};

const prepareData = (dataSoure, maxItemsLength) => {
    if (dataSoure && Array.isArray(dataSoure)) {
        const dataLength = dataSoure.length;
        const length = maxItemsLength < dataLength ? maxItemsLength : dataLength;
        const data = [];

        dataSoure.forEach((item, i) => {
            data.push({
                type: 'term',
                value: item.term,
                position: i + 1,
                url: item.url,
                categoryPosition: i + 1,
            });

            if (item.depts) {
                item.depts.map((dept, index) => {
                    data.push({
                        type: 'dept',
                        value: ` in ${dept.name}`,
                        parentItem: item.term,
                        link: dept.href,
                        position: index + 1,
                        categoryPosition: i + 1,
                    });

                    return data;
                });
            }
        });

        return _take(data, length);
    }
    return null;
};

export class ProductSearch extends Component {
    static defaultProps = {
        minSearchInputLength: 3,
        maxItemsLength: 10,
        actions: {},
        productSearchResults: [],
        automationId: '',
        showFeatureResult: true,
        deviceType: {},
        location: {},
        abTestEngagements: [],
        regionZone: '',
        posDeviceStatus: {},
        typeaheadEnabled: false,
        productDisplayEnabled: true,
        linkPrefix: '',
        featureFlags: {},
        productSearchFocus: '',
        productSearchContainerCustomClass: '',
        productSearchIconCustomClass: '',
        productSearchInputCustomClass: '',
        productResetIconCustomClass: '',
        isNoResultsProductSearch: false,
        searchSelectAnimationRemove: null,
        isFragment: false,
        isNative: false,
        enableBloomReach: false,
        enableBloomReachV3PLP: true,
        context: {},
        headerScroll: '',
        isUserLoggedIn: false,
    };

    static propTypes = {
        minSearchInputLength: PropTypes.number,
        maxItemsLength: PropTypes.number,
        actions: PropTypes.object.isRequired,
        productSearchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
        automationId: PropTypes.string,
        showFeatureResult: PropTypes.bool,
        deviceType: PropTypes.object,
        location: PropTypes.object,
        abTestEngagements: PropTypes.array, // updated this to address this warning - Failed prop type: Invalid prop `abTestEngagements` of type `array` supplied to `ProductSearch`, expected `object`.
        regionZone: PropTypes.string, // updated to fix this warning -- Failed prop type: Invalid prop `regionZone` of type `number` supplied to `ProductSearch`, expected `string`.
        posDeviceStatus: PropTypes.object,
        typeaheadEnabled: PropTypes.bool,
        productDisplayEnabled: PropTypes.bool,
        linkPrefix: PropTypes.string,
        autoSuggestVersion: PropTypes.number.isRequired,
        featureFlags: PropTypes.object,
        productSearchFocus: PropTypes.string,
        productSearchContainerCustomClass: PropTypes.string,
        productSearchIconCustomClass: PropTypes.string,
        productSearchInputCustomClass: PropTypes.string,
        productResetIconCustomClass: PropTypes.string,
        isNoResultsProductSearch: PropTypes.bool,
        searchSelectAnimationRemove: PropTypes.func,
        isFragment: PropTypes.bool,
        isNative: PropTypes.bool,
        enableBloomReach: PropTypes.bool,
        enableBloomReachV3PLP: PropTypes.bool,
        context: PropTypes.object,
        headerScroll: PropTypes.string,
        isUserLoggedIn: PropTypes.bool,
    };

    static deviceType = {
        isDesktop: false,
        isMobile: true,
        isTablet: false,
    };

    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            datasourceType: config.data.dataType.recentSearchTerm,
            cacheData: [],
            isFocused: false,
            isIgnoreBlur: false,
            header: null,
            retainSearchStatus: 0, // 0: search text not mounted, 1: search text mounted, 2: search triggered on focus
            placeholder: searchPlaceholder,
        };
        this.headerOverlay = null;
    }

    componentDidMount() {
        const seachTerm = this.getUserSearchText();
        if (
            seachTerm.length >= this.props.minSearchInputLength &&
            this.state.retainSearchStatus === 0
        ) {
            // eslint-disable-next-line
            this.setState({
                searchText: seachTerm,
                retainSearchStatus: 1,
            });
        }
        /* istanbul ignore next */
        if (this.props.deviceType.isMobile) {
            const contentContainers = document.getElementsByClassName('contentContainer');
            if (
                contentContainers &&
                contentContainers[0] &&
                contentContainers[0].addEventListener
            ) {
                contentContainers[0].addEventListener('click', this.disableBodyBlur);
            }
        }
        this.headerOverlay = document.getElementById('headerOverlay');
        if (this.headerOverlay) {
            this.headerOverlay.addEventListener('click', this.handleHeaderOverlayClick);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { posDeviceStatus } = nextProps;
        /* istanbul ignore next */
        if (
            posDeviceStatus &&
            posDeviceStatus.scannedHeaderSearchTerm &&
            posDeviceStatus !== this.props.posDeviceStatus
        ) {
            this.setState({ searchText: posDeviceStatus.scannedHeaderSearchTerm });
            this.onSubmit({ term: posDeviceStatus.scannedHeaderSearchTerm });
        }
    }

    componentWillUnmount() {
        /* istanbul ignore next */
        if (this.props.deviceType.isMobile) {
            const contentContainers = document.getElementsByClassName('contentContainer');
            if (
                contentContainers &&
                contentContainers[0] &&
                contentContainers[0].removeEventListener
            ) {
                contentContainers[0].removeEventListener('click', this.disableBodyBlur);
            }
        }
        if (this.headerOverlay) {
            this.headerOverlay.removeEventListener('click', this.handleHeaderOverlayClick);
        }
    }

    onFocus = () => {
        this.props.actions.productSearchFocusAction('makeWhite');
        const widthBefore = document.body.offsetWidth;
        const root = document.getElementsByTagName('html')[0];
        if (!this.props.isNoResultsProductSearch) {
            /* IE11 only supports one class addition/removal at a time */
            root.classList.add('blockContentScroll');
            root.classList.add('scrollBlock');
            root.classList.add('searchScrollBlock');
            this.showHeaderOverlay();
        }
        root.style.marginRight = `${document.body.offsetWidth - widthBefore}px`;
        const { retainSearchStatus, searchText } = this.state;

        const { isDesktop } = this.props.deviceType;
        const isFireFox = this.getFireFoxVersion();
        /* istanbul ignore next */
        if (isDesktop && isFireFox && !this.props.isNoResultsProductSearch) {
            document && document.body.classList.add('mozLockScroll');
        }

        /* istanbul ignore else */
        if (!this.props.typeaheadEnabled) {
            this.props.actions.showLazy(typeaheadEnable);
        }

        /* istanbul ignore else */
        if (searchText.length === 0) {
            this.resetCurrentSearchResults(config.data.dataType.recentSearchTerm);
        }
        if (retainSearchStatus === 1) {
            this.onChange(searchText);
            this.setState({ retainSearchStatus: 2 });
        }

        this.setState({ isFocused: true, placeholder: searchPlaceholder });
    };

    onBlur = () => {
        const isiOSDevice =
            window.navigator.platform === 'iPhone' || window.navigator.platform === 'iPad';
        if (isiOSDevice && !this.props.deviceType.isDesktop) {
            return;
        }
        this.props.actions.productSearchFocusAction('');
        this.removeContentScrollClasses();
        this.removeHeaderOverlay();
        if (!this.state.isIgnoreBlur) {
            this.setState({ isFocused: false, placeholder: searchPlaceholder });
        }
    };

    onClearCache = () => {
        RecentSearch.clearData();
        this.setState({ cacheData: [], isFocused: false });
        this.removeContentScrollClasses();
    };

    onChange = (value) => {
        const { predictSearch, recentSearchTerm } = config.data.dataType;
        const Product = 'products';
        const TypeAhead = 'TypeAhead';
        const {
            minSearchInputLength,
            deviceType,
            abTestEngagements,
            autoSuggestVersion,
            isNative,
            enableBloomReach,
            enableBloomReachV3PLP = true,
            context,
        } = this.props;

        if (value.length >= minSearchInputLength) {
            let params = {};

            // passing user search text in q
            params.q = value;
            params.channel = fetchChannelInfo(deviceType);
            params.v = autoSuggestVersion;

            // passing search query param based on device Type
            if (deviceType.isDesktop) {
                params.responseGroup = Product;
            }
            // fetching userSegment key if testType is 'TypeAhead'
            const abTestSegment = _find(abTestEngagements, (obj) => obj.testType === TypeAhead);
            /* istanbul ignore else */
            if (abTestSegment) {
                const userSegInfo = abTestSegment.userSegments.split('=');
                /* istanbul ignore else */
                if (userSegInfo.length > 1) {
                    params[userSegInfo[0]] = userSegInfo[1];
                }
            }

            /* istanbul ignore next */
            const userSegParams = window.getUserSeg ? window.getUserSeg() : {};
            /* istanbul ignore next */
            if (!_isEmpty(userSegParams)) {
                params = { ...params, ...userSegParams };
            }

            if (isBloomReachAudience(enableBloomReach, isNative) || enableBloomReachV3PLP) {
                const brParams = {};
                brParams.catalog_views = context?.isPreview ? 'jcp_en_preview_staging' : 'jcp';
                brParams.request_id = new Date().getTime();
                brParams._br_uid_2 = Cookies.load('_br_uid_2', true);
                brParams.url = `${context?.hostname}${context?.requestUrl}`;
                if (context?.refererUrl?.split('?')?.[0]) {
                    brParams.ref_url = `${context?.refererUrl?.split('?')?.[0]}`;
                }
                brParams.q = value;
                brParams.request_type = 'suggest';

                this.props.actions.getBRProductSearchAction(brParams, '/api/v2');
            } else {
                this.props.actions.getProductSearchAction(params, this.props.regionZone);
            }

            this.setState({
                header: null,
                datasourceType: predictSearch,
            });
        } else if (value.length === 0) {
            this.resetCurrentSearchResults(recentSearchTerm);
        } else {
            const root = document.getElementsByTagName('html')[0];
            root.classList.add('blockContentScroll');
            this.setState({
                cacheData: null,
                header: null,
                datasourceType: recentSearchTerm,
            });
        }

        this.setState({
            searchText: value,
            isFocused: true,
        });
    };

    onMouseDown = () => {
        this.setState({ isIgnoreBlur: true });
    };

    onMouseUp = () => {
        this.setState({ isIgnoreBlur: false });
    };

    onKeyDown = (value) => {
        this.setState({
            searchText: value,
        });
    };

    onReset = () => {
        this.setState({ searchText: '', isFocused: false });
        this.resetCurrentSearchResults(config.data.dataType.recentSearchTerm);
        if (
            this.props.deviceType.isDesktop ||
            this.props.deviceType.isTablet ||
            this.props.deviceType.isMobile
        ) {
            return;
        }
        this.props.actions.productSearchFocusAction('');
        /* istanbul ignore else */
        this.removeContentScrollClasses();
        this.removeHeaderOverlay();
    };

    // predictive search
    onSelect = (searchResult) => {
        if (this.props.deviceType.isMobile && this.props.searchSelectAnimationRemove) {
            this.props.searchSelectAnimationRemove();
        }
        if (this.props.featureFlags.disableNewAutoSuggestion) {
            const searchText = searchResult.term;
            let searchTextWithDept = searchText;
            let type = 'keyword';
            /* istanbul ignore next */
            if (searchResult.dept) {
                searchTextWithDept = `${searchText}${searchResult.dept}`;
                searchResult.term = searchTextWithDept;
                searchResult.dept = '';
                type = 'department';
            }
            /* istanbul ignore else */
            if (this.state.searchText.length >= this.props.minSearchInputLength) {
                const predictiveInfo = encodeURIComponent(
                    `${this.state.searchText}:${searchResult.position}:${searchResult.term}`
                );
                searchResult.predictiveDetail = `${type}:${predictiveInfo}`;
            }
            const { predictSearch, recentSearchTerm } = config.data.dataType;
            /* istanbul ignore next */
            const datasourceType =
                this.state.searchText.length >= this.props.minSearchInputLength
                    ? predictSearch
                    : recentSearchTerm;
            this.setState(
                {
                    datasourceType,
                },
                () => {
                    this.navigateToSearch(searchResult);
                }
            );
        } else {
            this.setState(
                {
                    datasourceType: '',
                },
                () => {
                    this.navigateToSearch(searchResult);
                }
            );
        }
    };

    // Search textbox enter key event
    onSubmit = (searchResult) => {
        this.setState(
            {
                datasourceType: '',
                isFocused: false,
            },
            () => {
                if (this.props.deviceType.isMobile && this.props.searchSelectAnimationRemove) {
                    this.props.searchSelectAnimationRemove();
                }
                this.navigateToSearch(searchResult);
            }
        );
    };

    /** Fix for only Firefox 71 and above version */
    getFireFoxVersion = () => {
        const checkFFVersion = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
        /* istanbul ignore next */
        return checkFFVersion && parseInt(checkFFVersion[1], 10) >= 71;
    };

    getLocationSearchParams = () => {
        const { isFragment, location } = this.props;
        if (!isFragment) {
            return window.location.search;
        }
        return location.search;
    };

    /**
     * util function to read URL params
     * @param {string} key - query parameter name
     * @param {http url} url (optional) - soruce of url to read params
     */
    getURLParameterBykey = (key, url = this.getLocationSearchParams()) => {
        // TODO - this will moved to Util function later
        let name = key;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        /* istanbul ignore else */
        if (!results) return null;
        /* istanbul ignore next */
        if (!results[2]) return '';
        // While passing to search page, blank space are replaced by +
        // So when we get the value, + is replaced by blankspace
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    getUserSearchText = () => {
        // 'Ntt' will get on following navigation home >> search
        let userSearchText = this.getURLParameterBykey('Ntt');
        // 'redirectTerm' will get on following navigation home >> department
        /* istanbul ignore else */
        if (!userSearchText) {
            userSearchText = this.getURLParameterBykey('redirectTerm');
        }
        // 'searchTerm' will get on following navigation home >> search >> pdp
        /* istanbul ignore else */
        if (!userSearchText) {
            userSearchText = this.getURLParameterBykey('searchTerm');
        }
        return userSearchText || '';
    };

    removeContentScrollClasses = () => {
        /* istanbul ignore else */
        if (document && !this.props.isNoResultsProductSearch) {
            const root = document.getElementsByTagName('html')[0];
            /* IE11 only supports one class addition/removal at a time */
            root.classList.remove('blockContentScroll');
            root.classList.remove('scrollBlock');
            root.classList.remove('searchScrollBlock');
            root.style.marginRight = '';
            document.body.classList.remove('mozLockScroll');
        }
    };

    /* istanbul ignore next */
    showHeaderOverlay = () => {
        if (this.headerOverlay) this.headerOverlay.style.display = 'block';
    };

    removeHeaderOverlay = () => {
        if (this.headerOverlay) this.headerOverlay.style.display = 'none';
    };

    /* istanbul ignore next */
    handleHeaderOverlayClick = () => {
        this.props.actions.productSearchFocusAction('');
        this.removeContentScrollClasses();
        this.removeHeaderOverlay();
        if (!this.state.isIgnoreBlur) {
            this.setState({ isFocused: false, placeholder: searchPlaceholder });
        }
    };

    /* istanbul ignore next */
    disableBodyBlur = () => {
        this.props.actions.productSearchFocusAction('');
    };

    // reseting current search results
    resetCurrentSearchResults = (recentSearchTerm) => {
        this.setState({
            cacheData: RecentSearch.getRecentData(),
            header: this.renderCachedSuggestionBoxHeader(),
            datasourceType: recentSearchTerm,
        });
        const { productSearchResults } = this.props;
        if (productSearchResults && productSearchResults.length > 0) {
            this.props.actions.resetProductSearchResults();
        }
    };

    navigateToSearch = (searchResult) => {
        const nav = {
            term: searchResult.term,
            dept: searchResult.dept,
            link: searchResult.link,
        };

        const { abTestEngagements } = this.props;

        /* istanbul ignore else */
        if (searchResult.predictiveDetail) {
            nav.predictiveDetail = searchResult.predictiveDetail;
        }

        /* istanbul ignore else */
        if (abTestEngagements) {
            const abTestSegment = _find(
                this.props.abTestEngagements,
                (obj) => obj.testType && obj.testType.toLowerCase() === 'organicsearch'
            );
            if (abTestSegment) {
                nav.testVersion = abTestSegment.abTest;
            }
        }
        navigateTo(nav, this.state.datasourceType, this.props.linkPrefix);
    };

    renderCachedSuggestionBoxHeader() {
        return (
            <li>
                {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                <div
                    className={styles.productSearchTermBoxHeader}
                    onClick={this.handleHeaderOverlayClick}
                >
                    <div className={styles.productTermWrapper}>Recent Searches</div>
                    <div className={styles.productSearchTermButtonWrapper}>
                        <Button
                            type="button"
                            className={styles.typeaheadTermBoxButton}
                            size="Sm"
                            buttonType="Link"
                            onClick={this.onClearCache}
                        >
                            Clear History
                        </Button>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const {
            automationId,
            maxItemsLength,
            productSearchResults,
            deviceType,
            showFeatureResult,
            typeaheadEnabled,
            productDisplayEnabled,
            productSearchFocus,
            productSearchContainerCustomClass,
            productSearchInputCustomClass,
            productSearchIconCustomClass,
            productResetIconCustomClass,
            featureFlags,
            headerScroll,
            isUserLoggedIn,
        } = this.props;
        const {
            datasourceType,
            cacheData,
            header,
            isFocused,
            searchText,
            placeholder,
        } = this.state;
        const type = datasourceType === config.data.dataType.recentSearchTerm;
        const datasource = type ? cacheData : productSearchResults;
        const suggestionData = prepareData(datasource, maxItemsLength);
        const typeaheadInputClass =
            searchText.length === 0 ? 'typeaheadInputDisabled' : 'typeaheadInputEnabled';
        return (
            <div
                data-automation-id={automationId}
                data-automation-container="search-block"
                className={cx('productSearchContainer', 'newHeader')}
            >
                <div className={styles.productSearchInputWrapper}>
                    <TypeaheadInput
                        datasource={datasource}
                        data={suggestionData}
                        header={header}
                        showFeatureResult={showFeatureResult}
                        type="search"
                        isFocused={isFocused}
                        placeholder={placeholder}
                        inputText={searchText}
                        deviceType={deviceType}
                        onChange={this.onChange}
                        onSelect={this.onSelect}
                        onSubmit={this.onSubmit}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onReset={this.onReset}
                        onKeyDown={this.onKeyDown}
                        onMouseDown={this.onMouseDown}
                        onMouseUp={this.onMouseUp}
                        automationId="search-text-box"
                        disableAutoComplete
                        presetText={searchText}
                        typeaheadInputClass={typeaheadInputClass}
                        typeaheadEnabled={typeaheadEnabled}
                        productDisplayEnabled={productDisplayEnabled}
                        productSearchFocus={productSearchFocus}
                        typeaheadContainerCustomClass={productSearchContainerCustomClass}
                        typeaheadSearchInputCustomClass={productSearchInputCustomClass}
                        typeaheadSearchIconCustomClass={productSearchIconCustomClass}
                        typeaheadResetIconCustomClass={productResetIconCustomClass}
                        enableHeaderSigninUpdate={featureFlags.enableHeaderSigninUpdate}
                        headerScroll={headerScroll}
                        isUserLoggedIn={isUserLoggedIn}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    productSearchResults: state.productSearchResults,
    productSearchDetailResult: state.productSearchDetailResult,
    regionZone: state.regionalPricing ? state.regionalPricing.regionZone : ProductSearch.regionZone,
    deviceType: state.context ? state.context.deviceType : ProductSearch.deviceType,
    abTestEngagements: state.context
        ? state.context.abTestEngagements
        : ProductSearch.abTestEngagements,
    isFragment: selectIsFragment(state),
    posDeviceStatus: state.posDeviceStatus,
    typeaheadEnabled: selectLazy(state)[typeaheadEnable],
    productDisplayEnabled: !_get(state.context, 'featureFlags.disableSearchProductdisplay', false),
    linkPrefix:
        _get(state.context, 'featureFlags.enableLinkPrefix', ProductSearch.linkPrefix) &&
        _get(state.context, 'preferences.linkPrefix', ProductSearch.linkPrefix),
    autoSuggestVersion: _get(state.context, 'preferences.autoSuggestVersion', 2), // Sending this param to get SEO V2 version always - MNPYGD-2890
    featureFlags: selectFeatureFlags(state),
    productSearchFocus: state.productSearchFocus,
    isNative: selectIsNative(state),
    enableBloomReach: _get(state.context?.featureFlags, 'enableBloomReach', false),
    enableBloomReachV3PLP: _get(state.context?.featureFlags, 'enableBloomReachV3PLP', true),
    context: state.context,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...actions,
            showLazy,
            productSearchFocusAction,
        },
        dispatch
    ),
});

export default compose(
    withReducerSaga([ProductSearchReducer], [ProductSearchSaga]),
    connect(mapStateToProps, mapDispatchToProps)
)(ProductSearch);
