import {
    SHOW_SHOP_DEPARTMENT,
    HIDE_SHOP_DEPARTMENT,
} from '../actionTypes/ShowShopDepartmentActionTypes';

const showShopDepartment = () => ({
    type: SHOW_SHOP_DEPARTMENT,
});

const hideShopDepartment = () => ({
    type: HIDE_SHOP_DEPARTMENT,
});

export default {
    showShopDepartment,
    hideShopDepartment,
};
