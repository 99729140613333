import { createSelector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { templateTypes } from './selectProductType.config';
import { selectProductDetailsBySource } from '../PdpNameSpaceSelector';

const isProductBeauty = (productDetails) => {
    const productLotDefault = find(productDetails.lots, { isDefault: true });
    return (
        (productLotDefault &&
            productLotDefault.tags &&
            productLotDefault.tags.indexOf('beauty') !== -1) ||
        false
    );
};

const isProductSilver = (productDetails) =>
    !!productDetails.type && productDetails.type.toLowerCase() === 'largeappliance';

const isProductBundleSelector = (productDetails) =>
    !!productDetails.type && productDetails.type.toLowerCase() === 'bundle';

export const isProductBundle = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (productDetails) =>
        isProductBundleSelector(productDetails)
    )
);

const isCollection = (productDetails) =>
    productDetails.collection && productDetails.collection.length;

const getProductTemplateType = (productDetails) => {
    let type = templateTypes.REGULAR;
    const defaultProductLot = filter(productDetails.lots, { isDefault: true });
    const customizationModel =
        defaultProductLot && defaultProductLot[0] && defaultProductLot[0].customizationModel
            ? defaultProductLot[0].customizationModel
            : {};
    if (customizationModel.type) {
        type =
            customizationModel.type === 'MadeToMeasure'
                ? templateTypes.MADETOMEASURE
                : templateTypes.VDATA;
    }
    return type;
};

const selectProductType = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (productDetails) => {
        let type = '';
        if (Object.keys(productDetails).length > 0 && productDetails.id) {
            /* istanbul ignore next */
            if (isProductSilver(productDetails)) {
                type = templateTypes.SILVER;
            } else if (isProductBundleSelector(productDetails)) {
                type = templateTypes.BUNDLE;
            } else if (isProductBeauty(productDetails)) {
                type = templateTypes.BEAUTY;
            } else {
                type = getProductTemplateType(productDetails);
            }
        }
        return {
            templateType: type,
            isCollection: isCollection(productDetails),
        };
    })
);

export const getFirstProductSelector = (productDetails) =>
    get(productDetails, 'products[0].product', '');

export const getFirstProduct = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (productDetails) =>
        getFirstProductSelector(productDetails)
    )
);

export const isApplianceBundle = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (productDetails) => {
        const productBundle = isProductBundleSelector(productDetails);
        const firstProduct = getFirstProductSelector(productDetails);
        return productBundle && get(firstProduct, 'type') === 'largeAppliance';
    })
);

export default selectProductType;
