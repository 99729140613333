import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import get from 'lodash/get';

const defaults = {};

/* productDetails start */
// used by other selectors
export const selectProductDetailsBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpProductDetails[0]', defaults);
    }
    return state.productDetails || defaults;
};

// used by directly accessing
export const selectProductDetailsWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (result) => result)
);
/* productDetails end */

/* productOptions start */
// used by other selectors
export const selectProductOptionsBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpProductOptions[0]', defaults);
    }
    return state.productOptions || defaults;
};

// used by directly accessing
export const selectProductOptionsWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectProductOptionsBySource(sourceMiniPDP)], (result) => result)
);
/* productOptions end */

/* colorSwatched start */
// used by other selectors
export const selectColorSwatchesBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpcolorSwatches[0]', defaults);
    }
    return state.colorSwatches || defaults;
};

// used by directly accessing
export const selectColorSwatchesWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectColorSwatchesBySource(sourceMiniPDP)], (result) => result)
);
/* colorSwatched end */

/* rebateInfo start */
// used by other selectors
export const selectRebateInfoBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniRebateInfo[0]', defaults);
    }
    return state.rebateInfo || defaults;
};

// used by directly accessing
export const selectRebateInfoWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectRebateInfoBySource(sourceMiniPDP)], (result) => result)
);
/* rebateInfo end */

/* inventory start */
// used by other selectors
export const selectInventoryBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpInventory[0]', defaults);
    }
    return state.inventory || defaults;
};

// used by directly accessing
export const selectInventoryWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectInventoryBySource(sourceMiniPDP)], (result) => result)
);
/* inventory end */

/* selectedProtectionPlan start */
// used by other selectors
export const selectedProtectionPlanSource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpSelectedProtectionPlan[0]', defaults);
    }
    return state.selectedProtectionPlan || defaults;
};

// used by directly accessing
export const selectedProtectionPlanWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectedProtectionPlanSource(sourceMiniPDP)], (result) => result)
);
/* state.selectedProtectionPlan end */

/* productOffering start */
// used by other selectors
export const selectProductOfferingBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpProductOffering[0]', defaults);
    }
    return state.productOffering || defaults;
};

// used by directly accessing
export const selectProductOfferingWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectProductOfferingBySource(sourceMiniPDP)], (result) => result)
);
/* productOffering end */

/* selectedQuantity start */
// used by other selectors
export const selectSelectedQuantityBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpSelectedQuantity[0]', 1);
    }
    return state.selectedQuantity || 1;
};

// used by directly accessing
export const selectSelectedQuantityWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectSelectedQuantityBySource(sourceMiniPDP)], (result) => result)
);
/* selectedQuantity end */

/* messages start */
// used by other selectors
export const selectMessagesBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpMessages[0]', defaults);
    }
    return state.messages || defaults;
};

// used by directly accessing
export const selectMessagesWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectMessagesBySource(sourceMiniPDP)], (result) => result)
);
/* messages end */

/* myalerts start */
// used by other selectors
export const selectMyAlertsBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpMyAlerts[0]', defaults);
    }
    return state.myAlerts || defaults;
};

// used by directly accessing
export const selectMyAlertsWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectMyAlertsBySource(sourceMiniPDP)], (result) => result)
);
/* myalerts end */

/* myalertsModalSlot start */
// used by other selectors
export const selectMyAlertsModalSlotBySource = (sourceMiniPDP) => (state) => {
    if (sourceMiniPDP) {
        return get(state, 'miniPdpmyAlertsModalSlot[0]', defaults);
    }
    return state.myAlertsModalSlot || defaults;
};

// used by directly accessing
export const selectMyAlertsModalSlotWrapper = memoize((sourceMiniPDP) =>
    createSelector([selectMyAlertsModalSlotBySource(sourceMiniPDP)], (result) => result)
);
/* myalerts end */
