import { put, takeLatest, call, all } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import { getPromotionalBannerDataForAllPages } from 'yoda-interfaces/lib/PromotionalBanner/PromotionalBannerApi';
import * as PromotionalBannerActionTypes from '../actionTypes/PromotionalBannerActionTypes';
import { addApiResponseErrorToStore, clearErrorMsgs } from '../actions/ApiErrorAction';
import CustomErrorLogger from '../helpers/CustomErrorLogger/CustomErrorLogger';
import getPageType from '../helpers/Utils/pageType';

function* PromotionalBannerSaga(params) {
    let pageType = 'home';

    try {
        const url = window.location.href;

        const getURLParameterBykey = (key, getUrl, removeSplChars) => {
            // TODO - this will moved to Util function later
            let name = key;
            name = name.replace(/[[\]]/g, '\\$&');
            const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
            let results = regex.exec(url);
            if (!results) return '';
            if (!results[2]) return '';
            results = decodeURIComponent(results[2]);
            return (
                (removeSplChars ? results.replace(/['+\s]/g, '') : results.replace(/\+/g, ' ')) ||
                ''
            );
        };

        const getppId = (getUrl) => {
            const regExp = /[?](.*?)[/]/;
            const reverseURL = getUrl.split('').reverse().join('');
            const matches = regExp.exec(reverseURL);
            const ppId = matches[1].split('').reverse().join('');
            return ppId || '';
        };
        const getNidByKey = (key, getUrl) => {
            const extract =
                getUrl.match(/N-(.*)[?]/) || getUrl.match(/N-(.*)[/]/) || getUrl.match(/N-(.*)/);
            const nId = extract && extract.length > 0 ? extract[1] : '';
            return nId ? `N-${nId}` : '';
        };
        const ntypeId = () => getNidByKey('N-', url);

        const paramsSearch = () => ({
            Ntt: getURLParameterBykey('Ntt', url),
        });

        const paramsPDP = () => ({
            ppId: getppId(url),
            brand: getURLParameterBykey('brand', url),
            pTmplType: getURLParameterBykey('pTmplType', url),
        });

        let promotionalBannerData = 0;
        pageType = getPageType();
        switch (pageType) {
            case 'departments': {
                promotionalBannerData = yield call(
                    getPromotionalBannerDataForAllPages,
                    ntypeId(),
                    ''
                );
                break;
            }
            case 'search': {
                promotionalBannerData = yield call(
                    getPromotionalBannerDataForAllPages,
                    ntypeId(),
                    paramsSearch()
                );
                break;
            }
            case 'pdp': {
                promotionalBannerData = yield call(
                    getPromotionalBannerDataForAllPages,
                    '',
                    paramsPDP()
                );
                break;
            }
            default: {
                promotionalBannerData = yield call(
                    getPromotionalBannerDataForAllPages,
                    ntypeId(),
                    ''
                );
            }
        }

        if (promotionalBannerData.status < 200 || promotionalBannerData.status >= 300) {
            yield put(
                addApiResponseErrorToStore(
                    PromotionalBannerActionTypes.PROMOTIONALBANNERDATA_GET_ERROR
                )
            );
        } else {
            yield all([
                put(clearErrorMsgs(PromotionalBannerActionTypes.PROMOTIONALBANNERDATA_GET_ERROR)),
                put({
                    type: PromotionalBannerActionTypes.PROMOTIONALBANNERDATA_GET_SUCCESS,
                    promotionalBannerData,
                    params,
                }),
            ]);
        }
        if (_isEmpty(promotionalBannerData.data)) {
            const errorDetails = {
                errorType: 'sitepromo API failure',
                errorDescription: 'site promotions are not available',
                pageType,
            };
            yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
        }
    } catch (error) {
        const errorDetails = {
            errorType: error.errorCode || 'sitepromo API failure',
            errorDescription: error.errorMessage || 'site promotions are not available',
            pageType,
        };
        yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
        yield put({ type: 'PROMOTIONALBANNERDATA_GET_ERROR', error });
    }
}

const watchPromotionalBannerActionRequest = function* watchPromotionalBannerActionRequest() {
    yield takeLatest(
        PromotionalBannerActionTypes.PROMOTIONALBANNERDATA_GET_REQUEST,
        PromotionalBannerSaga
    );
};
watchPromotionalBannerActionRequest.sagaName = 'watchPromotionalBannerActionRequest';

export default watchPromotionalBannerActionRequest;
