export const GET_SITE_LIST = 'GET_SITE_LIST';
export const GET_SITE_LIST_SUCCESS = 'GET_SITE_LIST_SUCCESS';
export const GET_SITE_LIST_ERROR = 'GET_SITE_LIST_ERROR';
export const GET_MYLIST = 'GET_LIST';
export const CREATE_SITE_NEW_LIST = 'CREATE_SITE_NEW_LIST';
export const CREATENEW_SITE_LIST_SUCCESS = 'CREATENEW_SITE_LIST_SUCCESS';
export const CREATENEW_SITE_LIST_ERROR = 'CREATENEW_SITE_LIST_ERROR';
export const MOVETO_SITE_NEW_LIST = 'MOVETO_SITE_NEW_LIST';
export const MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS = 'MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS';
export const MOVETO_SITE_NEW_LIST_ERROR = 'MOVETO_SITE_NEW_LIST_ERROR';
export const MOVE_SITE_ITEMS_EVENT_SUCCESS = 'MOVE_SITE_ITEMS_EVENT_SUCCESS';
export const ADD_SITE_ITEM_TO_LIST = 'ADD_SITE_ITEM_TO_LIST';
export const ADD_SITE_ITEM_TO_LIST_SUCCESS = 'ADD_SITE_ITEM_TO_LIST_SUCCESS';
export const ADD_SITE_ITEM_TO_LIST_ERROR = 'ADD_SITE_ITEM_TO_LIST_ERROR';
export const CREATE_SITE_LIST_CLICK_EVENT = 'CREATE_SITE_LIST_CLICK_EVENT';
export const ADD_SITE_TO_LIST_EVENT = 'ADD_SITE_TO_LIST_EVENT';
export const ADD_SITE_TO_LIST_ERROR_EVENT = 'ADD_SITE_TO_LIST_ERROR_EVENT';
export const MOVE_SITE_ITEMS_EVENT_ANALYTICS = 'MOVE_SITE_ITEMS_EVENT_ANALYTICS';
export const SITE_MOVETOLIST_ERROR_EVENT = 'SITE_MOVETOLIST_ERROR_EVENT';
export const SITE_GETLIST_BASEDON_ID_SUCCESS = 'SITE_GETLIST_BASEDON_ID_SUCCESS';
export const SITE_GETLIST_BASEDON_ID_ERROR = 'SITE_GETLIST_BASEDON_ID_ERROR';
export const SITE_GETLIST_BASEDON_ID = 'SITE_GETLIST_BASEDON_ID';
export const GET_SITE_LIST_GUEST = 'GET_SITE_LIST_GUEST';
export const CLEAR_LIST_ERROR = 'CLEAR_LIST_ERROR';
