import { DISPLAY_HAMBURGER_SLIDER } from '../actionTypes/HamburgerActionTypes';

const HamburgerSliderReducer = function HamburgerSliderReducer(
    state = { isHamburgerSliderOpen: false },
    action
) {
    switch (action.type) {
        case DISPLAY_HAMBURGER_SLIDER:
            return { ...state, isHamburgerSliderOpen: action.payload };
        default:
            return state;
    }
};

HamburgerSliderReducer.reducerName = 'HamburgerSlider';

export default HamburgerSliderReducer;
