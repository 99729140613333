import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getCouponList } from 'yoda-interfaces/lib/CouponIRIS/CouponApi';
import get from 'lodash/get';

import {
    COUPON_LIST_GET_SUCCESS,
    COUPON_LIST_GET_REQUEST,
} from '../actionTypes/CouponListActionTypes';
import {
    selectFeatureFlags,
    selectPreferences,
    selectDeviceType,
} from '../selectors/ContextSelector';

function* CouponListSaga() {
    const preferences = yield select(selectPreferences);
    const url = get(preferences, 'couponDrawerDetails.couponListUrl', '/content-api/coupon-tiles');
    const couponListTypes = get(preferences, 'couponDrawerDetails.couponListTypes', [
        'online',
        'all',
        'instore',
    ]);
    const enableCouponSplitBarcodes = get(selectFeatureFlags, 'enableCouponSplitBarcodes', false);
    const deviceType = yield select(selectDeviceType);

    // For channel specific coupons
    let channel = 'desktop';
    if (deviceType.isDesktop) {
        channel = 'desktop';
    } else if (deviceType.isMobile) {
        channel = 'mobile';
    } else if (deviceType.isTablet) {
        channel = 'tablet';
    }

    try {
        const couponList = yield call(getCouponList, {
            url,
            channel,
        });
        if (!couponList || !couponList.length) {
            return;
        }
        const formattedCouponList = [];
        const couponListObj = {}; // temp obj
        // format data
        couponList.forEach((coupon) => {
            const { redeemingChannel } = coupon.content;
            if (!couponListObj[redeemingChannel]) {
                couponListObj[redeemingChannel] = [];
            }

            const { offers } = coupon.content;
            const offersWithBarCode = offers ? offers.filter((offer) => offer.barCode) : [];
            if (enableCouponSplitBarcodes && offers && offersWithBarCode.length > 1) {
                // split coupon into multiple when there're multiple barCodes(offers)
                offers.forEach((offer) => {
                    const splitCoupon = {
                        ...coupon,
                        content: { ...coupon.content, offers: [offer] },
                    };
                    couponListObj[redeemingChannel].push(splitCoupon);
                });
            } else {
                couponListObj[redeemingChannel].push(coupon);
            }
        });
        // put together data using the configured channel order
        couponListTypes.forEach((redeemingChannel) => {
            if (couponListObj[redeemingChannel]) {
                formattedCouponList.push(...couponListObj[redeemingChannel]);
            }
        });

        yield put({ type: COUPON_LIST_GET_SUCCESS, couponList: formattedCouponList });
    } catch (error) {
        yield put({ type: 'CONTENT_ERROR', error });
    }
}

const watchCouponListSaga = function* watchCouponListSaga() {
    yield takeLatest(COUPON_LIST_GET_REQUEST, CouponListSaga);
};

watchCouponListSaga.sagaName = 'CouponListSaga';

export default watchCouponListSaga;
