import { HIDE_PREVIEW_ZONE, SHOW_PREVIEW_ZONE } from '../actionTypes/ShowPreviewZoneActionTypes';

function ShowZoneReducer(state = false, action) {
    switch (action.type) {
        case SHOW_PREVIEW_ZONE:
            return true;
        case HIDE_PREVIEW_ZONE:
            return false;
        default:
            return state;
    }
}

ShowZoneReducer.reducerName = 'showPreviewZone';

export default ShowZoneReducer;
