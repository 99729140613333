import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'CAMNavLazy',
        path: () => path.join(__dirname, './CAMNav'),
        resolve: () => require.resolveWeak('./CAMNav'),
        load: () =>
            Promise.all([import(/* webpackChunkName: "CAMNav" */ './CAMNav')]).then(
                (proms) => proms[0]
            ),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
