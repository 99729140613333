import { CSE_CID, PLA_REC_PAGE_TYPE, PRODUCT } from '../../common/Constants';

export const getRecPageType = (query = {}, utmSourceValue = '', featureFlags) => {
    if (Object.keys(query).length) {
        if (featureFlags.plaRecommendation) {
            const { utm_source } = query;
            const kwid = query.kwid || '';
            const getPLA = kwid ? kwid.split('-').indexOf('adType^PLA') : null;
            if (featureFlags.enablePLARecommendationByCID) {
                const cidCSE =
                    featureFlags.enablePLARecommendationByCID &&
                    query.cid &&
                    query.cid.startsWith(CSE_CID);
                return cidCSE ? PLA_REC_PAGE_TYPE : PRODUCT;
            }
            if (utm_source === utmSourceValue || (getPLA && getPLA !== -1)) {
                return PLA_REC_PAGE_TYPE;
            }
            return PRODUCT;
        }
    }
};

export const isPLAPage = (query = {}, utlSourceValue = '', featureFlags) => {
    const pageType = getRecPageType(query, utlSourceValue, featureFlags);
    return pageType === PLA_REC_PAGE_TYPE;
};
