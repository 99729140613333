import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { selectProductDetailsBySource } from './PdpNameSpaceSelector';

const selectDefaultLot = (productDetails) => find(productDetails.lots, { isDefault: true }) || {};
const defaultImages = [];

const selectProductDetails = (productDetails) => productDetails;

export const selectProductTitle = createSelector(
    [selectProductDetailsBySource(false)],
    (productDetails) => productDetails.name
);

export const isDeliveryOptionRequired = memoize((sourceMiniPDP) =>
    createSelector([selectProductDetailsBySource(sourceMiniPDP)], (productDetails) => {
        const defaultLot = selectDefaultLot(productDetails);
        return !!defaultLot.deliveryAvailabilityCheckRequired;
    })
);

export const getProductImages = createSelector(
    [selectProductDetails],
    (productDetails) => productDetails.images || defaultImages
);

export const selectProductReviewID = createSelector(
    [selectProductDetailsBySource(false)],
    (productDetails) => get(productDetails, 'valuation.id', productDetails.id)
);

export const getDefaultProductImage = createSelector([getProductImages], (productImages) => {
    /* istanbul ignore else */
    if (productImages.length > 0) {
        return productImages[0];
    }
    return {};
});

export const selectProductID = createSelector(
    [selectProductDetailsBySource(false)],
    (productDetails) => get(productDetails, 'id', '')
);
