import { IRIS_GLOBAL_NAVIGATION_GET_SUCCESS } from '../actionTypes/DepartmentVisualNavigationActionTypes';

const irisGlobalNavigationReducer = function irisGlobalNavigationReducer(state = [], action) {
    switch (action.type) {
        case IRIS_GLOBAL_NAVIGATION_GET_SUCCESS:
            return action.departments;

        default:
            return state;
    }
};

irisGlobalNavigationReducer.reducerName = 'IrisGlobalNavigation';

export default irisGlobalNavigationReducer;
