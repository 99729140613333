import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _map from 'lodash/map';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './LinkFarm.css';

const cx = classNames.bind(styles);

class LinkFarm extends PureComponent {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(category, e) {
        e.preventDefault();

        /* istanbul ignore next */
        const {
            target: { href = '', innerText },
        } = e;
        const softRouteURL = href;

        if (softRouteURL) {
            const { triggerNavigationClick } = this.props;
            const eventConent = `left:${category}:${innerText}`;

            triggerNavigationClick({
                isReload: false,
                linkName: eventConent,
            });

            window.location.href = softRouteURL;
        }
    }

    renderDepartmentHeader = (category, subItems) => {
        const { automationId, categoryLink } = this.props;
        const navSubHeaderBlockStyles = dt(['bg-white', 'sm:p-0', 'lg:py-0', 'lg:px-2', 'lg:m-0']);
        const navSubHeaderStyles = dt([
            'border-b-0',
            'font-open-sans-bold',
            'text-normal',
            'm-0',
            'pb-3',
            'capitalize',
        ]);

        return (
            <div className={`${cx('linkFarmContainer')} ${navSubHeaderBlockStyles}`}>
                {categoryLink ? (
                    <a className={navSubHeaderStyles} href={categoryLink}>
                        {category}
                    </a>
                ) : (
                    <div className={navSubHeaderStyles}>{category}</div>
                )}
                <ul data-automation-id={automationId}>
                    {this.renderSubHeaderItems(subItems, category)}
                </ul>
            </div>
        );
    };

    renderSubHeaderItems = (items, category) =>
        _map(items, (item) => {
            const { imageUrl, name, targetUrl, targetWindow } = item;
            const navSubHeaderItemLinkStyles = dt([
                'inline-block',
                'text-black',
                'font-open-sans-regular',
                'not-italic',
                'text-small',
                'mb-0',
                'hover:underline',
            ]);
            const navSubHeaderListItemStyles = dt(['list-none', 'pb-3', 'leading-4']);
            const imageIconsStyles = dt(['mr-2', 'w-4', 'h-4']);

            return (
                <li className={navSubHeaderListItemStyles} key={name}>
                    {imageUrl && <img src={imageUrl} className={imageIconsStyles} alt="" />}
                    <a
                        className={navSubHeaderItemLinkStyles}
                        data-automation-id="department-navigation-link"
                        data-type={targetWindow}
                        href={targetUrl}
                        onClick={(event) => {
                            this.handleClick(category, event);
                        }}
                    >
                        {name}
                    </a>
                </li>
            );
        });

    render() {
        const { category, items } = this.props;
        return this.renderDepartmentHeader(category, items);
    }
}

/* istanbul ignore next */
LinkFarm.defaultProps = {
    automationId: 'link-farm',
    category: '',
    categoryLink: '',
    triggerNavigationClick: () => {},
};

LinkFarm.propTypes = {
    automationId: PropTypes.string,
    category: PropTypes.string,
    categoryLink: PropTypes.string,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    triggerNavigationClick: PropTypes.func,
};

export default LinkFarm;
