import * as LazyComponentActionTypes from '../actionTypes/LazyActionTypes';

export const showLazy = (identifier) => ({
    type: LazyComponentActionTypes.SHOW_LAZY,
    payload: { identifier },
});

export const hideLazy = (identifier) => ({
    type: LazyComponentActionTypes.HIDE_LAZY,
    payload: { identifier },
});
