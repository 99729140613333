import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import compact from 'lodash/compact';
import CustomErrorLogger from '../../helpers/CustomErrorLogger/CustomErrorLogger';
import { selectFeatureFlags } from '../../selectors/ContextSelector';

const ERROR_TYPE = 'ErrorBoundary';

export class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        errorTemplate: PropTypes.node,
        featureFlags: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        logError: PropTypes.func.isRequired,
    };

    static defaultProps = {
        children: null,
        errorTemplate: null,
        featureFlags: {},
    };

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    /* istanbul ignore next */
    componentDidCatch(error, { componentStack = '' } = {}) {
        const {
            featureFlags: { errorBoundaryConsoleLog, errorBoundaryLogCollection },
        } = this.props;
        const { pageType, pageName } = get(window.digitalData, 'page.pageInfo', {});
        const value = `${error.stack || 'Stacktrace unavailable.'}\n${
            componentStack || 'Unhandled exception'
        }`;

        const details = {
            errorType: ERROR_TYPE,
            errorDescription: value,
            errorData: { componentStack, ...error },
            pageType: compact([pageType, pageName]).join('-') || window.location.pathname,
        };

        if (errorBoundaryLogCollection) {
            this.props.logError(details);
        }

        if (errorBoundaryConsoleLog) {
            console.log(details);
        }
        this.setState({ hasError: true });
        try {
            // eslint-disable-next-line no-underscore-dangle, no-param-reassign
            error._boundaryAlreadyHandled = true;
        } catch (e) {
            console.log('Error while assigning a value to _boundaryAlreadyHandled', e);
        }
    }

    render() {
        /* istanbul ignore next */
        if (this.state.hasError) {
            return <div>{this.props.errorTemplate}</div>;
        }
        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    featureFlags: selectFeatureFlags(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    logError: (err) => {
        const dispatchErr = CustomErrorLogger.triggerCustomErrorLog(err);
        return dispatch(dispatchErr);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
