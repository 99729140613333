import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as styles from './RichText.css';

const cx = classNames.bind(styles);

/**
 * Richtext component
 * default tagName is 'span'
 */

const renderRichText = (text) => ({ __html: text });

const Richtext = ({ text, richTextClass, TagName, automationId }) => (
    <TagName
        className={cx('wrapper', richTextClass)}
        data-automation-id={automationId}
        dangerouslySetInnerHTML={renderRichText(text)}
    />
); /* eslint-disable-line react/no-danger */

Richtext.defaultProps = {
    text: '',
    richTextClass: '',
    TagName: 'span',
    automationId: 'headline',
};

Richtext.propTypes = {
    text: PropTypes.string,
    richTextClass: PropTypes.string,
    TagName: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    automationId: PropTypes.string,
};

export default Richtext;
