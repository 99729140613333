import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';

import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'FooterLazy',
        path: () => path.join(__dirname, './YodaFooter'),
        resolve: () => require.resolveWeak('./YodaFooter'),
        load: () =>
            Promise.all([import(/* webpackChunkName: "FooterLazy" */ './YodaFooter')]).then(
                (proms) => proms[0]
            ),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
