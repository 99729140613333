/* eslint-disable no-unused-vars */
import ReducerSagaRegistryAction from '../actionTypes/ReducerSagaRegistryActionTypes';

export function requiredParam(details) {
    throw new Error(`REDUCER_SAGA_REGISTRY: Required param ${details}`);
}

const ERRORS = {
    name: 'NAME as string',
    reducer: 'REDUCER as func',
    saga: 'SAGA as iterator',
    requestedBy: 'REQUESTED_BY as string',
};

const injectReducer = (reducers) => {
    reducers.forEach(
        ({
            name = requiredParam(ERRORS.name),
            reducer = requiredParam(ERRORS.reducer),
            requestedBy = requiredParam(ERRORS.requestedBy),
        }) => null
    );
    return {
        type: ReducerSagaRegistryAction.INJECT_REDUCER,
        payload: reducers,
    };
};

const rejectReducer = (reducers) => {
    reducers.forEach(
        ({ name = requiredParam(ERRORS.name), requestedBy = requiredParam(ERRORS.requestedBy) }) =>
            null
    );
    return {
        type: ReducerSagaRegistryAction.REJECT_REDUCER,
        payload: reducers,
    };
};

const injectSaga = ({
    name = requiredParam(ERRORS.name),
    saga = requiredParam(ERRORS.saga),
    requestedBy = requiredParam(ERRORS.requestedBy),
}) => ({
    type: ReducerSagaRegistryAction.INJECT_SAGA,
    payload: {
        name,
        saga,
        requestedBy,
    },
});

const rejectSaga = ({
    name = requiredParam(ERRORS.name),
    requestedBy = requiredParam(ERRORS.requestedBy),
}) => ({
    type: ReducerSagaRegistryAction.REJECT_SAGA,
    payload: {
        name,
        requestedBy,
    },
});

export default {
    injectReducer,
    rejectReducer,
    injectSaga,
    rejectSaga,
};
