/**
 * This class would be responsible for loading various list in the system like saved items, recently viewed etc
 */

import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { updateList } from 'yoda-interfaces/lib/MyList/MyList';
import FSaga from './FactorySaga';
import * as ListsActionTypes from '../actionTypes/ListsActionTypes';
import * as UpdateItemActionTypes from '../actionTypes/UpdateItemActionTypes';

function* updateListSaga(action) {
    const updateListResult = yield call(FSaga, updateList, action.payload, undefined, false);
    try {
        const statusCode =
            get(updateListResult, 'status') || get(updateListResult, 'response.status');
        if (updateListResult.isSuccess) {
            yield put({ type: UpdateItemActionTypes.UPDATE_ITEM_SUCCESS, updateListResult });
            yield put({ type: ListsActionTypes.UPDATE_ITEM_LIST_SLIDER });
        } else {
            /** To render Uh-oh broken page */
            const updateListError = {
                isSuccess: false,
                error: {
                    errorCode: get(
                        updateListResult,
                        'response.data.errorCode',
                        'SRV_DOWN_STREAM_API_DOWN_ERROR'
                    ),
                    errorMessage: get(updateListResult, 'response.data.errorMessage', ''),
                    statusCode,
                },
            };
            yield put({ type: UpdateItemActionTypes.UPDATE_ITEM_FAIL, updateListError });
        }
    } catch (error) {
        yield put({ type: UpdateItemActionTypes.UPDATE_ITEM_FAIL, error });
    }
}

function* watchListsRequest() {
    yield takeLatest(UpdateItemActionTypes.UPDATE_ITEM, updateListSaga);
}

watchListsRequest.sagaName = 'watchListsRequest';
export default watchListsRequest;
