import { WEEKLY_AD_DETAILS_SUCCESS } from '../actionTypes/WeeklyAdsActionTypes';

function WeeklyAdsReducer(state = {}, action = {}) {
    switch (action.type) {
        case WEEKLY_AD_DETAILS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

WeeklyAdsReducer.reducerName = 'weeklyAdsMessages';

export default WeeklyAdsReducer;
