import RegionActionTypes from '../actionTypes/RegionActionTypes';

const setRegion = (payload) => ({
    type: RegionActionTypes.JS_SET_REGION,
    payload,
});

export default {
    setRegion,
};
