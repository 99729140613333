import {
    CRITEO_GET_REQUEST,
    CRITEO_CLEAR_DATA,
    CRITEO_SET_ON_LOAD_BEACON_STATUS,
    CRITEO_SET_ON_VIEW_BEACON_STATUS,
    CRITEO_SET_ON_CLICK_BEACON_STATUS,
    CRITEO_SET_ON_BASKET_BEACON_STATUS,
    CRITEO_SET_ON_WISHLIST_BEACON_STATUS,
    CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH,
    CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK,
} from '../actionTypes/CriteoActionTypes';

export const getCriteoData = (payload) => ({
    type: CRITEO_GET_REQUEST,
    payload,
});

export const clearCriteoData = () => ({
    type: CRITEO_CLEAR_DATA,
});

export const setOnLoadBeaconStatus = () => ({
    type: CRITEO_SET_ON_LOAD_BEACON_STATUS,
});

export const setOnViewBeaconStatus = (ppId) => ({
    type: CRITEO_SET_ON_VIEW_BEACON_STATUS,
    data: ppId,
});

export const setOnClickBeaconStatus = (ppId) => ({
    type: CRITEO_SET_ON_CLICK_BEACON_STATUS,
    data: ppId,
});

export const setOnClickBeaconStatusForSwatch = (ppId) => ({
    type: CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH,
    data: ppId,
});

export const setOnClickBeaconStatusForSwatchAltimageClick = (ppId, isAltimagevisited) => ({
    type: CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK,
    data: ppId,
    isAltimagevisited,
});

export const setOnBasketBeaconStatus = (ppId) => ({
    type: CRITEO_SET_ON_BASKET_BEACON_STATUS,
    data: ppId,
});
export const setOnWishListBeaconStatus = (ppId) => ({
    type: CRITEO_SET_ON_WISHLIST_BEACON_STATUS,
    data: ppId,
});
export default getCriteoData;
