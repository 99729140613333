import { LOG_CLIENT_ERROR } from '../actionTypes/ClientLoggerActionTypes';

const logError = (errorType, errorDescription) => ({
    type: LOG_CLIENT_ERROR,
    payload: {
        errorType,
        errorDescription,
    },
});

export default {
    logError,
};
