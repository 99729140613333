import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getDepartmentS1 } from 'yoda-interfaces/lib/IrisPage/IrisPage';
import {
    FETCH_IRIS_S1_TARGETER,
    FETCH_IRIS_S1_TARGETER_SUCCESS,
    FETCH_IRIS_S1_TARGETER_ERROR,
} from '../actionTypes/IRISS1TargeterActionTypes';

function* irisfetchS1Targeter(params) {
    try {
        const response = yield call(getDepartmentS1, params.payload) || {};
        const irisS1TargeterInfo = get(response.data, 'content.components', []);
        yield put({
            type: FETCH_IRIS_S1_TARGETER_SUCCESS,
            irisS1TargeterInfo,
        });
    } catch (error) {
        yield put({ type: FETCH_IRIS_S1_TARGETER_ERROR, error });
    }
}

const watchS1TargeterRequest = function* watchS1TargeterRequest() {
    yield takeLatest(FETCH_IRIS_S1_TARGETER, irisfetchS1Targeter);
};

watchS1TargeterRequest.sagaName = 'watchS1TargeterRequest';

export default watchS1TargeterRequest;
