import { ADJUSTMENTS_GET_SUCCESS } from '../actionTypes/AdjustmentActionTypes';

function AdjustmentReducer(state = [], action) {
    switch (action.type) {
        case ADJUSTMENTS_GET_SUCCESS:
            return action.appliedAdjustmentsResponse;
        default:
            return state;
    }
}

AdjustmentReducer.reducerName = 'appliedAdjustments';
export default AdjustmentReducer;
