import {
    SHOW_INLINE_IMAGE_LOADER,
    REMOVE_INLINE_IMAGE_LOADER,
} from '../actionTypes/InlineImageLoaderActionTypes';

function InlineImageLoaderReducer(state = false, action) {
    switch (action.type) {
        case SHOW_INLINE_IMAGE_LOADER:
            return true;
        case REMOVE_INLINE_IMAGE_LOADER:
            return false;
        default:
            return state;
    }
}

InlineImageLoaderReducer.reducerName = 'isInlineImageLoading';

export default InlineImageLoaderReducer;
