import {
    SHOW_SHOP_DEPARTMENT,
    HIDE_SHOP_DEPARTMENT,
} from '../actionTypes/ShowShopDepartmentActionTypes';

const showShopDepartmentReducer = function showShopDepartmentReducer(state = false, action) {
    switch (action.type) {
        case SHOW_SHOP_DEPARTMENT:
            return true;
        case HIDE_SHOP_DEPARTMENT:
            return false;
        default:
            return state;
    }
};

showShopDepartmentReducer.reducerName = 'showShopDepartment';

export default showShopDepartmentReducer;
