import { SITEWIDE_WIDGET_SELECTION } from '../actionTypes/CMSwitchWidgetActionTypes';
import { CM_FALLBACK_TO_IRIS } from '../actionTypes/CMPageActionTypes';

const initialState = {
    cmSwitchWidgetSelectedValue: '',
    isIrisFallBackEnabled: false,
};

const cmSwitchWidgetReducer = function cmSwitchWidgetReducer(state = initialState, action) {
    switch (action.type) {
        case SITEWIDE_WIDGET_SELECTION:
            return { ...state, cmSwitchWidgetSelectedValue: action.selectedValue };
        case CM_FALLBACK_TO_IRIS:
            return { ...state, isIrisFallBackEnabled: true };
        default:
            return state;
    }
};

cmSwitchWidgetReducer.reducerName = 'cmSwitchWidgetData';

export default cmSwitchWidgetReducer;
