import { takeLatest, put, call, select } from 'redux-saga/effects';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import _isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { getFooterCategory } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import {
    IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS,
    IRIS_GLOBAL_FOOTER_NAVIGATION_GET_REQUEST,
} from '../actionTypes/FooterNavigationActionTypes';
import { selectPreferences, selectFeatureFlags } from '../selectors/ContextSelector';

const fetchFooterLinks = (data) => {
    const linkSection = get(data[0], 'content.items', []);

    const newState = [];
    _forEach(linkSection, (value, index) => {
        const { linkText: text = '' } = value.content;
        const footerLink = [];
        const footerLinkitems = get(value, 'content.items', []);
        _forEach(footerLinkitems, (value, index) => {
            const {
                linkText: text = '',
                analyticsTag = '',
                openInNewTab = false,
                link: { url: linkPath = '' } = {},
            } = value;
            footerLink.push({
                text,
                analyticsTag,
                key: index + 1,
                openInNewTab,
                linkPath: removeParamFromUrl(linkPath, 'cm_re'), // Removing cm_re params which IRIS is sending by default
            });
        });
        newState.push({
            text,
            key: index + 1,
            data: footerLink,
        });
    });

    return newState;
};

const removeParamFromUrl = (url, param) => {
    const [path, queryParams] = url.split('?');
    let preservedQueryParams = '';

    if (queryParams) {
        preservedQueryParams = queryParams
            .split('&')
            .filter((queryParam) => !(queryParam === param || queryParam.startsWith(`${param}=`)))
            .join('&');
    }

    return `${path}${preservedQueryParams && `?${preservedQueryParams}`}`;
};

function* IrisGlobalFooterNavigationSaga() {
    try {
        const featureFlags = yield select(selectFeatureFlags);
        const preferences = yield select(selectPreferences);
        let footerLinkInfo;
        const cachedData = JSON.parse(LocalStorage.getData('irisfooterCategories', true));
        footerLinkInfo = cachedData || [];
        if (_isEmpty(footerLinkInfo)) {
            const response = yield call(getFooterCategory);
            const data = get(response.data, 'content.components', []);

            if (data.length > 0) {
                footerLinkInfo = fetchFooterLinks(data);
                const isFooterAPILocalStore = get(featureFlags, 'isFooterAPILocalStore', true);
                if (isFooterAPILocalStore) {
                    LocalStorage.setData(
                        'irisfooterCategories',
                        footerLinkInfo,
                        true,
                        '',
                        false,
                        preferences.cacheDepartmentAPI.cacheMins
                    );
                }
                yield put({
                    type: IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS,
                    footerLinkInfo,
                });
            } else {
                yield put({
                    type: IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS,
                    footerLinkInfo,
                });
            }
        } else {
            yield put({
                type: IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS,
                footerLinkInfo,
            });
        }
    } catch {
        yield put({
            type: IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS,
            footerLinkInfo: [],
        });
    }
}

const watchIrisGlobalFooterNavigationRequest = function* watchIrisGlobalFooterNavigationRequest() {
    yield takeLatest(IRIS_GLOBAL_FOOTER_NAVIGATION_GET_REQUEST, IrisGlobalFooterNavigationSaga);
};

watchIrisGlobalFooterNavigationRequest.sagaName = 'IrisGlobalFooterNavigationSaga';

export default watchIrisGlobalFooterNavigationRequest;
