import actionTypes from '../actionTypes/ApiErrorActionTypes';

export const clearErrorMsgs = (errorApiId) => ({
    type: actionTypes.CLEAR_ERROR_MSG,
    payload: errorApiId,
});

export const addApiResponseErrorToStore = (errorApiId) => ({
    type: actionTypes.API_RESPONSE_ERROR,
    payload: errorApiId,
});
