/* istanbul ignore next */
import {
    GET_STORE_BY_STORE_ID_SUCCESS,
    GET_STORE_BY_STORE_ID_SUCCESS_CHECKOUT,
} from '../actionTypes/OrderActionTypes';
import { SET_USER_STORES } from '../actionTypes/SetMyDefaultStoreActionTypes';
/* istanbul ignore next */
function StoreReducer(
    state = {
        isGeoStore: false,
        userStores: [],
    },
    action
) {
    /* istanbul ignore next */
    switch (action.type) {
        case GET_STORE_BY_STORE_ID_SUCCESS:
            return {
                ...state,
                storeDetails: action.selectedStore,
                isGeoStore: action.isGeoStore ? action.isGeoStore : false,
            };
        case GET_STORE_BY_STORE_ID_SUCCESS_CHECKOUT:
            return {
                ...state,
                storeDetails: action.selectedStore,
                isGeoStore: action.isGeoStore ? action.isGeoStore : false,
            };
        case SET_USER_STORES:
            return { ...state, storeDetails: action.stores };
        default:
            return state;
    }
}

StoreReducer.reducerName = 'selectedStore';

export default StoreReducer;
