import React from 'react';
import classNames from 'classnames/bind';
import Accordion from 'yoda-core-components/lib/components/Accordion/accordion';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import LinkConnected from '../Link/Link';
import * as styles from './AccordianTemplate.css';

const cx = classNames.bind(styles);

const titleStyles = dt([
    'text-black',
    'text-small',
    'font-medium',
    'sm:pt-2.5',
    'pr-0',
    'pb-0',
    'sm:pl-2.5',
    'text-left',
    'sm:w-[116px]',
]);

const TitleImageSection = (item, goToPageViewAll) => {
    let titleSec = '';
    /* istanbul ignore next */
    const targUrl = item.link && item.link.url ? item.link.url : '';
    /* istanbul ignore next */
    const imgURL = item.image && item.image.url ? item.image.url : '';
    /* istanbul ignore next */
    // fix for story 4403 - making image and label as clickable
    titleSec = item
        ? (titleSec = (
              <LinkConnected
                  type="button"
                  onClick={() => goToPageViewAll(targUrl)}
                  className={dt([
                      'bg-light-white',
                      'border-b',
                      'border-solid',
                      'border-gray-25',
                      'flex',
                      'sm:h-16',
                      'py-2',
                      'px-6',
                      'w-full',
                  ])}
                  data-automation-id="view-all-button"
              >
                  <img
                      className={dt([
                          'bg-gray-15',
                          'flex-1',
                          'max-h-12',
                          'sm:max-w-[48px]',
                          'rounded-full',
                      ])}
                      alt={item.linkText}
                      src={imgURL}
                  />
                  <span className={`${titleStyles} ${cx('title')}`}> {item.linkText} </span>
                  <span
                      className={dt([
                          'text-black',
                          'text-small',
                          'sm:py-2.5',
                          'sm:px-0.5',
                          'text-right',
                          'underline',
                      ])}
                  >
                      View All
                  </span>
              </LinkConnected>
          ))
        : '';

    return titleSec;
};

const oneRow = (
    item,
    catSelectedPushToNid,
    meta = {},
    itemKey,
    isLoader,
    selAccordian,
    linkText
) => {
    // fix for defect 5176
    /* istanbul ignore next */
    const targUrl = item.link && item.link.url ? item.link.url : '';
    return (
        <li key={`hamb-accord-bottom-${itemKey}-${item.linkText}`}>
            <LinkConnected
                type="button"
                onClick={catSelectedPushToNid}
                data-targetdurl={targUrl}
                data-nid={selAccordian}
                data-title={meta.departmentName}
                data-name={linkText ? `${itemKey}:${item.linkText}` : item.linkText}
                data-pagetype="XGN"
                data-automation-id="dropdown-item-button"
                data-opennewtab={item.openInNewTab}
                className={dt([
                    'text-black',
                    'text-small',
                    'h-10',
                    'sm:-tracking-[0.3px]',
                    'sm:p-1.5',
                    'sm:pl-2.5',
                    'text-left',
                    'normal-case',
                    'w-full',
                ])}
                disabled={isLoader}
            >
                <span
                    className={cx(styles.menuListItemName, item.styleClass)}
                    aria-label={item.linkText}
                >
                    {item.linkText}
                </span>
            </LinkConnected>
        </li>
    );
};

const AccordianList = (
    items,
    catSelectedPushToNid,
    meta,
    itemKey,
    isLoader,
    selAccordian,
    linkText
) => {
    const fullDom = [];
    items.forEach((item) => {
        fullDom.push(
            oneRow(item, catSelectedPushToNid, meta, itemKey, isLoader, selAccordian, linkText)
        );
    });
    return fullDom;
};

const AccordianBuilder = (items, catSelectedPushToNid, meta, isLoader, accordianSelect) => {
    const accordianChilds = items.children;
    const fullDom = [];
    /* istanbul ignore next */
    if (!accordianChilds) {
        return null;
    }
    accordianChilds.forEach((oneItem, index) => {
        const titleLowercase = oneItem.linkText.toLowerCase();
        /* istanbul ignore next */
        const l2Children = oneItem.children || null;
        /* istanbul ignore next */
        if (l2Children) {
            fullDom.push({
                header: titleLowercase,
                expanded: accordianSelect === oneItem.linkText,
                panel: (
                    <div
                        className={`${dt([
                            'block',
                            'sm:h-[calc(100%-40px)]',
                            'relative',
                            'text-left',
                            'w-full',
                        ])} ${cx('dropdownMenu')}`}
                    >
                        <ul
                            className={`${dt(['h-full overflow-y-auto'])} ${cx(
                                'dropdownMenuWrapper'
                            )}`}
                            id="subMenuLevel3"
                        >
                            {AccordianList(
                                l2Children,
                                catSelectedPushToNid,
                                meta,
                                titleLowercase,
                                isLoader,
                                oneItem.linkText,
                                index,
                                accordianSelect,
                                oneItem.linkText
                            )}{' '}
                        </ul>
                    </div>
                ),
            });
        }
    });

    return (
        <Accordion
            automationId="test-automation-tab-list-0"
            children={fullDom}
            className={cx('mobileMenuAccordian')}
            toggleOnlyOne
            parentSection="hamburgermenu"
        />
    );
};

const AccordianTemplate = (
    items,
    catSelectedPushToNid,
    meta,
    goToPageViewAll,
    isLoader,
    accordianSelect
) => {
    return (
        <div
            className={`${dt([
                'bg-white',
                'sm:h-[calc(100%-48px)]',
                'overflow-y-auto',
                'pb-12',
            ])} ${cx('main', 'newHeaderAccordion')}`}
            data-section="accordian"
            id="subMenuLevel2"
        >
            {TitleImageSection(items, goToPageViewAll)}
            {AccordianBuilder(items, catSelectedPushToNid, meta, isLoader, accordianSelect)}
        </div>
    );
};

export default AccordianTemplate;
