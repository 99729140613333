import { COUPON_OPEN_DETAILS } from '../actionTypes/CouponListActionTypes';

const CouponDetailsModal = function CouponDetailsModal(state = false, action) {
    switch (action.type) {
        case COUPON_OPEN_DETAILS:
            return action.payload || false;
        default:
            return state;
    }
};

CouponDetailsModal.reducerName = 'couponDetailsModal';

export default CouponDetailsModal;
