import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { cstToUTCByOffset } from 'yoda-interfaces/lib//Common/CommonHelper';
import Timer, { TimerHelper } from 'yoda-core-components/lib/components/Timer/Timer';
import NavigationHelper from 'yoda-core-components/lib/helpers/NavigationHelper/NavigationHelper';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import IconClose from 'yoda-core-components/lib/assets/svg/IconClose.svg';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { detectisIE } from 'yoda-core-components/lib/helpers/Utils/Utils';
import classNames from 'classnames/bind';
import YodaTooltip from '../YodaTooltip/YodaTooltip';
import * as styles from './StickyTimer.css';
import RichText from '../RichText/RichText';

const cx = classNames.bind(styles);

export class StickyTimerContent extends Component {
    static propTypes = {
        stickyTimerActions: PropTypes.object,
        stickyTimerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        timerTextConfig: PropTypes.shape({
            h: PropTypes.string,
            m: PropTypes.string,
            s: PropTypes.string,
        }),
        timerWrapperThemeConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        stickyTimerWrapperThemeConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        stickyTimerDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        displaySingleDimentionTimer: PropTypes.bool,
        enableThreshold: PropTypes.bool,
        isPreview: PropTypes.bool,
        tzOffset: PropTypes.number,
        enableEventBasedStickyTimer: PropTypes.bool,
        renderFromHeader: PropTypes.bool,
        enableIristzOffset: PropTypes.bool,
    };

    static defaultProps = {
        stickyTimerActions: {},
        stickyTimerData: {},
        timerTextConfig: {
            h: 'HOURS',
            m: 'MINS',
            s: 'SECS',
        },
        timerWrapperThemeConfig: {},
        stickyTimerWrapperThemeConfig: {},
        stickyTimerDetails: {},
        displaySingleDimentionTimer: false,
        enableThreshold: false,
        isPreview: false,
        tzOffset: 300,
        enableEventBasedStickyTimer: false,
        renderFromHeader: false,
        enableIristzOffset: true,
    };

    static getThemeConfig(timerWrapperThemeConfig, displaySingleDimentionTimer) {
        return {
            timerContainerClass: cx('timerContainer'),
            timerWrapperClass: cx('timer'),
            /* istanbul ignore next */
            timerListItemClass: displaySingleDimentionTimer
                ? cx('timerListItemCoupon')
                : cx('timerListItem'),
            timerNumberClass: cx('timerNumber'),
            timerTextClass: cx('timerText'),
            timerSuffixClass: cx('timerListItem'),
            timerNumberWidthClass: cx('timerNumberWidth'),
            timerHourWidthClass: cx('timerHourWidth'),
            ...timerWrapperThemeConfig,
        };
    }

    constructor(props) {
        super(props);
        this.adjustTooltipPlacement = this.adjustTooltipPlacement.bind(this);
        this.timerWrapperThemeConfig = StickyTimerContent.getThemeConfig(
            props.timerWrapperThemeConfig,
            props.displaySingleDimentionTimer
        );
        this.state = {
            fullExclusionDisplay: false,
            controlledIsOpen: false,
            componentTimerExpired: false,
        };
    }

    expiredOffer = () => {
        if (!this.props.renderFromHeader) {
            this.setState({
                componentTimerExpired: true,
            });
        }
        this.props.stickyTimerActions.getStickyTimerDataAction();
    };

    closeExclusions = () => {
        this.setState({
            controlledIsOpen: false,
            fullExclusionDisplay: false,
        });
    };

    expandFullExclusions = () => {
        this.setState({
            fullExclusionDisplay: true,
        });
    };

    callBackFun = (isOpen) => {
        const { renderFromHeader } = this.props;
        /* istanbul ignore next */
        if (!__SERVER__ && !renderFromHeader && document.getElementById('containerWidthIris')) {
            if (isOpen) {
                document.getElementById('containerWidthIris').style.overflow = 'visible';
            } else {
                const tooltipOpenNode = [].slice.call(
                    document.querySelectorAll("[id='exclusionsTooltip']")
                );
                const tooltipOpenValue = tooltipOpenNode.filter((value) => {
                    return value.style.display === 'block';
                });
                if (tooltipOpenValue.length > 0) {
                    document.getElementById('containerWidthIris').style.overflow = 'visible';
                } else {
                    document.getElementById('containerWidthIris').style.overflow = 'hidden';
                }
            }
        }
        this.setState({
            controlledIsOpen: isOpen,
            fullExclusionDisplay: false,
        });
    };

    /* istanbul ignore next */
    getUTCOffsetMinutes = (dateObj) => {
        const input = `${dateObj}`.trim();
        if (/Z$/.test(input)) {
            return 0;
        }
        if (/[+-]\d{2}:\d{2}$/.test(input)) {
            const [hour, min] = input.slice(-6).split(':').map(Number);
            return hour * 60 + min;
        }
        if (/[+-]\d{4}$/.test(input)) {
            const [hour, min] = [input.slice(-4, -2), input.slice(-2)].map(Number);
            return hour * 60 + min;
        }
    };

    adjustTooltipPlacement() {
        /* istanbul ignore next */
        if (!__SERVER__ && document.getElementById('exclusion-btn')) {
            const boundingClientRect = document
                .getElementById('exclusion-btn')
                .getBoundingClientRect();
            const offsetLeft = boundingClientRect.left;
            const offsetRight = document.body.clientWidth - boundingClientRect.right;

            const mobileScreenThreshold = _get(
                this.props.stickyTimerDetails,
                'exclusionsPlacement.mobileScreenThreshold',
                599
            );
            const tooltipMaxWidth = _get(
                this.props.stickyTimerDetails,
                'exclusionsPlacement.tooltipMaxWidth',
                418
            );

            if (document.body.clientWidth < mobileScreenThreshold) {
                // mobile
            } else if (offsetLeft < tooltipMaxWidth / 2) {
                // See Details is too left
                document.getElementById('exclusionsTooltip').style.left = `${
                    -1 * (offsetLeft - 6)
                }px`;
            } else if (offsetRight < tooltipMaxWidth / 2) {
                // See Details is too right
                document.getElementById('exclusionsTooltip').style.right = `${
                    -1 * (offsetRight - 6)
                }px`;
            } else {
                document.getElementById('exclusionsTooltip').style.right = '-179px';
            }
        }
    }

    renderExclusions(disclaimer) {
        const { fullExclusionDisplay } = this.state;
        /* istanbul ignore next */
        const closeBtnClass = fullExclusionDisplay ? '' : 'hide';
        /* istanbul ignore next */
        const tooltipClass = fullExclusionDisplay
            ? 'exclusionsFullContent'
            : 'exclusionsBriefContent';
        return (
            <>
                <div className={cx('exclusionsTooltipBody')}>
                    <button
                        onClick={this.closeExclusions}
                        className={cx('closeBtn', closeBtnClass)}
                        data-automation-id="tooltip-button"
                    >
                        <Icon
                            type="button"
                            iconType="svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 25 25"
                            automationId="tooltip-icon"
                        >
                            <IconClose />
                        </Icon>
                    </button>
                    <div className={cx('exclusionsTooltipContent', tooltipClass)}>
                        <RichText
                            richTextClass={styles.disclaimerBody}
                            text={disclaimer}
                            TagName="div"
                        />
                        <button
                            className={styles.fullExclusionBtn}
                            onClick={this.expandFullExclusions}
                        >
                            Full Exclusions & Details
                        </button>
                    </div>
                </div>
            </>
        );
    }

    render() {
        /* istanbul ignore next */
        const {
            stickyTimerData = {},
            enableThreshold,
            isPreview,
            tzOffset,
            enableEventBasedStickyTimer,
            renderFromHeader,
            enableIristzOffset,
        } = this.props;
        const {
            stickyTimerWrapperClass,
            displayTimerHtmlWrapperClass,
            displayTimerClass,
            timeRemainingCouponCodeBlockClass,
            endInTimerClass,
            offerEndsTextClass,
        } = this.props.stickyTimerWrapperThemeConfig;
        const headerParentClass = renderFromHeader ? 'parentHeaderWrapper' : '';
        if (this.state.componentTimerExpired) {
            return '';
        }
        if (_isEmpty(stickyTimerData)) return null;
        let { publishingEndDate, publishingStartDate } = stickyTimerData;
        /* istanbul ignore next */
        const { content = {} } = stickyTimerData;
        const {
            daytimer = false,
            urgencyText = '',
            eventText,
            offerStartDate,
            offerEndDate,
            eventType,
            primaryLink: { link: { url: navigationIrisLink = '' } = {} } = {},
        } = content;
        /* istanbul ignore else */
        if (enableEventBasedStickyTimer) {
            publishingStartDate = offerStartDate;
            publishingEndDate = offerEndDate;
        }
        if (!publishingEndDate) {
            return '';
        }
        const {
            offerEndsText,
            promoCodeTextColor,
            instructionalTextColor,
            offerEndsTextColor,
            timerNumberColor,
            // applyBottomPaddingClass -- to add bottom margin only for cart pages #MNPDPYODA-6811
            timerTextColor,
            finalDayThreshold,
            finalHourThreshold,
            finalHoursText = '',
            applyBottomPaddingClass,
            navigationUrl = '',
            cmRe = '',
            enableDaysRestriction = false,
            daysRestriction = 1,
            enableExclusions = true,
            enableShopNowCTA,
        } = this.props.stickyTimerDetails;
        let previewDate = null;
        /* istanbul ignore next */
        if (isPreview) {
            const selectedDateValue = Cookies.load('DP_PREVIEW_DATE');
            if (selectedDateValue) {
                const offset = this.getUTCOffsetMinutes(publishingEndDate);
                if (enableIristzOffset && publishingEndDate && typeof offset !== 'undefined') {
                    previewDate = cstToUTCByOffset(selectedDateValue, -offset);
                } else {
                    previewDate = cstToUTCByOffset(selectedDateValue, tzOffset);
                }
            }
        }
        const publishedOfferEndDate = publishingEndDate && new Date(publishingEndDate);
        const offerWarningStartDate = publishingEndDate && new Date(publishingEndDate);
        offerWarningStartDate.setHours(offerWarningStartDate.getHours() - finalDayThreshold);
        // To show offer end date in central TimeZone
        const formattedDate = publishingEndDate.match(/^(\d{4})-(\d{2})-(\d{2})/);
        const formattedYear = formattedDate[1].match(/\d{2}$/);
        const localDateString = `${formattedDate[2]}/${formattedDate[3]}/${formattedYear}`;
        const timeRemaining = TimerHelper.getremainingTimerDetails(
            publishingStartDate,
            publishingEndDate,
            finalDayThreshold,
            previewDate,
            daytimer,
            enableEventBasedStickyTimer
        );
        /* istanbul ignore next */
        if (enableDaysRestriction && timeRemaining.daysLeft > daysRestriction) {
            return null;
        }

        const displayOfferEndsText = offerEndsText || '*offer ends';
        const couponCode = _get(this.props, 'stickyTimerData.content.promoCode', '');
        const colorConfig = {
            textColor: timerTextColor,
            suffixColor: '',
            digitBoxbgColor: '',
            digitBoxColor: timerNumberColor,
        };

        const timerHtml = (
            <Timer
                endTime={publishingEndDate}
                startTime={publishingStartDate}
                themeConfig={this.timerWrapperThemeConfig}
                timerTextConfig={this.props.timerTextConfig}
                completeCallback={this.expiredOffer}
                suffix={false}
                stickyTimer
                colorConfig={colorConfig}
                finalDayThreshold={finalDayThreshold}
                today={previewDate}
                dayTimer={daytimer}
                eventBasedTimer={enableEventBasedStickyTimer}
            />
        );

        let displayFinalHours = ''; // added this to unblock the builds please take care of the changes
        /* istanbul ignore if */ if (timeRemaining && timeRemaining.days) {
            displayFinalHours = `DAY ${timeRemaining.days} OF`;
            if (enableEventBasedStickyTimer) {
                displayFinalHours = daytimer ? `Day ${timeRemaining.days} Of` : '';
            }
        }
        let displayFinalHoursText = finalHoursText || 'FINAL HOURS';
        /* istanbul ignore next */
        if (enableEventBasedStickyTimer) {
            displayFinalHoursText =
                eventType === 'non-coupon' ? urgencyText : urgencyText || finalHoursText;
        }

        let showOfferExpired = true;
        /* istanbul ignore next */
        if (timeRemaining.isTodayLastDay) {
            // Today Time greater than warning time, need to remove Day Count
            showOfferExpired = false;
            displayFinalHours = '';
            const threshold = finalHourThreshold && finalHourThreshold.split(':');
            if (threshold && threshold.length > 0) {
                const thresholdHour = threshold[0] || 0;
                const thresholdMin = threshold[1] || 0;
                publishedOfferEndDate.setHours(publishedOfferEndDate.getHours() - thresholdHour);
                publishedOfferEndDate.setMinutes(publishedOfferEndDate.getMinutes() - thresholdMin);

                if (
                    enableThreshold &&
                    publishedOfferEndDate.getTime() - (previewDate || new Date()).getTime() < 0
                ) {
                    // Today Time greater than threshold time, need to show final hours
                    displayFinalHours = enableEventBasedStickyTimer
                        ? displayFinalHoursText
                        : `${displayFinalHoursText} OF`;
                }
            }
        }

        const displayTime = timeRemaining.total > 0;

        let exclusionsWrapperHTML = '';
        /* istanbul ignore else */
        if (enableExclusions && content && content.disclaimer) {
            exclusionsWrapperHTML = (
                <YodaTooltip
                    tooltipBodyClassName={cx('exclusionsTooltipWrapper')}
                    renderTooltipContents={this.renderExclusions(content.disclaimer)}
                    tooltipTextClass={cx('toolTipBtn')}
                    tooltipContentClass={
                        /* istanbul ignore next */
                        cx('exclusionsTooltipBox', detectisIE() ? 'exclusionsTooltipBoxIE' : '')
                    }
                    tooltipPlacement="Middle"
                    tooltipType="button"
                    callBackFun={this.callBackFun}
                    disableHideOnContentClick
                    controlledIsOpen={this.state.controlledIsOpen}
                    enableRicchTextData
                    clickTriggerTooltipOnClick={this.adjustTooltipPlacement}
                    activityMapId="exclusionsTooltip"
                >
                    <div className={cx('exclusionsbtnClass')} id="exclusion-btn">
                        details
                    </div>
                    <div
                        className={cx(
                            'exclusionsTooltipPseudo',
                            this.state.controlledIsOpen ? '' : 'hide'
                        )}
                    />
                </YodaTooltip>
            );
        }

        // if no url is associated with coupon api response (or flag is off), use configured url (could be empty)
        const navigationUrlAppended =
            cmRe && navigationUrl ? `${navigationUrl}?cm_re=${cmRe}_${couponCode}` : navigationUrl;
        let navigateUrlOnClick =
            enableShopNowCTA && content && content.linkUrl
                ? content.linkUrl
                : navigationUrlAppended;

        let displayEventText = (
            <>
                <span
                    style={{ color: instructionalTextColor }}
                    className={cx('timeRemaining')}
                    data-automation-id="timeRemainingText"
                >{`${displayFinalHours} COUPON CODE`}</span>
                <RichText
                    style={{ color: promoCodeTextColor }}
                    text={couponCode}
                    richTextClass={cx('couponCode')}
                    TagName="span"
                    automationId="couponCodeText"
                />
            </>
        );
        /* istanbul ignore else */
        if (enableEventBasedStickyTimer) {
            displayEventText = (
                <RichText
                    style={{ color: instructionalTextColor }}
                    text={`${displayFinalHours} ${eventText}`}
                    richTextClass={cx('timeRemaining')}
                    TagName="span"
                    automationId="timeRemainingText"
                />
            );
            navigateUrlOnClick = navigationIrisLink || '';
        }

        /* eslint-disable jsx-a11y/no-static-element-interactions */
        const renderTimerHTML = displayTime && (
            <div className={cx('displayTimerHtml-wrapper', displayTimerHtmlWrapperClass)}>
                <div
                    className={cx('display-timer', displayTimerClass)}
                    data-automation-id="display-timerWrapper"
                >
                    <span
                        className={cx('display-timer-wrapper')}
                        onClick={
                            /* istanbul ignore next */
                            () => {
                                navigateUrlOnClick && NavigationHelper.navigate(navigateUrlOnClick);
                            }
                        }
                    >
                        <span
                            className={cx(
                                'timeRemainingCouponCodeBlock',
                                timeRemainingCouponCodeBlockClass
                            )}
                        >
                            {displayEventText}
                        </span>
                        <span className={cx('endInTimer', endInTimerClass)}>
                            <span className={cx('timerHtml')} data-automation-id="">
                                {timerHtml}
                            </span>
                        </span>
                        {showOfferExpired && (
                            <span
                                style={{ color: offerEndsTextColor }}
                                className={cx('offerEndsText', offerEndsTextClass)}
                                data-automation-id="offerEndsText"
                            >{`${displayOfferEndsText} ${localDateString}`}</span>
                        )}
                    </span>
                    <span className={cx('exclusionsCell')}>{exclusionsWrapperHTML}</span>
                </div>
            </div>
        );

        return (
            <div className={cx('parent-wrapper', headerParentClass)}>
                {
                    /* istanbul ignore else */
                    navigateUrlOnClick ? (
                        <div
                            className={cx('timerWrapper', 'withLink', {
                                applyBottomPadding: applyBottomPaddingClass,
                            })}
                        >
                            {renderTimerHTML}
                        </div>
                    ) : (
                        <div
                            className={cx('timerWrapper', stickyTimerWrapperClass, {
                                applyBottomPadding: applyBottomPaddingClass,
                            })}
                            data-automation-id="timerWrapper"
                        >
                            {renderTimerHTML}
                        </div>
                    )
                }
            </div>
        );
    }
}

export default StickyTimerContent;
