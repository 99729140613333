import { call, put, select, takeLatest, fork } from 'redux-saga/effects';
import get from 'lodash/get';
import getPageAggregator from 'yoda-interfaces/lib/CMPage/CMPage';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { dangerouslySetServerResponseHeaders } from '../actions/DangerouslySetServerResponseHeadersAction';
import CMSwitchWidgetAction from '../actions/CMSwitchWidgetAction';
import * as CMPageActionTypes from '../actionTypes/CMPageActionTypes';
import analyticsActions from '../actions/AnalyticsAction';
import { selectFeatureFlags, isPreview, selectPreferences } from '../selectors/ContextSelector';
import trimCmData from '../CoreMedia/CmDataReduction/trimCmData';
import { IrisPageCallSaga } from './IrisSaga';

function* cmFallBackRedirect({
    pageParams,
    statusCode,
    showErrorPage,
    enableCmFallBack = false,
    isCMComponentDataEmpty = false,
}) {
    const isMktFallbackRedirectEnabled = get(
        pageParams,
        'payload.cmFallbackPreferences.isMktFallbackRedirectEnabled',
        false
    );
    const url =
        isMktFallbackRedirectEnabled &&
        get(pageParams, 'payload.cmFallbackPreferences.marketingFallbackUrl', false);
    const redirectAction = get(pageParams, 'payload.redirectAction');
    const locPath = get(pageParams, 'payload.params', '');
    const featureFlags = yield select(selectFeatureFlags);
    const enableCMSwitchWidget = get(featureFlags, 'enableCMSwitchWidget', false);
    const preview = yield select(isPreview);
    const preferences = yield select(selectPreferences);
    const irisFallbackEnabled = featureFlags?.irisFallbackEnabled || false;
    const disableIrisCall = featureFlags?.disableIrisCall || false;
    if (enableCmFallBack) {
        if (redirectAction) {
            if (
                url &&
                !locPath.includes('/home') &&
                !locPath.includes('/m/') &&
                !locPath.includes('/d/') &&
                isCMComponentDataEmpty
            ) {
                window.location.href = url;
            } else {
                if (__SERVER__) {
                    yield fork(IrisPageCallSaga, {
                        payload: {
                            params: locPath,
                            aggregateAPI: true,
                            postProcessor: false,
                            irisFallbackEnabled,
                            irisFallbackPreferences: preferences.irisfallbackPreferences || {},
                            redirectAction,
                            disableIrisCall,
                            isFallingBackAfterCMFailed: true,
                            initialLoad: true,
                            showErrorPage: true,
                        },
                    });
                }
                yield put({
                    type: CMPageActionTypes.CM_FALLBACK_TO_IRIS,
                });
                if (preview && enableCMSwitchWidget) {
                    yield put(CMSwitchWidgetAction.getSiteWideWidgetSelection('IRIS'));
                }
            }
        } else {
            // triggering Error Page in here
            yield put({
                type: CMPageActionTypes.CM_LOAD_ERROR_PAGE,
                statusCode,
                showErrorPage,
            });
        }
    } else if (url && redirectAction && !locPath.includes('/d/')) {
        // Check for dept page and it should not redirect to evergeen
        // Redirect to fallback just on the server
        LocalStorage.setData('cmEvergreenload', true, true);
        window.location.href = url;
    } else {
        // triggering Error Page in here
        yield put({
            type: CMPageActionTypes.CM_LOAD_ERROR_PAGE,
            statusCode,
            showErrorPage,
        });
    }
}

const checkCMDataAvailable = (cmRowData) => {
    let isPlacementDataEmpty = false;
    cmRowData?.forEach((placements) => {
        placements?.placements?.forEach((plcmt) => {
            if (plcmt?.name === 'S2' && plcmt?.items?.length === 0) {
                isPlacementDataEmpty = true;
            }
        }); // check S2 is empty or not. If it is empty Marketing page will be redirected to evergreen and Other pages to Iris
    });

    return isPlacementDataEmpty;
};

function* CMPageCallSaga(pageParams) {
    try {
        let isCMComponentDataEmpty = false;
        const featureFlags = yield select(selectFeatureFlags);
        const enableFullCmDataContent = get(featureFlags, 'enableFullIrisDataContent', false);
        const enableCmFallBack = get(featureFlags, 'enableCmFallBack', false);
        const cmDatas = yield call(
            getPageAggregator,
            pageParams.payload,
            pageParams.payload.cmFallbackEnabled,
            pageParams.payload.cmFallbackPreferences
        );
        const statusCode = cmDatas.status; // getting the status code
        const { showErrorPage } = pageParams.payload;
        // Checking if statusCode is in range 199 - 300 then load the page
        if (statusCode > 199 && statusCode < 300) {
            const cmDataResponse = get(cmDatas, 'data', {});
            const cmData = !enableFullCmDataContent ? trimCmData(cmDataResponse) : cmDataResponse;

            const cmRowData = get(cmDataResponse, 'page.grid.rows', []);

            isCMComponentDataEmpty =
                cmRowData?.length > 0 ? checkCMDataAvailable(cmRowData) : false;

            if (isCMComponentDataEmpty) {
                yield call(cmFallBackRedirect, {
                    pageParams,
                    statusCode,
                    showErrorPage,
                    enableCmFallBack,
                    isCMComponentDataEmpty,
                });
            }

            if (
                __SERVER__ &&
                cmDatas.responseHeadersToProxy &&
                cmDatas.responseHeadersToProxy.length
            ) {
                yield put(dangerouslySetServerResponseHeaders(cmDatas.responseHeadersToProxy));
            }
            yield put({ type: CMPageActionTypes.CM_PAGE_LOAD_SUCCESS, cmDataResponse });
        } else {
            yield call(cmFallBackRedirect, {
                pageParams,
                statusCode,
                showErrorPage,
                enableCmFallBack,
                isCMComponentDataEmpty,
            });
        }
        /** If it is a client call then set this */
        /** https://jira.jcpenney.com/browse/MNPDPYODA-9947
         extra condition is for Gallery
         Since Gallery is will Load only initialy at serverSide
         rest it will call client side ex: pagination filter etc.
         */
        if (!__SERVER__ && pageParams.payload.initialLoad) {
            const errorMessage =
                'tof|the page is failed to load server but instead loaded client side';
            yield put(
                analyticsActions.triggerFormError([
                    {
                        errorDescription: errorMessage,
                    },
                ])
            );
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('CMP:: CMP api error');
        yield put({
            type: CMPageActionTypes.CM_PAGE_LOAD_ERROR,
            error,
            showErrorPage: pageParams.payload.showErrorPage,
        });
    }
}

const watchCMPageRequest = function* watchCMPageRequest() {
    yield takeLatest(CMPageActionTypes.CM_PAGE_LOAD_REQUEST, CMPageCallSaga);
};

watchCMPageRequest.sagaName = 'CMPageCallSaga';

export default watchCMPageRequest;
