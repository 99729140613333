import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';

export const isBloomReachAudience = (enableBloomReach = false, isNative = false) => {
    if (!enableBloomReach || isNative) return false;

    const BRCookieValue = Cookies.load('BRtest', true);

    return !!BRCookieValue;
};

export const enableBloomReachPage = (
    enableBloomReachV3PLP = false,
    requestUrl = '',
    plpBloomreachInclusionList = []
) => {
    const params = requestUrl;
    const paramsPageType = params?.slice(0, 2);
    return enableBloomReachV3PLP && plpBloomreachInclusionList?.includes(paramsPageType);
};

export default isBloomReachAudience;
