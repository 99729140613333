import {
    BREADCRUMBNAV_GET_REQUEST,
    BREADCRUMBNAV_GET_SUCCESS,
} from '../actionTypes/BreadCrumbNavActionTypes';

const getBreadCrumbsAction = (payload) => ({
    type: BREADCRUMBNAV_GET_REQUEST,
    payload,
});

const getBreadCrumbsOnSuccess = (breadCrumbs) => ({
    type: BREADCRUMBNAV_GET_SUCCESS,
    breadCrumbs,
});

export default {
    getBreadCrumbsAction,
    getBreadCrumbsOnSuccess,
};
