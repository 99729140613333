import { COUPON_LIST_GET_SUCCESS } from '../actionTypes/CouponListActionTypes';

const CouponListReducer = function CouponListReducer(state = [], action) {
    switch (action.type) {
        case COUPON_LIST_GET_SUCCESS:
            return action.couponList && action.couponList.length ? action.couponList : state;
        default:
            return state;
    }
};

CouponListReducer.reducerName = 'couponList';

export default CouponListReducer;
