import {
    ADJUSTMENTS_POST_SUCCESS,
    ADJUSTMENTS_POST_ERROR,
    ADJUSTMENTS_POST_SERIAL,
    ADJUSTMENTS_INLINE_LOADER,
    ADJUSTMENTS_BY_ID_DELETE_SUCCESS,
} from '../actionTypes/AdjustmentActionTypes';

export const defaultState = {
    isInlineLoader: null,
    isApplySuccess: null,
    isRemoveSuccess: null,
    selectedCouponCode: '',
    doAnimation: null,
};

function AdjustmentReducer(state = defaultState, action) {
    switch (action.type) {
        case ADJUSTMENTS_POST_SERIAL:
        case ADJUSTMENTS_POST_SUCCESS: {
            return {
                ...state,
                ...action.adjustmentResponse,
                isApplySuccess: true,
                doAnimation: true,
                selectedCoupon: {
                    ...action.adjustmentResponse,
                },
            };
        }

        case ADJUSTMENTS_POST_ERROR: {
            return {
                ...state,
                ...action.adjustmentResponse,
                isApplySuccess: false,
                doAnimation: true,
            };
        }

        case ADJUSTMENTS_INLINE_LOADER: {
            const { isInlineLoader, selectedCouponCode, isApplyLoader } = action;
            const extraState = {};
            if (isInlineLoader) {
                extraState.isRemoveSuccess = false;
                extraState.isApplySuccess = false;
            }
            return {
                ...state,
                isInlineLoader,
                openDrawerCall: false,
                selectedCouponCode,
                doAnimation: isApplyLoader ? null : state.doAnimation,
                ...extraState,
            };
        }

        case ADJUSTMENTS_BY_ID_DELETE_SUCCESS: {
            return {
                ...state,
                /* ...action.adjustmentResponse, */
                isRemoveSuccess: true,
                doAnimation: null,
                selectedCoupon: {},
            };
        }

        default:
            return state;
    }
}

AdjustmentReducer.reducerName = 'couponApplicationState';

export default AdjustmentReducer;
