import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import SlickCarousel from '../../SlickCarousel/SlickCarousel';
import Link from '../../Link/Link';
import * as styles from '../PromotionalBanner.css';
import RichText from '../../RichText/RichText';

const cx = classNames.bind(styles);

/**
 * TODO :: check and analyze the possibility of moving it into core components more time may be required.
 */
class PromoBannerAnimate extends Component {
    static propTypes = {
        offerDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        promoTimeOut: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
        promoMouseOp: PropTypes.bool,
        enableIrisSitePromo: PropTypes.bool,
        enablePromoCarousel: PropTypes.bool,
        height: PropTypes.string,
    };

    static defaultProps = {
        promoTimeOut: null,
        offerDetails: {},
        promoMouseOp: false,
        enableIrisSitePromo: false,
        enablePromoCarousel: true,
        height: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            showOfferElement: '',
            showtargetUrl: '',
            showtargetWindow: '',
            animateText: true,
            showTargetUrlFlag: false,
            showSecondaryText: '',
            showBackgroundColor: '',
        };
        this.index = 1;
        this.animSetTimeout = null;
        this.key = 0;
        this.secondaryTextIntialized = false;
        this.backgroundInitialized = false;
        this.slideInitialized = true;
        this.loadOfferDetails = this.loadOfferDetails.bind(this);
        this.renderPromoText = this.renderPromoText.bind(this);

        this.onPromoBannerEnter = debounce(this.onPromoBannerEnter, 100);
        this.onPromoBannerLeave = debounce(this.onPromoBannerLeave, 100);
    }

    componentDidMount() {
        const { offerDetails } = this.props;
        /* istanbul ignore else */
        if (offerDetails.length) {
            this.loadOfferDetails();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.animSetTimeout);
    }

    /* istanbul ignore next */
    onPromoBannerEnter(index = 0) {
        clearTimeout(this.animSetTimeout);
        this.index = index;
        if (this.state.animateText) {
            this.setState({
                animateText: false,
            });
        }
    }

    onPromoBannerLeave() {
        this.loadOfferDetails(true, 2000);
    }

    getAnimateTime() {
        const adjustTime = 500;
        const defaultTime = 5000;
        const finalAdjustedTime = defaultTime - adjustTime;
        if (this.props.promoTimeOut) {
            const animateTime = this.props.promoTimeOut;
            /** Formula to find sec int(X/1000) */
            const animDuration = parseInt(animateTime / 1000, 10);

            let animateDuration = '5s';
            let animationTime = finalAdjustedTime;

            if (animDuration) {
                animateDuration = `${animDuration}s`;
                animationTime = animateTime - adjustTime;
            }

            return {
                time: animationTime,
                duration: animateDuration,
            };
        }
        return {
            time: finalAdjustedTime,
            duration: '5s',
        };
    }

    generateKey(prefix = 'id') {
        this.key += 1;
        return `${prefix}${this.key}`;
    }

    loadOfferDetails(animate = null, animateTime = null) {
        const animationTimeOut = animateTime || this.getAnimateTime().time;
        /* istanbul ignore next */
        this.animSetTimeout = setTimeout(() => {
            const {
                promoText = '',
                targetUrl = '',
                linkText = '',
                targetWindow = '',
                secondaryText = '',
                backgroundColor = '',
            } = this.props.offerDetails[this.index] || {};
            const linkUrlFlag = !targetUrl;
            if (animate) {
                this.setState({
                    showOfferElement: promoText,
                    showOfferLinkElement: linkText,
                    showtargetUrl: targetUrl,
                    animateText: true,
                    showtargetWindow: targetWindow,
                    showTargetUrlFlag: linkUrlFlag,
                    showSecondaryText: secondaryText,
                    showBackgroundColor: backgroundColor,
                });
            } else {
                this.setState({
                    showOfferElement: promoText,
                    showOfferLinkElement: linkText,
                    showtargetUrl: targetUrl,
                    showtargetWindow: targetWindow,
                    showTargetUrlFlag: linkUrlFlag,
                    showSecondaryText: secondaryText,
                    showBackgroundColor: backgroundColor,
                });
            }
            this.index = this.props.offerDetails.length - 1 === this.index ? 0 : this.index + 1;
            this.loadOfferDetails();
        }, animationTimeOut);
    }

    navigateurl(event, link, targetWindow) {
        event.preventDefault();
        return {
            url: link,
            openInNewTab: targetWindow,
        };
    }

    renderMainOfferText = (offerText) => (
        <span
            className={dt([
                'text-large',
                'font-semibold',
                'whitespace-normal',
                'overflow-hidden',
                'overflow-ellipsis',
                'max-w-[400px]',
                'pt-2',
            ])}
        >
            {offerText}
        </span>
    );

    renderPromoText(data) {
        const {
            enableIrisSitePromo,
            offerDetails,
            promoMouseOp,
            enablePromoCarousel,
            height,
        } = this.props;
        let promoText;
        let linkText;
        let targetWindow;
        let slideBgColor;
        let secondaryText;
        let primaryLink;
        if (enablePromoCarousel) {
            clearTimeout(this.animSetTimeout);
            promoText = data.promoText;
            linkText = data.linkText;
            targetWindow = data.targetWindow;
            slideBgColor = data.backgroundColor;
            secondaryText = data.secondaryText;
            primaryLink = data.targetUrl;
        } else {
            promoText = this.state.showOfferElement || offerDetails[0].promoText;
            linkText = this.state.showOfferLinkElement || offerDetails[0].linkText;
            targetWindow = this.state.showtargetWindow || offerDetails[0].targetWindow;
            slideBgColor = this.state.showBackgroundColor;
            if (!this.backgroundInitialized && !_isEmpty(offerDetails[0].backgroundColor)) {
                slideBgColor = offerDetails[0].backgroundColor;
                this.setState({
                    showBackgroundColor: slideBgColor,
                });
                this.backgroundInitialized = true;
            }
            secondaryText = this.state.showSecondaryText;
            if (!this.secondaryTextIntialized && !_isEmpty(offerDetails[0].secondaryText)) {
                secondaryText = offerDetails[0].secondaryText;
                this.setState({
                    showSecondaryText: secondaryText,
                });
                this.secondaryTextIntialized = true;
            }
        }

        /**
         * Mouse Operations enable and disable
         */
        let mouseEnter = () => null;
        let mouseLeave = () => null;

        if (promoMouseOp && !enablePromoCarousel) {
            /* istanbul ignore next */
            mouseEnter = () => {
                offerDetails.length > 1 && this.onPromoBannerEnter(this.index);
            };
            /* istanbul ignore next */
            mouseLeave = () => {
                offerDetails.length > 1 && this.onPromoBannerLeave();
            };
        }
        let link = '';
        let offerText = '';
        let offerLink = '';
        let openInNewTab = false;
        let offerSecondaryText = '';
        let offerBackgroundColor = '';

        if (enableIrisSitePromo) {
            offerLink = linkText;
            offerText = promoText;
            offerSecondaryText = secondaryText;
            offerBackgroundColor = slideBgColor;
            openInNewTab = targetWindow;
            /* istanbul ignore next */
            link = enablePromoCarousel
                ? primaryLink
                : this.state.showtargetUrl ||
                  (this.state.showTargetUrlFlag ? '' : offerDetails[0].targetUrl);
        } else {
            const getOfferLink = promoText.match(/<a>(.*?)<\/a>/g);
            offerLink = getOfferLink ? getOfferLink[0].replace(/<\/?a>/g, '') : '';
            offerText = promoText.split('<a>')[0];
            offerSecondaryText = offerSecondaryText.split('<a>')[0];
            /* istanbul ignore next */
            link = enablePromoCarousel
                ? primaryLink
                : this.state.showtargetUrl || offerDetails[0].targetUrl;
        }
        const sitePromoClassName =
            offerDetails.length > 1 && this.state.animateText
                ? /* istanbul ignore next */
                  cx('promotextWithAnimation', 'promotextWrapper')
                : cx('promotextWrapper');
        const animStyle =
            offerDetails.length > 1 && this.state.animateText
                ? { animationDuration: this.getAnimateTime().duration }
                : {};
        const noPaddingBottom = offerDetails.length === 1 ? 'noPaddingBottom' : '';
        const promoSubTextClass = dt(['text-xs', 'pt-0.5']);
        const dataToRender = enableIrisSitePromo ? (
            <div
                key={this.generateKey('promoText')}
                className={cx('irispromolink', sitePromoClassName, 'newHeader', noPaddingBottom)}
                style={{
                    ...animStyle,
                    backgroundColor: offerBackgroundColor,
                    height,
                }}
                data-automation-id="irispromolink"
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
            >
                {link ? (
                    <Link
                        href={link}
                        onClick={(event) => this.navigateurl(event, link, openInNewTab)}
                        className={cx('seeDetailsLink')}
                    >
                        {this.renderMainOfferText(offerText)}
                        {offerLink ? <span className={cx('promolink')}>{offerLink}</span> : null}
                        <div className={promoSubTextClass}>
                            <RichText
                                className={cx('promotext', 'promoSecText')}
                                text={offerSecondaryText}
                            />
                        </div>
                    </Link>
                ) : (
                    <div
                        data-automation-id="seeDetailsLink"
                        className={cx('seeDetailsLink', 'noLink')}
                    >
                        {this.renderMainOfferText(offerText)}
                        <div className={promoSubTextClass}>
                            <RichText
                                className={cx('promotext', 'promoSecText')}
                                text={offerSecondaryText}
                            />
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div
                key={this.generateKey('promoText')}
                className={sitePromoClassName}
                style={animStyle}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
            >
                {this.renderMainOfferText(offerText)}
                <div className={promoSubTextClass}>
                    {' '}
                    <RichText
                        className={cx('promotext', 'promoSecText')}
                        text={offerSecondaryText}
                    />
                </div>
                {offerLink ? (
                    <Link href={link} className={cx('seeDetailsLink')}>
                        <span dangerouslySetInnerHTML={{ __html: offerLink }} />
                    </Link>
                ) : (
                    <Link href={link} className={cx('seeDetailsLink')}>
                        See Details
                    </Link>
                )}
            </div>
        );
        return dataToRender;
    }

    renderOffersSlides() {
        const { offerDetails } = this.props;
        clearTimeout(this.animSetTimeout);
        if (this.state.animateText) {
            this.setState({
                animateText: false,
            });
        }

        const carouselTheme = {
            bullet: `${dt(['align-top', 'ml-2', 'mr-0', 'border-0'])} ${cx('bullet')}`,
            bulletContainer: `${dt([
                'absolute',
                'bottom-1',
                'w-full',
                'bg-transparent',
                'sm:h-2.5',
                'align-top',
            ])} ${cx('bulletContainer')}`,
            carouselBullets: `${dt(['bg-[none]', 'mt-2', 'text-[0]'])} ${cx('carouselBullets')}`,
            carouselSelected: cx('selected'),
            slideClass: cx('carouselSlide', 'slick-slide'),
        };

        const carouselThemePromoBanner = {
            bulletContainer: `${dt([
                'absolute',
                'bottom-1',
                'w-full',
                'bg-transparent',
                'align-top',
            ])}`,
            bullet: `${dt(['align-top', 'ml-2', 'mr-0', 'border-0'])}`,
        };

        // need to set this key to force re-render on client
        const key = `${offerDetails.length}-${this.state.hasMounted ? 'c' : 's'}`;
        // as product card will be common component added to make sure it will load only for gallery not for RR zone
        return (
            <ErrorBoundary>
                <SlickCarousel
                    autoSize
                    automationId="data-slick-carousel"
                    autoplay
                    carouselData={offerDetails}
                    carouselItemRenderer={this.renderPromoText}
                    displayArrows={false}
                    key={key}
                    pauseOnDotsHover
                    pauseOnHover
                    repeat
                    rewindOnChange
                    slideInitialized={this.slideInitialized}
                    speed={0}
                    themeConfig={carouselTheme}
                    carouselBulletTheme={carouselThemePromoBanner}
                    autoPlay
                    isPromoBanner
                />
            </ErrorBoundary>
        );
    }

    render() {
        const { offerDetails, enablePromoCarousel } = this.props;

        return offerDetails.length > 0 ? (
            <div
                data-automation-id="promotionalBannerWrapper"
                className={cx('promotionalBannerWrapper', 'newHeader')}
            >
                {enablePromoCarousel ? this.renderOffersSlides() : this.renderPromoText()}
            </div>
        ) : null;
    }
}

export default PromoBannerAnimate;
