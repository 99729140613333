import { all, takeLatest, call, put, select, fork } from 'redux-saga/effects';
import findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { getStores } from 'yoda-interfaces/lib/Order/OrderApi';
import { getFilterByServicePills } from 'yoda-interfaces/lib/Common/LocationServiceApi';
import { getMoreStoresByLink } from 'yoda-interfaces/lib/Order/StoreApi';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import Location from 'yoda-core-components/lib/helpers/Location/Location';
import { getLatLongUsingGeocode } from './FindStoresSaga';
import * as OrderActionTypes from '../actionTypes/OrderActionTypes';
import {
    FIND_STORES_PAGE,
    FIND_STORES_PAGE_SUCCESS,
    FIND_MORE_STORES_PAGE,
    FIND_STORES_BY_SERVICE,
    SET_SELECTED_SERVICES,
    FIND_MORE_STORES_PAGE_SUCCESS,
    PRE_POPULATE_STORES,
    SHOW_STORE_LOADER,
    HIDE_STORE_LOADER,
    FIND_STORES_ERROR,
    SET_ZIP_CODE,
    STORES_ON_LOAD,
    FIND_STORES_PAGE_INVALID_INPUT,
    SET_SEARCH_OBJECT,
    FIND_STORES_PAGE_FILTER,
    GET_FILTER_BY_SERVICES,
    GET_FILTER_BY_SERVICES_SUCCESS,
    GET_FILTER_BY_SERVICES_FAILURE,
} from '../actionTypes/FindAStorePageActionTypes';
import AnalyticsActionTypes from '../actionTypes/AnalyticsActionTypes';
import { selectFeatureFlags } from '../selectors/ContextSelector';

function getPageLinks(responseData) {
    const currentPageIndex = findIndex(responseData.page, { selected: true });
    const nextPage = responseData.page[currentPageIndex + 1];
    return (nextPage && nextPage.url) || '';
}

function getStoreSelectedServices(state, newService) {
    const { storeSelectedServices } = state.findAStorePageInfo;
    const selectedServicesSet = new Set(storeSelectedServices);
    if (newService) {
        const services = newService.split(',');
        services.forEach((item) => {
            if (selectedServicesSet.has(item)) {
                selectedServicesSet.delete(item);
            } else {
                selectedServicesSet.add(newService);
            }
        });
    }
    return [...selectedServicesSet];
}

function formatServicesResponse(state = {}, response) {
    if (response) {
        return Object.keys(response).map((item) => ({
            label: response[item],
            value: item,
        }));
    }
    const {
        context: {
            preferences: { storeFilterServices },
        },
    } = state;
    return storeFilterServices;
}

function* getLatLongUsingZipcode(zipCode) {
    /* eslint consistent-return: 0 */
    if (!__SERVER__) {
        return yield call(getLatLongUsingGeocode, zipCode);
    }
}

const getPreviousSearchObject = (state) => {
    const { findAStorePageInfo } = state;
    if (!findAStorePageInfo) return {};
    const previousSearchObject = {
        zipCode: findAStorePageInfo.zipCode,
        userLatLong: findAStorePageInfo.userLatLong,
        miles: findAStorePageInfo.miles,
    };
    if (findAStorePageInfo.showAvailable) previousSearchObject.showAvailable = true;
    if (findAStorePageInfo.useActualLocation) previousSearchObject.useActualLocation = true;
    if (findAStorePageInfo.skus) previousSearchObject.skus = true;
    return previousSearchObject;
};

const previousSelectedStore = (state) => ({
    selectedStore: state.selectedStore,
    findAStorePageInfo: state.findAStorePageInfo,
});

export function* findStoresSaga(action) {
    try {
        // save the search parameter in redux
        yield put({ type: SET_SEARCH_OBJECT, payload: { ...action.payload } });

        if (!action.payload.isPDPMajorAppliances) {
            yield put({ type: SHOW_STORE_LOADER });
        }
        yield put({ type: STORES_ON_LOAD });
        const getStoresPayload = {};

        let storeService = [];
        if (action.payload.services) {
            storeService = action.payload.services;
        } else {
            storeService = action.payload.isPDPMajorAppliances
                ? yield select(getStoreSelectedServices, action.payload.storeService)
                : yield select(getStoreSelectedServices);
        }
        let userLatLong = '';
        getStoresPayload.radius = action.payload.miles;
        if (action.payload.pageSize) {
            getStoresPayload.pageSize = action.payload.pageSize;
        }

        if (storeService) {
            getStoresPayload.storeService = storeService.toString();
        }

        let address = '';
        const { zipCode } = action.payload;
        const resetStores = {
            zipCode,
            miles: action.payload.miles,
            stores: [],
            nextPageLink: null,
            count: 0,
        };

        if (zipCode) {
            // Todo: Check why call is not working
            // const [latLong, userLocation] = yield all([
            //     call(getLatLong, { address: zipCode }),
            //     call(Location.getLatLongAsPromise),
            // ]);
            yield put({ type: SET_ZIP_CODE, zipCode });

            const featureFlags = yield select(selectFeatureFlags);
            const { enableExternalGoogle = true } = featureFlags;
            if (
                enableExternalGoogle &&
                (!featureFlags.enableExternalGoogleOnlyZip || !isNaN(zipCode))
            ) {
                getStoresPayload.location = zipCode;
            } else {
                const getLatLongOption = {
                    useActualLocation: action.payload.useActualLocation
                        ? action.payload.useActualLocation
                        : false,
                };

                const latLong = yield call(getLatLongUsingZipcode, zipCode);

                const userLocationCords = yield call(
                    Location.getLatLongAsPromise,
                    getLatLongOption
                );
                const userLocation = userLocationCords
                    ? { lat: userLocationCords.lat, lng: userLocationCords.lng }
                    : {};

                const invalidLatLong = !latLong || !latLong.lng || !latLong.lat;
                // invalidLatLong: block sending userLatitude & userLongitude when lat & long found with user entered zipcode
                if (userLocation && userLocation.lat && userLocation.lng && invalidLatLong) {
                    userLatLong = `${userLocation.lat},${userLocation.lng}`;
                    getStoresPayload.userLatitude = userLocation.lat;
                    getStoresPayload.userLongitude = userLocation.lng;
                }
                // Invalid address entered. Unable to get lat lng for it.
                if (invalidLatLong) {
                    yield all([
                        put({
                            type: FIND_STORES_PAGE_INVALID_INPUT,
                            storeInfo: resetStores,
                        }),
                        put({ type: HIDE_STORE_LOADER }),
                    ]);
                    if (action.payload.formError) {
                        yield put({
                            type: AnalyticsActionTypes.FORM_ERROR,
                            errorDetails: [
                                {
                                    errorDescription: action.payload.formError,
                                },
                            ],
                        });
                    }
                    return;
                }
                getStoresPayload.latitude = latLong.lat;
                getStoresPayload.longitude = latLong.lng;
                address = `${latLong.lat},${latLong.lng}`;
            }
        } else {
            getStoresPayload.latitude = action.payload.lat;
            getStoresPayload.longitude = action.payload.lng;
            address = `${getStoresPayload.latitude},${getStoresPayload.longitude}`;
            userLatLong =
                getStoresPayload.latitude && getStoresPayload.longitude
                    ? `${getStoresPayload.latitude},${getStoresPayload.longitude}`
                    : userLatLong;
        }

        const response = yield call(getStores, getStoresPayload);

        if (response.status === 200) {
            const storesData = {
                count: 0,
                nextPageLink: '',
            };

            if (response.data && response.data.page && response.data.page.length) {
                storesData.nextPageLink = getPageLinks(response.data);
                storesData.initialPageLink =
                    (response.data.page && response.data.page[0].url) || '';
                storesData.count = response.data.meta.totalStores;
            } else {
                storesData.initialPageLink = '';
            }
            storesData.zipCode = zipCode;
            storesData.miles = action.payload.miles;
            storesData.stores = response.data.stores;
            storesData.userLatLong = userLatLong;

            if (action.payload.setMyDefaultStore && storesData.stores.length) {
                Cookies.save('userStore', storesData.stores[0].id);
                Cookies.save('geoLocStoreId', storesData.stores[0].id);
                yield all([
                    put({ type: FIND_STORES_PAGE_SUCCESS, storeInfo: storesData }),
                    put({
                        type: OrderActionTypes.GET_STORE_BY_STORE_ID_SUCCESS,
                        selectedStore: storesData.stores[0],
                        isGeoStore: action.payload.isGeoStore,
                    }),
                    put({ type: HIDE_STORE_LOADER }),
                ]);
            } else {
                yield all([
                    put({ type: FIND_STORES_PAGE_SUCCESS, storeInfo: storesData }),
                    put({ type: HIDE_STORE_LOADER }),
                ]);
            }
            // If no stores are available then trigger formError
            if (action.payload.formError && !storesData.stores.length) {
                yield put({
                    type: AnalyticsActionTypes.FORM_ERROR,
                    errorDetails: [
                        {
                            errorDescription: action.payload.formError,
                        },
                    ],
                });
            }
        } else {
            yield all([
                put({ type: FIND_STORES_PAGE_SUCCESS, storeInfo: resetStores }),
                put({ type: HIDE_STORE_LOADER }),
            ]);
        }
    } catch (error) {
        yield all([put({ type: FIND_STORES_ERROR, error }), put({ type: HIDE_STORE_LOADER })]);
    }
}

function* FindStoresPaginationSaga(action) {
    try {
        yield put({ type: SHOW_STORE_LOADER });
        // Constructing payload for change store api call
        const findMoreStoresAction = {};
        const { filterByService } = action.payload;
        let pageLink = action.payload.nextPageLink;

        // when filtering by services, need to clear possible storeService string in initialPageLink before appending the whole list
        if (filterByService) {
            let { initialPageLink } = action.payload;
            const filterParamName = 'storeService=';
            let leftIndexOfFilter = initialPageLink.indexOf(filterParamName);

            if (leftIndexOfFilter > -1) {
                if (initialPageLink.charAt(leftIndexOfFilter - 1) === '&') {
                    leftIndexOfFilter -= 1;
                }
                for (
                    let i = leftIndexOfFilter + filterParamName.length;
                    i < initialPageLink.length;
                    i += 1
                ) {
                    if (initialPageLink.charAt(i) === '&') {
                        // have reached the end of service filter
                        initialPageLink =
                            initialPageLink.slice(0, leftIndexOfFilter) + initialPageLink.slice(i);
                        break;
                    }
                    if (i === initialPageLink.length - 1) {
                        // have reached the end of url
                        initialPageLink = initialPageLink.slice(0, leftIndexOfFilter);
                    }
                }
            }
            pageLink = action.payload.services.length
                ? `${initialPageLink}&${filterParamName + action.payload.services.toString()}`
                : initialPageLink;
        }
        // findMoreStoresAction.type = action.type;
        findMoreStoresAction.payload = {
            pageLink,
        };

        // Call the check store Api to get the stores list
        const response = yield call(getMoreStoresByLink, findMoreStoresAction);

        if (response.status === 200) {
            const payload = {};
            payload.nextPageLink = getPageLinks(response.data);
            const actionType = filterByService
                ? FIND_STORES_BY_SERVICE
                : FIND_MORE_STORES_PAGE_SUCCESS;

            payload.stores = response.data.stores;
            payload.count = response.data.meta.totalStores;

            yield all([put({ type: actionType, payload }), put({ type: HIDE_STORE_LOADER })]);
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        yield all([put({ type: FIND_STORES_ERROR, error }), put({ type: HIDE_STORE_LOADER })]);
    }
}

function* prePopulateStores() {
    try {
        const {
            selectedStore,
            findAStorePageInfo: { miles },
        } = yield select(previousSelectedStore);
        const selectedZip = _get(selectedStore, 'storeDetails.zip');
        if (selectedZip) {
            const selectedStoreDetails = {
                payload: {
                    zipCode: selectedZip,
                    miles,
                },
            };
            yield fork(findStoresSaga, selectedStoreDetails);
        }
    } catch (error) {
        // yield put({
        //     type: FIND_MORE_STORES_ERROR,
        //     error,
        // });
    }
}

function* findStoresPageFilter(action) {
    const storeService = yield select(
        getStoreSelectedServices,
        action.payload.storeService || action.payload.filterValue
    );
    yield put({ type: SET_SELECTED_SERVICES, storeService });
    action.payload.services = storeService;

    // 1. if updating storeService filter after search, start with the returned pageLink
    if (action.payload.initialPageLink) {
        yield fork(FindStoresPaginationSaga, action);
    } else {
        const previousSearchObject = yield select(getPreviousSearchObject);
        // 2. if updating storeService filter without performing search yet, should stop search
        let { userLatLong } = previousSearchObject;
        userLatLong = (userLatLong && userLatLong.split(',')) || [];
        if (!previousSearchObject.zipCode && !previousSearchObject.userLatLong) return;
        // 3. if updating storeService filter after an empty search, should continue searching with previous
        action.payload = { ...previousSearchObject, lat: userLatLong[0], lng: userLatLong[1] };
        yield fork(findStoresSaga, action);
    }
}

function* findStoreFilterByServices() {
    try {
        const response = yield call(getFilterByServicePills);
        const pillsInfo = yield select(formatServicesResponse, response.data);
        yield put({ type: GET_FILTER_BY_SERVICES_SUCCESS, payload: pillsInfo });
    } catch (error) {
        const pillsInfo = yield select(formatServicesResponse);
        yield put({ type: GET_FILTER_BY_SERVICES_FAILURE, payload: pillsInfo });
    }
}

export const watchfindAStorePage = function* watchfindAStorePage() {
    yield takeLatest(FIND_STORES_PAGE, findStoresSaga);
};

watchfindAStorePage.sagaName = 'findStoresSaga';

export const watchMoreFindAStorePage = function* watchMoreFindAStorePage() {
    yield takeLatest(FIND_MORE_STORES_PAGE, FindStoresPaginationSaga);
};

watchMoreFindAStorePage.sagaName = 'FindStoresPaginationSaga';

export const watchPrePopulateStores = function* watchPrePopulateStores() {
    yield takeLatest(PRE_POPULATE_STORES, prePopulateStores);
};

watchPrePopulateStores.sagaName = 'prePopulateStoresSaga';

export const watchFindStoresPageFilter = function* watchFindStoresPageFilter() {
    yield takeLatest(FIND_STORES_PAGE_FILTER, findStoresPageFilter);
};

watchFindStoresPageFilter.sagaName = 'findStoresPageFilterSaga';

export const watchFindStoreFilterByServices = function* watchFindStoreFilterByServices() {
    yield takeLatest(GET_FILTER_BY_SERVICES, findStoreFilterByServices);
};

watchFindStoreFilterByServices.sagaName = 'findStoreFilterByServices';
