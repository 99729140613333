import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS } from '../actionTypes/FooterNavigationActionTypes';

const initialState = () => {
    const cachedData = JSON.parse(LocalStorage.getData('irisfooterCategories', true));
    const footerLinkInfo = cachedData || [];
    return footerLinkInfo;
};

const irisGlobalFooterNavigationReducer = function irisGlobalFooterNavigationReducer(
    state = initialState(),
    action
) {
    switch (action.type) {
        case IRIS_GLOBAL_FOOTER_NAVIGATION_GET_SUCCESS:
            return action.footerLinkInfo;

        default:
            return state;
    }
};

irisGlobalFooterNavigationReducer.reducerName = 'IrisGlobalFooterNavigation';

export default irisGlobalFooterNavigationReducer;
