import { takeLatest, call, fork, put } from 'redux-saga/effects';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import Location from 'yoda-core-components/lib/helpers/Location/Location';
import { DP_USER_DEFAULT_STORE } from '../common/Constants';
import Storage from '../helpers/Storage/Storage';
import {
    SET_MY_DEFAULT_STORE,
    SET_MY_DEFAULT_STORE_ERROR,
    SET_USER_STORES,
} from '../actionTypes/SetMyDefaultStoreActionTypes';
import { findStoresSaga } from './FindAStorePageSaga';
import { getStoresSaga } from './StoreSaga';
import User from '../helpers/User/User';

function* FindSetMyDefaultStoreSaga() {
    try {
        let stores = [];

        // Check for default store
        const userStore = Storage.load('userStore', true);
        // If we have the store Id then fetch the store info using store Id else find a store using location.
        if (userStore) {
            yield fork(getStoresSaga, { payload: { storeId: userStore } });
        } else {
            const additionalParams = {
                miles: 25,
                setMyDefaultStore: true,
                isGeoStore: true,
            };
            const userLocation = yield call(Location.getLatLongAsPromise, additionalParams);

            if (userLocation.lat && userLocation.lng) {
                yield fork(findStoresSaga, { payload: userLocation });
            }
        }

        // If user is authenticated then fetch user stores and set it as default store in header
        // TODO: Setting user stores is not required - CHeck and remove
        if (User.isUserLoggedIn()) {
            const userDefLocalStore = TokenProvider.get(DP_USER_DEFAULT_STORE);
            if (userDefLocalStore && userDefLocalStore !== '') {
                stores = JSON.parse(userDefLocalStore);
            }
        }

        if (stores.length) {
            yield put({
                type: SET_USER_STORES,
                stores,
            });
        }
    } catch (error) {
        yield put({
            type: SET_MY_DEFAULT_STORE_ERROR,
            error,
        });
    }
}

const watchSetMyDefaultStore = function* watchSetMyDefaultStore() {
    yield takeLatest(SET_MY_DEFAULT_STORE, FindSetMyDefaultStoreSaga);
};

watchSetMyDefaultStore.sagaName = 'FindSetMyDefaultStoreSaga';

export default watchSetMyDefaultStore;
