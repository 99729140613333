import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'HeaderChunksAndFooterLazy',
        path: () => path.join(__dirname, './ExitPos'),
        resolve: () => require.resolveWeak('./ExitPos'),
        load: () =>
            Promise.all([
                import(/* webpackChunkName: "HeaderChunksAndFooterLazy" */ './ExitPos'),
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => null,
        error: (e) => {
            console.log(e);
        },
        timeout,
    }
);
