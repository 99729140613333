const PRODUCTSEARCH_GET_REQUEST = 'PRODUCTSEARCH_GET_REQUEST';
const PRODUCTSEARCH_GET_SUCCESS = 'PRODUCTSEARCH_GET_SUCCESS';
const PRODUCTSEARCH_GET_ERROR = 'PRODUCTSEARCH_GET_ERROR';
const PRODUCTSEARCHDETAIL_GET_SUCCESS = 'PRODUCTSEARCHDETAIL_GET_SUCCESS';
const PRODUCTSEARCHDETAIL_GET_ERROR = 'PRODUCTSEARCHDETAIL_GET_ERROR';
const RESET_PRODUCTSEARCH_RESULTS = 'RESET_PRODUCTSEARCH_RESULTS';

// Bloomreach Action constanst
const PRODUCTSEARCH_GET_REQUEST_BR = 'PRODUCTSEARCH_GET_REQUEST_BR';
const PRODUCTSEARCH_GET_SUCCESS_BR = 'PRODUCTSEARCH_GET_SUCCESS_BR';
const PRODUCTSEARCH_GET_ERROR_BR = 'PRODUCTSEARCH_GET_ERROR_BR';
const RESET_PRODUCTSEARCH_RESULTS_BR = 'RESET_PRODUCTSEARCH_RESULTS_BR';

export default {
    PRODUCTSEARCH_GET_REQUEST,
    PRODUCTSEARCH_GET_SUCCESS,
    PRODUCTSEARCH_GET_ERROR,
    PRODUCTSEARCHDETAIL_GET_SUCCESS,
    PRODUCTSEARCHDETAIL_GET_ERROR,
    RESET_PRODUCTSEARCH_RESULTS,
    PRODUCTSEARCH_GET_REQUEST_BR,
    PRODUCTSEARCH_GET_SUCCESS_BR,
    PRODUCTSEARCH_GET_ERROR_BR,
    RESET_PRODUCTSEARCH_RESULTS_BR,
};
