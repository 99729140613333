import {
    IS_SESSION_TIMED_OUT,
    CLEAR_SESSION_TIMED_OUT_MSG,
    SET_SESSION_TIMED_OUT_MSG,
} from '../actionTypes/SessionActionTypes';

function SessionReducer(
    state = {
        signedOut: false,
        isSessionTimedOut: false,
        errorMessage: null,
        redirectUrl: null,
        errorFrom: null,
    },
    action
) {
    const newState = { ...state };
    switch (action.type) {
        case IS_SESSION_TIMED_OUT:
            newState.isSessionTimedOut = action.payload;
            return newState;
        case SET_SESSION_TIMED_OUT_MSG:
            newState.errorMessage = action.payload && action.payload.errorMessage;
            newState.redirectUrl = action.payload && action.payload.redirectUrl;
            newState.errorFrom = action?.payload?.errorFrom;
            return newState;
        case CLEAR_SESSION_TIMED_OUT_MSG:
            newState.errorMessage = null;
            newState.redirectUrl = null;
            newState.errorFrom = null;
            return newState;
        default:
            return state;
    }
}

SessionReducer.reducerName = 'session';

export default SessionReducer;
