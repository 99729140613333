import { SHOW_LOADER, SHOW_CUSTOM_LOADER, REMOVE_LOADER } from '../actionTypes/LoadingActionTypes';

export const showLoader = () => ({
    type: SHOW_LOADER,
});

export const showCustomLoader = (payload) => ({
    type: SHOW_CUSTOM_LOADER,
    payload,
});

export const removeLoader = () => ({
    type: REMOVE_LOADER,
});

export default {
    showLoader,
    showCustomLoader,
    removeLoader,
};
