import * as SignInActionTypes from '../actionTypes/SigninActionTypes';
import * as RewardsActionTypes from '../actionTypes/RewardsActionTypes';

export const postSignIn = (payload) => ({
    type: SignInActionTypes.POST_SIGNIN_DETAILS,
    payload,
    isSignInSliderReq: payload.isSignInSliderReq,
});

export const clearSignIn = () => ({
    type: SignInActionTypes.CLEAR_SIGNIN_DETAILS,
});

export const signinPageLoadedAction = (payload) => ({
    type: SignInActionTypes.SIGNIN_PAGE_LOADED_SUCCESS,
    payload,
});

export const signinFormErrorAction = (errors) => ({
    type: SignInActionTypes.SIGNIN_FORM_ERROR,
    payload: errors,
});

export const signinPostRequestErrorAction = (errors) => ({
    type: SignInActionTypes.POST_SIGNIN_REQUEST_ERROR_ANALYTICS,
    payload: errors,
});

export const signinAttemptedAction = () => ({
    type: SignInActionTypes.SIGNIN_ATTEMPTED,
});

export const signinFailedAction = (isPhone = false) => ({
    type: SignInActionTypes.SIGNIN_FAILED,
    isPhone,
});

export const signinSuccessAction = (
    isPhone = false,
    rememberMe = false,
    keepMeLoggedIn = false
) => ({
    type: SignInActionTypes.SIGNIN_SUCCESS,
    isPhone,
    rememberMe,
    keepMeLoggedIn,
});

export const groupBPageLoadedAction = () => ({
    type: SignInActionTypes.GROUPB_PAGE_LOADED_SUCCESS,
});

export const getHeader = (hostName) => ({
    type: RewardsActionTypes.GET_HEADER_REQUEST,
    payload: hostName,
});

export const getFooter = (hostName) => ({
    type: RewardsActionTypes.GET_FOOTER_REQUEST,
    payload: hostName,
});

export const checkRegistry = (itemId, id, quantity, accountId, accessToken, host) => ({
    type: SignInActionTypes.GET_REGISTRY_REQUEST,
    payload: { itemId, product: { id }, quantity, accountId, accessToken, host },
});

export const refreshHeader = () => ({
    type: SignInActionTypes.REFRESH_HEADER,
});

// export default postSignIn;
