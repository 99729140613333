import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';
import { flushServerSideUsedSvg } from 'yoda-core-components/lib/SvgPortal/SvgPortal';
import get from 'lodash/get';
import Components from './Components';
import yodaSiteComponentsReducers from '../../reducers/CommonReducer';
import yodaSiteComponentsSagas from '../../sagas/CommonSaga';
import webviewInstall from './webViewInstallExternal';

export class CommonExternal {
    /**
     * initializing redux store with default value
     */
    store = {};

    static currentStore = null;

    constructor() {
        this.commonComponents = Components;
        this.flushServerSideUsedSvg = flushServerSideUsedSvg; // added method to be able to flush external components icons on app-shell
    }

    getConfig() {
        return new Promise((resolve) => {
            let newContext = {};
            /* istanbul ignore next */
            if (window.__FRAGMENT__PRELOAD__ && window.__FRAGMENT__PRELOAD__.length) {
                window.__FRAGMENT__PRELOAD__.forEach((data) => {
                    newContext = { ...newContext, ...data };
                });
            }
            RestClient.setContext(newContext.context);
            CommonExternal.createNewStore(newContext);
            resolve(true);
        });
    }

    getReducers() {
        return yodaSiteComponentsReducers;
    }

    /**
     * create context for the components to get it as a state
     */
    static createContextState(state = {}) {
        return state;
    }

    /**
     * getCombinedReducer will return root reducer
     */
    static getCombinedReducer() {
        const allreducer = yodaSiteComponentsReducers;
        const reducerCombined = combineReducers({
            ...allreducer,
            context: CommonExternal.createContextState,
        });
        return reducerCombined;
    }

    /**
     * createNewStore will compose saga, reducer and create store
     */
    static createNewStore(newStore) {
        const sagaMiddleware = createSagaMiddleware();
        const rootReducer = CommonExternal.getCombinedReducer();

        CommonExternal.store = createStore(rootReducer, newStore, applyMiddleware(sagaMiddleware));
        const isNative = get(newStore, 'context.isNative', false);
        /* istanbul ignore next */
        if (isNative) {
            webviewInstall(CommonExternal.store);
        }
        /* istanbul ignore next */
        CommonExternal.task = yodaSiteComponentsSagas
            ? sagaMiddleware.run(yodaSiteComponentsSagas)
            : null;
    }

    getComponentForExternal(name, customProps, elementId) {
        return this.renderComponent(name, customProps, elementId);
    }
    /** *
     * getComponentForExternal is for vendor sites.
     * @name: component name
     * @customProps: pass all the props required for tyhe component
     * @elementId: dom id to add
     */

    renderComponent(name, customProps, elementId) {
        try {
            /* istanbul ignore next */
            if (!name) {
                console.warn('Common Comp:: component name is madatory ');
                return;
            }
            /* istanbul ignore next */
            if (!elementId) {
                console.warn('Common Comp:: element Id is madatory ');
                return;
            }
            /* istanbul ignore next */
            if (!this.commonComponents[name]) {
                console.warn('Common Comp: forgot to export yoda component');
            }
            let fragmentName = name;
            if (window.__FRAGMENT__PRELOAD__ && window.__FRAGMENT__PRELOAD__.length) {
                const headerConfig = window.__FRAGMENT__PRELOAD__.find(
                    (item) => item?.context?.requestUrl?.indexOf('/header') > 0
                );
                const enableHeaderV3 = headerConfig?.context?.featureFlags?.enableHeaderV3 || false;
                fragmentName = enableHeaderV3 && name === 'header' ? 'headerV3' : name;
            }
            const CustomComponent = this.commonComponents[fragmentName];
            /* istanbul ignore next */
            this.getConfig().then(() => {
                ReactDOM.render(
                    <Provider store={CommonExternal.store}>
                        <CustomComponent {...customProps} />
                    </Provider>,
                    document.getElementById(elementId)
                );
            });
        } catch (e) {
            console.warn('Common Comp:: unanble to paint');
        }
    }
}

export default new CommonExternal();
