import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getProductsPriceByBrowseApi } from 'yoda-interfaces/lib/Product/PricingApi';
import { SHOP_THE_LOOK_GET_REQUEST } from '../actionTypes/ShopTheLookActionTypes';
import {
    getShopTheLookPricing,
    throwErrorForShopTheLookPricing,
} from '../actions/ShopTheLookAction';

function* shopTheLookSaga(data) {
    try {
        const productPrices = yield all([call(getProductsPriceByBrowseApi, data)]);
        yield put(getShopTheLookPricing(productPrices));
    } catch (error) {
        yield put(throwErrorForShopTheLookPricing(error));
    }
}

const watchShopTheLookRequest = function* watchShopTheLookRequest() {
    yield takeLatest(SHOP_THE_LOOK_GET_REQUEST, shopTheLookSaga);
};

watchShopTheLookRequest.sagaName = 'shopTheLookRequest';

export default watchShopTheLookRequest;
