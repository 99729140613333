const GET_REGION_REQUEST = 'GET_REGION_REQUEST';
const GET_REGION_ERROR = 'GET_REGION_ERROR';
const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';
const JS_UPDATE_PRICING_STATUS = 'JS_UPDATE_PRICING_STATUS';
const JS_SET_REGION = 'JS_SET_REGION';

export default {
    GET_REGION_REQUEST,
    GET_REGION_ERROR,
    GET_REGION_SUCCESS,
    JS_UPDATE_PRICING_STATUS,
    JS_SET_REGION,
};
