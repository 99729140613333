export const ADJUSTMENTS_GET_REQUEST = 'ADJUSTMENTS_GET_REQUEST';
export const ADJUSTMENTS_GET_SUCCESS = 'ADJUSTMENTS_GET_SUCCESS';
export const ADJUSTMENTS_GET_ERROR = 'ADJUSTMENTS_GET_ERROR';
export const ADJUSTMENTS_POST_REQUEST = 'ADJUSTMENTS_POST_REQUEST';
export const ADJUSTMENTS_POST_SUCCESS = 'ADJUSTMENTS_POST_SUCCESS';
export const ADJUSTMENTS_APPLIED_SUCCESS = 'ADJUSTMENTS_APPLIED_SUCCESS';
export const ADJUSTMENTS_POST_ERROR = 'ADJUSTMENTS_POST_ERROR';
export const ADJUSTMENTS_BY_ID_GET_REQUEST = 'ADJUSTMENTS_BY_ID_GET_REQUEST';
export const ADJUSTMENTS_BY_ID_GET_SUCCESS = 'ADJUSTMENTS_BY_ID_GET_SUCCESS';
export const ADJUSTMENTS_BY_ID_GET_ERROR = 'ADJUSTMENTS_BY_ID_GET_ERROR';
export const ADJUSTMENTS_BY_ID_DELETE_REQUEST = 'ADJUSTMENTS_BY_ID_DELETE_REQUEST';
export const ADJUSTMENTS_BY_ID_DELETE_SUCCESS = 'ADJUSTMENTS_BY_ID_DELETE_SUCCESS';
export const ADJUSTMENTS_BY_ID_DELETE_ERROR = 'ADJUSTMENTS_BY_ID_DELETE_ERROR';
export const ADJUSTMENTS_UPDATED = 'ADJUSTMENTS_UPDATED';
export const ADJUSTMENT_EXPIRED = 'ADJUSTMENT_EXPIRED';
export const ADJUSTMENTS_GET_SUCCESS_COUPONPAGE = 'ADJUSTMENTS_GET_SUCCESS_COUPONPAGE';
export const ADJUSTMENTS_ERROR_TRIGGER = 'ADJUSTMENTS_ERROR_TRIGGER';
export const ADJUSTMENTS_POST_SERIAL = 'ADJUSTMENTS_POST_SERIAL';
export const ADJUSTMENTS_INLINE_LOADER = 'ADJUSTMENTS_INLINE_LOADER';
export const APPLIED_DISCOUNTS_GET_REQUEST = 'APPLIED_DISCOUNTS_GET_REQUEST';
