import { put, call, takeLatest } from 'redux-saga/effects';
import { hoverPannel, getPageTemplate } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import {
    SLIDER_CATEGORY_LOAD_SUCCESS,
    SLIDER_CATEGORY_LOAD_ERROR,
    SLIDER_HOVER_LOAD_REQUEST,
    SLIDER_HOVER_LOAD_SUCCESS,
    SLIDER_CATEGORY_LOAD_REQUEST,
} from '../actionTypes/HamburgerActionTypes';

function* ActiveMenuListSaga() {
    try {
        const categoriesData = yield call(getPageTemplate);
        const categories = categoriesData.data.topNavigation;
        yield put({ type: SLIDER_CATEGORY_LOAD_SUCCESS, categories });
    } catch (error) {
        yield put({ type: SLIDER_CATEGORY_LOAD_ERROR, error });
    }
}

function* loadHoverAPI(params) {
    try {
        const nidWithoutN = params.nid.split('-')[1];
        const categoriesData = yield call(hoverPannel, nidWithoutN);
        const categories = categoriesData.data;
        yield put({ type: SLIDER_HOVER_LOAD_SUCCESS, categories });
    } catch (e) {
        yield put({ type: SLIDER_CATEGORY_LOAD_ERROR, e });
    }
}

export const watchhamburgerchange = function* watchhamburgerchange() {
    yield takeLatest(SLIDER_CATEGORY_LOAD_REQUEST, ActiveMenuListSaga);
};

watchhamburgerchange.sagaName = 'HamburgerActiveMenuListSaga';

export const watchHoverApi = function* watchHoverApi() {
    yield takeLatest(SLIDER_HOVER_LOAD_REQUEST, loadHoverAPI);
};

watchHoverApi.sagaName = 'HamburgerLoadHoverAPI';
