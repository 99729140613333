import { SITEWIDE_WIDGET_SELECTION } from '../actionTypes/CMSwitchWidgetActionTypes';

/* istanbul ignore next */

const getSiteWideWidgetSelection = (siteWideWidgetValue) => ({
    type: SITEWIDE_WIDGET_SELECTION,
    selectedValue: siteWideWidgetValue,
});

export default {
    getSiteWideWidgetSelection,
};
