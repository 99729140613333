import { put, takeLatest, call, select } from 'redux-saga/effects';
import { TimerHelper } from 'yoda-core-components/lib/components/Timer/Timer';
import _get from 'lodash/get';
import getCountUpStickyTimer from 'yoda-interfaces/lib/StickyTimer/StickyTimerApi';
import getIrisStickyTimer from 'yoda-interfaces/lib/IrisStickyTimer/IrisStickyTimerApi';
import Storage from '../helpers/Storage/Storage';
import { selectPreferences, selectFeatureFlags, isPreview } from '../selectors/ContextSelector';
import * as StickyTimerActionTypes from '../actionTypes/StickyTimerActionTypes';

function* StickyTimerSaga(params) {
    try {
        let stickyTimerData;
        let cacheExpired = false;
        stickyTimerData = yield call(Storage.load, 'stickyTimerData');
        const preferences = yield select(selectPreferences);
        const featureFlags = yield select(selectFeatureFlags);
        const preview = yield select(isPreview);
        const stickyTimerDetails = _get(preferences, 'stickyTimerDetails');
        const enableEventBasedStickyTimer = _get(featureFlags, 'enableEventBasedStickyTimer');
        const stickyTimerDataVersion = _get(stickyTimerDetails, 'version', null);
        const cachedTS = _get(stickyTimerData, 'cachedTS', '');
        const cachedContentEnd = enableEventBasedStickyTimer
            ? _get(stickyTimerData, 'content.offerEndDate.', false)
            : _get(stickyTimerData, 'publishingEndDate', false);

        // Check if the content is stored in the local storage and the cached TS value is present
        if (cachedContentEnd && cachedTS) {
            // Check if the content is expired
            cacheExpired = TimerHelper.isExpired(
                cachedContentEnd,
                cachedTS,
                _get(stickyTimerDetails, 'ttl_limit', 30)
            );
        }

        /** For these scenarios we are going to call the API
         * Local storage data is empty
         * version is updated in the preferences
         * cached data is expired
         * there is no value for the cached TS
         */
        if (
            !cachedContentEnd ||
            stickyTimerData.version < stickyTimerDataVersion ||
            cacheExpired ||
            !cachedTS
        ) {
            stickyTimerData = enableEventBasedStickyTimer
                ? yield call(getIrisStickyTimer, params.urlParams)
                : yield call(getCountUpStickyTimer, stickyTimerDetails);
            stickyTimerData = enableEventBasedStickyTimer
                ? _get(stickyTimerData, 'data.content.components[0]', {})
                : _get(stickyTimerData, 'data[0]', {});
            stickyTimerData.cachedTS = Date.now();
            stickyTimerData.version = stickyTimerDataVersion;
            if (!preview)
                yield call(Storage.save, 'stickyTimerData', stickyTimerData || {}, '', '', false);
        }
        yield put({ type: StickyTimerActionTypes.STICKYTIMER_GET_SUCCESS, stickyTimerData });
    } catch (error) {
        yield put({ type: 'STICKYTIMER_GET_ERROR', error });
    }
}

const watchStickyTimerActionRequest = function* watchStickyTimerActionRequest() {
    yield takeLatest(StickyTimerActionTypes.STICKYTIMER_GET_REQUEST, StickyTimerSaga);
};

watchStickyTimerActionRequest.sagaName = 'StickyTimerSaga';

export default watchStickyTimerActionRequest;
