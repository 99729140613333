import { takeEvery, put, call } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { createGuestAccount } from 'yoda-interfaces/lib/Account/AccountApi';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { GENERIC_API_ERROR, SET_SERVICE_ERROR } from '../common/Constants';
import * as AccessTokenProviderActionTypes from '../actionTypes/AccessTokenProviderActionTypes';
// import * as LoadingAction from '../actions/LoadingAction';

export const storeAccessToken = (response) => {
    TokenProvider.storeTokens(response, 'body');
};

function* accessTokenProviderSaga(action) {
    try {
        const response = yield call(createGuestAccount);
        console.log('In accessTokenProviderSaga &&&&&&&&&& ', response);
        console.log('In getTokens $$$$$$$$$$ ', response);
        if (!isEmpty(response) && response.status !== null && response.status === 201) {
            console.log('In getTokens before storeAccessToken ******** ', response);
            response.isGuestUser = 'true';
            storeAccessToken(response);
            yield put(action.payload);
        } else {
            let errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
            errorInfo = errorInfo || {};
            const errorHandlerInfo = {};
            errorHandlerInfo.errorCode = isEmpty(errorInfo.errorCode)
                ? GENERIC_API_ERROR
                : errorInfo.errorCode;
            errorHandlerInfo.errorMessage = errorInfo.errorMessage;
            errorHandlerInfo.showRefresh = true;
            yield put({ type: SET_SERVICE_ERROR, errorHandlerInfo });
        }
    } catch (error) {
        // yield put(LoadingAction.removeLoader());
        yield put({ type: 'GET_ACCESS_TOKEN_REQUEST_ERROR', error });
    }
}

const watchAccessTokenProviderRequest = function* watchAccessTokenProviderRequest() {
    yield takeEvery(
        AccessTokenProviderActionTypes.GET_ACCESS_TOKEN_REQUEST,
        accessTokenProviderSaga
    );
};
watchAccessTokenProviderRequest.sagaName = 'watchAccessTokenProviderRequest';

export default watchAccessTokenProviderRequest;
