import actionTypes from '../actionTypes/ApiErrorActionTypes';

function ApiErrorReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.API_RESPONSE_ERROR:
            return { ...state, [action.payload]: true };
        case actionTypes.CLEAR_ERROR_MSG: {
            const newState = { ...state };
            delete newState[action.payload];
            return newState;
        }
        default:
            return state;
    }
}
ApiErrorReducer.reducerName = 'apiErrors';
export default ApiErrorReducer;
