export const POST_SIGNIN_DETAILS = 'POST_SIGNIN_DETAILS';
export const POST_SIGNIN_REQUEST_SUCCESS = 'POST_SIGNIN_REQUEST_SUCCESS';
export const POST_SIGNIN_REQUEST_ERROR = 'POST_SIGNIN_REQUEST_ERROR';
export const SIGNIN_PAGE_LOADED_SUCCESS = 'SIGNIN_PAGE_LOADED_SUCCESS';
export const SIGNIN_FORM_ERROR = 'SIGNIN_FORM_ERROR';
export const POST_SIGNIN_REQUEST_ERROR_ANALYTICS = 'POST_SIGNIN_REQUEST_ERROR_ANALYTICS';
export const GROUPB_PAGE_LOADED_SUCCESS = 'GROUPB_PAGE_LOADED_SUCCESS';
export const GET_REGISTRY_REQUEST = 'GET_REGISTRY_REQUEST';
export const GET_REGISTRY_REQUEST_SUCCESS = 'GET_REGISTRY_REQUEST_SUCCESS';
export const GET_REGISTRY_REQUEST_ERROR = 'GET_REGISTRY_REQUEST_ERROR';
export const SAVED_ITEMS_MERGE = 'SAVED_ITEMS_MERGE';
export const SIGNIN_ATTEMPTED = 'SIGNIN_ATTEMPTED';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const CLEAR_SIGNIN_DETAILS = 'CLEAR_SIGNIN_DETAILS';
export const REFRESH_HEADER = 'REFRESH_HEADER';
export const RELOAD_LOCATION = 'RELOAD_LOCATION';
