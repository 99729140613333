import { takeLatest, put, call } from 'redux-saga/effects';
import { optin } from 'yoda-interfaces/lib/Account/AccountApi';
import get from 'lodash/get';
import FactorySaga from './FactorySaga';
import * as OrderActionTypes from '../actionTypes/OrderActionTypes';

function* optInSaga(actions) {
    try {
        const from = get(actions, 'payload.from');
        if (from) {
            delete actions.payload.from;
        }
        const status = yield call(FactorySaga, optin, actions);

        status.from = from || 'footer'; // comes from footer by default
        status.type = get(actions, 'payload.type', 'EMAIL');

        if (status.isSuccess) {
            yield put({ type: OrderActionTypes.OPT_IN_POST_SUCCESS, status });
        } else {
            yield put({ type: OrderActionTypes.OPT_IN_POST_ERROR, status });
        }
    } catch (err) {
        console.error('ERROR in OPTIN_SAGA', err);
        yield put({
            type: OrderActionTypes.OPT_IN_POST_ERROR,
            status: {
                isSuccess: false,
                response: {},
            },
        });
    }
}

const watchOptInRequest = function* watchOptInRequest() {
    yield takeLatest(OrderActionTypes.OPT_IN_POST_REQUEST, optInSaga);
};

watchOptInRequest.sagaName = 'optInSaga';

export default watchOptInRequest;
