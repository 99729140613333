import universal from 'react-universal-component';
import React from 'react';
import universalImport from 'babel-plugin-universal-import/universalImport';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import path from 'path';
import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'SignInSliderConnectLazy',
        path: () => path.join(__dirname, './SignInSliderConnect'),
        resolve: () => require.resolveWeak('./SignInSliderConnect'),
        load: () =>
            Promise.all([
                import(/* webpackChunkName: "SignInSliderConnectLazy" */ './SignInSliderConnect'), // css name
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => (
            <div className="loading-wrapper">
                <Loader keepOverlay loaderClass="lazy-loader" />
            </div>
        ),
        error(...args) {
            console.error('SIGNIN SLIDER ERROR STACK:', args);
            return window.location.assign('/signin');
        },
        timeout,
    }
);
