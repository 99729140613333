import { takeLatest, call, select } from 'redux-saga/effects';
import { getProfile } from 'yoda-interfaces/lib/Account/AccountApi';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import * as SessionActionTypes from '../actionTypes/SessionActionTypes';
import { DP_FULL_ACCESS_EXPIRY } from '../common/Constants';
import FactorySaga from './FactorySaga';

let accessTokenTimer;

export function* accessTokenAuthCheck(action) {
    const context = (state) => state.context;
    const appContext = yield select(context);
    const { featureFlags } = appContext || {};
    if (
        featureFlags.recognisedStatePageRefresh &&
        TokenProvider.get('DP_FULL_ACCESS_EXPIRED') === 'true'
    ) {
        const profileDetails = yield call(FactorySaga, getProfile, action);
        console.log('profileDetails', profileDetails);
        const targetPath = TokenProvider.get('DP_FULL_ACCESS_TARGET');
        setTimeout(() => {
            LocalStorage.removeData('DP_FULL_ACCESS_EXPIRED');
            LocalStorage.removeData('DP_FULL_ACCESS_TARGET');
            if (targetPath === 'RELOAD_PAGE') {
                window.location.reload();
            } else {
                window.location.assign(targetPath);
            }
        }, 11 * 1000);
    }
}

export function* accessTokenStartTimer(expiryTimer) {
    const context = (state) => state.context;
    const appContext = yield select(context);
    const { featureFlags } = appContext || {};
    const { preferences } = appContext || {};

    if (featureFlags.recognisedStatePageRefresh && preferences.sessionHandlerUrl) {
        const currentPath = window.location.pathname ? window.location.pathname : undefined;
        const targetPath = preferences.sessionHandlerUrl[currentPath];
        const expiryTime =
            expiryTimer && typeof expiryTimer !== 'object'
                ? expiryTimer
                : TokenProvider.get(DP_FULL_ACCESS_EXPIRY);
        const expiryTimeout = (Number(expiryTime) + 11) * 1000;
        if (targetPath && expiryTimeout) {
            if (accessTokenTimer) {
                clearTimeout(accessTokenTimer);
            }
            // Adding a 11 seconds delay due to anyother api's fired and hystrix config
            accessTokenTimer = setTimeout(() => {
                if (TokenProvider.get('DP_USER_STATE') === '1') {
                    TokenProvider.set('DP_FULL_ACCESS_EXPIRED', 'true');
                    TokenProvider.set('DP_FULL_ACCESS_TARGET', targetPath);
                }
            }, expiryTimeout);
        }
    }
}

const watchSessionsRequest = function* watchSessionsRequest() {
    yield takeLatest(SessionActionTypes.ACCESS_TOKEN_TIMER, accessTokenStartTimer);
    yield takeLatest(SessionActionTypes.ACCESS_TOKEN_AUTH_CHECK, accessTokenAuthCheck);
};

watchSessionsRequest.sagaName = 'watchSessionsRequest';

export default watchSessionsRequest;
