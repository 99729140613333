import {
    GET_SITE_LIST_SUCCESS,
    GET_SITE_LIST_GUEST,
    SITE_GETLIST_BASEDON_ID_SUCCESS,
    CREATENEW_SITE_LIST_SUCCESS,
    MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS,
    GET_SITE_LIST_ERROR,
    GET_SITE_LIST,
    ADD_SITE_ITEM_TO_LIST_SUCCESS,
    SITE_MOVETOLIST_ERROR_EVENT,
    CLEAR_LIST_ERROR,
} from '../actionTypes/SavedListToolTip';

function SavedListToolTipReducer(state = { listItems: { data: {} }, products: {} }, action) {
    switch (action.type) {
        case GET_SITE_LIST: {
            const listItems = {
                data: {},
            };
            return { ...state, listItems };
        }

        case GET_SITE_LIST_GUEST: {
            const listItems = {
                data: {
                    totalList: 1,
                    listData: [
                        {
                            id: 'myFavorites',
                            name: 'My Favorites',
                            type: 'myFavorites',
                            isPublic: false,
                            items: [],
                        },
                    ],
                },
                status: 200,
            };
            return { ...state, listItems };
        }

        case GET_SITE_LIST_SUCCESS: {
            const listItems = action.listItems.response
                ? action.listItems.response
                : action.listItems;
            return { ...state, listItems, movedToList: {} };
        }

        case CREATENEW_SITE_LIST_SUCCESS: {
            const createList = action.list;
            return { ...state, createList };
        }

        case MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS: {
            const movedToList = action.movedToListResponse;
            return { ...state, movedToList, createList: {} };
        }

        case SITE_MOVETOLIST_ERROR_EVENT: {
            const movedToList = action.payload;
            return { ...state, movedToList };
        }

        case ADD_SITE_ITEM_TO_LIST_SUCCESS: {
            const addItems = action.addToListResponse;
            return { ...state, addItems, createList: {}, products: {} };
        }

        case SITE_GETLIST_BASEDON_ID_SUCCESS: {
            const { products } = action;
            return { ...state, products };
        }

        case GET_SITE_LIST_ERROR: {
            return state;
        }
        case CLEAR_LIST_ERROR: {
            return { ...state, movedToList: {} };
        }

        default:
            return state;
    }
}

SavedListToolTipReducer.reducerName = 'MTLSavedLists';
export default SavedListToolTipReducer;
