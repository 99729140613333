import {
    BREADCRUMBNAV_GET_SUCCESS,
    BREADCRUMBNAV_CLEAR,
} from '../actionTypes/BreadCrumbNavActionTypes';

function BreadCrumbNavReducer(state = [], action) {
    switch (action.type) {
        case BREADCRUMBNAV_GET_SUCCESS:
            return action.breadCrumbs;
        case BREADCRUMBNAV_CLEAR:
            return [];
        default:
            return state;
    }
}

BreadCrumbNavReducer.reducerName = 'breadCrumbsData';

export default BreadCrumbNavReducer;
