import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { bindActionCreators } from 'redux';
import SiteComponent from '../SiteComponent/SiteComponent';
import stickyTimerActions from '../../actions/StickyTimerAction';
import getLocationPath from '../../utils/index';
import StickyTimerContent from './StickyTimerContent';

export class StickyTimer extends SiteComponent {
    static propTypes = {
        stickyTimerActions: PropTypes.object,
        stickyTimerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        timerTextConfig: PropTypes.shape({
            h: PropTypes.string,
            m: PropTypes.string,
            s: PropTypes.string,
        }),
        timerWrapperThemeConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        stickyTimerWrapperThemeConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        stickyTimerDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        displaySingleDimentionTimer: PropTypes.bool,
        enableThreshold: PropTypes.bool,
        isPreview: PropTypes.bool,
        tzOffset: PropTypes.number,
        enableEventBasedStickyTimer: PropTypes.bool,
        disableSitewideSeoUrl: PropTypes.bool,
        disableIrisCall: PropTypes.bool,
        renderFromHeader: PropTypes.bool,
        mountOnServer: PropTypes.bool,
        location: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        enableIristzOffset: PropTypes.bool,
        enableTimerSSR: PropTypes.bool,
    };

    static defaultProps = {
        stickyTimerActions: {},
        stickyTimerData: {},
        timerTextConfig: {
            h: 'HOURS',
            m: 'MINS',
            s: 'SECS',
        },
        timerWrapperThemeConfig: {},
        stickyTimerWrapperThemeConfig: {},
        stickyTimerDetails: {},
        displaySingleDimentionTimer: false,
        enableThreshold: false,
        isPreview: false,
        tzOffset: 300,
        enableEventBasedStickyTimer: false,
        disableSitewideSeoUrl: false,
        disableIrisCall: false,
        renderFromHeader: false,
        mountOnServer: true,
        location: {},
        enableIristzOffset: true,
        enableTimerSSR: false,
    };

    hydrateOnMount() {
        /* istanbul ignore next */
        const {
            enableEventBasedStickyTimer,
            disableSitewideSeoUrl,
            disableIrisCall,
            location,
            enableTimerSSR,
        } = this.props;
        const seoUrl = getLocationPath(location.pathname);
        const locationParams = seoUrl + location.search;
        const getUrl = enableEventBasedStickyTimer && !disableSitewideSeoUrl ? locationParams : '';
        /* Feature flag added to call the Timer API call for SSR */
        !disableIrisCall &&
            (!__SERVER__ || enableTimerSSR) &&
            this.props.stickyTimerActions.getStickyTimerDataAction(getUrl);
    }

    render() {
        return <StickyTimerContent {...this.props} />;
    }
}

const mapStateToProps = ({ context, StickyTimerReducer: stickyTimerData = {} }) => {
    const stickyTimerDetails = _get(context, 'preferences.stickyTimerDetails', {});
    const enableThreshold = _get(context, 'featureFlags.enableThreshold', false);
    const enableEventBasedStickyTimer = _get(
        context,
        'featureFlags.enableEventBasedStickyTimer',
        false
    );
    const disableSitewideSeoUrl = _get(context, 'featureFlags.disableSitewideSeoUrl', false);
    const disableIrisCall = _get(context, 'featureFlags.disableIrisCall', false);
    const tzOffset = _get(context, 'tzOffset', 300);
    const isPreview = context.isPreview || false;
    const enableIristzOffset = _get(context, 'featureFlags.enableIristzOffset', true);
    const enableTimerSSR = _get(context, 'featureFlags.enableTimerSSR', false);

    return {
        stickyTimerData,
        stickyTimerDetails,
        enableThreshold,
        enableEventBasedStickyTimer,
        disableSitewideSeoUrl,
        disableIrisCall,
        isPreview,
        tzOffset,
        enableIristzOffset,
        enableTimerSSR,
    };
};

const mapDispatchToProps = (dispatch) => ({
    stickyTimerActions: bindActionCreators({ ...stickyTimerActions }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StickyTimer));
