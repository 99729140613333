import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getL2Category } from 'yoda-interfaces/lib/Catalog/CategoriesApi';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import {
    IRIS_SLIDER_CATEGORY_LOAD_SUCCESS,
    IRIS_SLIDER_CATEGORY_LOAD_REQUEST,
} from '../actionTypes/HamburgerActionTypes';
import { selectPreferences, selectFeatureFlags } from '../selectors/ContextSelector';
import CustomErrorLogger from '../helpers/CustomErrorLogger/CustomErrorLogger';
import getPageType from '../helpers/Utils/pageType';
import logApiErrors from '../helpers/Utils/logApiErrors';

const apiName = 'l2Navigation';

/* eslint-disable */
function* loadL2Cat() {
    const preferences = yield select(selectPreferences);
    let categories;
    try {
        const featureFlags = yield select(selectFeatureFlags);
        let categoriesData;
        if (featureFlags.isDepartmentAPILocalStore) {
            const cachedData = JSON.parse(LocalStorage.getData('irisdepartmentCatIris', true));
            categories =
                !cachedData || cachedData?.createdTS || cachedData?.key === 'desktopMenu'
                    ? ''
                    : cachedData.val;

            if (_isEmpty(categories)) {
                categoriesData = yield call(getL2Category);
                categories = _get(categoriesData, 'data.contents', {});

                if (!_isEmpty(categories)) {
                    const irisMenuStorageKey = { key: 'mobileMenu', val: categories };

                    irisMenuStorageKey &&
                        LocalStorage.setData(
                            'irisdepartmentCatIris',
                            irisMenuStorageKey,
                            true,
                            '',
                            false,
                            _get(preferences, 'cacheDepartmentAPI.cacheMins', 30)
                        );
                }
            }
        } else {
            // This is the existing functionality. Call fetchCategories function.
            categoriesData = yield call(getL2Category);
            categories = _get(categoriesData, 'data.contents', false);
        }

        const errorDetails = logApiErrors(apiName, categoriesData, categories);
        if (!_isEmpty(errorDetails)) {
            const pageType = getPageType(window && window.location.href);
            errorDetails.pageType = pageType;
            yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
            categories = _get(preferences, 'departments', {});
        }
        yield put({ type: IRIS_SLIDER_CATEGORY_LOAD_SUCCESS, categories });
    } catch (error) {
        categories = _get(preferences, 'departments', {});
        const pageType = getPageType();
        const errorDetails = {
            errorType: 'l2 navigation',
            errorDescription: 'categories are not available in l2 navigation',
            pageType,
        };
        yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
        yield put({ type: IRIS_SLIDER_CATEGORY_LOAD_SUCCESS, categories });
    }
}

const watchIrisHamburgerChange = function* watchIrisHamburgerChange() {
    yield takeLatest(IRIS_SLIDER_CATEGORY_LOAD_REQUEST, loadL2Cat);
};

watchIrisHamburgerChange.sagaName = 'IrisHamburgerloadL2Cat';

export default watchIrisHamburgerChange;
