import { OVERLAY_SHOW, OVERLAY_REMOVE } from '../actionTypes/OverlayActionTypes';

const OverlayReducer = function OverlayReducer(state = false, action) {
    switch (action.type) {
        case OVERLAY_SHOW:
            return true;
        case OVERLAY_REMOVE:
            return false;
        default:
            return state;
    }
};

OverlayReducer.reducerName = 'showOverlay';

export default OverlayReducer;
