import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Hamburger from '../../components/IrisHamburger/Hamburger';

const DepartmentWrapper = ({ ...restProps }) => {
    const headerId = get(restProps, 'headerId', 'header');
    const externalDepartmentId = get(restProps, 'externalDepartmentId', 'external_department');
    return (
        <div id={headerId}>
            <div id={externalDepartmentId}>
                <Hamburger enableHamburgerMenuLabel slideOpen isNative {...restProps} />
            </div>
        </div>
    );
};

DepartmentWrapper.propTypes = {
    restProps: PropTypes.object,
};

DepartmentWrapper.defaultProps = {
    restProps: {},
};

export default connect(null, null)(DepartmentWrapper);
