import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * PlaceHolder is used for displaying place holder content until the actual content is got via Api call/other passed.
 * Currently it supports customPlaceHolder a component which can be passed to it by the parent to render till the data
 * is available
 *
 * Many components can use this where ever they need a placeholder e.x cards / any UI can be server side rendered with HTML and
 * once data is available we could render only the content.
 *
 * TODO:: This component can be improved upon vastly as and when the requirements are available.
 */

export default class PlaceHolder extends Component {
    static propTypes = {
        /**
         * ready used when ready to render childs
         * placeholder is displayed when it is false
         * @type {boolean}
         */
        ready: PropTypes.bool,

        /**
         * Children nodes to render
         * @type {node}
         */
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,

        /**
         * customPlaceHolder nodes passed
         * as prop for rendering
         * @type {node}
         */
        customPlaceHolder: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),

        /**
         * showPlaceHolder will show/hide
         * holder html
         * @type {boolean}
         */
        showPlaceHolder: PropTypes.bool,

        /**
         * determine if values need to be updated
         * will show when it doesn't match the previous value
         * @type {boolean}
         */
        rerender: PropTypes.bool,
    };

    static defaultProps = {
        ready: false,
        customPlaceHolder: null,
        showPlaceHolder: true,
        rerender: false,
    };

    /** update only when the props doesn't match */
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.ready ||
            this.props.ready !== nextProps.ready ||
            this.props.rerender !== nextProps.rerender
        );
    }

    getFilling() {
        const { showPlaceHolder, customPlaceHolder } = this.props;
        let placeHolderFilling = null;

        if (showPlaceHolder) {
            placeHolderFilling = customPlaceHolder || <div className="text-center">Loading...</div>;
        }

        return placeHolderFilling;
    }

    render() {
        const { ready, children } = this.props;
        let renderedContent = this.getFilling(); // use custom placeholder

        // if the response is ready
        if (ready) {
            renderedContent = children;
        }

        return renderedContent;
    }
}
