const CUSTOM_OPTIONS_GET_REQUEST = 'CUSTOM_OPTIONS_GET_REQUEST';
const CUSTOM_OPTIONS_GET_SUCCESS = 'CUSTOM_OPTIONS_GET_SUCCESS';
const CUSTOM_OPTIONS_GET_ERROR = 'CUSTOM_OPTIONS_GET_ERROR';
const CUSTOM_OPTIONS_ONCHANGE = 'CUSTOM_OPTIONS_ONCHANGE';

export default {
    CUSTOM_OPTIONS_GET_REQUEST,
    CUSTOM_OPTIONS_GET_SUCCESS,
    CUSTOM_OPTIONS_GET_ERROR,
    CUSTOM_OPTIONS_ONCHANGE,
};
