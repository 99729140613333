import { createSelector } from 'reselect';
import _get from 'lodash/get';

const selectAppliedAdjustments = (state) => state.appliedAdjustments;

const getAppliedCode = createSelector([selectAppliedAdjustments], (appliedAdjustments) =>
    _get(appliedAdjustments, '[0].value', '')
);

export default getAppliedCode;
