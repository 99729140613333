import forEach from 'lodash/forEach';
import pick from 'lodash/pick';

const getHotDealsContent = (data) => {
    const trimmedItems = [];
    const { productGrid: { items = [] } = {} } = data;
    if (items && items.length) {
        forEach(items, (item) => {
            const itemObj = pick(item, [
                'id',
                'name',
                'ppUrl',
                'images',
                'pricing',
                'enableMiniPDP',
                'valuation',
            ]);
            trimmedItems.push(itemObj);
        });
    }
    return { ...data, productGrid: { items: trimmedItems } };
};

const trimIrisComponents = (response) => {
    let trimmedContent = {};
    const { componentType = '', content = {} } = response;

    switch (componentType) {
        case 'hotdeals':
            trimmedContent = getHotDealsContent(content);
            return { ...response, content: trimmedContent };
        default:
            return response;
    }
};

export default trimIrisComponents;
