import {
    GET_SDPU_STORE_INFO,
    SET_STORE_INFO_TO_LOCAL_STORAGE,
} from '../actionTypes/SdpuStoreInfoTypes';

export const getSdpuStoreData = (payload) => ({
    type: GET_SDPU_STORE_INFO,
    payload,
});

export const setSdpuLocalStorage = () => ({
    type: SET_STORE_INFO_TO_LOCAL_STORAGE,
});
