import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { isFeatureEnabled } from 'yoda-core-components/lib/helpers/Utils/Utils';

/**
 * Component to throttle child render based on feature flags stored in
 * featureFlags, Preferences Object, request header, request cookie.
 */
export default class WrappedComponent extends PureComponent {
    static propTypes = {
        // consumer-passed props
        /**
         * featurePath: This can be nesetd path in featureflag object
         * used to throttle the renderer execution
         */
        featurePath: PropTypes.string,
        /**
         * preferencesPath: This can be nesetd path in preferences object
         * used to throttle the renderer execution
         */
        preferencesPath: PropTypes.string,
        /**
         * headerName: This supplied to test by passing as request header throttling without configs
         * Note: This value may be ignored if any of the above value is truthy
         */
        headerName: PropTypes.string,
        /**
         * cookieName: This supplied to test throttling by passing as request cookie without configs
         * Note: This value may be ignored if any of the above value is truthy
         */
        cookieName: PropTypes.string,
        /**
         * children: This is rendered or executor function which gets parameter *isEnabled* from
         * Wrapped component based on any of the above flag/s is truthy
         * @param  {Boolean} isEnabled  : parameer is supplied by this wrapped component to child renderer function
         * @returns {React.Element} : React element
         */
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

        // props from connect hoc
        /**
         * featureFlags: From context object using connect hoc probably
         */
        featureFlags: PropTypes.object,
        /**
         * preferences: From context object using connect hoc probably
         */
        preferences: PropTypes.object,
        /**
         * reqHeaders: From context object using connect hoc probably
         */
        reqHeaders: PropTypes.object,
    };

    static defaultProps = {
        featurePath: '',
        preferencesPath: '',
        headerName: '',
        cookieName: '',
        children: null,
        featureFlags: {},
        preferences: {},
        reqHeaders: {},
    };

    render() {
        const {
            featureFlags,
            featurePath,
            preferencesPath,
            preferences,
            cookieName,
            headerName,
            reqHeaders,
            children: renderChildren,
        } = this.props;
        const isEnabled = isFeatureEnabled(
            featureFlags,
            preferences,
            reqHeaders,
            featurePath,
            cookieName,
            headerName,
            preferencesPath
        );
        if (typeof renderChildren === 'function') {
            return renderChildren(isEnabled);
        }
        return isEnabled ? renderChildren : null;
    }
}
