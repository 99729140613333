const PRODUCT_PRICING_GET_REQUEST = 'PRODUCT_PRICING_GET_REQUEST';
const PRODUCT_PRICING_GET_SUCCESS = 'PRODUCT_PRICING_GET_SUCCESS';
const PRODUCT_PRICING_GET_ERROR = 'PRODUCT_PRICING_GET_ERROR';
const PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST =
    'PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST';
const PRODUCT_PRICING_FALLBACK_GET_REQUEST = 'PRODUCT_PRICING_FALLBACK_GET_REQUEST';
const PRODUCT_PRICING_FALLBACK_GET_SUCCESS = 'PRODUCT_PRICING_FALLBACK_GET_SUCCESS';
const PRODUCT_PRICING_FALLBACK_GET_ERROR = 'PRODUCT_PRICING_FALLBACK_GET_ERROR';
const SET_BUNDLE_PRODUCT_PRICING = 'SET_BUNDLE_PRODUCT_PRICING';
const UPDATE_PRODUCT_PRICING = 'UPDATE_PRODUCT_PRICING';

export default {
    PRODUCT_PRICING_GET_REQUEST,
    PRODUCT_PRICING_GET_SUCCESS,
    PRODUCT_PRICING_GET_ERROR,
    PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST,
    PRODUCT_PRICING_FALLBACK_GET_REQUEST,
    PRODUCT_PRICING_FALLBACK_GET_SUCCESS,
    PRODUCT_PRICING_FALLBACK_GET_ERROR,
    SET_BUNDLE_PRODUCT_PRICING,
    UPDATE_PRODUCT_PRICING,
};
