import { STICKYTIMER_GET_SUCCESS } from '../actionTypes/StickyTimerActionTypes';

const stickyTimerDetailsReducer = function stickyTimerDetailsReducer(state = [], action) {
    switch (action.type) {
        case STICKYTIMER_GET_SUCCESS:
            return action.stickyTimerData;
        default:
            return state;
    }
};

stickyTimerDetailsReducer.reducerName = 'StickyTimerReducer';

export default stickyTimerDetailsReducer;
