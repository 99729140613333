import { CHANGE_STATE_CONTEXT_SHOULD_RENDER } from '../actionTypes/ContextActionTypes';

/**
 * Reducer to handle Context Changes
 */

function ContextReducer(state = {}, action) {
    switch (action.type) {
        case CHANGE_STATE_CONTEXT_SHOULD_RENDER:
            return {
                ...state,
                shouldRender: action.payload,
            };
        default:
            return state;
    }
}

ContextReducer.reducerName = 'context';

export default ContextReducer;
