import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { findMobileOS } from 'yoda-core-components/lib/helpers/Utils/Utils';

export const getCriteoPageEnv = (deviceType, isNative) => {
    let env = 'd';
    if (deviceType?.isMobile || deviceType?.isTablet) {
        env = 'm';
        if (isNative) {
            const appType = findMobileOS();
            if (appType === 'Android') env = 'aa';
            if (appType === 'iOS') env = 'aios';
        }
    }
    return env;
};

export const noLogCheck = (hostname, env) =>
    hostname !== 'www.jcpenney.com' || (hostname === 'www.jcpenney.com' && env === 'development');

export const customerIdCheck = () => {
    let customerId = null;
    const userState = TokenProvider.get('DP_USER_STATE');

    if (userState === '1' || userState === '2') {
        const accountId = TokenProvider.get('ACCOUNT_ID');
        if (accountId) customerId = accountId;
    }

    return customerId;
};

export const getRetailVisitorId = () => {
    let id = LocalStorage.getData('CRITEO_ID');
    if (!id) {
        id = `cu-${new Date().getTime().toString(36)}-${crypto.randomUUID()}`;
        LocalStorage.setData('CRITEO_ID', id);
    }
    return id;
};
