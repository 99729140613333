import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { getProductDetails } from 'yoda-interfaces/lib/Product/ProductApi';
import { errorPageActions } from '../components/ErrorPage/ErrorPage';
import { selectFeatureFlags } from '../selectors/ContextSelector';
import { SET_SERVICE_ERROR } from '../common/Constants';
import ProductDetailsActionTypes from '../actionTypes/ProductDetailsActionTypes';
import { SHOW_CART_ATC_SLIDER } from '../actionTypes/AtcSliderStatusActionTypes';
import {
    isProductBundle,
    isApplianceBundle,
    getFirstProduct,
} from '../selectors/productDetails/selectProductType';

const defineErrorStatusCode = (status) => {
    let errorStatusCode;
    if (!(status > 199 && status < 300)) {
        errorStatusCode = status;
    }
    return errorStatusCode;
};

const fireEvent = (productDetails) => {
    try {
        if (!__SERVER__) {
            const e = new CustomEvent('pdp.success', { detail: productDetails });
            document.dispatchEvent(e);
        }
    } catch (err) {
        console.log(err);
    }
};

function* ProductDetailsSaga(action) {
    const sourceMiniPDP = !!action.payload.sourceMiniPDP;
    const { payload: { sourceCart = false } = {} } = action;
    try {
        const featureFlags = yield select(selectFeatureFlags);
        const response = yield call(getProductDetails, action.payload, featureFlags);
        const redirectAction = get(action, 'payload.redirectAction');
        const cacheActionType = get(action, 'payload.cacheAction.type', '');
        const productDetails = response.data;
        const errorStatusCode = defineErrorStatusCode(response.status);
        if (errorStatusCode) {
            if (sourceCart) {
                let errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                errorInfo = errorInfo || {};
                const errorHandlerInfo = {};
                errorHandlerInfo.errorCode = errorInfo.errorCode;
                errorHandlerInfo.errorMessage = errorInfo.errorMessage;
                errorHandlerInfo.errorComponent = 'CART_SECTION';
                errorHandlerInfo.key = 'CART_SECTION';
                yield all([
                    put({ type: 'SHOW_GLOBAL_LOADER', payload: false }),
                    put({ type: SET_SERVICE_ERROR, errorHandlerInfo }),
                ]);
            } else if (!sourceMiniPDP) {
                yield put(errorPageActions.showErrorPage(errorStatusCode));
            } else {
                yield put({
                    type: ProductDetailsActionTypes.PRODUCTDETAILS_GET_SUCCESS,
                    productDetails: { error: errorStatusCode, id: action.payload.ppid },
                    sourceMiniPDP,
                });
            }
        } else {
            yield put({
                type: ProductDetailsActionTypes.PRODUCTDETAILS_GET_SUCCESS,
                productDetails,
                sourceMiniPDP,
            });
            yield all([
                put({ type: 'SHOW_GLOBAL_LOADER', payload: false }),
                put({ type: SHOW_CART_ATC_SLIDER, payload: true }),
            ]);

            if (productDetails.CacheControl && cacheActionType) {
                yield put({
                    type: cacheActionType,
                    cache: { ttl: String(productDetails.CacheControl).match(/\d+/g)[0] },
                });
            }

            if (sourceMiniPDP) {
                fireEvent(productDetails);
            }

            const productBundle = yield select(isProductBundle(sourceMiniPDP));
            const applianceBundle = yield select(isApplianceBundle(sourceMiniPDP));

            if (
                !featureFlags.regularBundle &&
                redirectAction &&
                productBundle &&
                !applianceBundle
            ) {
                const firstProduct = yield select(getFirstProduct(sourceMiniPDP));
                const firstProductURL = get(firstProduct, 'links.url', false);
                if (firstProductURL) {
                    if (__SERVER__) {
                        yield put(redirectAction({ url: firstProductURL }));
                    } else if (typeof window !== 'undefined') {
                        window.location.href = firstProductURL;
                    }
                }
            }
        }
    } catch (error) {
        yield put({
            type: ProductDetailsActionTypes.PRODUCTDETAILS_GET_ERROR,
            error,
            sourceMiniPDP,
        });
    }
}

const watchProductDetailsSaga = function* watchProductDetailsSaga() {
    yield takeLatest(ProductDetailsActionTypes.PRODUCTDETAILS_GET_REQUEST, ProductDetailsSaga);
};

watchProductDetailsSaga.sagaName = 'watchProductDetailsSaga';

export default watchProductDetailsSaga;
