import get from 'lodash/get';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { DP_USER_STATE, DP_FIRST_NAME, DP_REWARDS_STATUS } from '../../common/Constants';

export default class User {
    /*
     *  This function will only return true or false based on user logged in status.
     *  It won't return a value of cookie 'DP_USER_STATE'
     *  USAGE : User.isUserLoggedIn()
     * */
    static isUserLoggedIn(hardLoginCheck = false) {
        const userState = TokenProvider.get(DP_USER_STATE) || '0';
        if (hardLoginCheck) {
            return userState === '1' || false;
        }
        return userState !== '0' || false;
    }

    /**
     * Is user in recognized state
     * @param {Object} headers context headers to retrive user state
     */
    static isRecognized(headers) {
        return __SERVER__ && headers
            ? get(headers, 'cookies.DP_USER_STATE', '0') === '2'
            : TokenProvider.get(DP_USER_STATE) === '2';
    }

    /*
     *  This function will only return true or false based on user rewards enroll status.
     *  It won't return a value of cookie 'DP_REWARDS_STATUS'
     *  USAGE : User.isRewardsEnrolled()
     * */
    static isRewardsEnrolled() {
        const rewardsStatus = TokenProvider.get(DP_REWARDS_STATUS) || '';
        return rewardsStatus.toLowerCase() === 'enrolled' || false;
    }

    /*
     *  This function will return userId or empty string based on user logged in status.
     *  USAGE: User.getUserId()
     */
    static getUserId() {
        const userId = User.isUserLoggedIn() ? Cookies.load('DYN_USER_ID') : '';
        return userId;
    }

    /*
     *  This function will return the user's first name or empty string based on user logged in status.
     *  USAGE: User.getUserName()
     */
    static getUserName() {
        const userName = User.isUserLoggedIn() ? TokenProvider.get(DP_FIRST_NAME) : '';
        return userName;
    }

    /*
     *  This function will return the user state to know logged  in status.
     *  USAGE: User.getUserName()
     */
    static getUserState() {
        const userState = TokenProvider.get(DP_USER_STATE);
        return userState;
    }
}
