import CustomOptionsActionTypes from '../actionTypes/CustomOptionsActionTypes';

function CustomOptionsReducer(state = {}, action) {
    switch (action.type) {
        case CustomOptionsActionTypes.CUSTOM_OPTIONS_GET_SUCCESS:
            return {
                ...state,
                options: action.payload,
            };
        case CustomOptionsActionTypes.CUSTOM_OPTIONS_ONCHANGE:
            return action.payload;
        default:
            return state;
    }
}

CustomOptionsReducer.reducerName = 'customOptions';

export default CustomOptionsReducer;
