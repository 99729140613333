import { createSelector } from 'reselect';
import { isFeatureEnabled } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { selectFeatureFlags, selectPreferences, selectReqHeaders } from './ContextSelector';
import {
    KEEP_ME_LOGGED_IN_COOKIE_FLAGNAME,
    KEEP_ME_LOGGED_IN_HEADER_FLAGNAME,
} from '../common/Constants';

export const DEFAULTS = {};

const isKeepMeLoggedInEnabled = (featureFlags, preferences, reqHeaders) => {
    const { enableKeepMeLoggedIn = true } = featureFlags || {};
    const featureFlagsWithDefault = { enableKeepMeLoggedIn };
    return isFeatureEnabled(
        featureFlagsWithDefault,
        preferences,
        reqHeaders,
        // featureflag,        cookieName            headerName
        'enableKeepMeLoggedIn',
        KEEP_ME_LOGGED_IN_COOKIE_FLAGNAME,
        KEEP_ME_LOGGED_IN_HEADER_FLAGNAME
    );
};

// FF
export const selectKeepMeLoggedInThrottleFF = createSelector(
    [selectFeatureFlags, selectPreferences, selectReqHeaders],
    isKeepMeLoggedInEnabled
);
