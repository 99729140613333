/** tokens */
export const GET_ACCESS_TOKEN = 'WEB_GET_ACCESS_TOKEN_REQUEST';
export const GET_REFRESH_TOKEN = 'WEB_GET_REFRESH_TOKEN_REQUEST';
export const CONTINUE_PROCESSING_ACCESS_TOKEN = 'WEB_CONTINUE_PROCESSING_ACCESS_TOKEN_REQUEST';
export const CONTINUE_PROCESSING_REFRESH_TOKEN = 'WEB_CONTINUE_PROCESSING_REFRESH_TOKEN_REQUEST';

/** sign in | account */
export const SIGN_IN = 'WEB_SIGN_IN_REQUEST';
export const CHECKOUT_SIGN_IN = 'WEB_CHECKOUT_SIGN_IN_REQUEST';
export const CREATE_ACCOUNT = 'WEB_CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_COMPLETED = 'WEB_CREATE_ACCOUNT_COMPLETED_REQUEST';
export const REWARDS_SIGN_IN_COMPLETE = 'APP_REWARDS_SIGN_IN_COMPLETE';
export const GET_USER_EMAIL_ID = 'WEB_GET_USER_EMAIL_ID';
export const GET_USER_EMAIL_ID_REQUEST = 'WEB_GET_USER_EMAIL_ID_REQUEST';
export const WEB_CREATE_ACCOUNT_SLIDER_REQUEST = 'WEB_CREATE_ACCOUNT_SLIDER_REQUEST';

/** location service */
export const GET_APP_FAV_STORE = 'WEB_GET_APP_FAV_STORE';
export const CONTINUE_PROCESSING_APP_FAV_STORE = 'WEB_GET_APP_FAV_STORE_REQUEST';
export const ASK_USER_CURRENT_LOCATION = 'WEB_ASK_USER_CURRENT_LOCATION';
export const CONTINUE_PROCESSING_USER_CURRENT_LOCATION = 'WEB_ASK_USER_CURRENT_LOCATION_REQUEST';
export const PUT_NATIVE_LAT_LONG = 'PUT_NATIVE_LAT_LONG';
export const RESET_NATIVE_LAT_LONG = 'RESET_NATIVE_LAT_LONG';
export const SAVE_NATIVE_FAVORITE_STORE = 'SAVE_NATIVE_FAVORITE_STORE';

/** others */
export const ADD_TO_CART = 'WEB_ADD_TO_CART_REQUEST';
export const ORDER_PLACED = 'WEB_ORDER_PLACED_REQUEST';
export const UPDATE_CART_COUNT = 'WEB_UPDATE_CART_COUNT_REQUEST';
export const GET_BLACK_BOX_ID = 'WEB_GET_BLACK_BOX_ID_REQUEST';
export const PATCH_HEADER = 'WEB_PATCH_HEADER';
export const CANCEL_ORDER_OCP = 'POST_CANCEL_ORDER_DETAILS_OCP_TO_NATIVE';
export const FAVORITES_UPDATED = 'WEB_FAVOURITE_LIST_UPDATED';
export const PUT_USER_EMAIL = 'PUT_USER_EMAIL';
export const PUT_USER_EMAIL_ERROR = 'PUT_USER_EMAIL_ERROR';
export const GET_APP_GIFT_CARDS = 'WEB_GET_GIFT_CARDS';
export const CONTINUE_PROCESSING_APP_GIFT_CARDS = 'WEB_GET_GIFT_CARDS_REQUEST';
export const UPDATE_APPLIED_GC_TO_APP = 'WEB_GET_APPLIED_GIFT_CARDS';
export const GIFT_CARDS_FROM_APP = 'GIFT_CARDS_FROM_APP';
export const GET_HANDLE_BACK_PRESS = 'GET_HANDLE_BACK_PRESS';
export const GET_APP_TAB_CHANGE = 'WEB_APP_TAB_CHANGE';
export const CONTINUE_PROCESSING_APP_TAB_CHANGE = 'WEB_APP_TAB_CHANGE_REQUEST';
export const WEB_APP_ACCOUNT_LOCKED = 'WEB_APP_ACCOUNT_LOCKED';
export const APP_UNDO_ACTION = 'WEB_APP_UNDO_ACTION';
export const WEB_GET_APP_GC_BALANCE = 'WEB_GET_APP_GC_BALANCE';
export const CONTINUE_PROCESSING_APP_GC_BALANCE_REQUEST = 'WEB_GET_APP_GC_BALANCE_REQUEST';

// Quick Screen
export const WEB_OPEN_QUICK_SCREEN = 'WEB_OPEN_QUICK_SCREEN';
export const WEB_GET_REWARD_PROFILE = 'WEB_GET_REWARD_PROFILE';
export const WEB_GET_REWARD_PROFILE_REQUEST = 'WEB_GET_REWARD_PROFILE_REQUEST';
export const WEB_OPEN_QUICK_SCREEN_REQUEST = 'WEB_OPEN_QUICK_SCREEN_REQUEST';
export const WEB_SYPI_WIDGET_REQUEST = 'WEB_SYPI_WIDGET_REQUEST';
