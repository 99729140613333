import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, bool, func, object, objectOf, oneOfType } from 'prop-types';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import SiteComponent from '../SiteComponent/SiteComponent';
import actions from '../../actions/PromotionalBannerAction';
import getLocationPath from '../../utils/index';
import PlaceHolder from './components/PlaceHolder';
import PromoBannerAnimate from './components/PromoBannerAnimate';
import * as styles from './PromotionalBanner.css';
import {
    PROMOTIONALBANNERDATA_GET_ERROR,
    IRIS_PROMOTIONALBANNERDATA_GET_ERROR,
} from '../../actionTypes/PromotionalBannerActionTypes';

const cx = classNames.bind(styles);
let isSiteWidePromoCalled = false;

export class PromotionalBanner extends SiteComponent {
    static propTypes = {
        actions: objectOf(func),
        deviceType: oneOfType([object, array]),
        disableIrisCall: bool,
        disableSitewideSeoUrlforPromo: bool,
        enableIrisSitePromo: bool,
        enablePromoCarousel: bool,
        offerDetails: oneOfType([array, object]),
        failedOfferDetails: bool,
    };

    static defaultProps = {
        actions: {},
        deviceType: {},
        disableIrisCall: false,
        disableSitewideSeoUrlforPromo: false,
        enableIrisSitePromo: false,
        enablePromoCarousel: true,
        isReady: false,
        offerDetails: {},
        promoTimeOut: null,
        failedOfferDetails: false,
    };

    constructor(props) {
        super(props);
        this.triggerSitePromoAction = this.triggerSitePromoAction.bind(this);
        this.getSitePromoData = this.getSitePromoData.bind(this);
        this.secondaryTextPresent = false;
    }

    unlisten = null;

    hydrateOnMount() {
        /* istanbul ignore else */
        if (!__SERVER__ && !isSiteWidePromoCalled) {
            this.getSitePromoData();
            isSiteWidePromoCalled = true;
        }
    }

    triggerSitePromoAction() {
        /* istanbul ignore next */
        if (getBrowserHistory() && typeof getBrowserHistory().listen === 'function') {
            this.unlisten = getBrowserHistory().listen(() => {
                // Calling action when url is updated
                if (!isSiteWidePromoCalled) {
                    this.getSitePromoData();
                } else {
                    isSiteWidePromoCalled = false;
                }
            });
        }
    }

    getSitePromoData() {
        /* istanbul ignore next */
        const seoUrl = getLocationPath(window.location.pathname);
        const {
            enableIrisSitePromo,
            deviceType,
            disableIrisCall,
            disableSitewideSeoUrlforPromo,
        } = this.props;
        const locationParams = seoUrl + window.location.search;
        const getUrl = disableSitewideSeoUrlforPromo ? '' : locationParams;
        /* istanbul ignore else */
        if (!disableIrisCall) {
            enableIrisSitePromo
                ? this.props.actions.getIrisOfferDetailsAction(getUrl)
                : this.props.actions.getOfferDetailsAction(deviceType);
        }
    }

    componentDidMount() {
        this.triggerSitePromoAction();
        // This is added as part of MNPDPYODA-12655 for adobe to trigger iris SitePromoCall and pass relevant cookie to obtain version of the S&H Banner
        window.triggerSitewidePromoCall = () => {
            this.getSitePromoData();
        };
    }

    componentWillUnmount() {
        /* istanbul ignore else */
        if (typeof this.unlisten === 'function') {
            this.unlisten();
        }
    }

    getHeight() {
        const {
            deviceType: { isMobile, isTablet },
            offerDetails,
            enablePromoCarousel,
        } = this.props;

        let promoTextHeight = enablePromoCarousel ? 72 : 56;
        // height for single line promo

        let textLimit = 109; // textLimit for desktop devices above 1024 resolution. Adding limit based on width. Can be handled in app config later
        /* istanbul ignore else */
        if (offerDetails.length) {
            this.getSecondaryItems();
        }
        /* istanbul ignore next */
        if (isMobile) {
            textLimit = 36;
        } else if (isTablet) {
            textLimit = 59;
        }
        /* istanbul ignore next */
        if (this.secondaryTextPresent) {
            if (enablePromoCarousel) {
                return { height: '64px' };
            }
            return { height: '32px' };
        }
        /* istanbul ignore else */
        if (!enablePromoCarousel) {
            offerDetails.forEach(({ promoText, targetUrl, linkText }) => {
                const content = promoText + (targetUrl ? linkText : '');
                const textLength = content.length + 1;
                /* istanbul ignore next */
                if (textLength > textLimit) {
                    const lineCount = Math.floor(textLength / textLimit);
                    // to increase height for each line
                    const cal = 16 * lineCount;
                    const currentHeight = cal + 56;
                    promoTextHeight =
                        currentHeight >= promoTextHeight ? currentHeight : promoTextHeight;
                }
            });
        }

        return `${promoTextHeight}px`;
    }

    getSecondaryItems() {
        const { offerDetails } = this.props;
        const filterSecData = offerDetails.filter((item) => !item.secondaryText);
        if (filterSecData.length === offerDetails.length) {
            this.secondaryTextPresent = true;
        }
    }

    render() {
        const { isReady, failedOfferDetails } = this.props;
        const height = this.getHeight();
        const containerStyle = failedOfferDetails ? {} : { height };
        return (
            <>
                <PlaceHolder
                    customPlaceHolder={
                        <div
                            data-automation-id="sitePromoCustomPlaceholder"
                            className={`${styles.customPlaceHolder} ${styles['bg-transparent']}`}
                            style={containerStyle}
                        />
                    }
                    ready={isReady}
                    showPlaceHolder={this.props.promoPlaceHolder}
                    rerender={failedOfferDetails}
                >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <PromoBannerAnimate {...this.props} height={height} />
                </PlaceHolder>
            </>
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const { context } = store;
    const promoTimeOut = _get(context, 'preferences.promoTimeOut', null);
    const promoPlaceHolder = _get(context, 'featureFlags.enablePromoPlaceHolder', null);
    const promoMouseOp = _get(context, 'featureFlags.enablePromoMouseOp', null);
    const enableIrisSitePromo = _get(context, 'featureFlags.enableIrisSitePromo', false);
    const enablePromoCarousel = _get(context, 'featureFlags.enablePromoCarousel', true);
    const disableSitewideSeoUrlforPromo = _get(
        context,
        'featureFlags.disableSitewideSeoUrlforPromo',
        false
    );
    const disableIrisCall = _get(context, 'featureFlags.disableIrisCall', false);
    const isReady = enableIrisSitePromo
        ? _get(store, 'irisPromotionalBannerData.isReady', false)
        : _get(store, 'promotionalBannerData.isReady', false);
    return {
        enableIrisSitePromo,
        deviceType: ownProps ? ownProps.deviceType : {},
        promoTimeOut,
        promoPlaceHolder,
        promoMouseOp,
        isReady,
        offerDetails: enableIrisSitePromo
            ? _get(store, 'irisPromotionalBannerData.content', [])
            : _get(store, 'promotionalBannerData.content', []),
        failedOfferDetails: enableIrisSitePromo
            ? _get(store, ['apiErrors', IRIS_PROMOTIONALBANNERDATA_GET_ERROR], false)
            : _get(store, ['apiErrors', PROMOTIONALBANNERDATA_GET_ERROR], false),
        disableSitewideSeoUrlforPromo,
        disableIrisCall,
        enablePromoCarousel,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalBanner);
