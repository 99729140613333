import React from 'react';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Icon2023JcpenneyLogo from 'yoda-core-components/lib/assets/svg/2023JcpenneyLogo.svg';
import Help from 'yoda-core-components/lib/assets/svg/Help.svg';
import ArrowRight from 'yoda-core-components/lib/assets/svg/ArrowRight.svg';
import ShoppingBagFill from 'yoda-core-components/lib/assets/svg/ShoppingBagFill.svg';
import TrackOrderNew from 'yoda-core-components/lib/assets/svg/TrackOrderNew.svg';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import Button from 'yoda-core-components/lib/components/Button/Button';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import {
    headerStore,
    FIND_STORES_SELECT_A_STORE_HEADER_TEXT,
    REWARD_PERSONALIZATION_REWARD_AVAILABLE,
    REWARD_PERSONALIZATION_REWARDS_MEMBER,
    REWARD_PERSONALIZATION_DP_REWARDS_DETAILS,
    REWARD_PERSONALIZATION_POINTS,
    REWARD_PERSONALIZATION_EARNED_POINT_THRESHOLD,
    DP_REWARDS_STATUS,
    SIGNIN,
    ENROLLED,
} from '../../common/Constants';

import RootCategoryTemplate from './RootCategoryTemplate';
import * as styles from './DefaultTemplate.css';
import Link from '../Link/Link';
import Storeline from '../../assets/svg/store-icon-filled.svg';
import Account from '../../assets/svg/account-icon-filled.svg';
import AccountWithDot from '../../assets/svg/account-with-dot.svg';
import HambCloseNew from '../../assets/svg/closeIconNew.svg';

const cx = classNames.bind(styles);

let singleAnalytics = null;
let openSignInSlider = null;
let openStoreSelectionSlider = null;

const menuListItemLinkStyle = dt([
    'w-full',
    'text-sm',
    'leading-6',
    'text-left',
    'text-black',
    'py-2',
    'px-4',
    'h-auto',
]);

/* istanbul ignore next */
const menuClicked = (e) => {
    const clickedItem = e ? e.target.closest('button').dataset : {};
    singleAnalytics(`top:${clickedItem.name}`);
    /* istanbul ignore next */
    if (clickedItem?.isSliderNeeded) {
        const isSliderFalse = openSignInSlider(clickedItem?.targetdurl);
        if (isSliderFalse) {
            return clickedItem?.targetdurl;
        }
    } else {
        return clickedItem?.targetdurl; // redirect through Link component
    }
    return false; // tell Link component not to redirect
};

const selectAStoreTriggered = (e) => {
    e.stopPropagation();
    openStoreSelectionSlider();
};

const oneRow = (item, logOutAction, userProfile, enableHeaderSigninUpdate) => {
    const getRewardDetailsFromLocalStorage =
        TokenProvider.get(REWARD_PERSONALIZATION_DP_REWARDS_DETAILS) || {};
    const rewardsStatus = TokenProvider.get(DP_REWARDS_STATUS);
    const {
        points: { earnedPoints = 0, pointsThreshold = 200 } = {},
        certificates: { totalCertsValue = 0 } = {},
    } = getRewardDetailsFromLocalStorage;
    const rewardsInfoTextWithNoCerts =
        earnedPoints >= REWARD_PERSONALIZATION_EARNED_POINT_THRESHOLD
            ? `${earnedPoints} / ${pointsThreshold} ${REWARD_PERSONALIZATION_POINTS}`
            : REWARD_PERSONALIZATION_REWARDS_MEMBER;
    let rewardsInfoText =
        totalCertsValue === 0
            ? rewardsInfoTextWithNoCerts
            : `$${totalCertsValue} ${REWARD_PERSONALIZATION_REWARD_AVAILABLE}`;
    rewardsInfoText = rewardsStatus && toLower(rewardsStatus) === ENROLLED ? rewardsInfoText : '';
    if (item?.name === 'My Account') {
        const userStateFlag = userProfile && userProfile.userState && userProfile.userState !== '0';
        item.icon = 'account';
        item.iconComponent =
            !userStateFlag && enableHeaderSigninUpdate ? <AccountWithDot /> : <Account />;
        /* istanbul ignore next */
        /* istanbul ignore next */
        const firstName = userProfile && userProfile.firstName;
        /* istanbul ignore next */
        if ((userStateFlag && firstName) || (firstName && !userStateFlag)) {
            const newAccountTitle = !userStateFlag ? item.name : `Hi, ${startCase(firstName)}`;
            item.name = newAccountTitle;
        } else {
            item.name = item.name;
        }
        const newAccountSubtitle = userStateFlag ? rewardsInfoText : SIGNIN;
        item.subTitle = newAccountSubtitle;
    }
    item.icon = item.name === 'Track My Order' ? 'trackorder-new' : item.icon;
    item.iconComponent = item.name === 'Track My Order' ? <TrackOrderNew /> : item.iconComponent;
    const signoutStyle = dt(['text-link-hover']);
    const accountTitleStyle =
        item.subTitle && item.subTitle !== '' && dt(['text-small', 'font-open-sans-semibold']);

    return (
        <li
            key={`hamb-base-${item?.name}`}
            className={cx({
                hr: item?.seperator,
                siginDecor: item?.styleClass === signoutStyle,
            })}
        >
            <Link
                type="button"
                onClick={item.styleClass === signoutStyle ? logOutAction : menuClicked}
                className={cx('menuListItemLink', menuListItemLinkStyle)}
                data-targetdurl={item.link}
                data-name={item.name}
                data-is-slider-needed={item.isSliderNeeded}
                data-automation-id="dropdown-item-button"
            >
                <span
                    className={`${dt(['flex', 'items-center'])} ${cx(item.styleClass)}`}
                    aria-label={item.name}
                >
                    <span
                        className={`${dt(['pr-2', 'ml-2', 'font-open-sans-semibold'])} ${cx(
                            'iconBlock'
                        )}`}
                    >
                        {item.icon ? (
                            <Icon
                                className={styles[item.icon]}
                                iconType="svg"
                                width="32px"
                                height="32px"
                                viewBox="0 0 32 32"
                            >
                                {item.iconComponent}
                            </Icon>
                        ) : null}
                    </span>
                    <span
                        data-automation-id={`hamburger-menu-title-${item.icon}`}
                        className={`${dt(['text-small', 'inline-block', 'align-middle'])} ${cx(
                            'iconText',
                            accountTitleStyle
                        )}`}
                        data-qm-encrypt="true"
                    >
                        {item.name}
                        {item.subTitle && (
                            <div
                                data-automation-id={`hamburger-menu-subtitle-${item.icon}`}
                                className={dt([
                                    'text-xsmall',
                                    'font-open-sans-regular',
                                    'lg:font-small',
                                ])}
                            >
                                {item.subTitle}
                            </div>
                        )}
                    </span>
                </span>
            </Link>
        </li>
    );
};

const renderSection = (items, logOutAction, userProfile, enableHeaderSigninUpdate) => {
    const fullDom = [];
    if (items) {
        items.forEach((item) => {
            fullDom.push(oneRow(item, logOutAction, userProfile, enableHeaderSigninUpdate));
        });
    }
    return fullDom;
};

const renderMidSection = (items, userProfileInfo, logOutAction, enableHybridHamburger) => {
    const optedItems =
        userProfileInfo?.userState && userProfileInfo?.userState !== '0'
            ? items?.signedin
            : items?.guest;
    return (
        optedItems &&
        renderSection(
            optedItems.filter(
                (item) => !enableHybridHamburger || (!item?.topSection && !item?.bottomSection)
            ),
            logOutAction
        )
    );
};

const hrStyles = dt(['border-b', 'border-solid', 'border-gray-15', 'sm:pb-[7px]']);
const shopDepartmentsStyle = dt([
    'sm:h-[51px]',
    'w-full',
    'text-normal',
    'leading-6',
    'text-left',
    'text-black',
    `pt-2.5`,
    `sm:pr-[5px]`,
    `sm:pb-[5px]`,
    `pl-2.5`,
]);
const shoppingBagFillStyle = dt(['w-6', 'h-6']);
const rightArrowStyle = dt(['float-right']);
const svgIconStyle = dt(['content-[""]', 'font-open-sans-regular', 'text-xlarge', `sm:pr-[5px]`]);
const needHelpStyle = dt([
    'h-14',
    'text-small',
    `leading-[2.86]`,
    'text-left',
    'text-black',
    'w-full',
    `sm:mt-[7px]`,
]);
const accessibleViewStyle = dt([
    'h-11',
    'text-small',
    'leading-[2.86]',
    'text-left',
    'text-white',
    'bg-black',
    'w-full',
    'pl-7',
]);
const findAStoreStyle = dt([
    `sm:h-[59px]`,
    `py-2.5`,
    `sm:px-[7px]`,
    'border-b',
    'border-solid',
    'border-gray-15',
    'w-full',
    'text-left',
    'relative',
    `sm:z-[2]`,
]);
const findIconStyle = dt([`sm:pt-[7px]`, 'absolute']);
const findIconWithoutLabelStyle = dt(['findIconWithoutLabel']);
const findTitleStyle = dt([
    'sm:pl-[35px]',
    'font-open-sans',
    'text-xsmall',
    'leading-[1.33]',
    'text-left',
    'text-black',
]);
const findTextStyle = dt([
    'block',
    'sm:pl-[35px]',
    'font-open-sans',
    'text-sm',
    'font-open-sans-semibold',
    'leading-[1.71]',
    'text-left',
    'text-black',
    'inline-block',
    'align-middle',
    'lg:pt-4',
]);
const findTextHybridStyle = dt([
    'text-sm',
    'leading-[1.43]',
    'tracking-[-0.5px]',
    'font-open-sans-semibold',
    'inline-block',
    'align-middle',
    'lg:pt-4',
]);
const hambStoreStyle = dt(['h-8', 'w-8', 'mr-1', 'mb-0', 'ml-1']);
const topSectionStyle = dt(['relative', 'bg-light-white']);
const menuCloseIconDefaultStyle = dt(['sm:z-[9]', 'sm:-mr-1']);
const findStoreSectionStyle = dt([
    'relative',
    'sm:z-[2]',
    'border-b',
    'border-solid',
    'border-gray-15',
    'bg-light-white',
    'px-2',
    'py-2',
]);
const moreStoresLinkStyle = dt([
    'absolute',
    'right-3',
    'top-1/2',
    'text-black',
    'text-xsmall',
    'font-open-sans-regular',
    'leading-5',
    'underline',
]);
const findStoreTextStyle = dt([
    'sm:font-open-sans-semibold',
    'lg:font-open-sans-bold',
    'sm:text-small',
    'lg:text-normal',
    'text-black',
]);
const storeInformationTxtStyle = dt(['font-open-sans-semibold', 'sm:!text-xsmall', 'text-black']);

const bottomSectionStyle = dt([
    'border-t',
    'border-solid',
    'border-gray-25',
    'mt-1.5',
    'py-2',
    'px-0',
]);

const shopDepartmentsRoot = (shopDepartmentAction) => (
    <li className={hrStyles} id="subMenuLevel0">
        <button
            onClick={shopDepartmentAction}
            data-itemid="root"
            data-title="MENU"
            data-name="shop department"
            data-pagetype="base"
            data-nid="root"
            className={shopDepartmentsStyle}
        >
            <Icon
                iconType="svg"
                className={shoppingBagFillStyle}
                width="14px"
                height="14px"
                viewBox="0 0 18 18"
            >
                <ShoppingBagFill />
            </Icon>
            SHOP DEPARTMENTS
            <i className={rightArrowStyle}>
                <Icon
                    iconType="svg"
                    className={svgIconStyle}
                    width="14px"
                    height="14px"
                    viewBox="0 0 18 18"
                >
                    <ArrowRight />
                </Icon>
            </i>
        </button>
    </li>
);

const needHelpSection = () => (
    <li>
        <Link
            type="button"
            onClick={menuClicked}
            className={needHelpStyle}
            data-name="needhelp"
            data-targetdurl="/customerService"
        >
            <Icon
                iconType="svg"
                className={cx('svg-icon')}
                width="24px"
                height="24px"
                viewBox="0 0 18 18"
            >
                <Help />
            </Icon>{' '}
            Need Help
        </Link>
    </li>
);

const accessabilityView = (isAccessibleV2Enabled) => (
    <li>
        <Link
            type="button"
            onClick={menuClicked}
            className={accessibleViewStyle}
            data-name="accessbality"
            data-targetdurl={
                isAccessibleV2Enabled
                    ? '/?UsableNetAssistive=true'
                    : 'http://assistive.jcpenney.com/h5/access/index'
            }
        >
            Accessible View
        </Link>
    </li>
);

const findAStore = (
    selectedStore,
    shopDepartmentAction,
    disableStoreInfoFromHeader,
    enableExternalComponent,
    disableGeoLocatedStoreInfo
) => {
    let title = '';
    let storeText = '';

    if ((enableExternalComponent && disableStoreInfoFromHeader) || disableGeoLocatedStoreInfo) {
        title = ' ';
        storeText = <span className={findTextStyle}> Find a Store </span>;
    } else if (
        selectedStore.isGeoStore &&
        selectedStore.storeDetails &&
        selectedStore.storeDetails.name
    ) {
        title = <span className={findTitleStyle}> Store Nearest You </span>;
        storeText = <span className={findTextStyle}> {selectedStore?.storeDetails?.name} </span>;
    } else if (selectedStore?.storeDetails && selectedStore?.storeDetails.name) {
        title = <span className={findTitleStyle}> My Store </span>;
        storeText = <span className={findTextStyle}> {selectedStore?.storeDetails?.name} </span>;
    } else {
        title = <span className={findTitleStyle}> Near You </span>;
        storeText = <span className={findTextStyle}> Find a Store </span>;
    }

    const findStoreButtonClass =
        enableExternalComponent && disableStoreInfoFromHeader
            ? findIconWithoutLabelStyle
            : findIconStyle;

    return (
        <Link
            type="button"
            onClick={shopDepartmentAction}
            className={findAStoreStyle}
            data-itemid="root"
            data-title="MENU"
            data-name="Select Store"
            data-pagetype="findastore"
            data-nid="findastore"
        >
            <span
                className={`${dt(['pr-2', 'inline-block', 'align-middle'])} ${cx(
                    'findStoreIcon',
                    findStoreButtonClass
                )}`}
            >
                <Icon
                    iconType="svg"
                    automationId="location"
                    className={dt(['fill-black'])}
                    width="28px"
                    height="22px"
                    viewBox="0 0 20 20"
                >
                    <Storeline />
                </Icon>
            </span>
            {title}
            {storeText}
        </Link>
    );
};

const findAStoreHybrid = (
    shopDepartmentAction,
    disableStoreNameFromHeader,
    locationServiceStoreInfo,
    isFragment
) => {
    // let title = '';
    let storeText = '';
    let storeChangeText = '';
    const { storeName = '', city = '', state = '' } = locationServiceStoreInfo;
    // if storeDetails has value, and flag for store info removal is not enabled, show store name
    if (storeName && storeName.length > 0) {
        storeText = (
            <span className={cx('findTextHybrid', 'storeName', findTextHybridStyle)}>
                {storeName}
                {
                    <div
                        className={dt(['text-xsmall', 'font-normal', 'text-left', 'lg:text-small'])}
                    >
                        {city && `${city}`}
                        {city && state && `, `}
                        {state && `${state}`}
                    </div>
                }
            </span>
        );
        storeChangeText = isFragment ? (
            ''
        ) : (
            <Button
                buttonType="Link"
                className={`${dt(['text-black', 'mr-2'])} ${cx('storeChangeText')}`}
                automationId="hamb-storeinfo-btn"
                buttonId="hambStoreInfoBtn"
                onClick={shopDepartmentAction}
            >
                <span className={dt(['sr-only'])}>Store Information</span>
                <Icon
                    className={dt(['h-6', 'w-6', 'pr-1'])}
                    iconType="svg"
                    automationId="hamb-storeinfo"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                >
                    <ArrowRight />
                </Icon>
            </Button>
        );
    } else {
        storeText = (
            <div className={cx('findStoreText', findStoreTextStyle)}>
                <div>{headerStore.NO_STORE_LOCATED}</div>
                {isFragment ? null : (
                    <Button
                        buttonType="Link"
                        className={cx('storeInformationTxt', storeInformationTxtStyle)}
                        onClick={selectAStoreTriggered}
                    >
                        {FIND_STORES_SELECT_A_STORE_HEADER_TEXT}
                    </Button>
                )}
            </div>
        );
    }

    return (
        <li className={cx('findStoreSection', findStoreSectionStyle)}>
            <Link
                type="button"
                onClick={shopDepartmentAction}
                className={`${dt(['p-2'])} ${cx('menuListItemLink', menuListItemLinkStyle)}`}
                data-itemid="root"
                data-title="MENU"
                data-name="Select Store"
                data-pagetype="findastore"
                data-nid="findastore"
            >
                <span
                    className={`${dt(['pr-2', 'inline-block', 'align-middle'])} ${cx(
                        'findStoreIcon'
                    )}`}
                >
                    <Icon
                        className={hambStoreStyle}
                        iconType="svg"
                        automationId="hamb-store"
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                    >
                        <Storeline />
                    </Icon>
                </span>
                {/* {title} */}
                <span
                    className={`${dt(['text-small', 'inline-block', 'align-middle'])} ${cx(
                        'iconText'
                    )}`}
                >
                    {storeText}
                </span>
            </Link>
            {/* More Stores Link */}
            {storeChangeText && (
                <div className={cx('moreStoresLink', moreStoresLinkStyle)}>{storeChangeText}</div>
            )}
            {/* More Stores Link */}
        </li>
    );
};

const renderTopSection = (
    items,
    userProfileInfo,
    logOutAction,
    closeMenu,
    enableHeaderSigninUpdate
) => {
    const optedItems =
        userProfileInfo?.userState && userProfileInfo?.userState !== '0'
            ? items?.signedin
            : items?.guest;

    const closeIcon = (
        <button
            onClick={closeMenu}
            data-automation-id="slider-button-close-default"
            className={menuCloseIconDefaultStyle}
        >
            <i data-automation-id="hamburger-menu-close-icon-default">
                <Icon iconType="svg" width="32px" height="32px" viewBox="0 0 32 32">
                    <HambCloseNew />
                </Icon>
            </i>
        </button>
    );
    return (
        <div className={cx(topSectionStyle, 'topSection')}>
            <div
                className={dt([
                    'flex',
                    'align-middle',
                    'justify-between',
                    'px-6',
                    'py-2',
                    'border-b',
                    'border-solid',
                    'border-gray-15',
                    'bg-white',
                ])}
            >
                <Icon
                    iconType="svg"
                    width="110"
                    height="34"
                    viewBox="0 0 110 34"
                    className={cx('jcpLogo')}
                >
                    <Icon2023JcpenneyLogo />
                </Icon>
                {closeIcon}
            </div>
            {optedItems &&
                renderSection(
                    optedItems.filter((item) => item?.topSection),
                    logOutAction,
                    userProfileInfo,
                    enableHeaderSigninUpdate
                )}
        </div>
    );
};

const renderDepartments = (items, shopDepartmentAction, loader, preferences) => {
    if (items) {
        return RootCategoryTemplate(items, shopDepartmentAction, loader, true, preferences);
    }
    return null;
};

const renderBottomSection = (items, userProfileInfo, logOutAction) => {
    const optedItems =
        userProfileInfo?.userState && userProfileInfo?.userState !== '0'
            ? items?.signedin
            : items?.guest;
    return (
        <div className={`${styles.bottomSection} ${bottomSectionStyle}`}>
            {optedItems &&
                renderSection(
                    optedItems.filter((item) => item?.bottomSection),
                    logOutAction
                )}
        </div>
    );
};

const DropdownDefault = (
    menuData,
    shopDepartmentAction,
    userProfileInfo = {
        firstName: null,
        accountId: null,
        rewardsStatus: null,
        userState: '0',
        totalCerts: null,
    },
    selectedStore = {
        isGeoStore: false,
        storeDetails: {
            name: '',
        },
    },
    logOutAction,
    analyticsForDefault,
    enableHybridHamburger,
    deptData,
    deviceType,
    loader,
    disableStoreInfoFromHeader,
    enableExternalComponent,
    openSlider,
    deptTitle,
    disableGeoLocatedStoreInfo,
    disableStoreNameFromHeader,
    isAccessibleV2Enabled,
    preferences,
    closeMenu,
    isNative,
    locationServiceStoreInfo = {},
    isFragment,
    triggerStoreSelectionSlider,
    enableHeaderSigninUpdate
) => {
    singleAnalytics = analyticsForDefault;
    openSignInSlider = openSlider;
    openStoreSelectionSlider = triggerStoreSelectionSlider;
    // TODO: #TJ think about reusability
    const departmentSection = renderDepartments(
        deptData,
        shopDepartmentAction,
        loader,
        preferences
    );
    const hybridHamburger = isNative
        ? [departmentSection]
        : [
              renderTopSection(
                  menuData,
                  userProfileInfo,
                  logOutAction,
                  closeMenu,
                  enableHeaderSigninUpdate
              ),
              findAStoreHybrid(
                  shopDepartmentAction,
                  disableStoreNameFromHeader,
                  locationServiceStoreInfo,
                  isFragment
              ),
              departmentSection,
              renderMidSection(menuData, userProfileInfo, logOutAction, enableHybridHamburger),
              renderBottomSection(menuData, userProfileInfo, logOutAction),
          ];
    const nonHybridHamburger = [
        shopDepartmentsRoot(shopDepartmentAction),
        findAStore(
            selectedStore,
            shopDepartmentAction,
            disableStoreInfoFromHeader,
            enableExternalComponent,
            disableGeoLocatedStoreInfo
        ),
        renderMidSection(menuData, userProfileInfo, logOutAction, enableHybridHamburger),
        needHelpSection(),
        accessabilityView(isAccessibleV2Enabled),
    ];
    const dropdownMenuStyle = dt(['w-full', 'text-left', 'h-full']);

    return (
        <div
            className={`${dt(['w-full', 'h-full', 'text-left'])} ${cx(
                'dropdownMenu',
                'newHeaderDropDown',
                dropdownMenuStyle,
                {
                    hybridHamburger: enableHybridHamburger,
                }
            )}`}
        >
            <ul className={dt(['h-full', 'overflow-y-auto', 'pb-12'])}>
                {enableHybridHamburger ? hybridHamburger : nonHybridHamburger}
            </ul>
        </div>
    );
};

export default DropdownDefault;
