import RegionActionTypes from '../../actionTypes/RegionActionTypes';

const defaultPriceZone = -1;

function RegionReducer(state = {}, action) {
    switch (action.type) {
        case RegionActionTypes.JS_UPDATE_PRICING_STATUS:
            return { isEnabled: action.payload };

        case RegionActionTypes.JS_SET_REGION:
            return { regionZone: action.payload || defaultPriceZone };

        default:
            return state;
    }
}

RegionReducer.reducerName = 'regionalPricing';

export default RegionReducer;
