import * as types from '../actionTypes/LazyActionTypes';

const lazyReducer = function LazyReducer(state = {}, action) {
    switch (action.type) {
        case types.SHOW_LAZY: {
            if (!state[action.payload.identifier]) {
                return {
                    ...state,
                    [action.payload.identifier]: true,
                };
            }
            return state;
        }
        case types.HIDE_LAZY: {
            if (state[action.payload.identifier]) {
                return {
                    ...state,
                    [action.payload.identifier]: false,
                };
            }
            return state;
        }
        default:
            return state;
    }
};

lazyReducer.reducerName = 'lazy';

export default lazyReducer;
