/* istanbul ignore if */
/* istanbul ignore next */
if (
    /* istanbul ignore next */ (typeof window !== 'undefined' /* istanbul ignore next */ &&
        !window._babelPolyfill) ||
    /* istanbul ignore next */
    (typeof global !== 'undefined' /* istanbul ignore next */ && !global._babelPolyfill)
) {
    /* istanbul ignore next */ require('@babel/polyfill'); // eslint-disable-line
}

/* istanbul ignore next */
module.exports = require('./CommonExternal');
