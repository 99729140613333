/* eslint-disable no-console */
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import isEmpty from 'lodash/isEmpty';
import { convertFavoriteListForApp } from '../../utils/index';
import { addItem, removeItem } from '../../actions/SavedItemAction';
import { saveNativeFavoriteStore, getHandleBackPress } from '../../actions/NativeAppAction';
import { DP_SAVED_ITEMS } from '../../common/Constants';

/* istanbul ignore next */
export default function installWebView(store) {
    const NATIVE_APP_ERROR = 'NATIVE_APP_ERROR';
    window.jcpWebApp = {
        postMessageToApp: (payload) => {
            try {
                console.info('posting message to APP', payload);
                if (window.webkit) {
                    window.webkit.messageHandlers.iosListener.postMessage(payload); // NOSONAR
                } else if (window.androidListener) {
                    const payloadStr = JSON.stringify(payload || '{}');
                    window.androidListener.postMessage(payloadStr); // NOSONAR
                } else {
                    console.warn('Could not find IOS or Android app message listener', payload);
                    store.dispatch({ type: NATIVE_APP_ERROR, payload });
                    const ErrorPayload = { ...payload };
                    ErrorPayload.errorType =
                        'WEBVIEW::ERROR - Could not find IOS or Android app message listener';
                    store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
                }
            } catch (e) {
                console.warn('Exception occurred when posting message to APP', payload, e);
                store.dispatch({ type: NATIVE_APP_ERROR, payload });
                const ErrorPayload = { ...payload };
                ErrorPayload.errorType =
                    'WEBVIEW::ERROR - Exception occurred when posting message to APP';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
        },
        postMessage: (type, payload = '{}') => {
            try {
                if (type) {
                    console.info('message from APP', type, payload);
                    store.dispatch({ type, payload: JSON.parse(payload) });
                } else {
                    console.warn('Message from app with empty type', type, payload);
                    store.dispatch({ type: NATIVE_APP_ERROR, payload });
                    const ErrorPayload = { ...payload };
                    ErrorPayload.errorType = 'WEBVIEW::ERROR - Message from app with empty type';
                    store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
                }
            } catch (e) {
                console.warn('Exception occurred when Message from app', payload, e);
                const ErrorPayload = { ...payload };
                ErrorPayload.errorType =
                    'WEBVIEW::ERROR - Exception occurred when Message from app';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
        },
        // handles back button
        handleBackPress: () => {
            const isSliderOpen = !!document.getElementById('atPanelContent');
            try {
                if (isSliderOpen) {
                    const event = new Event('nativeAppBackPress');
                    document.dispatchEvent(event);
                } else {
                    console.info('handleBackPress from APP');
                    store.dispatch(getHandleBackPress({ isHandleBackPress: isSliderOpen }));
                }
            } catch (err) {
                console.error(`WEBVIEW::ERROR - failed handleBackPress ${err}`);
                const ErrorPayload = { ...err };
                ErrorPayload.errorType = 'WEBVIEW::ERROR - failed handleBackPress';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
            return { handled: isSliderOpen };
        },
        /*  // handles switch between footer tabs
        handleSwitchBetweenFooterTabs: (isSwitchBetweenFooterTabs = false) => {
            try {
                console.info('handleSwitchBetweenFooterTabs from APP');
                store.dispatch(getHandleSwitchBetweenFooterTabs(isSwitchBetweenFooterTabs));
            } catch (err) {
                console.error(`WEBVIEW::ERROR - failed to handleSwitchBetweenFooterTabs ${err}`);
                const ErrorPayload = { ...err };
                ErrorPayload.errorType = 'WEBVIEW::ERROR - failed to handleSwitchBetweenFooterTabs';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
            return { handleBetweenFooterTabs: isSwitchBetweenFooterTabs };
        }, */
        // apps telling yoda to set tokens on localstorage
        setTokens: (tokenObjStr = '{}') => {
            try {
                const tokenObj =
                    typeof tokenObjStr === 'string' ? JSON.parse(tokenObjStr) : tokenObjStr;
                TokenProvider.setTokensFromNativeApp(tokenObj);
                return true;
            } catch (err) {
                console.error(`WEBVIEW::ERROR - failed to set tokens ${err}`);
            }
            return false;
        },
        // apps telling yoda to save apps favouritestore
        userChangedFavouriteStore: (storeData = '{}') => {
            try {
                console.info('userChangedFavouriteStore from APP', storeData);
                store.dispatch(saveNativeFavoriteStore(storeData));
            } catch (err) {
                console.error(`WEBVIEW::ERROR - failed to save userChangedFavouriteStore ${err}`);
                const ErrorPayload = { ...err };
                ErrorPayload.errorType =
                    'WEBVIEW::ERROR - failed to save userChangedFavouriteStore';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
            return '';
        },
        // apps send to clear tokens after logout
        signout: () => {
            try {
                TokenProvider.logout();
                console.info('posting message to APP for signout');
                return true;
            } catch (err) {
                console.error(`WEBVIEW::ERROR - failed to signout ${err}`);
                const ErrorPayload = { ...err };
                ErrorPayload.errorType = 'WEBVIEW::ERROR - signout clearing token failed';
                store.dispatch({ type: 'LOG_CLIENT_ERROR', ErrorPayload });
            }
            return false;
        },
        // returns current favorite list
        getGuestUserFavList: () => {
            const favList = JSON.parse(TokenProvider.get(DP_SAVED_ITEMS) || '[]');
            return convertFavoriteListForApp(favList);
        },
        // returns updated favorite list from APP
        guestUserFavListUpdateFromCAM: (addedList = '[]', removedList = '[]') => {
            const addedListArr = JSON.parse(addedList);
            const removedListArr = JSON.parse(removedList);

            // Adds new item to localstorage
            if (!isEmpty(addedListArr)) {
                addedListArr.forEach(({ itemId, id }) => {
                    const payload = [
                        {
                            product: {
                                id,
                            },
                            itemId,
                            quantity: 1,
                        },
                    ];
                    store.dispatch(addItem(payload));
                });
                // removes new item from localstorage
            }
            if (!isEmpty(removedListArr)) {
                removedListArr.forEach(({ id }) => {
                    const payload = {
                        ppId: id,
                        id,
                    };
                    store.dispatch(removeItem(payload));
                });
            }
        },
    };
}
