import {
    SLIDER_CATEGORY_LOAD_SUCCESS,
    SLIDER_HOVER_LOAD_SUCCESS,
} from '../actionTypes/HamburgerActionTypes';

const itemCountReducer = function itemCountReducer(state = {}, action) {
    switch (action.type) {
        case SLIDER_CATEGORY_LOAD_SUCCESS: {
            const keys = Object.keys(action.categories);
            const flattenObject = [];
            keys.forEach((key) => {
                flattenObject.push(action.categories[key][0]);
            });
            return flattenObject;
        }
        case SLIDER_HOVER_LOAD_SUCCESS: {
            const keys = Object.keys(action.categories.hoverPanelMap);
            let flattenObject = {};
            keys.forEach((key) => {
                flattenObject = { ...flattenObject, ...action.categories.hoverPanelMap[key] };
            });
            return {
                menu: flattenObject,
                meta: {
                    imageSrc: action.categories.imageSrc,
                    title: action.categories.viewAllText,
                    viewAllLink: action.categories.viewAllLink,
                    viewAllTextUrl: action.categories.viewAllTextUrl,
                    departmentName: action.categories.departmentName,
                },
            };
        }
        default:
            return state;
    }
};

itemCountReducer.reducerName = 'hambergurMenu';

export default itemCountReducer;
