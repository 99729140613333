import React from 'react';
import Success from 'yoda-core-components/lib/assets/svg/Success.svg';
import Warning from 'yoda-core-components/lib/assets/svg/Warning.svg';
import SflError from 'yoda-core-components/lib/assets/svg/SflError.svg';

const Config = {
    errors: {
        EMPTY_COUPON: 'Please enter a coupon or reward code.',
    },
    svgNameArray: [
        { icon: 'success', iconComponent: <Success /> },
        { icon: 'warning', iconComponent: <Warning /> },
        { icon: 'sfl-error', iconComponent: <SflError /> },
    ],
    TOF: 'tof',
    SERIAL_CODE_NEED: 'SERIAL_CODE_NEED',
    COUPON_INLINE_MESSAGES_ID: 'COUPON_INLINE_MESSAGES_ID',
    COUPON_POPUP_MESSAGES_ID: 'COUPON_POPUP_MESSAGES_ID',
    COUPON_QUAL_ORIG_MSG: 'The item(s) in your cart is not eligible for this coupon.',
    COUPON_QUAL_TRANS_MSG:
        '<strong>The item(s) in your cart is not eligible for this coupon.</strong> Please add items that do not have exclusions.',
    SERIAL_CODE_MESSAGES_ID: 'SERIAL_CODE_MESSAGES_ID',
    SERIAL_CODE_EMPTY_MSG: 'Enter a serial number.',
};
export default Config;
