import { createSelector } from 'reselect';
import get from 'lodash/get';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { selectFeatureFlags, selectReqHeaders } from './ContextSelector';

export const getDisableBYDeliveryDateFlag = createSelector(
    [selectFeatureFlags, selectReqHeaders],
    (featureFlags, headers) => {
        const isFeatureDisabled = featureFlags.disableBYDeliveryDate;
        if (!isFeatureDisabled) {
            let akamaiThrottlingCookie = null;
            if (__SERVER__) {
                akamaiThrottlingCookie = get(headers, 'cookies.DPCPT', '');
            } else {
                akamaiThrottlingCookie = TokenProvider.get('DPCPT', true);
            }
            if (akamaiThrottlingCookie) {
                // DPCPT = |CP1Y|CP2N|CP3Y
                const akamaiThrottlingCookieList = akamaiThrottlingCookie.split('|');
                return !akamaiThrottlingCookieList.includes('CP5Y');
            }
        }
        return true;
    }
);

export default {
    getDisableBYDeliveryDateFlag,
};
