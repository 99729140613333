import 'regenerator-runtime/runtime';
import { fork, all } from 'redux-saga/effects';
import watchProductSearchRequest from './ProductSearchSaga';
import watchOptInRequest from './OptInSaga';
import watchSessionsRequest from './SessionSaga';
import watchListsRequest from './ListsSaga';
import {
    watchUserStoreLocationInfo,
    watchUpdateUserStoreLocationInfo,
} from './LocationServiceSaga';
import watchWeeklyAdsSaga from './WeeklyAdsSaga';
import watchProductListRequest, {
    watchResetProductList,
    watchEnsightenErrorRequest,
} from './ProductCartridgeSaga';
import watchPromotionalBannerActionRequest from './PromotionalBannerSaga';
import watchStoreSagaActionsRequest, { watchSetMyStore } from './StoreSaga';
import watchBreadCrumbNavRequest from './BreadCrumbNavSaga';
import {
    watchApplyAdjustmentsRequest,
    watchRemoveAdjustmentsRequest,
    watchgetAppliedAdjustmentsRequest,
} from './AdjustmentSaga';
import watchAccessTokenProviderRequest from './AccessTokenProviderSaga';
import { watchFindStoresRequest, watchFindMoreStoresRequest } from './FindStoresSaga';
import watchAccountRequest from './AccountSaga';
import watchSavedItem from './SavedItemsSaga';
import watchSelectStore from './SelectStoreSaga';
import {
    watchfindAStorePage,
    watchMoreFindAStorePage,
    watchPrePopulateStores,
} from './FindAStorePageSaga';
import watchSetMyDefaultStore from './SetMyDefaultStoreSaga';
import { watchhamburgerchange, watchHoverApi } from './HamburgerSaga';
import watchMTLToolTipSaga from './SavedListToolTip';
import watchAddToCartSaga from './AddToCartSaga';
import watchIrisRequest from './IrisSaga';
import watchEcoRebatesSaga from './EcoRebatesSaga';
import watchProductDetailsSaga from './ProductDetailsSaga';
import watchProductPricingSaga from './ProductPricingSaga';
import watchInventorySaga from './ProductInventorySaga';
import watchIrisGlobalNavigationRequest from './IrisGlobalNavigationSaga';
import watchIrisHamburgerChange from './IrisHamburgerSaga';
import watchStickyTimerActionRequest from './StickyTimerSaga';
import watchCouponListSaga from './CouponListSaga';
import watchIrisGlobalFooterNavigationRequest from './IrisGlobalFooterNavigationSaga';
import watchCMPageRequest from './CMPageSaga';
import watchS1TargeterRequest from './IRISS1TargeterSaga';
import watchShopTheLookRequest from './ShopTheLookSaga';
import watchBrightEdgeAutopilotRequest from './BrightEdgeSaga';
import watchCriteoSaga from './CriteoSaga';

export function* sagaList() {
    yield all([
        fork(watchProductSearchRequest),
        fork(watchOptInRequest),
        fork(watchSessionsRequest),
        fork(watchListsRequest),
        fork(watchProductListRequest),
        fork(watchStoreSagaActionsRequest),
        fork(watchBreadCrumbNavRequest),
        fork(watchResetProductList),
        fork(watchEnsightenErrorRequest),
        fork(watchApplyAdjustmentsRequest),
        fork(watchRemoveAdjustmentsRequest),
        fork(watchAccessTokenProviderRequest),
        fork(watchgetAppliedAdjustmentsRequest),
        fork(watchFindStoresRequest),
        fork(watchFindMoreStoresRequest),
        fork(watchPromotionalBannerActionRequest),
        fork(watchAccountRequest),
        fork(watchSavedItem),
        fork(watchSelectStore),
        fork(watchhamburgerchange),
        fork(watchHoverApi),
        fork(watchfindAStorePage),
        fork(watchMoreFindAStorePage),
        fork(watchPrePopulateStores),
        fork(watchSetMyStore),
        fork(watchSetMyDefaultStore),
        fork(watchMTLToolTipSaga),
        fork(watchAddToCartSaga),
        fork(watchIrisRequest),
        fork(watchCMPageRequest),
        fork(watchEcoRebatesSaga),
        fork(watchProductDetailsSaga),
        fork(watchProductPricingSaga),
        fork(watchInventorySaga),
        fork(watchIrisGlobalNavigationRequest),
        fork(watchIrisHamburgerChange),
        fork(watchStickyTimerActionRequest),
        fork(watchCouponListSaga),
        fork(watchWeeklyAdsSaga),
        fork(watchUserStoreLocationInfo),
        fork(watchUpdateUserStoreLocationInfo),
        fork(watchIrisGlobalFooterNavigationRequest),
        fork(watchS1TargeterRequest),
        fork(watchShopTheLookRequest),
        fork(watchBrightEdgeAutopilotRequest),
        fork(watchCriteoSaga),
    ]);
}

export default function* helloSaga() {
    yield all([
        fork(watchProductSearchRequest),
        fork(watchOptInRequest),
        fork(watchSessionsRequest),
        fork(watchListsRequest),
        fork(watchProductListRequest),
        fork(watchStoreSagaActionsRequest),
        fork(watchBreadCrumbNavRequest),
        fork(watchResetProductList),
        fork(watchEnsightenErrorRequest),
        fork(watchApplyAdjustmentsRequest),
        fork(watchRemoveAdjustmentsRequest),
        fork(watchAccessTokenProviderRequest),
        fork(watchgetAppliedAdjustmentsRequest),
        fork(watchFindStoresRequest),
        fork(watchFindMoreStoresRequest),
        fork(watchPromotionalBannerActionRequest),
        fork(watchAccountRequest),
        fork(watchSavedItem),
        fork(watchSelectStore),
        fork(watchhamburgerchange),
        fork(watchHoverApi),

        fork(watchfindAStorePage),
        fork(watchMoreFindAStorePage),
        fork(watchPrePopulateStores),
        fork(watchSetMyStore),
        fork(watchSetMyDefaultStore),
        fork(watchMTLToolTipSaga),

        fork(watchAddToCartSaga),
        fork(watchIrisRequest),
        fork(watchCMPageRequest),
        fork(watchEcoRebatesSaga),
        fork(watchProductDetailsSaga),
        fork(watchProductPricingSaga),
        fork(watchInventorySaga),
        fork(watchIrisGlobalNavigationRequest),
        fork(watchIrisHamburgerChange),
        fork(watchStickyTimerActionRequest),
        fork(watchCouponListSaga),
        fork(watchWeeklyAdsSaga),
        fork(watchUserStoreLocationInfo),
        fork(watchUpdateUserStoreLocationInfo),
        fork(watchIrisGlobalFooterNavigationRequest),
        fork(watchS1TargeterRequest),
        fork(watchShopTheLookRequest),
        fork(watchBrightEdgeAutopilotRequest),
        fork(watchCriteoSaga),
    ]);
}
