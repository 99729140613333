import { SET_SWITCH_WIDGET_SELECTION } from '../actionTypes/PLPSwitchWidgetActionTypes';

const initialState = {
    switchWidgetSelectedValue: '',
};

const plpSwitchWidgetReducer = function plpSwitchWidgetReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SWITCH_WIDGET_SELECTION:
            return { ...state, switchWidgetSelectedValue: action.selectedValue };
        default:
            return state;
    }
};

plpSwitchWidgetReducer.reducerName = 'switchWidgetData';

export default plpSwitchWidgetReducer;
