import {
    ADD_PRODUCT_CARTRIDGE_SLOT,
    ADD_LOADER_TO_LIST,
    ADD_CERTONA_LOADER_TO_LIST,
    CERTONA_OPTIONS,
    RESET_PRODUCT_CARTRIDGE_REDUCER,
    CLEAR_CERTONA_OPTIONS,
    REMOVE_PRODUCT_CARTRIDGE_SLOT,
} from '../actionTypes/ProductCartridgeActionTypes';

const initialState = {
    productCartridgeSlots: {},
    loaders: {},
    certonaOptions: [],
};

const productCartridgeReducer = function productCartridgeReducer(
    state = { ...initialState },
    action
) {
    switch (action.type) {
        case ADD_PRODUCT_CARTRIDGE_SLOT: {
            const slotData = { ...state.productCartridgeSlots, ...action.slotData };
            return { ...state, productCartridgeSlots: slotData };
        }
        case REMOVE_PRODUCT_CARTRIDGE_SLOT: {
            if (action.schemaName) {
                const schemaNames = Array.isArray(action.schemaName)
                    ? action.schemaName
                    : [action.schemaName];
                const slotData = { ...state.productCartridgeSlots };
                schemaNames.forEach((schemaName) => {
                    delete slotData[schemaName];
                });
                return { ...state, productCartridgeSlots: slotData };
            }
            return state;
        }
        case ADD_LOADER_TO_LIST:
        case ADD_CERTONA_LOADER_TO_LIST: {
            const { loader, pageType } = action.initialData.payload;
            let loaders;
            // If loader already exists then add the pageType else add both loader and pageType
            if (state.loaders[loader]) {
                const loaderTypes = {
                    ...state.loaders[loader],
                    [pageType]: action.responseData || {},
                };
                loaders = { [loader]: loaderTypes };
            } else {
                loaders = { ...state.loaders, [loader]: { [pageType]: {} } };
            }

            return { ...state, loaders };
        }
        case CERTONA_OPTIONS: {
            const { certonaOptions = [] } = state;
            return {
                ...state,
                certonaOptions: [...certonaOptions, action.payload],
            };
        }
        case CLEAR_CERTONA_OPTIONS: {
            return {
                ...state,
                certonaOptions: [],
            };
        }
        case RESET_PRODUCT_CARTRIDGE_REDUCER: {
            return { ...initialState };
        }
        default:
            return state;
    }
};

productCartridgeReducer.reducerName = 'productCartridge';

export default productCartridgeReducer;
