import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import ActiveCartReminderLazy from './ActiveCartReminder/ActiveCartReminderChunksLazy';
import {
    getLastCartVisitTime,
    removeLastCartVisitTime,
} from '../../helpers/Utils/activeCartLastCartVisitTime';
import { ITEM_COUNT } from '../../common/Constants';
import * as styles from './CartWrapper.css';

const cx = classNames.bind(styles);

function CartWrapper(props) {
    const { enableActiveCart, toggleWiggleCart, activeCartConfig } = props;
    let showActiveCartReminder = false;
    if (enableActiveCart) {
        /* istanbul ignore next */
        if (!isEmpty(getLastCartVisitTime()) && Cookies.load(ITEM_COUNT) === 0) {
            removeLastCartVisitTime();
        }
        if (Cookies.load(ITEM_COUNT) > 0) {
            showActiveCartReminder = true;
        }
    }
    const wiggleEffect = toggleWiggleCart.wiggleCart ? 'wiggleCart' : '';
    const wiggleAnimation = { animationDuration: `${activeCartConfig.cartWiggleTimeInSec || 3}s` };
    return (
        <div className={cx(props.cartStyleName, showActiveCartReminder ? 'relative' : '')}>
            <div
                className={cx(showActiveCartReminder ? wiggleEffect : '')}
                style={showActiveCartReminder ? wiggleAnimation : {}}
            >
                {props.children}
                {showActiveCartReminder && <ActiveCartReminderLazy {...props} />}
            </div>
        </div>
    );
}

CartWrapper.defaultProps = {
    enableActiveCart: false,
    children: '',
    cartStyleName: '',
    activeCartConfig: {},
    toggleWiggleCart: {},
};

CartWrapper.propTypes = {
    enableActiveCart: PropTypes.bool,
    children: PropTypes.element,
    cartStyleName: PropTypes.string,
    activeCartConfig: PropTypes.number,
    toggleWiggleCart: PropTypes.bool,
};

export default CartWrapper;
