import { takeLatest, put, call } from 'redux-saga/effects';
import { getBrightEdgeApi } from 'yoda-interfaces/lib/BrightEdge/BrightEdgeApi';
import {
    BRIGHTEDGE_AUTOPILOT_GET_REQUEST,
    BRIGHTEDGE_AUTOPILOT_GET_SUCCESS,
    BRIGHTEDGE_AUTOPILOT_GET_ERROR,
} from '../actionTypes/BrightEdgeActionTypes';

function* brightEdgeAutopilotSaga(params) {
    try {
        const brightEdgeData = yield call(getBrightEdgeApi, params.payload) || {};
        yield put({
            type: BRIGHTEDGE_AUTOPILOT_GET_SUCCESS,
            brightEdgeData,
        });
    } catch (error) {
        yield put({ type: BRIGHTEDGE_AUTOPILOT_GET_ERROR, error });
    }
}

const watchBrightEdgeAutopilotRequest = function* watchBrightEdgeAutopilotRequest() {
    yield takeLatest(BRIGHTEDGE_AUTOPILOT_GET_REQUEST, brightEdgeAutopilotSaga);
};

watchBrightEdgeAutopilotRequest.sagaName = 'watchBrightEdgeAutopilotRequest';

export default watchBrightEdgeAutopilotRequest;
