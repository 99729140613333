import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import classNames from 'classnames/bind';
import { detectisIE } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import config from './Header.config';
import AnalyticsAction from '../../actions/AnalyticsAction';
import OverlayAction from '../../actions/OverlayAction';
import * as styles from './SecondaryNavigationPanel.css';
import Link from '../Link/Link';
import shopDeptActions from '../../actions/ShowShopDepartmentAction';
import getNavigationData from './GetNavigationData';
import BgWhiteAction from '../../actions/BgWhiteAction';

const cx = classNames.bind(styles);

const flyoutMenuDefault = {
    firstLevelHover: false,
    hoverDepartmentData: '',
    lastBindedDept: '',
    hoverDept: '',
    hoverCategory: '',
    mousePosition: 1,
    displayLevel2Columns: 0,
    enableFlyoutMenu: false,
    flyoutItemSelected: '',
    leftOffsetFlyoutMenu: 0,
    borderTransition: '',
    leftAlign: 'auto',
    rightAlign: 'auto',
    widthAlign: 'auto',
    heightValFlag: false,
};

const columnMegaMenuBlockStyles = dt([
    'static',
    'block',
    'bg-none',
    'h-auto',
    'overflow-visible',
    'py-0',
    'px-6',
    'border-t-0',
    "before:content-['']",
    'before:absolute',
    'before:-top-2',
    'before:left-0',
    'before:w-full',
    'before:shadow-[0_4px_8px_0px_rgba(83,96,107,0.14)]',
    'before:h-2',
]);

export class FlyoutMenu extends Component {
    static propTypes = {
        actions: PropTypes.object,
        shopDepartmentMenuInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        shopDeptOverlay: PropTypes.number,
        enableSingleFlyerMouseIntention: PropTypes.bool,
        enableTestTriangle: PropTypes.bool,
        insideTriangleDelay: PropTypes.number, // when stay inside triangle for some time, trigger the hovered l1 (default 500ms)
        outsideTriangleDelay: PropTypes.number, // when move outside triangle, add an optional delay to trigger l1 (deault 0ms)
        updateMaxColumn: PropTypes.number, // maxColumn number that we can display against L1
        enableColumnStyleFlyer: PropTypes.bool,
        maxL3Column: PropTypes.number,
        offsetAlignInterchange: PropTypes.number,
        isDepartmentData: PropTypes.bool,
        enableMultiRowL2Nav: PropTypes.bool,
    };

    static defaultProps = {
        actions: {},
        shopDepartmentMenuInfo: [],
        shopDeptOverlay: null,
        enableSingleFlyerMouseIntention: false,
        enableTestTriangle: false,
        insideTriangleDelay: 500,
        outsideTriangleDelay: 0,
        updateMaxColumn: 0,
        enableColumnStyleFlyer: false,
        maxL3Column: 15,
        offsetAlignInterchange: 700,
        isDepartmentData: false,
        enableMultiRowL2Nav: false,
    };

    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.state = { ...flyoutMenuDefault };

        this.timeoutId = null;
        this.currentHoverRight = 0;
        this.currentHoverLeft = 0;
        this.offsetLeftValue = 0;
        this.offsetRightValue = 0;
        this.timerL1Hover = null;
        this.animationFrameId = null;
        this.currentMouseCoordinates = {};
        this.previousMouseCoordinates = {};
        this.activeSubMenuTopLeftCoordinates = {};
        this.activeSubMenuBottomLeftCoordinates = {};
        this.debouncedDisplayCategory = _debounce(
            this.displayCategory,
            this.props.outsideTriangleDelay
        );
    }

    componentWillReceiveProps(nextProps) {
        /* istanbul ignore next */
        if (!this.props.outsideTriangleDelay && nextProps.outsideTriangleDelay) {
            this.debouncedDisplayCategory = _debounce(
                this.displayCategory,
                nextProps.outsideTriangleDelay
            );
        }
    }

    componentDidUpdate() {
        /* istanbul ignore next */
        if (this.props.enableSingleFlyerMouseIntention) {
            // after render; not called for the initial render
            this.refreshSubmenuCoords();
            // for testing only
            this.canvas = document.getElementById('canvas');
        }
    }

    componentWillUnmount() {
        /* istanbul ignore next */
        if (this.props.enableSingleFlyerMouseIntention) {
            // for testing only
            if (this.props.enableTestTriangle) {
                this.clearTriangle();
            }
        }
    }

    /* onWindowMouseMove = (event) => {
        if (this.animationFrameId) {
            window.cancelAnimationFrame(this.animationFrameId);
        }

        this.animationFrameId = window.requestAnimationFrame(() => {
            this.saveMouseCoordinates(event.pageX, event.pageY);

            // for testing only
            if (this.props.enableTestTriangle) {
                this.clearTriangle();
                if (this.state.mousePosition === 1) {
                    this.drawTriangle();
                }
            }
        });
    } */

    /* istanbul ignore next */
    onLevel1MouseOver = (event) => {
        this.props.actions.showOverlay();
        this.props.actions.showBgWhite();
        const currentElemetOrPrevElement = event.target;
        const menuName = currentElemetOrPrevElement.textContent;
        this.currentHoverLeft = currentElemetOrPrevElement.getBoundingClientRect().left;
        this.currentHoverRight = currentElemetOrPrevElement.getBoundingClientRect().right;
        const offsetLeftValue =
            currentElemetOrPrevElement.getBoundingClientRect().left -
            this.subMenuL1Ref.getBoundingClientRect().left;
        // const leftOffsetFlyoutMenuValue = this.getLeftOffsetFlyoutMenu(offsetLeftValue);
        const { shopDepartmentMenuInfo } = this.props;
        const item = _find(
            shopDepartmentMenuInfo,
            (value) => value.title === menuName && !_isEmpty(value.departments)
        );
        const columnData = this.props?.enableMultiRowL2Nav
            ? this?.buildColumns(item.departments)
            : item.departments;
        const getOffsetWidth = this.getOffsetWidth(item.inspirationalContent, columnData.length);
        const borderTransitionElement = (
            <span
                className={`${dt([
                    'sm:z-[1111]',
                    'border-t-4',
                    'border-solid',
                    'border-brand-red',
                    'absolute',
                    'bottom-0',
                ])} ${cx('columnMegaMenuBlockBorder')}`}
                style={{ width: currentElemetOrPrevElement.offsetWidth, left: offsetLeftValue }}
            />
        );

        this.enableFlyoutMenu(menuName);
        if (!this.props.enableSingleFlyerMouseIntention) {
            const { hoverDept } = this.state;
            if (!hoverDept) {
                this.displayCategory(menuName);
            }
        } else {
            // TODO: #TJ on move out of L1 clear it as well
            this.timeoutId && clearTimeout(this.timeoutId);

            if (this.shouldChangeActiveMenuItem()) {
                if (
                    _isEmpty(this.state.hoverDepartmentData) ||
                    menuName !== this.state.lastBindedDept
                ) {
                    this.debouncedDisplayCategory(menuName, null, columnData);
                }
            } else {
                // if moving within the triangle, but stay on an L1 for enough time, we still activate new category
                /* istanbul ignore next */
                this.timeoutId = setTimeout(() => {
                    if (this.state.mousePosition === 1) {
                        this.displayCategory(menuName);
                    }
                }, this.props.insideTriangleDelay);
            }
        }

        this.setState({
            hoverDept: menuName,
            hoverCategory: '',
            mousePosition: 1,
            borderTransition: borderTransitionElement,
            widthAlign: getOffsetWidth,
            heightValFlag: true,
        });
    };

    /* istanbul ignore next */
    onLevel1MouseOut = (event) => {
        this.timerL1Hover && clearTimeout(this.timerL1Hover);
        const element = event.nativeEvent ? event.nativeEvent.relatedTarget : event.relatedTarget;
        if (this.elementToDisableL1Mouseout(element)) {
            if (!_isEmpty(this.l1FlyoutRef)) {
                this.setState({ heightValFlag: false });
            }
            setTimeout(() => {
                this.setState({
                    hoverDept: '',
                    leftOffsetFlyoutMenu: 0,
                    borderTransition: '',
                    leftAlign: 'auto',
                    rightAlign: 'auto',
                    hoverDepartmentData: '',
                });
                this.props.actions.removeOverlay();
                this.props.actions.removeBgWhite();
                this.disableFlyoutMenu();
            }, 300);
        }
        if (!this.props.enableSingleFlyerMouseIntention) {
            /* istanbul ignore next */
            const overlayDelay = this.props.shopDeptOverlay || config.desktop.mouseOverDelay;
            setTimeout(() => {
                const { hoverDept } = this.state;
                this.state.mousePosition === 1 ? this.displayCategory(hoverDept) : '';
            }, overlayDelay);
        }
    };

    /* istanbul ignore next */
    onLevel2HeadingMouseOver = () => {
        this.setState({
            hoverCategory: '',
        });
    };

    /* istanbul ignore next */
    onLevel2MouseEnter = () => {
        this.setState({
            mousePosition: 2,
        });
    };

    /* istanbul ignore next */
    onLevel2MouseLeave = (event) => {
        this.timerL1Hover && clearTimeout(this.timerL1Hover);
        const element = event.nativeEvent ? event.nativeEvent.relatedTarget : event.relatedTarget;
        if (this.elementToDisableL1Mouseout(element)) {
            if (!_isEmpty(this.l1FlyoutRef)) {
                this.setState({ heightValFlag: false });
            }
            setTimeout(() => {
                this.setState({
                    mousePosition: 1,
                    hoverDept: '',
                    leftOffsetFlyoutMenu: 0,
                    borderTransition: '',
                    leftAlign: 'auto',
                    rightAlign: 'auto',
                    hoverDepartmentData: '',
                });
                this.props.actions.removeOverlay();
                this.disableFlyoutMenu();
            }, 300);
        }
    };

    /* istanbul ignore next */
    onLevel2MouseOver = (event) => {
        const currentElemetOrPrevElement = event.target;
        const menuName = currentElemetOrPrevElement.textContent;
        const { lastBindedDept, hoverCategory } = this.state;

        if (!hoverCategory) {
            this.displayCategory(lastBindedDept, menuName);
        }

        this.setState({
            hoverCategory: menuName,
        });
    };

    /* istanbul ignore next */
    onLevel2MouseOut = () => {
        const overlayDelay = this.props.shopDeptOverlay || config.desktop.mouseOverDelay;
        /* istanbul ignore next */
        setTimeout(() => {
            const { lastBindedDept, hoverCategory } = this.state;

            if (hoverCategory && this.state.mousePosition === 2) {
                this.displayCategory(lastBindedDept, hoverCategory);
            }
        }, overlayDelay);
    };

    /* istanbul ignore next */
    onLevel3MouseEnter = () => {
        this.setState({
            mousePosition: 3,
            hoverCategory: '',
        });
    };

    /* istanbul ignore next */
    onLevel3MouseLeave = () => {
        this.setState({
            mousePosition: 2,
            hoverCategory: '',
        });
    };

    /* istanbul ignore next */
    onL1Click = (event, title, links, isInternalUrl) => {
        if (this.props.enableSingleFlyerMouseIntention) {
            this.timeoutId && clearTimeout(this.timeoutId);
            this.displayCategory(title);
        }
        return this.navigateurl(event, title, links, isInternalUrl);
    };

    /* istanbul ignore next */
    onL1HoverEvent = (event) => {
        event.persist();
        this.timerL1Hover = setTimeout(() => {
            this.onLevel1MouseOver(event);
        }, 300);
    };

    /* istanbul ignore next */
    getOffsetWidth = (item = [], numColumns) => {
        let offsetWidth = 'auto';
        const screenSize = window?.innerWidth;

        if (
            (screenSize <= 1280 && (numColumns > 5 || (numColumns === 5 && !_isEmpty(item)))) ||
            (screenSize <= 1080 && numColumns === 5 && _isEmpty(item))
        ) {
            offsetWidth = '100%';
        }

        return offsetWidth;
    };

    setsubMenuL1Ref = (node) => {
        this.subMenuL1Ref = node;
    };

    /* getLeftOffsetFlyoutMenu = (leftOffset = 0) => {
        // This configuration will be removed after Triangle implementation of Menu
        if (window.innerWidth >= 1350 && window.innerWidth < 1450) {
            if (leftOffset > 0 && leftOffset <= 700) {
                return 35;
            } else if (leftOffset > 700 && leftOffset <= 840) {
                return 160;
            } else if (leftOffset > 840 && leftOffset <= 1150) {
                return 310;
            } else if (leftOffset > 1150 && leftOffset <= 1240) {
                return 380;
            } else if (leftOffset > 1240 && leftOffset <= 1300) {
                return 640;
            } else if (leftOffset > 1300) {
                return 700;
            }
        } else if (window.innerWidth >= 1450) {
            if (leftOffset > 0 && leftOffset <= 700) {
                return 35;
            } else if (leftOffset > 700 && leftOffset <= 1120) {
                return 300;
            } else if (leftOffset > 1120 && leftOffset <= 1260) {
                return 330;
            } else if (leftOffset > 1260) {
                return 760;
            }
        } else if (window.innerWidth >= 1240 && window.innerWidth < 1350) {
            if (leftOffset > 0 && leftOffset <= 700) {
                return 35;
            } else if (leftOffset > 700 && leftOffset <= 840) {
                return 160;
            } else if (leftOffset > 840 && leftOffset <= 1060) {
                return 200;
            } else if (leftOffset > 1060 && leftOffset <= 1175) {
                return 250;
            } else if (leftOffset > 1175 && leftOffset <= 1200) {
                return 500;
            } else if (leftOffset > 1200 && leftOffset <= 1300) {
                return 560;
            }
        } else {
            return 35;
        }

        return 0;
    } */

    /* istanbul ignore next */
    setL1Ref = (node) => {
        this.l1FlyoutRef = node;
    };

    /* istanbul ignore next */
    getDynamicWidthL1 = (item = []) => {
        let lengthVal = 0;
        _map(item, (department) => {
            if (department.categories) {
                if (department.categories.length > 15) {
                    const categoriesAbsoluteLength = department.categories.length;
                    lengthVal += Math.ceil(categoriesAbsoluteLength / this.props.maxL3Column);
                } else {
                    lengthVal += 1;
                }
            }
        });
        const maxWidth = lengthVal >= 3 ? lengthVal * 196 : 500; // Here maxWidth is calculate based on L3 count and L3 width 196 is the width of each L3
        return maxWidth;
    };

    // This is used for animation effects when tabs hovered across
    /* istanbul ignore next */
    getDynamicCategoriesHeight = (item = []) => {
        let lengthVal = 0;
        _map(item, (department) => {
            if (department.categories) {
                if (department.categories.length > 15) {
                    const categoriesAbsoluteLength = department.categories.length;
                    const modifiedLengthVal =
                        categoriesAbsoluteLength /
                        Math.ceil(categoriesAbsoluteLength / this.props.maxL3Column);
                    lengthVal = modifiedLengthVal > lengthVal ? modifiedLengthVal : lengthVal;
                } else {
                    lengthVal =
                        department.categories.length > lengthVal
                            ? department.categories.length
                            : lengthVal;
                }
            }
        });
        const heightVal = 132 + lengthVal * 30;
        return heightVal;
    };

    /* istanbul ignore next */
    setimageRef = (node) => {
        this.l1ImageRef = node;
    };

    /* istanbul ignore next */
    getStyleWrapper = () => {
        let heightVal = 0;
        let topVal = 0;
        /* istanbul ignore next */
        if (!_isEmpty(this.state.hoverDepartmentData && this.l1FlyoutRef)) {
            heightVal = this.l1FlyoutRef.scrollHeight;
            topVal = this.l1FlyoutRef.parentNode.parentElement.clientHeight;
        }
        const wrapperCSS = {
            height: this.state.heightValFlag ? heightVal : 0,
            left: this.leftAlign(),
            right: this.rightAlign(),
            top: topVal,
            opacity: this.state.heightValFlag ? 1 : 0,
            width: this.state.widthAlign,
        };
        return wrapperCSS;
    };

    /* istanbul ignore next */
    leftAlign = () => {
        let { leftAlign } = this.state;
        if (
            !_isEmpty(this.state.hoverDepartmentData && this.l1FlyoutRef && this.state.hoverDept) &&
            this.state.leftAlign !== 'auto'
        ) {
            const clienWidth = this.l1FlyoutRef.parentNode.clientWidth;
            const leftOffset =
                clienWidth - this.offsetLeftValue < 50
                    ? this.offsetLeftValue + 50
                    : this.offsetLeftValue;
            if (clienWidth < leftOffset) {
                leftAlign = `${Math.round(leftOffset - clienWidth + 100)}px`;
            }
        }
        return leftAlign;
    };

    /* istanbul ignore next */
    rightAlign = () => {
        let { rightAlign } = this.state;
        if (
            !_isEmpty(this.state.hoverDepartmentData && this.l1FlyoutRef && this.state.hoverDept) &&
            this.state.rightAlign !== 'auto'
        ) {
            const clienWidth = this.l1FlyoutRef.parentNode.clientWidth;
            const rightOffset = this.offsetRightValue;
            if (clienWidth < rightOffset) {
                rightAlign = _isEmpty(this.l1ImageRef)
                    ? `${Math.round(rightOffset - clienWidth + 100)}px`
                    : `${Math.round(rightOffset - clienWidth + 300)}px`;
            }
        }
        return rightAlign;
    };

    /* istanbul ignore next */
    navigateurl(event, title, link, isInternalUrl = true, analyticData, targetWindow) {
        event.preventDefault();
        const eventName = analyticData ? analyticData.join(':') : title;
        const eventData = `top:${eventName}`;
        this.props.actions.triggerNavigationClick({
            linkName: eventData,
            isReload: !isInternalUrl,
        });

        if (link) {
            return {
                url: link,
                openInNewTab: targetWindow,
            };
        }
        return true; // redirect through <Link />
    }

    /* #NewChange
    resetMenu = () => {
        this.props.actions.removeOverlay();
        this.props.actions.hideShopDepartment();
    } */

    /* istanbul ignore next */
    elementToDisableL1Mouseout = (element) => {
        if (this.subMenuL1Ref && this.subMenuL1Ref.contains(element)) {
            return false;
        }
        if (this.l1FlyoutRef && this.l1FlyoutRef.contains(element)) {
            return false;
        }
        return true;
    };

    /* istanbul ignore next */
    enableFlyoutMenu = (value) => {
        this.setState({ enableFlyoutMenu: true, flyoutItemSelected: value });
    };

    /* istanbul ignore next */
    disableFlyoutMenu = () => {
        this.setState({ enableFlyoutMenu: true, flyoutItemSelected: '' });
    };

    /* istanbul ignore next */
    displayInspirationImage = (value, index, item) => {
        const isContentDisplay = _isEmpty(value.headline) && _isEmpty(value.subHeadline);
        const imageContent = (
            <div className={`${dt(['bg-white', 'pt-2'])} ${cx('imageSecInspirational')}`}>
                <Link
                    href={value.links}
                    className={`${dt(['text-black no-underline'])} ${cx('imageSecLink')}`}
                    onClick={
                        /* istanbul ignore next */
                        (event) =>
                            /* istanbul ignore next */
                            this.navigateurl(
                                event,
                                value.headline,
                                value.links,
                                true,
                                [item.title, `IC${index}`],
                                false
                            )
                    }
                >
                    <img
                        src={value.imageUrl}
                        ref={this.setimageRef}
                        alt={value.altText}
                        data-automation-id="banner-image"
                        className={`${dt(['w-60', 'max-w-full'])} ${cx('editorialBannerImage', {
                            noCursor: !value.links,
                        })}`}
                    />
                    {!isContentDisplay && (
                        <div
                            className={`${dt(['py-3', 'sm:leading-[1.1]'])} ${cx('offerContent')}`}
                        >
                            <h4 className={dt(['font-bold', 'text-small', 'text-black'])}>
                                {value.headline}
                            </h4>

                            <span
                                className={`${dt(['text-small', 'text-black'])} ${cx(
                                    'offerContentSubHeadline'
                                )}`}
                            >
                                {value.subHeadline}
                            </span>
                        </div>
                    )}
                </Link>
            </div>
        );
        return imageContent;
    };

    /* istanbul ignore next */
    compareImageURL = (item = {}) => {
        if (!_isEmpty(item) && !_isEmpty(item.inspirationalContent)) {
            const currentInsImageURL = item.inspirationalContent[0].imageUrl;
            const previousInsImageURL = this.l1ImageRef.src;
            return currentInsImageURL !== previousInsImageURL;
        }
        return true;
    };

    /* istanbul ignore next */
    displayCategory = (hoverDept, menuName, columnData) => {
        this.setState({
            lastBindedDept: hoverDept,
        });
        const {
            shopDepartmentMenuInfo,
            updateMaxColumn,
            enableColumnStyleFlyer,
            enableMultiRowL2Nav,
        } = this.props;
        const item = _find(
            shopDepartmentMenuInfo,
            (d) => d.title === hoverDept && !_isEmpty(d.departments)
        );
        this.offsetLeftValue =
            this.currentHoverLeft - this.subMenuL1Ref.getBoundingClientRect().left;
        this.offsetRightValue =
            this.subMenuL1Ref.getBoundingClientRect().right - this.currentHoverRight;
        let leftAlignOffset;
        let rightAlignOffset;

        const screenSize = window.innerWidth;

        const numColumns = enableMultiRowL2Nav
            ? columnData?.length
            : item.departments?.reduce(
                  // eslint-disable-next-line no-return-assign, no-param-reassign
                  (total, curr) => (total += Math.ceil(curr?.categories?.length / 15)),
                  0
              );
        if (this.offsetLeftValue >= this.props.offsetAlignInterchange) {
            leftAlignOffset = 'auto';

            rightAlignOffset =
                screenSize <= 1080 ||
                numColumns > 5 ||
                (numColumns === 5 && !_isEmpty(item.inspirationalContent)) ||
                this.currentHoverRight > 891 ||
                screenSize <= 1280
                    ? 0
                    : '235px';
        } else {
            leftAlignOffset = 0;
            rightAlignOffset =
                numColumns > 5 || (numColumns === 5 && !_isEmpty(item.inspirationalContent))
                    ? 0
                    : 'auto';

            if (screenSize > 1280) rightAlignOffset = 'auto';
        }
        // const displayCategoryStyles = !__SERVER__ ? document.getElementById('subMenuLevel1').parentNode.parentElement.clientHeight : 114;
        // const getOffsetWidth = this.getOffsetWidth(item.inspirationalContent);
        // This is add reflow during animation when mouse hover instead of rerendering
        const imageTrigger = !__SERVER__ && document.getElementById('imageSec');
        if (!_isEmpty(imageTrigger)) {
            const imageClassName = imageTrigger.className;
            imageTrigger.classList.remove(imageClassName);
            imageTrigger.offsetWidth; // trigger a DOM reflow
            imageTrigger.classList.add(imageClassName);
            if (!_isEmpty(this.l1ImageRef) && this.compareImageURL(item)) {
                this.l1ImageRef.src = ''; // To remove previous image content
            }
        }
        if (item) {
            const { maxColumn } = item;
            // const flyoutHeight = this.getDynamicCategoriesHeight(item.departments);
            const finalMaxColumn = updateMaxColumn || maxColumn;
            const data = this.props?.enableMultiRowL2Nav
                ? _map(columnData, (column) => this.renderEachDepartment(column, item, menuName))
                : _map(item.departments, (department) =>
                      this.renderEachDepartment(department, item, menuName)
                  );
            const megaMenuBlockClassName = enableColumnStyleFlyer
                ? `${columnMegaMenuBlockStyles} ${cx('columnMegaMenuBlock')}`
                : `${dt(['h-auto', 'px-6', 'pb-4'])} ${cx('megaMenuBlock')}`;
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            const finalData = (
                <div
                    id="submenuElement"
                    className={megaMenuBlockClassName}
                    data-automation-id="megaMenuBlock"
                    onMouseEnter={this.onLevel2MouseEnter}
                    ref={this.setL1Ref}
                    onMouseLeave={(event) => this.onLevel2MouseLeave(event)}
                >
                    {this.renderSubMenuElement(item)}
                    <div
                        data-automation-id="megaMenuSubMenu"
                        className={`${dt(['flex', 'items-start', 'justify-start', 'gap-0'])} ${cx(
                            'subMenuLevel1ListBlock'
                        )}`}
                        id="subMenuLevel2"
                    >
                        {data}
                        {!_isEmpty(item.inspirationalContent) && numColumns < 6 ? (
                            <div id="imageSec" className={cx('imageSec')}>
                                {_map(item.inspirationalContent, (value, index) =>
                                    this.displayInspirationImage(value, index + 1, item)
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            );

            this.setState({
                hoverDepartmentData: finalData,
                firstLevelHover: true,
                displayLevel2Columns: finalMaxColumn,
                leftAlign: leftAlignOffset,
                rightAlign: rightAlignOffset,
            });
            // Rerendeing the component to reflect the value of parent wrapper
            this.forceUpdate();
        } else {
            // empty L2
            this.setState({
                hoverDepartmentData: null,
                firstLevelHover: false,
            });
        }
    };

    /* istanbul ignore next */
    displaySubCategory = (department, analyticData, menuName) => {
        if (department.key === menuName) {
            const subCategoryList = [];
            const truncatedSubcategory = department.categories;
            const columnPosition = department.numericColumnPosition;
            const activityMapId = this.props.enableColumnStyleFlyer
                ? `subMenuLevel3:${columnPosition}`
                : 'subMenuLevel3';
            const getL3ColumnCount =
                truncatedSubcategory.length === 0
                    ? 1
                    : Math.ceil(truncatedSubcategory.length / this.props.maxL3Column);
            _forEach(truncatedSubcategory, (category, index) => {
                const eventData = [analyticData, department.key, category.name];
                const targetWindow = category.targetWindow === 'NW';
                const key = `${category.name}_megaMenuSubCategories_${index}`;
                subCategoryList.push(
                    <li
                        key={key}
                        id={activityMapId}
                        data-automation-id="megamenuInnerSubCategories"
                        className={`${dt(['w-full'])} ${cx('megaMenuSubCategories')}`}
                    >
                        <Link
                            href={category.targetUrl}
                            className={`${dt([
                                'block',
                                'text-small',
                                'text-black',
                                'py-1',
                                'hover:underline',
                            ])} ${cx('subMenuLevelLink', { noCursor: !category.targetUrl })}`}
                            onClick={
                                /* istanbul ignore next */
                                (event) =>
                                    /* istanbul ignore next */
                                    this.navigateurl(
                                        event,
                                        category.name,
                                        category.targetUrl,
                                        true,
                                        eventData,
                                        targetWindow
                                    )
                            }
                            data-automation-id="sub-menu-level2"
                        >
                            {category.name}
                        </Link>
                    </li>
                );
            });

            return (
                <ul
                    style={{ columnCount: getL3ColumnCount, WebkitColumnCount: getL3ColumnCount }}
                    data-automation-id="megaMenuInnerSubCategories"
                    className={cx('megaMenuInnerSubCategories')}
                >
                    {subCategoryList}
                </ul>
            );
        }

        return null;
    };

    /* istanbul ignore next */
    saveMouseCoordinates = (x, y) => {
        // mousemove event gets triggered even when pageX/pageY are unchanged, hence the sanity check
        if (x !== this.currentMouseCoordinates.x && y !== this.currentMouseCoordinates.y) {
            this.previousMouseCoordinates = {
                x: this.currentMouseCoordinates.x,
                y: this.currentMouseCoordinates.y,
            };
            this.currentMouseCoordinates = { x, y };
        }
    };

    // Return `true` if there currently isn't an active menu item, or if
    // `currentMouseCoordinates` is outside of the triangle drawn between
    // `previousMouseCoordinates`, `activeSubMenuTopLeftCoordinates` and
    // `activeSubMenuBottomLeftCoordinates`.
    /* istanbul ignore next */
    shouldChangeActiveMenuItem = () => {
        const previousTopGradient = this.calculateGradient(
            this.previousMouseCoordinates,
            this.activeSubMenuTopLeftCoordinates
        );
        const currentTopGradient = this.calculateGradient(
            this.currentMouseCoordinates,
            this.activeSubMenuTopLeftCoordinates
        );
        const previousBottomGradient = this.calculateGradient(
            this.previousMouseCoordinates,
            this.activeSubMenuBottomLeftCoordinates
        );
        const currentBottomGradient = this.calculateGradient(
            this.currentMouseCoordinates,
            this.activeSubMenuBottomLeftCoordinates
        );

        if (
            isNaN(previousTopGradient) ||
            isNaN(currentTopGradient) ||
            isNaN(previousBottomGradient) ||
            isNaN(currentBottomGradient)
        ) {
            return true; // if for some reason flyout is not shown, return true
        }

        // if currently no L1 is triggered, or moving out of the triangle, then trigger the hovered L1
        return (
            !this.state.hoverDept ||
            previousTopGradient < currentTopGradient ||
            previousBottomGradient > currentBottomGradient
        );
    };

    /* istanbul ignore next */
    refreshSubmenuCoords = () => {
        // use a more precise triangle
        const categoryElements = document.querySelectorAll(
            '[data-automation-id="megaMenuInnerBlock"]'
        );
        if (categoryElements) {
            let bottomLeftSlope;
            let bottomLeftCoordinate;
            // iterate htmlCollection
            [].forEach.call(categoryElements, (categoryElement) => {
                const currentBottomLeftCoordinate = this.calculateBottomLeftCoordinate(
                    categoryElement
                );
                if (!currentBottomLeftCoordinate) return;
                // find the max slope for the bottom edge of the triangle
                const currentBottomLeftSlope = this.calculateGradient(
                    this.currentMouseCoordinates,
                    {
                        x: currentBottomLeftCoordinate.x,
                        y: currentBottomLeftCoordinate.y,
                    }
                );
                if (
                    typeof bottomLeftSlope === 'undefined' ||
                    bottomLeftSlope < currentBottomLeftSlope
                ) {
                    bottomLeftSlope = currentBottomLeftSlope;
                    bottomLeftCoordinate = currentBottomLeftCoordinate;
                }
            });
            if (!bottomLeftCoordinate) return;
            this.activeSubMenuBottomLeftCoordinates = {
                x: bottomLeftCoordinate.x,
                y: bottomLeftCoordinate.y,
            };
        }

        const submenuElement = document.getElementById('submenuElement');
        if (submenuElement) {
            const topLeftCoordinate = this.calculateTopLeftCoordinate(submenuElement);
            this.activeSubMenuTopLeftCoordinates = {
                x: topLeftCoordinate.x,
                y: topLeftCoordinate.y,
            };
        }
    };

    /* istanbul ignore next */
    drawTriangle = () => {
        if (this.canvas && this.canvas.getContext) {
            const ctx = this.canvas.getContext('2d');
            const rect = this.canvas.getBoundingClientRect(); // abs. size of element
            const scaleX = this.canvas.width / rect.width; // relationship bitmap vs. element for X
            const scaleY = this.canvas.height / rect.height;
            ctx.beginPath();
            ctx.moveTo(
                (this.currentMouseCoordinates.x - rect.left) * scaleX,
                (this.currentMouseCoordinates.y - rect.top) * scaleY
            );
            ctx.lineTo(
                (this.activeSubMenuTopLeftCoordinates.x - rect.left) * scaleX,
                (this.activeSubMenuTopLeftCoordinates.y - rect.top) * scaleY
            );
            ctx.lineTo(
                (this.activeSubMenuBottomLeftCoordinates.x - rect.left) * scaleX,
                (this.activeSubMenuBottomLeftCoordinates.y - rect.top) * scaleY
            );
            ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
            ctx.fill();
        }
    };

    /* istanbul ignore next */
    clearTriangle = () => {
        if (this.canvas && this.canvas.getContext) {
            const ctx = this.canvas.getContext('2d');
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    };

    /** utilities */
    /* istanbul ignore next */
    calculateTopLeftCoordinate = (element) => {
        const rect = element.getBoundingClientRect();
        const x = rect.left + (window.pageXOffset || document.documentElement.scrollLeft);
        const y = rect.top + (window.pageYOffset || document.documentElement.scrollTop);
        return { x, y };
    };

    /* istanbul ignore next */
    calculateBottomLeftCoordinate = (element) => {
        const rect = element.getBoundingClientRect();
        const x = rect.left + (window.pageXOffset || document.documentElement.scrollLeft);
        const y = rect.top + (window.pageYOffset || document.documentElement.scrollTop);
        return { x, y: y + element.offsetHeight };
    };

    /* istanbul ignore next */
    calculateGradient = (A, B) => (B.y - A.y) / (B.x - A.x);
    /** utilities end */

    /* istanbul ignore next */
    findClearance = (item) => {
        const attibute = 'clearance';
        const isClearance = item.toLowerCase().includes(attibute)
            ? `${'enableClearanceColor'} ${dt(['text-brand-red'])}`
            : '';
        return isClearance;
    };

    /* istanbul ignore next */
    renderSubMenuElement = (item) => {
        const data = [];
        const L15MaxWidth = this.getDynamicWidthL1(item.departments);
        _map(item.subDepartments, (value) => {
            data.push(
                <span
                    className={`${dt([
                        'border-r',
                        'border-solid',
                        'border-gray-25',
                        'py-0',
                        'px-2',
                        'inline-block',
                        'last:border-r-0',
                    ])} ${cx('subMenuL1SubHeader')}`}
                >
                    <Link
                        href={value.links}
                        className={`${dt(['no-underline', 'text-black', 'hover:underline'])} ${cx(
                            'subMenuL1SubHeaderLink',
                            this.findClearance(value.name)
                        )}`}
                        onClick={
                            /* istanbul ignore next */
                            (event) =>
                                /* istanbul ignore next */
                                this.navigateurl(
                                    event,
                                    value.name,
                                    value.links,
                                    true,
                                    [item.title, value.name],
                                    false
                                )
                        }
                    >
                        {value.name}
                    </Link>
                </span>
            );
        });
        return (
            <>
                <div
                    className={cx(
                        'item_title_section',
                        dt(['pr-4', 'pt-6', 'pb-4', 'pl-0', 'sm:max-w-[800px]'])
                    )}
                    style={{ maxWidth: L15MaxWidth }}
                >
                    <h2 className={cx('subMenuL1Header', dt(['inline-block']))}>
                        <Link
                            href={item.links}
                            className={cx(
                                'subMenuL1HeaderLink',
                                dt(['no-underline', 'text-black', 'text-2xlarge', 'font-normal'])
                            )}
                            onClick={
                                /* istanbul ignore next */
                                (event) =>
                                    /* istanbul ignore next */
                                    this.navigateurl(
                                        event,
                                        item.title,
                                        item.links,
                                        true,
                                        [item.title],
                                        false
                                    )
                            }
                        >
                            {item.title}
                        </Link>
                    </h2>
                    {data}
                </div>
            </>
        );
    };

    /* istanbul ignore next */
    buildColumns = (irisData) => {
        const MAX_COLUMNS = 6;
        const MAX_LINKS = 16;
        let targetColumn = 1;
        const columnData = [];

        // case: one department per column
        if (irisData.every((d) => d.numericColumnPosition === irisData[0].numericColumnPosition)) {
            const columns = _map(irisData, (c) => [c]);
            return columns;
        }

        irisData.sort((d1, d2) => d1.numericColumnPosition - d2.numericColumnPosition);
        while (targetColumn <= MAX_COLUMNS) {
            const departments = irisData.filter((d) => d.numericColumnPosition === targetColumn);
            departments.reduce((total, curr, index, source) => {
                const linkCount = curr.categories.length + 1;
                // case: split into 2 columns if more than MAX_LINKS
                if (total === 0 && linkCount > MAX_LINKS) {
                    const newColumn = {
                        ...curr,
                        key: '',
                        categories: curr.categories.splice(MAX_LINKS - 1),
                        numericColumnPosition: targetColumn + 1,
                    };
                    irisData.splice(targetColumn, 0, newColumn);
                    // Move remaining departments to the next column
                    departments.splice(1).forEach((d) => {
                        d.numericColumnPosition = targetColumn + 1;
                    });

                    // reduce() will stop if source is modified
                    source.splice(0);
                    return total;
                }
                // Move column over if it doesn't fit
                if (total + linkCount > MAX_LINKS) {
                    curr.numericColumnPosition = targetColumn + 1;
                    return MAX_LINKS;
                }
                return (total += linkCount);
            }, 0);
            targetColumn++;
        }

        for (let i = 0; i < irisData.length; i++) {
            const departments = irisData.filter((d) => d.numericColumnPosition === i + 1);
            if (!departments.length) continue;
            columnData.push(departments);
        }
        return columnData;
    };

    /* istanbul ignore next */
    renderEachDepartment = (department, item) => {
        if (this.props?.enableMultiRowL2Nav) {
            const column = department;
            let getL3NextColumnBlock;
            const finalData = _map(column, (category, i) => {
                const { title } = item;
                const {
                    key,
                    links,
                    targetWindow: deptTargetWindow,
                    numericColumnPosition,
                } = category;
                const eventData = [title, category.key];
                const activityMapId = this.props.enableColumnStyleFlyer
                    ? `sub-menu-level2:${numericColumnPosition}`
                    : 'sub-menu-level2';
                const targetWindow = deptTargetWindow === 'NW';
                getL3NextColumnBlock =
                    category.categories && category.categories.length > 15
                        ? 'subMenuLevel3ColumnNext'
                        : '';
                return (
                    <>
                        <h4
                            className={`${dt(['text-small'])} ${cx('megaMenuTitle')}`}
                            data-automation-id="sub-menu-level1"
                            id={activityMapId}
                        >
                            {_isEmpty(links) ? (
                                <span
                                    className={`${dt([
                                        `${i > 0 ? 'pt-5' : 'pt-2'}`,
                                        `${!key?.length ? `mt-4` : ''}`,
                                        'pb-1',
                                        'text-black',
                                        'font-bold',
                                        'font-sans',
                                        'no-underline',
                                        'inline-block',
                                    ])} ${cx('subMenuLevelLink', 'subMenuLevel1Link', {
                                        noCursor: !links,
                                    })}`}
                                    onClick={(e) =>
                                        this.navigateurl(
                                            e,
                                            key,
                                            links,
                                            true,
                                            eventData,
                                            targetWindow
                                        )
                                    }
                                >
                                    {key.length ? (
                                        <>
                                            <span className={dt(['inline-block'])}>{key}</span>
                                        </>
                                    ) : (
                                        key
                                    )}
                                </span>
                            ) : (
                                <Link
                                    href={links}
                                    className={`${dt([
                                        `${i > 0 ? 'pt-5' : 'pt-2'}`,
                                        `${!key.length ? `mt-4` : ''}`,
                                        'pb-1',
                                        'text-black',
                                        'font-bold',
                                        'font-sans',
                                        'no-underline',
                                        'inline-block',
                                    ])} ${cx('subMenuLevelLink', 'subMenuLevel1Link', {
                                        noCursor: !links,
                                    })}`}
                                    onClick={(e) =>
                                        this.navigateurl(
                                            e,
                                            key,
                                            links,
                                            true,
                                            eventData,
                                            targetWindow
                                        )
                                    }
                                >
                                    {key.length ? (
                                        <>
                                            <span
                                                className={dt([
                                                    'inline-block',
                                                    'hover:underline',
                                                    'underline-offset-4',
                                                ])}
                                            >
                                                {key}
                                            </span>
                                            <span className={dt(['pl-1'])}>›</span>
                                        </>
                                    ) : (
                                        key
                                    )}
                                </Link>
                            )}
                        </h4>
                        {this.displaySubCategory(category, title, category.key)}
                    </>
                );
            });

            return (
                <>
                    <div
                        data-automation-id="megaMenuInnerBlock"
                        className={`${dt(['pb-6', 'break-inside-avoid', 'pr-8', 'last:pr-0'])} ${cx(
                            'megaMenuInnerBlock',
                            'newL2Nav',
                            getL3NextColumnBlock
                        )}`}
                    >
                        {finalData}
                    </div>
                </>
            );
        }
        const { title } = item;
        const { key, links, targetWindow: deptTargetWindow, numericColumnPosition } = department;
        const eventData = [title, department.key];
        const activityMapId = this.props.enableColumnStyleFlyer
            ? `sub-menu-level2:${numericColumnPosition}`
            : 'sub-menu-level2';
        const targetWindow = deptTargetWindow === 'NW';
        const getL3NextColumnBlock =
            department.categories && department.categories.length > 15
                ? 'subMenuLevel3ColumnNext'
                : '';

        return (
            <div
                data-automation-id="megaMenuInnerBlock"
                className={`${dt([
                    'pb-6',
                    'break-inside-avoid',
                    'pr-8',
                    'sm:w-[210px]',
                    'last:pr-0',
                ])} ${cx('megaMenuInnerBlock', getL3NextColumnBlock)}`}
            >
                <h4
                    className={`${dt(['border-b', 'border-solid', 'border-gray-25', 'mb-3'])} ${cx(
                        'megaMenuTitle'
                    )}`}
                    data-automation-id="sub-menu-level1"
                    id={activityMapId}
                >
                    {_isEmpty(links) ? (
                        <span
                            className={`${dt([
                                'pt-2',
                                'pb-1',
                                'text-black',
                                'font-bold',
                                'font-sans',
                                'no-underline',
                                'inline-block',
                                'hover:underline',
                            ])} ${cx('subMenuLevelLink', 'subMenuLevel1Link', {
                                noCursor: !links,
                            })}`}
                            onClick={(e) =>
                                this.navigateurl(e, key, links, true, eventData, targetWindow)
                            }
                        >
                            {key}
                        </span>
                    ) : (
                        <Link
                            href={links}
                            className={`${dt([
                                'pt-2',
                                'pb-1',
                                'text-black',
                                'font-bold',
                                'font-sans',
                                'no-underline',
                                'inline-block',
                                'hover:underline',
                            ])} ${cx('subMenuLevelLink', 'subMenuLevel1Link', {
                                noCursor: !links,
                            })}`}
                            onClick={(e) =>
                                this.navigateurl(e, key, links, true, eventData, targetWindow)
                            }
                        >
                            {key}
                        </Link>
                    )}
                </h4>
                {this.displaySubCategory(department, title, department.key)}
            </div>
        );
    };

    render = () => {
        const { shopDepartmentMenuInfo } = this.props;
        const { lastBindedDept } = this.state;

        const flyoutmenuForMd = `${dt(['md:!h-auto', 'md:!top-auto', 'md:!bottom-auto'])}`;

        /* istanbul ignore else */
        if (shopDepartmentMenuInfo) {
            const submenuStyle = cx('subMenuList');
            /* istanbul ignore next */
            const menuSelectedHeader = !_isEmpty(this.state.flyoutItemSelected)
                ? 'menuSelectedHeader'
                : '';

            return (
                <>
                    {/* single flyout - for testing only: */}
                    {
                        /* istanbul ignore next */
                        this.props.enableTestTriangle && (
                            <canvas
                                id="canvas"
                                height="576"
                                width="944"
                                style={{
                                    position: 'absolute',
                                    top: '56px',
                                    left: 0,
                                    zIndex: 999,
                                    pointerEvents: 'none',
                                }}
                            />
                        )
                    }

                    <div className={`${dt(['relative', 'w-full'])} ${cx('megaMenuLayout')}`}>
                        <div
                            className={submenuStyle}
                            data-automation-id="menu-list"
                            ref={this.setsubMenuL1Ref}
                            onMouseOut={
                                /* istanbul ignore next */
                                (event) => this.onLevel1MouseOut(event)
                            }
                        >
                            {/* " level1Block " Class for the hover Functionality for BG White once the mouse
                        pointer enteres onto the UL Block  Eg: Shop Dept => Men => Block */}
                            <ul
                                className={
                                    /* istanbul ignore next */
                                    `${dt([
                                        'flex',
                                        'w-full',
                                        'smOnly:min-h-[40px]',
                                        'justify-between',
                                        'items-end',
                                    ])} ${cx('subMenuListBlock', menuSelectedHeader)}`
                                }
                                id="subMenuLevel1"
                                data-automation-id="menu-list-block"
                            >
                                {_map(shopDepartmentMenuInfo, (item, i) => {
                                    const { title, links, isInternalUrl } = item;
                                    const styleName =
                                        item.title !== 'clearance'
                                            ? `${dt([
                                                  'tracking-normal',
                                                  'font-semibold',
                                                  'font-sans',
                                                  'text-small',
                                                  'text-center',
                                                  'text-black',
                                                  'sm:pb-1.5',
                                                  'inline-block',
                                              ])} ${cx('menuItemLink', { noCursor: !links })}`
                                            : `${dt([
                                                  'tracking-normal',
                                                  'font-semibold',
                                                  'font-sans',
                                                  'text-small',
                                                  'text-center',
                                                  'text-black',
                                                  'sm:pb-1.5',
                                                  'inline-block',
                                              ])} ${cx('menuItemLink', 'clearanceMenuLink', {
                                                  noCursor: !links,
                                              })}`;
                                    /* istanbul ignore next */
                                    const secondLevelStyle =
                                        item.title === lastBindedDept &&
                                        this.state.enableFlyoutMenu &&
                                        this.state.flyoutItemSelected === item.title
                                            ? `${dt([
                                                  'px-2',
                                                  'pb-0',
                                                  'text-center',
                                                  'first:pl-6',
                                                  'last:pr-6',
                                                  'border-b-[5px]',
                                                  'border-solid',
                                                  'border-transparent',
                                              ])} ${cx('menuItem', 'menuSelected', 'enableFlyout')}`
                                            : `${dt([
                                                  'px-2',
                                                  'pb-0',
                                                  'text-center',
                                                  'first:pl-6',
                                                  'last:pr-6',
                                                  'border-b-[5px]',
                                                  'border-solid',
                                                  'border-transparent',
                                              ])} ${cx('menuItem', 'disableFlyout')}`;

                                    return (
                                        <li
                                            className={secondLevelStyle}
                                            data-automation-id={`menu-item-${i}`}
                                        >
                                            {/* " menuSelected " Class for the hover bold and BG white
                                        Functionality while the mouse pointer hover the level1 List item
                                        Eg: Shop Dept => Men */}
                                            {
                                                /* istanbul ignore next */
                                                this.props.isDepartmentData ? (
                                                    <div
                                                        className={styleName}
                                                        onMouseOver={this.onL1HoverEvent}
                                                        data-automation-id={`menu-item-link-${i}`}
                                                    >
                                                        {title}
                                                    </div>
                                                ) : (
                                                    <Link
                                                        href={links}
                                                        className={styleName}
                                                        onClick={
                                                            /* istanbul ignore next */
                                                            (event) =>
                                                                this.onL1Click(
                                                                    event,
                                                                    title,
                                                                    links,
                                                                    isInternalUrl
                                                                )
                                                        }
                                                        data-automation-id={`menu-item-link-${i}`}
                                                    >
                                                        {title}
                                                    </Link>
                                                )
                                            }
                                        </li>
                                    );
                                })}
                            </ul>
                            {
                                /* istanbul ignore next */
                                !_isEmpty(this.state.borderTransition) &&
                                    this.state.borderTransition
                            }
                        </div>
                        <div
                            style={this.getStyleWrapper()}
                            className={`${dt(['absolute', 'bg-white', 'sm:-mt-[0.5px]'])} ${cx(
                                'subMenuLevelWrapper'
                            )} ${flyoutmenuForMd}`}
                        >
                            {
                                /* istanbul ignore next */
                                !_isEmpty(this.state.hoverDept) && this.state.hoverDepartmentData
                            }
                        </div>
                    </div>
                </>
            );
        }
        /* istanbul ignore next */
        return null;
    };
}

const mapStateToProps = ({ context, IrisGlobalNavigation }) => {
    const shopDeptOverlay = _get(context, 'preferences.shopDeptOverlay', null);
    const updateMaxColumn = _get(context, 'preferences.updateMaxColumn', null);
    const maxL3Column = _get(context, 'preferences.maxL3Column', 15);
    const enableColumnStyleFlyer = _get(context, 'featureFlags.enableColumnStyleFlyer', false);
    const offsetAlignInterchange = _get(context, 'featureFlags.offsetAlignInterchange', 700);
    const enableMultiRowL2Nav = _get(context, 'featureFlags.enableMultiRowL2Nav', true);

    // for mouse intention, have a seperate flag for IE
    let enableSingleFlyerMouseIntention;
    /* istanbul ignore next */
    if (detectisIE()) {
        enableSingleFlyerMouseIntention = _get(
            context,
            'featureFlags.enableSingleFlyerMouseIntentionForIE',
            false
        );
    } else {
        enableSingleFlyerMouseIntention = _get(
            context,
            'featureFlags.enableSingleFlyerMouseIntention',
            false
        );
    }
    // enableTestTriangle comes as a query string
    let queryParams;
    /* istanbul ignore next */
    if (!__SERVER__) {
        queryParams = window.location.href.split('?')[1];
    }
    /* istanbul ignore next */
    const enableTestTriangle = queryParams
        ? queryParams.split('&').filter((query) => query.indexOf('enableTestTriangle') === 0)
              .length > 0
        : false;
    const insideTriangleDelay = _get(context, 'preferences.singleFlyer.insideTriangleDelay', 500);
    const outsideTriangleDelay = _get(context, 'preferences.singleFlyer.outsideTriangleDelay', 0);

    let departmentVisualNav = [];
    const isDepartmentData = IrisGlobalNavigation.length > 0;
    departmentVisualNav = IrisGlobalNavigation;
    /* istanbul ignore else */
    if (departmentVisualNav.length === 0) {
        // Just adding in case of  newconfig not visible in envs will remove this later  point and will only keep departmentsData.
        const departmentsData = getNavigationData(_get(context, 'preferences.departmentsData', []));
        const departments = getNavigationData(_get(context, 'preferences.departments', []));
        /* istanbul ignore next */
        departmentVisualNav = departmentsData.length === 0 ? departments : departmentsData;
    }

    return {
        shopDeptOverlay,
        shopDepartmentMenuInfo: departmentVisualNav,
        enableSingleFlyerMouseIntention,
        insideTriangleDelay,
        enableTestTriangle,
        outsideTriangleDelay,
        queryParams,
        updateMaxColumn,
        enableColumnStyleFlyer,
        maxL3Column,
        offsetAlignInterchange,
        isDepartmentData,
        enableMultiRowL2Nav,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        { ...AnalyticsAction, ...OverlayAction, ...shopDeptActions, ...BgWhiteAction },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlyoutMenu);
