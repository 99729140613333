import { linkParser } from '../helpers/Utils/parseLink';
import {
    CLOSE_FIND_STORES_SLIDE_PANEL,
    CLOSE_FIND_STORES_SUB_SLIDE_PANEL,
    FIND_MORE_STORES_SUCCESS,
    FIND_STORES_ERROR,
    FIND_STORES_INVALID_INPUT,
    FIND_STORES_REQUEST,
    FIND_STORES_SUCCESS,
    OPEN_FIND_STORES_SLIDE_PANEL,
    OPEN_FIND_STORES_SUB_SLIDE_PANEL,
    PUT_SELECTED_STORE_SUCCESS,
    RESET_FIND_STORES,
    SET_AVAILABLE_FILTER,
} from '../actionTypes/FindStoresActionTypes';
import { FIND_STORES_INVALID_INPUT_NAME } from '../common/Constants';

const parseLinks = (linksHeader) => {
    const links = linkParser(linksHeader);
    return links && links.next ? links.next.url : undefined;
};

/**
 * The object structure for find stores reducer
 *   findStoreDetails: {
 *      isSlidePanelOpen: false,
 *      showAvailable: true,
 *      zipCode: 12345,
 *      nextPageLink: '',
 *      miles: 25,
 *      count: 40,
 *      stores: [{}],
 *      isGeoLocationUsed: true | false <= DA - needed for selectStore event
 *   }
 */
function FindStoresReducer(state = {}, action) {
    const { payload } = action;
    let newState = {};
    switch (action.type) {
        case FIND_STORES_REQUEST:
            // Retain prev value for isSlidePanelOpen
            newState.isSlidePanelOpen = state.isSlidePanelOpen || false;
            newState.showAvailable = payload.showAvailable;
            newState.zipCode = payload.zipCode;
            newState.showCartItemAvailability =
                state.showCartItemAvailability || payload.showCartItemAvailability || false;
            // Put small loader
            newState.isLoading = true;

            return newState;
        case FIND_STORES_SUCCESS:
            newState = { ...state };
            // Retain prev value for isSlidePanelOpen
            newState.count = payload.count;
            newState.isGeoLocationUsed = payload.isGeoLocationUsed; // <= DA - needed for selectStore event
            newState.isLoading = false;
            newState.isSlidePanelOpen = state.isSlidePanelOpen || false;
            newState.miles = payload.miles;
            newState.nextPageLink = payload.nextPageLink
                ? payload.nextPageLink
                : parseLinks(payload.link);
            newState.showAvailable = payload.showAvailable;
            newState.storeFetchStatus = 'SUCCESS';
            newState.stores = payload.stores;
            newState.userLatLong = payload.userLatLong;
            newState.inputZipCode = payload.inputZipCode;
            newState.zipCode = payload.zipCode;

            return newState;
        case FIND_STORES_ERROR:
            newState = { ...state };
            // Retain prev value for isSlidePanelOpen
            newState.isLoading = false;
            newState.isSlidePanelOpen = state.isSlidePanelOpen || false;
            newState.storeFetchStatus = 'FAILURE';
            newState.zipCode = FIND_STORES_INVALID_INPUT_NAME;

            return newState;
        case FIND_MORE_STORES_SUCCESS:
            // Deep copy current state
            newState = JSON.parse(JSON.stringify(state));
            newState.isLoading = false;
            newState.nextPageLink = payload.nextPageLink
                ? payload.nextPageLink
                : parseLinks(payload.link);
            newState.stores = [...newState.stores, ...payload.stores];

            return newState;
        case FIND_STORES_INVALID_INPUT:
            newState = JSON.parse(JSON.stringify(state));

            return {
                ...newState,
                isLoading: false,
                nextPageLink: null,
                stores: [],
                zipCode: FIND_STORES_INVALID_INPUT_NAME,
            };
        case OPEN_FIND_STORES_SLIDE_PANEL:
            newState = { ...state };
            newState.isOpenSubSlidePanel = false;
            newState.isSlidePanelOpen = true;

            return newState;
        case PUT_SELECTED_STORE_SUCCESS:
        case CLOSE_FIND_STORES_SLIDE_PANEL:
            newState = { ...state };
            newState.isSlidePanelOpen = false;
            newState.showCartItemAvailability = false;
            return newState;
        case SET_AVAILABLE_FILTER:
            newState = { ...state };
            newState.showAvailable = payload;

            return newState;
        case OPEN_FIND_STORES_SUB_SLIDE_PANEL:
            newState = { ...state };
            newState.isOpenSubSlidePanel = true;

            return newState;
        case CLOSE_FIND_STORES_SUB_SLIDE_PANEL:
            newState = { ...state };
            newState.isOpenSubSlidePanel = false;

            return newState;
        case RESET_FIND_STORES:
            newState = { ...state };
            newState.nextPageLink = null;
            newState.stores = [];
            newState.zipCode = '';
            newState.showCartItemAvailability = false;

            return newState;
        default:
            return state;
    }
}

FindStoresReducer.reducerName = 'findStoresDetails';
export default FindStoresReducer;
