import {
    IOVATION_SCRIPT_LOAD_ERROR,
    IOVATION_SCRIPT_LOAD_SUCCESS,
} from '../actionTypes/KeepMeLoggedInActionTypes';

const onIovationScriptLoadSuccess = () => ({
    type: IOVATION_SCRIPT_LOAD_SUCCESS,
});

const onIovationScriptLoadError = () => ({
    type: IOVATION_SCRIPT_LOAD_ERROR,
});

export default {
    onIovationScriptLoadSuccess,
    onIovationScriptLoadError,
};
