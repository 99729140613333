import React from 'react';
import Optical from '../../assets/svg/services_optical_icon.svg';
import Portraits from '../../assets/svg/services_portraits_icon.svg';
import Salon from '../../assets/svg/services_salon_icon.svg';

const config = {
    storeOptions: [
        {
            text: 'JCPenney Salon',
            label: 'Book Appointment',
            link: '/signin?next=/account/dashboard/appointments',
            key: 'salon',
            svgIcon: <Salon />,
        },
        {
            text: 'JCPenney Portrait',
            label: 'Schedule a Session',
            link: 'http://www.jcpportraits.com/',
            key: 'portrait studio',
            svgIcon: <Portraits />,
        },
        {
            text: 'JCPenney Optical',
            label: 'Schedule Eye Exam',
            link: 'http://www.jcpenneyoptical.com/',
            key: 'optical',
            svgIcon: <Optical />,
        },
    ],
};

export default config;
