import {
    CRITEO_GET_SUCCESS,
    CRITEO_GET_ERROR,
    SPONSOURED_GET_RESULTS,
    CRITEO_CLEAR_DATA,
    CRITEO_WAIT_FOR_DATA,
    CRITEO_SET_ON_LOAD_BEACON_STATUS,
    CRITEO_SET_ON_VIEW_BEACON_STATUS,
    CRITEO_SET_ON_CLICK_BEACON_STATUS,
    CRITEO_SET_ON_BASKET_BEACON_STATUS,
    CRITEO_SET_ON_WISHLIST_BEACON_STATUS,
    CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH,
    CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK,
    CRITEO_GET_GROUP_BEACONS,
} from '../actionTypes/CriteoActionTypes';

export const defaultState = {
    waitForCriteo: false,
    onLoadBeaconCalled: false,
    onViewBeaconCalledPPIDs: [],
    onClickBeaconCalledPPIDs: [],
    onBasketBeaconCalledPPIDs: [],
    onWishlistBeaconCalledPPIDs: [],
    onClickBeaconCalledSwatchPPIDs: [],
    onClickBeaconCalledSwatchPPIDsAltImgClick: {},
    criteoGroupBeacons: {},
};

export const criteoApiReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CRITEO_WAIT_FOR_DATA:
            sessionStorage.setItem('onLoadBeaconCalled', false);
            sessionStorage.setItem('onLoadViewBeaconFromSession', false);
            return { waitForCriteo: action.waitForCriteo };
        case CRITEO_GET_SUCCESS:
            sessionStorage.setItem('onLoadBeaconCalled', false);
            sessionStorage.setItem('onLoadViewBeaconFromSession', false);
            return { ...state, ...action.criteoCallData };
        case CRITEO_GET_ERROR:
            sessionStorage.setItem('onLoadBeaconCalled', false);
            sessionStorage.setItem('onLoadViewBeaconFromSession', false);
            return defaultState;
        case SPONSOURED_GET_RESULTS: {
            sessionStorage.setItem('onLoadBeaconCalled', false);
            sessionStorage.setItem('onLoadViewBeaconFromSession', false);
            return {
                ...state,
                sponsouredProduct: action.sponsouredProduct,
                waitForCriteo: false,
            };
        }
        case CRITEO_SET_ON_LOAD_BEACON_STATUS:
            sessionStorage.setItem('onLoadBeaconCalled', true);
            return {
                ...state,
                onLoadBeaconCalled: true,
            };
        case CRITEO_SET_ON_VIEW_BEACON_STATUS:
            return {
                ...state,
                onViewBeaconCalledPPIDs: [
                    ...(state?.onViewBeaconCalledPPIDs ? state?.onViewBeaconCalledPPIDs : []),
                    `${action?.data}`,
                ],
            };
        case CRITEO_SET_ON_CLICK_BEACON_STATUS:
            return {
                ...state,
                onClickBeaconCalledPPIDs: [
                    ...(state?.onClickBeaconCalledPPIDs ? state?.onClickBeaconCalledPPIDs : []),
                    `${action?.data}`,
                ],
            };
        case CRITEO_SET_ON_CLICK_BEACON_STATUS_SWATCH:
            return {
                ...state,
                onClickBeaconCalledSwatchPPIDs: [
                    ...(state?.onClickBeaconCalledSwatchPPIDs
                        ? state?.onClickBeaconCalledSwatchPPIDs
                        : []),
                    `${action?.data}`,
                ],
            };
        case CRITEO_SET_ON_CLICK_BEACON_STATUS_FOR_SWATCH_ALT_IMAGE_CLICK:
            return {
                ...state,
                onClickBeaconCalledSwatchPPIDsAltImgClick: {
                    ...state.onClickBeaconCalledSwatchPPIDsAltImgClick,
                    [action.data]: action.isAltimagevisited,
                },
            };
        case CRITEO_GET_GROUP_BEACONS:
            return {
                ...state,
                criteoGroupBeacons: { ...action?.criteoGroupBeacons },
            };
        case CRITEO_SET_ON_BASKET_BEACON_STATUS:
            return {
                ...state,
                onBasketBeaconCalledPPIDs: [
                    ...(state?.onBasketBeaconCalledPPIDs ? state?.onBasketBeaconCalledPPIDs : []),
                    `${action?.data}`,
                ],
            };
        case CRITEO_SET_ON_WISHLIST_BEACON_STATUS:
            return {
                ...state,
                onWishlistBeaconCalledPPIDs: [
                    ...(state?.onWishlistBeaconCalledPPIDs
                        ? state?.onWishlistBeaconCalledPPIDs
                        : []),
                    `${action?.data}`,
                ],
            };
        case CRITEO_CLEAR_DATA:
            sessionStorage.setItem('onLoadBeaconCalled', false);
            sessionStorage.setItem('onLoadViewBeaconFromSession', false);
            return defaultState;
        default:
            return state;
    }
};

criteoApiReducer.reducerName = 'criteo';

export default criteoApiReducer;
