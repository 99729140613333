import {
    IS_SESSION_TIMED_OUT,
    CLEAR_SESSION_TIMED_OUT_MSG,
    SET_SESSION_TIMED_OUT_MSG,
} from '../actionTypes/SessionActionTypes';

const setSessionTimeOut = (payload) => ({
    type: IS_SESSION_TIMED_OUT,
    payload,
});

const setSessionTimeOutMsg = (payload) => ({
    type: SET_SESSION_TIMED_OUT_MSG,
    payload,
});

const clearSessionTimeOutMsg = () => ({
    type: CLEAR_SESSION_TIMED_OUT_MSG,
});

export default {
    setSessionTimeOut,
    setSessionTimeOutMsg,
    clearSessionTimeOutMsg,
};
