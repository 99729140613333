import {
    SHOP_THE_LOOK_GET_REQUEST,
    SHOP_THE_LOOK_GET_SUCCESS,
    SHOP_THE_LOOK_GET_ERROR,
    UPDATE_LAST_SELECTED_COLOR,
} from '../actionTypes/ShopTheLookActionTypes';

export const requestShopTheLookPricing = (productIds) => ({
    type: SHOP_THE_LOOK_GET_REQUEST,
    productIds,
    cachedAPI: true,
});

export const getShopTheLookPricing = (payload) => ({
    type: SHOP_THE_LOOK_GET_SUCCESS,
    payload,
});

export const throwErrorForShopTheLookPricing = (error) => ({
    type: SHOP_THE_LOOK_GET_ERROR,
    error,
});

export const updateLastSelectedColor = (color) => ({
    type: UPDATE_LAST_SELECTED_COLOR,
    lastSelectedColor: color,
});
