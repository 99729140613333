import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';

import {
    MESSAGE_ADD,
    MESSAGE_REMOVE,
    MESSAGE_REMOVE_ALL,
    MESSAGE_RESET,
    MESSAGE_REMOVE_ALL_EXCEPT,
} from '../actionTypes/MessagesActionTypes';

export const addMessage = ({ section, message: { id, title, type }, sourceMiniPDP }) => {
    const payload = {
        section,
        message: {
            id: id || uniqueId('message_'),
            title,
            type: type || 'information',
        },
        sourceMiniPDP,
    };
    return {
        type: MESSAGE_ADD,
        payload,
    };
};

export const removeMessage = ({ id, section, sourceMiniPDP }) => ({
    // section is optional
    type: MESSAGE_REMOVE,
    payload: { id, section, sourceMiniPDP },
});

export const removeAllMessages = (payload) => ({
    type: MESSAGE_REMOVE_ALL,
    payload,
});

export const removeAllMessagesExcept = (payload) => ({
    type: MESSAGE_REMOVE_ALL_EXCEPT,
    payload,
});

export const resetMessages = (payload = {}) => ({
    type: MESSAGE_RESET,
    sourceMiniPDP: get(payload, 'sourceMiniPDP', true),
});
