import { GET_WEEKLY_AD_DETAILS } from '../actionTypes/WeeklyAdsActionTypes';

const getWeeklyAdDetails = (payload) => ({
    type: GET_WEEKLY_AD_DETAILS,
    payload,
});

export default {
    getWeeklyAdDetails,
};
