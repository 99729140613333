import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import MenuIconNew from 'yoda-core-components/lib/assets/svg/MenuIconNew.svg';
import HambCloseNew from '../../assets/svg/closeIconNew.svg';
import * as styles from './MobileBotHamburger.css';
import HamburgerAction from '../../actions/HamburgerAction';
import withReducerSaga from '../../hoc/ReducerRegistry/withReducerSaga';
import IrisHamburgerReducer from '../../reducers/IrisHambergurReducer';

const cx = classNames.bind(styles);

export class MobileBotHamburger extends Component {
    static propTypes = {
        actions: PropTypes.object,
        items: PropTypes.object.isRequired,
        pageName: PropTypes.string,
    };

    static defaultProps = {
        actions: {},
        items: {},
        pageName: 'HOME',
    };

    state = {
        slideOpen: false,
        title: 'MENU',
    };

    componentWillMount() {
        const hamburgerParams = {
            pageType: this.props.pageName,
        };
        this.props.actions.getIrisCategories(hamburgerParams);
    }

    getChildrenMenuWithLevel = (menuItem = {}, level = 1) => {
        const { children = [], linkText = '', link: { url = '' } = {} } = menuItem || {};
        return children.length ? (
            <div>
                {url ? (
                    <a className={cx(`level${level}`)} href={url}>
                        {linkText}
                    </a>
                ) : (
                    <h4 className={cx(`level${level}`)}>{linkText}</h4>
                )}
                {children.map((subChildren) =>
                    this.getChildrenMenuWithLevel(subChildren, level + 1)
                )}
            </div>
        ) : (
            <a className={cx(`level${level}`)} href={url}>
                {linkText}
            </a>
        );
    };

    toggleSlideState = (menuOpenStatus) => {
        if (menuOpenStatus) {
            document.body.classList.add('scrollLock');
        } else {
            document.body.classList.remove('scrollLock');
        }
        this.setState({
            slideOpen: menuOpenStatus,
        });
    };

    toggleMenu = () => {
        this.toggleSlideState(!this.state.slideOpen);
    };

    renderItemsForBot = () => {
        /* istanbul ignore next */
        const { items = {} } = this.props;
        let finalItemsObject = {};
        if (items instanceof Array) {
            /* istanbul ignore next */
            finalItemsObject = items.reduce((itemObject, item = {}) => {
                const itemKey = Object.keys(item)[0];
                const itemValue = item[itemKey];
                itemObject[itemKey] = itemValue;
                return itemObject;
            });
        } else {
            finalItemsObject = { ...items };
        }
        /* istanbul ignore next */
        return Object.keys(finalItemsObject).map((itemKey = '') => {
            const itemValue = finalItemsObject[itemKey];
            const level = 1;
            return this.getChildrenMenuWithLevel(itemValue, level);
        });
    };

    renderCloseButton = () => (
        <button
            onClick={this.toggleMenu}
            className={cx('menuWrapperClose')}
            data-automation-id="slider-button-close"
        >
            <i data-automation-id="hamburger-menu-close-icon" className={cx('menuCloseIcon')}>
                <Icon
                    className={cx('hambClose')}
                    iconType="svg"
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                >
                    <HambCloseNew />
                </Icon>
            </i>
        </button>
    );

    renderHamburgerButton = () => (
        <>
            <button
                data-automation-id="jcp-menu"
                className={cx('menuLink')}
                onClick={this.toggleMenu}
            >
                <span>
                    <Icon
                        iconType="svg"
                        automationId="test-automation-icon"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        pathClassName={cx('hamburgerIcon')}
                    >
                        <MenuIconNew />
                    </Icon>
                </span>
            </button>
        </>
    );

    render() {
        const departmentsClassName = this.state.slideOpen ? 'displayMenu' : 'hideMenu';
        return (
            <div className={cx('department-wrapper')}>
                <div className={cx(departmentsClassName, 'menuWrapper')}>
                    {this.renderItemsForBot()}
                </div>
                {this.renderHamburgerButton()}
                {this.state.slideOpen && this.renderCloseButton()}
            </div>
        );
    }
}

export const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...HamburgerAction }, dispatch),
});

export const mapStateToProps = (state) => {
    const { irishambergurMenu } = state;
    return {
        items: irishambergurMenu,
    };
};

export default compose(
    withReducerSaga([IrisHamburgerReducer]),
    connect(mapStateToProps, mapDispatchToProps)
)(MobileBotHamburger);
