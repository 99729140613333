import {
    PROMOTIONALBANNERDATA_GET_REQUEST,
    IRIS_PROMOTIONALBANNERDATA_GET_REQUEST,
} from '../actionTypes/PromotionalBannerActionTypes';

const getOfferDetailsAction = (deviceType) => ({
    type: PROMOTIONALBANNERDATA_GET_REQUEST,
    deviceType,
});

const getIrisOfferDetailsAction = (urlParams) => ({
    type: IRIS_PROMOTIONALBANNERDATA_GET_REQUEST,
    urlParams,
});

export default {
    getOfferDetailsAction,
    getIrisOfferDetailsAction,
};
