import { IRIS_SLIDER_CATEGORY_LOAD_SUCCESS } from '../actionTypes/HamburgerActionTypes';

const itemCountReducer = function itemCountReducer(state = {}, action) {
    switch (action.type) {
        case IRIS_SLIDER_CATEGORY_LOAD_SUCCESS: {
            return action.categories;
        }
        // case HamburgerActionTypes.IRIS_SLIDER_HOVER_LOAD_SUCCESS: {
        //     return {
        //         menu: action.categories,
        //     };
        // }
        default:
            return state;
    }
};

itemCountReducer.reducerName = 'irishambergurMenu';

export default itemCountReducer;
