const SITE_ECOREBATES_GET_REQUEST = 'SITE_ECOREBATES_GET_REQUEST';
const SITE_ECOREBATES_GET_ERROR = 'SITE_ECOREBATES_GET_ERROR';
const SITE_ECOREBATES_GET_SUCCESS = 'SITE_ECOREBATES_GET_SUCCESS';
const SITE_ECOREBATES_MODAL_OPEN = 'SITE_ECOREBATES_MODAL_OPEN';
const SITE_ECOREBATES_MODAL_CLOSE = 'SITE_ECOREBATES_MODAL_CLOSE';
const SITE_ECOREBATES_RESET = 'SITE_ECOREBATES_RESET';
const BUNDLE_ECOREBATES_GET_REQUEST = 'BUNDLE_ECOREBATES_GET_REQUEST';
const BUNDLE_ECOREBATES_GET_ERROR = 'BUNDLE_ECOREBATES_GET_ERROR';
const BUNDLE_ECOREBATES_GET_SUCCESS = 'BUNDLE_ECOREBATES_GET_SUCCESS';

export default {
    SITE_ECOREBATES_GET_REQUEST,
    SITE_ECOREBATES_GET_SUCCESS,
    SITE_ECOREBATES_GET_ERROR,
    SITE_ECOREBATES_RESET,
    SITE_ECOREBATES_MODAL_OPEN,
    SITE_ECOREBATES_MODAL_CLOSE,
    BUNDLE_ECOREBATES_GET_REQUEST,
    BUNDLE_ECOREBATES_GET_SUCCESS,
    BUNDLE_ECOREBATES_GET_ERROR,
};
