/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import React from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import Icon2023JcpenneyLogo from 'yoda-core-components/lib/assets/svg/2023JcpenneyLogo.svg';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Link from '../Link/Link';
import * as styles from './Header.css';

const cx = classNames.bind(styles);

// render logo
const RenderLogo = (props) => {
    // adding hyperlink only for TOF pages
    const logoLink = props.logoLink === 'on' ? '/' : '';
    const preferences = useSelector((state) => state?.context?.preferences || {});
    const deviceType = useSelector((state) => state?.context?.deviceType || {});
    const featureFlags = useSelector((state) => state?.context?.featureFlags || {});
    let iconWidth = '';
    let iconHeight = '';
    let viewBoxValue = '';
    const logoComponent = _get(preferences, 'assets.logoComponent', <Icon2023JcpenneyLogo />);
    const enableNewLogo = _get(featureFlags, 'enableNewLogo', true);
    if (featureFlags.enableHamburgerMenuLabel && !deviceType.isDesktop) {
        if (enableNewLogo) {
            iconWidth = '97px';
            iconHeight = '32px';
            viewBoxValue = '0 0 97 32';
        } else {
            iconWidth = '103px';
            iconHeight = '24px';
            viewBoxValue = '0 0 103 24';
        }
    } else if (enableNewLogo) {
        iconWidth = '125px';
        iconHeight = '40px';
        viewBoxValue = '0 0 125 40';
    } else {
        iconWidth = '130px';
        iconHeight = '34px';
        viewBoxValue = '0 0 130 34';
    }

    return (
        <Link
            href={logoLink}
            className={cx('logoLink')}
            data-automation-id="jcp-logo"
            title="JCPenney Home"
            id="jcp-logo"
            aria-label="Jcpenney logo navigate to home"
        >
            <Icon
                iconType="svg"
                width={iconWidth}
                height={iconHeight}
                viewBox={viewBoxValue}
                className={cx('jcpLogo')}
            >
                {logoComponent}
            </Icon>
        </Link>
    );
};

RenderLogo.defaultProps = {
    logoLink: 'on',
};

RenderLogo.propTypes = {
    logoLink: PropTypes.string,
};

export default RenderLogo;
