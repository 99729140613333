import {
    FIND_STORES_REQUEST,
    FIND_MORE_STORES_REQUEST,
    PUT_SELECTED_STORE_REQUEST,
    OPEN_FIND_STORES_SLIDE_PANEL,
    CLOSE_FIND_STORES_SLIDE_PANEL,
    SET_AVAILABLE_FILTER,
    OPEN_FIND_STORES_SUB_SLIDE_PANEL,
    CLOSE_FIND_STORES_SUB_SLIDE_PANEL,
    RESET_FIND_STORES,
    SET_FIND_STORE_SLIDER_PROPS,
} from '../actionTypes/FindStoresActionTypes';

export const findStores = (payload) => ({
    type: FIND_STORES_REQUEST,
    payload,
});

export const findMoreStores = (payload) => ({
    type: FIND_MORE_STORES_REQUEST,
    payload,
});

export const setAvailableFilter = (payload) => ({
    type: SET_AVAILABLE_FILTER,
    payload,
});

export const selectStore = (params) => ({
    type: PUT_SELECTED_STORE_REQUEST,
    params,
});

export const openSlidePanel = () => ({
    type: OPEN_FIND_STORES_SLIDE_PANEL,
});

export const closeSubSlidePanel = () => ({
    type: CLOSE_FIND_STORES_SUB_SLIDE_PANEL,
});

export const openSubSlidePanel = () => ({
    type: OPEN_FIND_STORES_SUB_SLIDE_PANEL,
});

export const closeSlidePanel = () => ({
    type: CLOSE_FIND_STORES_SLIDE_PANEL,
});
export const resetFindStores = (payload) => ({
    type: RESET_FIND_STORES,
    payload,
});

export const setFindStoreSliderProps = (payload) => ({
    type: SET_FIND_STORE_SLIDER_PROPS,
    payload,
});

export default {
    findStores,
    findMoreStores,
    selectStore,
    openSlidePanel,
    closeSlidePanel,
    openSubSlidePanel,
    closeSubSlidePanel,
    resetFindStores,
    setFindStoreSliderProps,
};
