function matching(link = '') {
    const m = link.match(/<?([^>]*)>(.*)/);
    const linkUrl = m[1];
    const parts = m[2].split(';');
    const parsedUrl = new URL(linkUrl);
    parts.shift();
    const info = parts.reduce(createObjects, {});
    if (!info.rel) {
        false;
    }
    const url = parsedUrl.pathname === '/' ? parsedUrl.origin : parsedUrl.href;
    return { url, rel: info.rel };
}

// its a reducer callback. will return output for 'rel=next' => rel = next
function createObjects(accumulator, currentValue = '') {
    const matchingString = currentValue.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);
    if (matchingString) accumulator[matchingString[1]] = matchingString[2];
    return accumulator;
}

/**
 * Usage: this method will use for parsing URL 
 * example : linkParser('<https://api.github.com/user/9287/repos?page=3&per_page=100>; rel="next", ' +
            '<https://api.github.com/user/9287/repos?page=1&per_page=100>; rel="prev"; pet="cat", ' +
            '<https://api.github.com/user/9287/repos?page=5&per_page=100>; rel="last"';)
 * output: {
            next: {
                rel: 'next',
                url: 'https://api.github.com/user/9287/repos?page=3&per_page=100'
            },
            prev: {
                rel: 'prev',
                url: 'https://api.github.com/user/9287/repos?page=1&per_page=100'
            },
            last: {
                rel: 'last',
                url: 'https://api.github.com/user/9287/repos?page=5&per_page=100'
            }
        }
 */

export function linkParser(linkHeader = '') {
    if (!linkHeader) return;
    let arrRes = linkHeader.split(/,\s*</);
    const obj = {};
    arrRes = arrRes.map(matching);
    return arrRes.reduce((obj, cur) => ({ ...obj, [cur.rel]: cur }), {});
}
