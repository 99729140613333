import {
    SHOW_ERROR_PAGE,
    REMOVE_ERROR_PAGE,
    ERROR_PAGE_LOADED_ANALYTICS,
} from '../actionTypes/ErrorPageActionTypes';

export const showErrorPage = (status, message) => ({
    type: SHOW_ERROR_PAGE,
    payload: { status, message },
});

export const removeErrorPage = () => ({
    type: REMOVE_ERROR_PAGE,
});

export const ErrorPageLoaded = (status) => ({
    type: ERROR_PAGE_LOADED_ANALYTICS,
    payload: { status },
});
