/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { call, put, select, take, race, delay, all } from 'redux-saga/effects';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import invoke from 'lodash/invoke';
import get from 'lodash/get';
import CoreConstants from 'yoda-core-components/lib/common/Constants';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { initiateCheckout } from 'yoda-interfaces/lib/Order/OrderApi';
import { createGuestAccount, refreshToken, signOut } from 'yoda-interfaces/lib/Account/AccountApi';
import TokenProvider, {
    setValue,
} from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { doPostSSRLogs } from 'yoda-core-components/lib/helpers/Utils/Utils';
import User from '../helpers/User/User';
import {
    IOVATION_SCRIPT_LOAD_ERROR,
    IOVATION_SCRIPT_LOAD_SUCCESS,
} from '../actionTypes/KeepMeLoggedInActionTypes';
import sessionActions from '../actions/SessionAction';
import AccessTokenProviderAction from '../actions/AccessTokenProviderAction';
import { selectPreferences, selectContext, selectFeatureFlags } from '../selectors/ContextSelector';
import { selectKeepMeLoggedInThrottleFF } from '../selectors/KeepMeLoggedInSelector';
import {
    DP_FULL_ACCESS_EXPIRY,
    ACCESS_TOKEN,
    ACCOUNT_ID,
    REFRESH_TOKEN,
    DP_USER_STATE,
    ACCOUNT_LOCKED_PAGE_URL,
    CHECKOUT_PAGE_URL,
    CLEAR_SERVICE_ERROR,
    SRV_ITEM_NOT_FOUND,
    CART_PAGE_URL,
    CART_PAGE_URL_LIST,
    SRV_ORDER_EMAILNOTFOUND,
    ACTION_NOT_ALLOWED_FOR_RECOGNIZED,
    SRV_PRODUCT_NOTFOUND,
    SRV_COMMERCE_ITEMNOTFOUND,
    SRV_PROMOTION_NOTFOUND,
    INITIATE_CHECKOUT_AGAIN,
    SRV_BILLING_ADDRESS_INVALID,
    GENERIC_API_ERROR,
    GENERIC_API_ERROR_MESSAGE,
    SET_SERVICE_ERROR,
} from '../common/Constants';
import { accessTokenStartTimer } from './SessionSaga';
import { getAccessToken, getRefreshToken } from '../actions/NativeAppAction';
import {
    CONTINUE_PROCESSING_REFRESH_TOKEN,
    CONTINUE_PROCESSING_ACCESS_TOKEN,
} from '../actionTypes/NativeAppActionTypes';
import ClientLoggerAction from '../actions/ClientLoggerAction';
import { refreshHeader } from '../actions/SignInAction';
import { triggerBnplAnalytics } from '../actions/AnalyticsAction';

const { DP_KEEP_ME_LOGGED } = CoreConstants;

const { setTokenInfo, setServerCookies } = AccessTokenProviderAction;

const DELAY_BEFORE_TIMEOUT = 2000;

/**
 * Validates the token object to see if it got at least accesstoken and accountId
 * @param {object} tokenObj tokens object
 */
function isInvalidTokens(tokenObj) {
    return isEmpty(tokenObj) || isEmpty(tokenObj[ACCESS_TOKEN]) || isEmpty(tokenObj[ACCOUNT_ID]);
}

export function* setServerCookieFromResponse(response) {
    if (__SERVER__ && response && response.headers) {
        const cookie = response.headers.get('X-Set-Cookie');
        if (cookie) {
            yield put(setServerCookies({ cookie }));
        }
    }
}

export function* getExpiryTimeForAccessToken(accessTokenExpiry) {
    if (accessTokenExpiry) {
        yield call(accessTokenStartTimer, accessTokenExpiry);
    }
}

function* getFilterCookies(cookies) {
    const context = (state) => state.context;
    const appContext = yield select(context);
    if (isEmpty(cookies)) {
        return cookies;
    }
    const blackListedCookies = ((appContext || {}).preferences || {}).blackListedCookies || [];
    const filterCookie = { ...cookies };
    forEach(blackListedCookies, (blackListedCookie) => {
        const isBlackListedCookie = Object.prototype.hasOwnProperty.call(
            filterCookie,
            blackListedCookie.key
        );
        if (isBlackListedCookie) {
            delete filterCookie[blackListedCookie.key];
        }
    });
    return filterCookie;
}

function* storeTokenInfo(tokenInfo) {
    TokenProvider.storeTokenFromServer(tokenInfo);
    if (tokenInfo.expires_in) {
        TokenProvider.set(DP_FULL_ACCESS_EXPIRY, tokenInfo.expires_in.toString());
        yield call(getExpiryTimeForAccessToken, tokenInfo.expires_in);
    }
}

function* getElbUrlHost() {
    const context = (state) => state.context;
    const appContext = yield select(context);
    const elbPreferences = {};
    if (__SERVER__) {
        const environmentName = (appContext.preferences || {}).environment;
        const environmentInfo = ((appContext.preferences || {}).environments || {})[
            environmentName
        ];
        elbPreferences.elbUrl = (((environmentInfo || {}).apiHosts || {}).server || {}).elb;
    }
    return elbPreferences;
}

function* getAppTimeout() {
    const context = yield select((state) => state.context);
    return get(context, 'preferences.nativeAppResponseTimeout', DELAY_BEFORE_TIMEOUT);
}

function* showInterveneModal(isCheckout, logMessage = '') {
    if (isCheckout) {
        yield put(sessionActions.setSessionTimeOut(true));
    } else {
        const errorHandlerInfo = {};
        errorHandlerInfo.errorCode = GENERIC_API_ERROR;
        errorHandlerInfo.errorMessage = GENERIC_API_ERROR_MESSAGE;
        errorHandlerInfo.showRefresh = true;
        errorHandlerInfo.doNotClearToken = true;
        errorHandlerInfo.errorDescription = '';
        yield all([
            put({ type: SET_SERVICE_ERROR, errorHandlerInfo }),
            put(
                ClientLoggerAction.logError(
                    'WEBVIEW::ERROR - glitch modal shown on factory saga',
                    logMessage
                )
            ),
        ]);
    }
}

function* doCreateAccount(logger, cookies, accountId) {
    const userState = TokenProvider.get(DP_USER_STATE);
    const createAccountResponse = yield call(createGuestAccount, accountId);
    const { cluster } = yield select(selectContext);
    doPostSSRLogs(
        logger,
        {
            requestInfo: 'GET ACCOUNT API',
            response: createAccountResponse,
        },
        cookies,
        cluster
    );
    const tokenInfo = {};
    if ((createAccountResponse || {}).status === 201) {
        // MCYODA-30140 Reset couponNudge while moving to guest session
        if (userState === 0 || userState === '0') {
            LocalStorage.removeData('couponExpireNudgeClosed');
        }
        createAccountResponse.data = createAccountResponse.data || {};
        tokenInfo[ACCESS_TOKEN] = createAccountResponse.data.access_token;
        tokenInfo[ACCOUNT_ID] = createAccountResponse.data.account_id;
        tokenInfo[REFRESH_TOKEN] = createAccountResponse.data.refresh_token;
        tokenInfo.expires_in = createAccountResponse.data.expires_in || '';
    }
    if (
        !__SERVER__ &&
        window?.location?.href?.indexOf('/cart') &&
        (createAccountResponse || {}).status === 422
    ) {
        const errorHandlerInfo = {};
        errorHandlerInfo.errorCode = GENERIC_API_ERROR;
        errorHandlerInfo.errorMessage = GENERIC_API_ERROR_MESSAGE;
        errorHandlerInfo.showRefresh = true;
        errorHandlerInfo.doNotClearToken = true;
        errorHandlerInfo.errorDescription = '';
        yield put({ type: SET_SERVICE_ERROR, errorHandlerInfo });
    }
    __SERVER__ ? yield put(setTokenInfo(tokenInfo)) : yield call(storeTokenInfo, tokenInfo);
    return tokenInfo;
}

/**
 * Converts to guest as the access token can't be extended via the refresh token
 */
function* doSoftLogout(isCheckout, logger, apiNotRequired, cookies, convertToGuestOnTimeOut) {
    let accountId = null;
    const isNative = yield select((state) => get(state, 'context.isNative', false));
    const { cluster } = yield select(selectContext);

    if (!apiNotRequired) {
        accountId = TokenProvider.get(ACCOUNT_ID);
        doPostSSRLogs(
            logger,
            {
                requestInfo: 'SOFT LOGOUT API',
            },
            cookies,
            cluster
        );
        TokenProvider.logout();
    } else {
        doPostSSRLogs(
            logger,
            {
                requestInfo: 'SOFT LOGOUT API - Without access token or account id',
            },
            cookies,
            cluster
        );
    }
    let tokenInfo = {};
    if (isCheckout) {
        yield put(sessionActions.setSessionTimeOut(true));
    } else if (!convertToGuestOnTimeOut) {
        !__SERVER__ && window.location.assign('/sessiontimeout?timeout=true');
    } else if (isNative) {
        yield put(getRefreshToken());
        // waits for x seconds for app to reply and then fallbacks
        const { proceed, timeout } = yield race({
            proceed: take(CONTINUE_PROCESSING_REFRESH_TOKEN),
            timeout: delay(yield getAppTimeout()),
        });

        const didAppCallFail = get(proceed, 'payload.isSuccess') === false;
        if (!didAppCallFail) {
            TokenProvider.setTokensFromNativeApp(get(proceed, 'payload.response.data') || {});
        }
        tokenInfo = TokenProvider.getTokens();
        // if app fails to set tokens then fallback
        if (isInvalidTokens(tokenInfo) || timeout || didAppCallFail) {
            const logMessage = `Failed to fetch tokens from app: tokenInfo
            ${JSON.stringify(tokenInfo)} timeout ${timeout}`;
            yield showInterveneModal(isCheckout, logMessage);
            console.error(logMessage);
            return null;
        }
    } else {
        tokenInfo = yield call(doCreateAccount, logger, cookies, accountId);
        yield put(refreshHeader());
    }
    return tokenInfo;
}

function getErrorInfo(response) {
    if (response) {
        return Array.isArray(response.data) ? response.data[0] : response.data;
    }
    return {};
}

function* getBBId() {
    let bbId = null;
    if (!__SERVER__ && window.IGLOO && window.IGLOO.getBlackbox) {
        const blackBoxInfo = invoke(window, 'IGLOO.getBlackbox');
        if (blackBoxInfo.finished) {
            bbId = blackBoxInfo.blackbox;
        } else {
            console.warn('Incomplete black box id generated');
            const preferences = yield select(selectPreferences);
            let { blackBoxIdGenerateRetryCount } = preferences;
            const { blackBoxIdGenerateWaitingTime } = preferences;
            while (blackBoxIdGenerateRetryCount > 0) {
                console.warn(
                    'Waiting to get complete black box id:',
                    blackBoxIdGenerateWaitingTime
                );
                blackBoxIdGenerateRetryCount -= 1;
                yield delay(blackBoxIdGenerateWaitingTime);
                const updatedBlackBoxInfo = window.IGLOO.getBlackbox();
                bbId = updatedBlackBoxInfo.blackbox;
                if (updatedBlackBoxInfo.finished) {
                    blackBoxIdGenerateRetryCount = 0;
                    console.warn('Successfully generated complete black box id');
                }
            }
        }
    }
    return bbId;
}

function* postTokenCheck(tokenInfo, isCheckout, payload) {
    if (tokenInfo[DP_USER_STATE] === '2') {
        if (isCheckout) {
            yield put(sessionActions.setSessionTimeOut(true));
            return null;
        }
        if (payload && payload.xCommand === 'initiate-checkout') {
            getBrowserHistory().push('/cart/signin');
            return null;
        }
    }
    return tokenInfo;
}

function updateLocalStorageFromCookie() {
    if (
        TokenProvider.get(ACCESS_TOKEN, true) &&
        TokenProvider.get(ACCESS_TOKEN, true) !== TokenProvider.get(ACCESS_TOKEN)
    ) {
        setValue(ACCESS_TOKEN, TokenProvider.get(ACCESS_TOKEN, true));
        console.log('Access Token LS After:', TokenProvider.get(ACCESS_TOKEN));
    }
    if (
        TokenProvider.get(REFRESH_TOKEN, true) &&
        TokenProvider.get(REFRESH_TOKEN, true) !== TokenProvider.get(REFRESH_TOKEN)
    ) {
        setValue(REFRESH_TOKEN, TokenProvider.get(REFRESH_TOKEN, true));
    }
    if (
        TokenProvider.get(DP_USER_STATE, true) &&
        TokenProvider.get(DP_USER_STATE, true) !== TokenProvider.get(DP_USER_STATE)
    ) {
        setValue(DP_USER_STATE, TokenProvider.get(DP_USER_STATE, true));
    }
    if (
        TokenProvider.get(ACCOUNT_ID, true) &&
        TokenProvider.get(ACCOUNT_ID, true) !== TokenProvider.get(ACCOUNT_ID)
    ) {
        setValue(ACCOUNT_ID, TokenProvider.get(ACCOUNT_ID, true));
    }
    if (
        TokenProvider.get(DP_FULL_ACCESS_EXPIRY, true) &&
        TokenProvider.get(DP_FULL_ACCESS_EXPIRY, true) !== TokenProvider.get(DP_FULL_ACCESS_EXPIRY)
    ) {
        setValue(DP_FULL_ACCESS_EXPIRY, TokenProvider.get(DP_FULL_ACCESS_EXPIRY, true));
    }
}

function* getNativeAccessToken(isCheckout) {
    yield put(getAccessToken(!isCheckout));
    let tokenInfo = {};
    // waits for x seconds for app to reply and then fallbacks
    const { proceed, timeout } = yield race({
        proceed: take(CONTINUE_PROCESSING_ACCESS_TOKEN),
        timeout: delay(yield getAppTimeout()),
    });
    const didAppCallFail = get(proceed, 'payload.isSuccess') === false;
    if (!didAppCallFail) {
        TokenProvider.setTokensFromNativeApp(get(proceed, 'payload.response.data') || {});
    }
    if (isCheckout && timeout && window.webkit) {
        updateLocalStorageFromCookie();
    }
    tokenInfo = TokenProvider.getTokens();
    const isIosCheckout = isCheckout && window.webkit;
    if (isInvalidTokens(tokenInfo) || (timeout && !isIosCheckout) || didAppCallFail) {
        const logMessage = `Failed to extend access tokens from app: tokenInfo
        ${JSON.stringify(tokenInfo)} timeout ${timeout}`;
        yield showInterveneModal(isCheckout, logMessage);
        console.error(logMessage);
        return null;
    }
    return yield postTokenCheck(tokenInfo, isCheckout, payload);
}

function* triggerAuthAnalytics(analyticsErrorInfo, analyticsParams) {
    const { src, errorDescription, fromPage = '' } = analyticsErrorInfo;
    const { errMessage, actionName, responseCode, traceID, quantumSessionID } = analyticsParams;
    const digitalData = window?.digitalData || {};
    const analyticsData = {
        error: [{ errorDescription }],
        eventName: 'formError',
        src,
        page: {
            pageInfo: {
                analyticsPageName: get(digitalData, 'page.pageInfo.analyticsPageName', fromPage),
                pageType: get(digitalData, 'page.pageInfo.pageType', ''),
            },
        },
    };
    LocalStorage.setData('analytics_PrevPage', analyticsData, true);
    yield put(
        ClientLoggerAction.logError(
            errMessage,
            `actionName: | ${actionName} |responseCode: ${responseCode} | user-agent:${window?.navigator?.userAgent} | quantumSessionID:${quantumSessionID} | traceID:${traceID}`
        )
    );
}

/**
 * Extends tokens using refresh token
 */
function* doUpdateAccount(
    refreshTokenParam,
    isCheckout,
    logger,
    cookies,
    payload,
    convertToGuestOnTimeOut,
    retry = false
) {
    let tokenInfo = {};

    const isNative = yield select((state) => get(state, 'context.isNative', false));

    if (isNative) {
        yield put(getAccessToken(!isCheckout));
        // waits for x seconds for app to reply and then fallbacks
        const { proceed, timeout } = yield race({
            proceed: take(CONTINUE_PROCESSING_ACCESS_TOKEN),
            timeout: delay(yield getAppTimeout()),
        });
        const didAppCallFail = get(proceed, 'payload.isSuccess') === false;
        if (!didAppCallFail) {
            TokenProvider.setTokensFromNativeApp(get(proceed, 'payload.response.data') || {});
        }

        if (isCheckout && timeout && window.webkit) {
            updateLocalStorageFromCookie();
        }
        tokenInfo = TokenProvider.getTokens();
        const isIosCheckout = isCheckout && window.webkit;
        if (isInvalidTokens(tokenInfo) || (timeout && !isIosCheckout) || didAppCallFail) {
            const logMessage = `Failed to extend access tokens from app: tokenInfo
            ${JSON.stringify(tokenInfo)} timeout ${timeout}`;
            yield showInterveneModal(isCheckout, logMessage);
            console.error(logMessage);
            return null;
        }
        return yield postTokenCheck(tokenInfo, isCheckout, payload);
    }

    const accountId = TokenProvider.get(ACCOUNT_ID);
    const isCart = !__SERVER__ && CART_PAGE_URL_LIST.indexOf(window.location.pathname) !== -1;
    let customHeaders = {};
    const enableKeepMeLoggedIn = yield select(selectKeepMeLoggedInThrottleFF);
    const keepMeLoggedInSelectedInSession = TokenProvider.get(DP_KEEP_ME_LOGGED) === '1';
    if (enableKeepMeLoggedIn && User.isUserLoggedIn(true) && keepMeLoggedInSelectedInSession) {
        let bbId = yield call(getBBId);
        if (!bbId) {
            console.warn('Iovation Script is loaded yet...');
            const preferences = yield select(selectPreferences);
            const { type } = yield race([
                take([IOVATION_SCRIPT_LOAD_ERROR, IOVATION_SCRIPT_LOAD_SUCCESS]),
                // this race condition added so no call blocks
                delay(get(preferences, 'iovationScriptLoadTimeOut', DELAY_BEFORE_TIMEOUT)),
            ]);
            if (!type || type === IOVATION_SCRIPT_LOAD_ERROR) {
                console.warn('Iovation script failed or timed out: -> ', type);
            } else {
                bbId = yield call(getBBId);
            }
        }
        if (!bbId) {
            bbId = get(invoke(window, 'IGLOO.getBlackbox'), 'blackbox');
        }
        customHeaders = {
            ...(bbId && { 'X-BLACKBOX': bbId }),
        };
    }
    const refreshTokenResponse = yield call(
        refreshToken,
        refreshTokenParam,
        accountId,
        customHeaders
    );
    const { cluster } = yield select(selectContext);
    doPostSSRLogs(
        logger,
        {
            requestInfo: 'GET REFRESH TOKEN API',
            response: refreshTokenResponse,
        },
        cookies,
        cluster
    );
    if ((refreshTokenResponse || {}).status === 200) {
        refreshTokenResponse.data = refreshTokenResponse.data || {};
        tokenInfo[ACCESS_TOKEN] = refreshTokenResponse.data.access_token;
        tokenInfo[ACCOUNT_ID] = refreshTokenResponse.data.account_id;
        tokenInfo[REFRESH_TOKEN] = refreshTokenResponse.data.refresh_token;
        tokenInfo.expires_in = refreshTokenResponse.data.expires_in || '';
        tokenInfo.shippingPromo = refreshTokenResponse.data.shippingPromo || {};

        if (tokenInfo.expires_in) {
            TokenProvider.set(DP_FULL_ACCESS_EXPIRY, tokenInfo.expires_in.toString());
            yield call(getExpiryTimeForAccessToken, tokenInfo.expires_in);
        }
        if (refreshTokenResponse.headers.get('DP_USER_STATE')) {
            tokenInfo[DP_USER_STATE] = refreshTokenResponse.headers.get('DP_USER_STATE');
        }
        __SERVER__ ? yield put(setTokenInfo(tokenInfo)) : yield call(storeTokenInfo, tokenInfo);

        // If DP_USER_STATE=2 and checkout => show session time out and land on cart
        // If DP_USER_STATE=2 and initiateCheckout call ==> land on checkout signin page.
        tokenInfo = yield postTokenCheck(tokenInfo, isCheckout, payload);
    } else if ((refreshTokenResponse || {}).status >= 500) {
        if (((refreshTokenResponse || {}).error || {}).name === 'TypeMismatchError') {
            if (isCart) {
                yield put(
                    ClientLoggerAction.logError(
                        'session timeout on cart',
                        `refresh token status: ${refreshTokenResponse.status}`
                    )
                );
            }
            tokenInfo = yield call(
                doSoftLogout,
                isCart || isCheckout,
                logger,
                false,
                cookies,
                convertToGuestOnTimeOut
            );
        } else if (!retry) {
            tokenInfo = yield call(
                doUpdateAccount,
                refreshToken,
                isCheckout,
                logger,
                cookies,
                payload,
                convertToGuestOnTimeOut,
                true
            );
        }
    } else if (
        (refreshTokenResponse || {}).status === 403 &&
        (getErrorInfo(refreshTokenResponse.response) || {}).errorCode ===
            'SVC_USR_ERR_PROFILE_LOCKED'
    ) {
        yield call(signOut);
        TokenProvider.logout();
        getBrowserHistory().push(ACCOUNT_LOCKED_PAGE_URL);
    } else if (!__SERVER__) {
        const isAccountWallet = (window?.location?.pathname).includes('/account/dashboard/wallet');
        const isAccountLocked =
            refreshTokenResponse?.data?.errorCode === 'SVC_USR_ERR_PROFILE_LOCKED';
        /**
         * Trigger accountLock analytics and Kibana log event
         */
        if (isAccountWallet && isAccountLocked) {
            const quantumSessionID = Cookies.load('QuantumMetricSessionID') || '';
            const traceID = refreshTokenResponse?.headers.get('x-trace-id');
            const analyticsErrorInfo = {
                errorDescription:
                    refreshTokenResponse?.data?.errorMessage || 'account locked from CAM',
                src: '4',
            };
            const analyticsParams = {
                errMessage: 'Account locked from wallet',
                actionName: 'CVV_account_locked',
                responseCode: refreshTokenResponse?.status,
                traceID,
                quantumSessionID,
            };
            yield call(triggerAuthAnalytics, analyticsErrorInfo, analyticsParams);
        }
        // refresh token is not found in db. Enable cart to be merged from one account to other
        const dontClearOrder = [403, 404].includes(get(refreshTokenResponse, 'status'));
        const showSessionTimeoutModal = isCheckout || (isCart && !dontClearOrder);
        if (showSessionTimeoutModal && isCart) {
            yield put(
                ClientLoggerAction.logError(
                    'session timeout on cart',
                    `refresh token status: ${refreshTokenResponse.status}`
                )
            );
        }
        tokenInfo = yield call(
            doSoftLogout,
            showSessionTimeoutModal,
            logger,
            dontClearOrder,
            cookies,
            convertToGuestOnTimeOut
        );
    }

    return tokenInfo;
}

function checkUserAgentIsEdgeOrIE() {
    if (__SERVER__) {
        return false;
    }
    const agentName = window.navigator.userAgent;
    return agentName.indexOf('Edge') !== -1 || agentName.indexOf('Trident') !== -1;
}

export default function* FactorySaga(api, action, logger, convertToGuestOnTimeOut = true) {
    const result = {};
    try {
        let isCheckout = !__SERVER__ && includes(window.location.pathname, CHECKOUT_PAGE_URL);
        if (__SERVER__) {
            const context = (state) => state.context;
            const appContext = yield select(context);
            isCheckout = includes(appContext.requestUrl, CHECKOUT_PAGE_URL);
        }
        const postData = {};
        let tokenInfo = {};
        let cookies = {};
        let isIgnoreErrorMsg = false;
        tokenInfo = TokenProvider.getTokens();
        const isNative = yield select((state) => get(state, 'context.isNative', false));

        if (__SERVER__) {
            cookies = (action.headers || {}).cookies || {};
            const accessToken = cookies[ACCESS_TOKEN];
            const accountId = cookies[ACCOUNT_ID];
            if (accessToken && accountId) {
                tokenInfo[ACCOUNT_ID] = accountId;
                tokenInfo[ACCESS_TOKEN] = accessToken;
                postData.requestHeaderCookie = yield call(getFilterCookies, cookies);
                const elbPreferences = yield call(getElbUrlHost);
                postData.elbApiHost = elbPreferences.elbUrl;
            }
        } else if (isInvalidTokens(tokenInfo)) {
            // we want to convertToGuestAccount when localstorage is totally cleared so
            // in that case  convertToGuestOnTimeOut: true across the micro-sites
            tokenInfo = yield call(doSoftLogout, isCheckout, logger, true, cookies, true);
        }
        if (isInvalidTokens(tokenInfo)) {
            result.isSuccess = false;
            return result;
        }
        postData.payload = action.payload ? action.payload : {};
        postData.accountId = tokenInfo[ACCOUNT_ID];
        postData.accessToken = tokenInfo[ACCESS_TOKEN];

        if (action.featureFlags) {
            postData.featureFlags = action.featureFlags;
        }
        // Added additional header {userAction}. if userAction is not available in headers, we will read it from actions.
        const userAction = get(action, 'headers.userAction') || get(action, 'userAction');
        if (userAction) postData.userAction = userAction;
        const errorHandlerInfo = {};
        const errorHandler = action.payload ? action.payload.errorHandler : null;
        if (errorHandler) {
            delete postData.payload.errorHandler;
            errorHandlerInfo.errorComponent = errorHandler.errorComponent;
            errorHandlerInfo.errorComponentIndex =
                errorHandler.index || errorHandler.errorComponentIndex;
            errorHandlerInfo.context = errorHandler.context;
        }
        const response = yield call(api, postData);
        const { cluster } = yield select(selectContext);
        doPostSSRLogs(
            logger,
            {
                requestInfo: action.apiName || 'Unhandled - API',
                response,
            },
            cookies,
            cluster
        );
        yield call(setServerCookieFromResponse, response);
        result.response = response;

        if (TokenProvider.get(DP_FULL_ACCESS_EXPIRY)) {
            yield call(getExpiryTimeForAccessToken, TokenProvider.get(DP_FULL_ACCESS_EXPIRY));
        }

        /**
         * HOT-FIX : Fallback to client side when server side api call failed
         */
        if (
            __SERVER__ &&
            response.status !== 200 &&
            response.status !== 201 &&
            response.status !== 204
        ) {
            result.isSuccess = false;
            return result;
        }
        if (typeof action.payload === 'object' && !action.payload.errorHandler) {
            action.payload.errorHandler = errorHandlerInfo;
        }
        switch (response.status) {
            case 200:
            case 201:
            case 202:
            case 204: {
                result.isSuccess = true;
                yield put({ type: CLEAR_SERVICE_ERROR });
                const bnplRemoved = result?.response?.headers?.get?.('x-bnpl-removed');
                const { disableBnpl = false } = yield select(selectFeatureFlags);
                if (!disableBnpl && !__SERVER__ && bnplRemoved === 'true') {
                    yield put(triggerBnplAnalytics('paylaterRemoved'));
                }
                break;
            }
            case 401: {
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                if (
                    errorInfo &&
                    errorInfo.errorCode === 'SRV_REFRESH_TOKEN_EXPIRED' &&
                    !action.refreshRetry
                ) {
                    yield put(ClientLoggerAction.logError('refresh token expired'));
                    tokenInfo = yield call(
                        doSoftLogout,
                        isCheckout,
                        logger,
                        false,
                        cookies,
                        convertToGuestOnTimeOut
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.refreshRetry = true;
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    if (isCheckout) {
                        // Setting the flag to ignore showing the refresh token expired message in checkout
                        // flow as user would have been shown the session timeout modal
                        isIgnoreErrorMsg = true;
                    }
                } else if (!action.refreshRetry) {
                    tokenInfo = yield call(
                        doUpdateAccount,
                        tokenInfo[REFRESH_TOKEN],
                        isCheckout,
                        logger,
                        cookies,
                        postData.payload,
                        convertToGuestOnTimeOut
                    );
                    /** while extending token if it becomes USER_STATE 2 - Do not call Quickscreen GET call */
                    const restrictQSEligibleCall =
                        tokenInfo?.DP_USER_STATE === '2' && action?.payload?.fromGETQS;
                    if (!isEmpty(tokenInfo) && !restrictQSEligibleCall) {
                        action.refreshRetry = true;
                        if (__SERVER__) {
                            action.headers = action.headers ? action.headers : {};
                            action.headers.cookies = action.headers.cookies
                                ? action.headers.cookies
                                : {};
                            action.headers.cookies = Object.assign(
                                action.headers.cookies,
                                tokenInfo
                            );
                        }
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    if (tokenInfo === null) {
                        isIgnoreErrorMsg = true;
                    }
                    errorHandlerInfo.tokenApiFailed = true;
                }
                break;
            }
            case 400: {
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                if (errorInfo && errorInfo.errorCode === SRV_ITEM_NOT_FOUND) {
                    doPostSSRLogs(logger, { requestInfo: 'Empty Cart' });
                    if (isCheckout) {
                        window.location.href = CART_PAGE_URL;
                    }
                    yield put({ type: CLEAR_SERVICE_ERROR });
                    return result;
                }
                const context = (state) => state.context;
                const appContext = yield select(context);
                const { enableRewardRedemption = true } = get(appContext, 'featureFlags', {});
                if (
                    enableRewardRedemption &&
                    errorInfo &&
                    errorInfo.errorCode === 'API_ERR_REDEEMED_COUPON_PRESENT'
                ) {
                    isIgnoreErrorMsg = true;
                }
                /*
                 * MCYODA-5319
                 * Customer unable to place order when DPJSessionID cookie drops in IE, edge and trident browsers
                 * Guest user
                 */
                const isUserAgentIsEdgeOrIE = checkUserAgentIsEdgeOrIE();
                if (errorInfo.errorCode === SRV_ORDER_EMAILNOTFOUND && isUserAgentIsEdgeOrIE) {
                    yield put(sessionActions.setSessionTimeOut(true));
                }
                break;
            }
            case 403: {
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                if (
                    errorInfo.errorCode === 'SRV_ACCOUNT_ID_MISMATCH' &&
                    !action.sessionExpiredRetry
                ) {
                    doPostSSRLogs(logger, { requestInfo: 'Session expired in order' });
                    tokenInfo = yield call(
                        doSoftLogout,
                        isCheckout,
                        logger,
                        false,
                        cookies,
                        convertToGuestOnTimeOut
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.sessionExpiredRetry = true;
                        if (__SERVER__) {
                            action.headers = action.headers ? action.headers : {};
                            action.headers.cookies = action.headers.cookies
                                ? action.headers.cookies
                                : {};
                            action.headers.cookies = Object.assign(
                                action.headers.cookies,
                                tokenInfo
                            );
                        }
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    return result;
                }
                if (
                    errorInfo &&
                    ['SVC_USR_ERR_PROFILE_LOCKED', 'SRV_REFRESH_TOKEN_EXPIRED'].includes(
                        errorInfo.errorCode
                    ) &&
                    !action.refreshRetry
                ) {
                    // doPostSSRLogs(logger, { requestInfo: 'Refresh token expired' });
                    tokenInfo = yield call(
                        doSoftLogout,
                        isCheckout,
                        logger,
                        false,
                        cookies,
                        convertToGuestOnTimeOut
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.refreshRetry = true;
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    if (isCheckout) {
                        // Setting the flag to ignore showing the refresh token expired message in checkout
                        // flow as user would have been shown the session timeout modal
                        isIgnoreErrorMsg = true;
                    }
                } else if (errorInfo.errorCode === 'SRV_SESSION_INVALID' && !action.refreshRetry) {
                    tokenInfo = yield call(
                        doUpdateAccount,
                        tokenInfo[REFRESH_TOKEN],
                        isCheckout,
                        logger,
                        cookies,
                        postData.payload
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.refreshRetry = true;
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    errorHandlerInfo.tokenApiFailed = true;
                } else if (errorInfo.errorCode === ACTION_NOT_ALLOWED_FOR_RECOGNIZED) {
                    isIgnoreErrorMsg = true;
                    if (isNative) {
                        tokenInfo = yield call(getNativeAccessToken, isCheckout);
                        console.log('tokenInfo', JSON.stringify(tokenInfo));
                        if (!isEmpty(tokenInfo)) {
                            action.refreshRetry = true;
                            return yield call(
                                FactorySaga,
                                api,
                                action,
                                logger,
                                convertToGuestOnTimeOut
                            );
                        }
                        errorHandlerInfo.tokenApiFailed = true;
                    } else if (isCheckout) {
                        window.location.assign('/cart/signin');
                    } else {
                        window.location.assign(
                            `/signin?next=${window.location.pathname}${window.location.search}`
                        );
                    }
                }
                break;
            }
            case 404: {
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                if (
                    errorInfo &&
                    (errorInfo.errorCode === 'SRV_REFRESH_TOKEN_NOT_FOUND' ||
                        errorInfo.errorCode === 'SRV_ACCOUNT_NOTFOUND') &&
                    !action.retry
                ) {
                    doPostSSRLogs(logger, { requestInfo: 'Refresh token not found' });
                    tokenInfo = yield call(
                        doSoftLogout,
                        isCheckout,
                        logger,
                        false,
                        cookies,
                        convertToGuestOnTimeOut
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.retry = true;
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                } else if (
                    action.apiName === 'GET_CART_API' &&
                    !action.retry &&
                    errorInfo &&
                    errorInfo.errorCode === SRV_PRODUCT_NOTFOUND
                ) {
                    const initiateCheckoutAction = {
                        payload: {
                            xCommand: 'initiate-checkout',
                            isVendorHeaderRequired: true,
                        },
                    };
                    initiateCheckoutAction.retry = true;
                    const initiateCheckoutResult = yield call(
                        FactorySaga,
                        initiateCheckout,
                        initiateCheckoutAction
                    );
                    doPostSSRLogs(logger, {
                        requestInfo: 'initite checkout API',
                        response: initiateCheckoutResult.response,
                    });
                    if (initiateCheckoutResult.isSuccess) {
                        action.retry = true;
                        return yield call(FactorySaga, api, action);
                    }
                } else if (
                    errorInfo &&
                    (errorInfo.errorCode === SRV_COMMERCE_ITEMNOTFOUND ||
                        errorInfo.errorCode === SRV_PROMOTION_NOTFOUND)
                ) {
                    action.showRefresh = true;
                    action.doNotClearToken = true;
                }
                break;
            }
            case 412: {
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                // Defensive code to prevent making the session time out in a loop if the API
                // keeps sending 412 response on subsequent calls
                if (errorInfo.errorCode === INITIATE_CHECKOUT_AGAIN) {
                    if (!action.initiateRetry) {
                        action.initiateRetry = true;
                        if (isCheckout) {
                            yield put(sessionActions.setSessionTimeOut(true));
                            action.doNotClearToken = true;
                            isIgnoreErrorMsg = true;
                            break;
                        }
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                    action.doNotClearToken = true;
                }
                break;
            }
            case 422: {
                /*
                 * MCYODA-5319
                 * Customer unable to place order when DPJSessionID cookie drops in IE, edge and trident browsers
                 * Register user
                 */
                result.isSuccess = false;
                const errorInfo = Array.isArray(response.data) ? response.data[0] : response.data;
                const isUserAgentIsEdgeOrIE = checkUserAgentIsEdgeOrIE();
                if (errorInfo.errorCode === SRV_BILLING_ADDRESS_INVALID && isUserAgentIsEdgeOrIE) {
                    yield put(sessionActions.setSessionTimeOut(true));
                }
                break;
            }
            case 500: {
                if (
                    response.error &&
                    response.error.name === 'TypeMismatchError' &&
                    !action.refreshRetryIE
                ) {
                    tokenInfo = yield call(
                        doUpdateAccount,
                        tokenInfo[REFRESH_TOKEN],
                        isCheckout,
                        logger,
                        cookies,
                        postData.payload,
                        convertToGuestOnTimeOut
                    );
                    if (!isEmpty(tokenInfo)) {
                        action.refreshRetryIE = true;
                        return yield call(
                            FactorySaga,
                            api,
                            action,
                            logger,
                            convertToGuestOnTimeOut
                        );
                    }
                }
                result.isSuccess = false;
                break;
            }
            default: {
                result.isSuccess = false;
            }
        }

        if (result.isSuccess === false && !isIgnoreErrorMsg) {
            if (response.status >= 500) {
                const logStr = `response code: ${response.status} | action: ${
                    action.type || action.apiName
                } | response ${JSON.stringify(response || {})}`;

                const context = (state) => state.context;
                const appContext = yield select(context);
                const { avoidFailedAPIRetry = false } = get(appContext, 'featureFlags', {});
                /**
                 * Reattempts API call to see if it clears
                 * Some calls fail due to possible multiple connections
                 */
                if (!action.retry && !avoidFailedAPIRetry) {
                    yield put(
                        ClientLoggerAction.logError(
                            'Retrying API call post failure on factory saga',
                            logStr
                        )
                    );

                    console.error(`Retrying API call post failure on factory saga - ${logStr}`);
                    action.retry = true;
                    return yield call(FactorySaga, api, action);
                }
                yield put(ClientLoggerAction.logError('API failure on factory saga', logStr));
                // eslint-disable-next-line no-console
                console.error(`API failure on factory saga - ${logStr}`);
            }
            const errorData = (result.response.data || {}).data;
            let errorInfo = errorData && Array.isArray(errorData) && (errorData[0] || []).errors[0];
            if (!errorInfo) {
                errorInfo = Array.isArray(result.response.data)
                    ? result.response.data[0]
                    : result.response.data;
            }
            errorInfo = errorInfo || {};
            errorHandlerInfo.errorCode = isEmpty(errorInfo.errorCode)
                ? GENERIC_API_ERROR
                : errorInfo.errorCode;
            errorHandlerInfo.errorMessage = errorInfo.errorMessage || GENERIC_API_ERROR_MESSAGE;
            errorHandlerInfo.showRefresh = action.showRefresh;
            errorHandlerInfo.doNotClearToken = true;
            errorHandlerInfo.errorDescription = errorInfo.errorDescription || '';
            yield put({ type: SET_SERVICE_ERROR, errorHandlerInfo });
        }
        return result;
    } catch (error) {
        result.isSuccess = false;
        return result;
    }
}
