import { GET_SITE_USER_PROFILE } from '../actionTypes/AccountActionTypes';
import { ACCESS_TOKEN_TIMER, ACCESS_TOKEN_AUTH_CHECK } from '../actionTypes/SessionActionTypes';

// Better to use it from SignInSliderActions directly
export {
    openSignInSlider,
    initiateSignInSliderClose,
    customStepUp,
    unmountSignInSlider,
    showLogoutToolTip as showLogoutTooltip,
    openRewardsPromotionLogin,
} from './SignInSliderActions';

export const getUserDetails = () => ({
    type: GET_SITE_USER_PROFILE,
});

export const accessTimerStart = () => ({
    type: ACCESS_TOKEN_TIMER,
});

export const accessTimerAuthCheck = (params) => ({
    type: ACCESS_TOKEN_AUTH_CHECK,
    payload: params,
});
