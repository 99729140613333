import { BG_WHITE_SHOW, BG_WHITE_REMOVE } from '../actionTypes/BgWhiteActionType';

const BgWhiteReducer = function BgWhiteReducer(state = false, action) {
    switch (action.type) {
        case BG_WHITE_SHOW:
            return true;
        case BG_WHITE_REMOVE:
            return false;
        default:
            return state;
    }
};

BgWhiteReducer.reducerName = 'showBgWhite';

export default BgWhiteReducer;
