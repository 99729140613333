import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';

import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'HeaderChunksAndFooterLazy',
        path: () => path.join(__dirname, './Hamburger'),
        resolve: () => require.resolveWeak('./Hamburger'),
        load: () =>
            Promise.all([
                import(/* webpackChunkName: "HeaderChunksAndFooterLazy" */ './Hamburger'),
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => null,
        error: () => null,
        timeout,
    }
);
