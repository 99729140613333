import { call, put, takeLatest } from 'redux-saga/effects';
import { getBreadCrumbs } from 'yoda-interfaces/lib/BreadCrumbs/BreadCrumbsApi';
import getBreadCrumbsOnSuccess from '../actions/BreadCrumbNavAction';
import * as BreadCrumbNavActionTypes from '../actionTypes/BreadCrumbNavActionTypes';

function* BreadCrumbNavSaga(actions) {
    try {
        const breadCrumbsData = yield call(getBreadCrumbs, actions.payload);
        const breadCrumbs = breadCrumbsData.data.breadcrumbs;
        yield put(getBreadCrumbsOnSuccess(breadCrumbs));
    } catch (error) {
        yield put({
            type: BreadCrumbNavActionTypes.BREADCRUMBNAV_GET_ERROR,
            error,
        });
    }
}

const watchBreadCrumbNavRequest = function* watchBreadCrumbNavRequest() {
    yield takeLatest(BreadCrumbNavActionTypes.BREADCRUMBNAV_GET_REQUEST, BreadCrumbNavSaga);
};

watchBreadCrumbNavRequest.sagaName = 'watchBreadCrumbNavRequest';

export default watchBreadCrumbNavRequest;
