import config from './Header.config';

export const setNormalizedEmailHash = (existingProfile, normalizedEmail) => {
    const profileValues = {
        profile: {
            ...existingProfile,
            normalizedEmailHash: normalizedEmail,
        },
    };
    !__SERVER__ && Object.assign(window.jcpDLjcp, profileValues);
};

export const getHeaderTheme = (headerType) => {
    let theme = 'fullHeader';
    if (headerType === config.headerTypes?.MINIMAL_HEADER) {
        theme = 'minimalHeader';
    } else if (headerType === config.headerTypes?.SLIM_HEADER) {
        theme = 'slimHeader';
    }
    return theme;
};

export default { setNormalizedEmailHash, getHeaderTheme };
