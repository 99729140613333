import { SHOW_LOADER, SHOW_CUSTOM_LOADER, REMOVE_LOADER } from '../actionTypes/LoadingActionTypes';

function LoadingReducer(state = false, action) {
    switch (action.type) {
        case SHOW_LOADER:
            return true;
        case SHOW_CUSTOM_LOADER:
            return action.payload;
        case REMOVE_LOADER:
            return false;
        default:
            return state;
    }
}

LoadingReducer.reducerName = 'isLoading';
export default LoadingReducer;
