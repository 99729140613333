export const SAVEDITEMS_GET_REQUEST = 'SAVEDITEMS_GET_REQUEST';
export const SAVEDITEMS_GET_SUCCESS = 'SAVEDITEMS_GET_SUCCESS';
export const SAVEDITEMS_GET_ERROR = 'SAVEDITEMS_GET_ERROR';
export const ADD_SAVEDITEMS = 'ADD_SAVEDITEMS';
export const ADD_SAVEDITEMS_SUCCESS = 'ADD_SAVEDITEMS_SUCCESS';
export const ADD_SAVEDITEMS_ERROR = 'ADD_SAVEDITEMS_ERROR';
export const DELETE_SAVEDITEMS = 'DELETE_SAVEDITEMS';
export const DELETE_SAVEDITEMS_SUCCESS = 'DELETE_SAVEDITEMS_SUCCESS';
export const DELETE_SAVEDITEMS_ERROR = 'DELETE_SAVEDITEMS_ERROR';
export const SAVEDITEMS_REMOVE_ITEM_SFL = 'SAVEDITEMS_REMOVE_ITEM_SFL';
export const SAVEDITEMS_ADD_ITEM_SFL = 'SAVEDITEMS_ADD_ITEM_SFL';
export const SAVEDITEMS_RESET_STATUS = 'SAVEDITEMS_RESET_STATUS';
