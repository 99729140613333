import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import isNil from 'lodash/isNil';
import { UPDATE_ITEM_COUNT, RESET_ITEM_COUNT } from '../actionTypes/OrderActionTypes';
import { ITEM_COUNT, DESKTOP_ITEM_COUNT } from '../common/Constants';

const saveCartCount = (count) => {
    Cookies.save(ITEM_COUNT, count, undefined, undefined, false); // Adding additional param to set it to unsecure cookie
};

/**
 * TEMP FIX: For: https://jira.jcpenney.com/browse/MNPDPYODA-2592
 *
 * Order API drops ItemCount cookie. However we found that this cookie
 * was not dropped from desktop. As a work around, we were told to use desktop cookie
 * DP_ORDER_INFO which get drops by ATG, meaning, when exp-com class header is loaded, this cookie is dropped
 * However, this would not fix the actual problem. Still if user stays on Yoda and never visting classic deskto pages
 * the count will never get shown till API fixes the issue.
 *
 * DP_ORDER_INFO: 1|$9.99|$
 */
const getCartCount = (count) => {
    // if we get count from action payload, give that a preference else go look directly for cookie value
    if (!isNil(count)) {
        saveCartCount(count);
        return count;
    }
    const orderAPICartCount = Cookies.load(ITEM_COUNT, true);
    /* istanbul ignore next */
    if (orderAPICartCount) {
        return orderAPICartCount;
    }

    // Fallback
    const desktopLegacyCartCount = Cookies.load(DESKTOP_ITEM_COUNT, true);
    /* istanbul ignore next */
    if (desktopLegacyCartCount) {
        const orderInfo = desktopLegacyCartCount.split('|');
        if (orderInfo && orderInfo.length > 0) {
            try {
                return Number(orderInfo[0]);
            } catch (err) {
                // Do nothing
            }
        }
    }

    return null;
};

const itemCountReducer = function itemCountReducer(state = 0, action) {
    let itemCount = 0;
    switch (action.type) {
        case UPDATE_ITEM_COUNT:
            itemCount = getCartCount(action.payload);
            return itemCount || 0;
        case RESET_ITEM_COUNT:
            saveCartCount(0);
            return 0;
        default:
            return state;
    }
};

itemCountReducer.reducerName = 'bagItemCount';

export default itemCountReducer;
