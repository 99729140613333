import GalleryRerenderAction from '../actionTypes/GalleryRerenderActionTypes';

const galleryRerenderAction = (payload) => ({
    type: GalleryRerenderAction.RERENDER_COMPONENT,
    payload,
});

export default {
    galleryRerenderAction,
};
