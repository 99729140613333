import { takeLatest, put, call, select } from 'redux-saga/effects';
import { getSearchResults, getSearchResultsFromBR } from 'yoda-interfaces/lib/Search/SearchApi';
import _get from 'lodash/get';
import productSearchActionTypes from '../actionTypes/ProductSearchActionTypes';

export const context = (state) => state.context;

function* productSearchSaga(action) {
    try {
        const contextData = yield select(context);
        const productSearchResults = yield call(
            getSearchResults,
            action.params,
            action.regionZone,
            action.version,
            contextData.featureFlags.disableNewAutoSuggestion
        );
        yield put({
            type: productSearchActionTypes.PRODUCTSEARCH_GET_SUCCESS,
            productSearchResults,
        });
    } catch (error) {
        yield put({ type: productSearchActionTypes.PRODUCTSEARCH_GET_ERROR, error });
    }
}

const getDepartment = (suggestion, searchTerm, orignalQuery) => {
    let department = '';
    const suggestionData = suggestion?.attributeSuggestions?.filter((item, index) => index <= 1);
    if (suggestionData?.length > 0) {
        suggestionData?.map((item) => {
            department = [
                ...department,
                {
                    name: item?.value || '',
                    href:
                        `/s?searchType=Predictive+Search&searchTerm=${searchTerm}&item_type=${encodeURIComponent(
                            item.value
                        )?.replaceAll('%20', '+')}&predSearchTerm=${orignalQuery}` || '',
                    products: [],
                },
            ];
        });
    }
    return department;
};

function* getData(productBRSearchResults) {
    if (!productBRSearchResults?.data) {
        return {};
    }
    const contextData = yield select(context);
    const enableBloomReachsubTerm = _get(
        contextData,
        'featureFlags.enableBloomReachsubTerm',
        false
    );
    const data = productBRSearchResults?.data?.suggestionGroups[0];
    return data?.querySuggestions.map((suggestion, index) => {
        const searchTerm = encodeURIComponent(suggestion?.query)?.replaceAll('%20', '+');
        const query = encodeURIComponent(
            productBRSearchResults?.data?.queryContext?.originalQuery?.trim()
        )?.replaceAll('%20', '+');
        return {
            term: suggestion.query,
            url: `/s?searchType=Predictive+Search&searchTerm=${searchTerm}&predSearchTerm=${query}`,
            ...(index === 0 &&
                enableBloomReachsubTerm && {
                    depts: getDepartment(data, searchTerm, query),
                }),
            products: suggestion.products,
        };
    });
}

function* productBRSearchSaga(action) {
    try {
        const productBRSearchResults = yield call(
            getSearchResultsFromBR,
            action.params,
            action.version
        );
        const productSearchResults = yield call(getData, productBRSearchResults);
        yield put({
            type: productSearchActionTypes.PRODUCTSEARCH_GET_SUCCESS_BR,
            productSearchResults,
        });
    } catch (error) {
        yield put({ type: productSearchActionTypes.PRODUCTSEARCH_GET_ERROR_BR, error });
    }
}

const watchProductSearchRequest = function* watchProductSearchRequest() {
    yield takeLatest(productSearchActionTypes.PRODUCTSEARCH_GET_REQUEST_BR, productBRSearchSaga);
    yield takeLatest(productSearchActionTypes.PRODUCTSEARCH_GET_REQUEST, productSearchSaga);
};

watchProductSearchRequest.sagaName = 'productSearchSaga';

export default watchProductSearchRequest;
