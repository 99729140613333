import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { getEcoRebates } from 'yoda-interfaces/lib/EcoRebates/EcoRebatesApi';
import ecoRebatesActionTypes from '../actionTypes/EcoRebatesActionTypes';

function* EcoRebatesSaga(action) {
    try {
        const rebatesInfo = yield call(getEcoRebates, action.payload);
        const sourceMiniPDP = action.payload.sourceMiniPDP || false;
        yield put({
            type: ecoRebatesActionTypes.SITE_ECOREBATES_GET_SUCCESS,
            payload: { ...rebatesInfo.data, sourceMiniPDP },
        });
    } catch (error) {
        yield put({ type: ecoRebatesActionTypes.SITE_ECOREBATES_GET_ERROR, error });
    }
}

function* BundleEcoRebatesSaga(action) {
    try {
        const rebatesInfo = yield call(getEcoRebates, action.payload);
        const ecoRebatesPayload = {
            ppId: action.payload.ppID,
            rebatesInfo: rebatesInfo.data,
            skuId: action.payload.itemsWithRebates,
        };
        yield put({
            type: ecoRebatesActionTypes.BUNDLE_ECOREBATES_GET_SUCCESS,
            payload: ecoRebatesPayload,
        });
    } catch (error) {
        yield put({ type: ecoRebatesActionTypes.BUNDLE_ECOREBATES_GET_ERROR, error });
    }
}

const watchEcoRebatesSaga = function* watchEcoRebatesSaga() {
    yield takeLatest(ecoRebatesActionTypes.SITE_ECOREBATES_GET_REQUEST, EcoRebatesSaga);
    yield takeEvery(ecoRebatesActionTypes.BUNDLE_ECOREBATES_GET_REQUEST, BundleEcoRebatesSaga);
};
watchEcoRebatesSaga.sagaName = 'watchEcoRebatesSaga';

export default watchEcoRebatesSaga;
