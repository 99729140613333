import { RESET_ITEM_COUNT, UPDATE_ITEM_COUNT } from '../actionTypes/OrderActionTypes';

const updateItemCountAction = (payload) => ({
    type: UPDATE_ITEM_COUNT,
    payload,
});

const resetItemCountAction = () => ({
    type: RESET_ITEM_COUNT,
});

export default {
    updateItemCountAction,
    resetItemCountAction,
};
