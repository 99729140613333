import {
    SAVEDITEMS_GET_REQUEST,
    ADD_SAVEDITEMS,
    DELETE_SAVEDITEMS,
    SAVEDITEMS_RESET_STATUS,
} from '../actionTypes/SavedItemActionTypes';

export const getSavedItems = (param) => ({
    type: SAVEDITEMS_GET_REQUEST,
    payload: param,
});

export const addItem = (param) => ({
    type: ADD_SAVEDITEMS,
    payload: param,
});

export const removeItem = (param) => ({
    type: DELETE_SAVEDITEMS,
    payload: { param },
});

export const resetStatus = (ppId) => ({
    type: SAVEDITEMS_RESET_STATUS,
    ppId,
});
