import { COUPONS_GET_SUCCESS } from '../actionTypes/CouponActionTypes';

function CouponsReducer(state = [], action) {
    switch (action.type) {
        default:
            return state;

        case COUPONS_GET_SUCCESS:
            return action.coupons;
    }
}

CouponsReducer.reducerName = 'coupons';
export default CouponsReducer;
