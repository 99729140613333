import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import {
    SET_USER_STORE_LOCATION_INFO,
    LOAD_USER_STORE_LOCATION_INFO_ERROR,
} from '../actionTypes/LocationServiceActionTypes';

import { LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE } from '../common/Constants';

const getInitialStoreValue = () => {
    const userPreferredStore = Cookies.load(LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE);

    if (userPreferredStore) {
        try {
            const userPreferredStoreData = JSON.parse(userPreferredStore);

            return userPreferredStoreData;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Could not parse the userPreferredStore: ', error);
            return {};
        }
    }

    return {};
};

const locationServiceReducer = function locationServiceReducer(
    state = getInitialStoreValue(),
    action
) {
    switch (action.type) {
        case SET_USER_STORE_LOCATION_INFO:
            return {
                ...action.data,
                status: 'success',
            };

        case LOAD_USER_STORE_LOCATION_INFO_ERROR:
            return {
                status: 'fail',
                storeId: null,
            };

        default:
            return state;
    }
};

locationServiceReducer.reducerName = 'locationServiceReducer';

export default locationServiceReducer;
