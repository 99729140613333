import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import YDTIcon from 'yoda-core-components/lib/components/Icon';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Button from 'yoda-core-components/lib/components/Button/Button';
import { headerStore, FIND_STORES_SELECT_A_STORE_HEADER_TEXT } from '../../common/Constants';
import YodaTooltipLazy from '../YodaTooltip/HeaderChunksAndFooterLazy';
import StoreInfo from '../StoreInfo/StoreInfo';
import { triggerNavigationClick } from '../../actions/AnalyticsAction';
import StoreLine from '../../assets/svg/store-icon-filled.svg';
import * as styles from './Header.css';

const cx = classNames.bind(styles);

const renderStoreInfo = (props) => {
    const isFragment = useSelector((state) => state.context?.isFragment || false);
    const preferences = useSelector((state) => state?.context?.preferences || {});

    const dispatch = useDispatch();

    const { storeName = '', city = '', state = '' } = props.userPreferredStore || {};
    const findAStoreLink = _get(
        preferences,
        'headerStoreHoverConfig.findAStoreLink',
        'https://www.jcpenney.com/findastore'
    );

    const renderHoverStoreTooltipData = () => {
        const { isUserLoggedIn, userPreferredStore, triggerStoreSelectionSlider } = props;
        return (
            <StoreInfo
                locationStoreInfo={userPreferredStore}
                isUserLoggedIn={isUserLoggedIn}
                triggerStoreSelectionSlider={triggerStoreSelectionSlider}
            />
        );
    };

    const findAStoreClickHandler = () => {
        dispatch(
            triggerNavigationClick({
                linkName: 'top:findastore',
                isReload: false,
            })
        );
        window.location.href = findAStoreLink;
    };

    return (
        <>
            <YodaTooltipLazy
                tooltipContentClass={cx('storeTooltipContent', 'storeIconWithNameTooltip')}
                tooltipTextClass={cx('storeHoverTooltip', 'new-store-service-block')}
                renderTooltipContents={renderHoverStoreTooltipData()}
                triggerEventName={storeName ? 'mouseover' : ''}
                tooltipBodyClassName={cx('storeContainer')}
                stayOnContentHover
                shouldToggleOnMouseOver
                tooltipType="link"
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                    className={cx('svg-icon', 'ctaList', 'storeBlock')}
                    data-automation-id="header_store_block"
                    onClick={findAStoreClickHandler}
                >
                    <div className={dt(['flex', 'justify-center'])}>
                        <YDTIcon width="28px" height="28px">
                            <StoreLine />
                        </YDTIcon>
                    </div>
                    {/* iconText to be hidden when flag is false in order to capture activity map */}
                    {<p className={`${cx('menuText', 'iconText')} ${dt(['!pl-0'])}`}>Stores</p>}
                </div>
                {
                    /* Displaying store name on header was implemented
                    as a part of enabling User Preffered Store On Header Story */

                    <div className={dt(['pl-2', 'pb-px'])}>
                        <Button
                            size="Sm"
                            type="linkbutton"
                            linkUrl="javascript:void(0)"
                            buttonType="Text"
                            className={cx('storeNameBlock')}
                            onClick={findAStoreClickHandler}
                        >
                            <div
                                className={`${cx('storeNameOnHeader')} ${dt([
                                    'whitespace-nowrap',
                                ])}`}
                            >
                                {storeName || headerStore.NO_STORE_LOCATED}
                            </div>
                            {city || state ? (
                                <span className={cx('storeAddress')}>
                                    {city && `${city}`}
                                    {city && state && `, `}
                                    {state && `${state}`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Button>
                        {!isFragment && isEmpty(storeName) ? (
                            <Button
                                size="Sm"
                                type="linkbutton"
                                linkUrl="javascript:void(0)"
                                buttonType="Link"
                                className={`${cx('storeInformationTxt')} ${dt([
                                    'font-normal',
                                    'bg-white',
                                    'text-xsmall',
                                    'flex',
                                    'leading-4',
                                    'h-4',
                                ])}`}
                                id="selectAStoreBtn"
                                onClick={props.triggerStoreSelectionSlider}
                            >
                                <span className={dt(['text-xsmall'])}>
                                    {FIND_STORES_SELECT_A_STORE_HEADER_TEXT}
                                </span>
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                }
            </YodaTooltipLazy>
        </>
    );
};

export default renderStoreInfo;
