import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import { timeout } from '../../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'ActiveCartReminderChunksLazy',
        path: () => path.join(__dirname, './ActiveCartReminder'),
        resolve: () => require.resolveWeak('./ActiveCartReminder'),
        load: () =>
            Promise.all([
                import(
                    /* webpackChunkName: "ActiveCartReminderChunksLazy" */ './ActiveCartReminder'
                ),
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => null,
        error: (e) => {
            console.log(e);
            return null;
        },
        timeout,
    }
);
