import {
    FETCH_IRIS_S1_TARGETER_SUCCESS,
    FETCH_IRIS_S1_TARGETER_ERROR,
} from '../actionTypes/IRISS1TargeterActionTypes';

const irisS1TargeterReducer = function irisS1TargeterReducer(state = [], action) {
    switch (action.type) {
        case FETCH_IRIS_S1_TARGETER_SUCCESS:
            return action.irisS1TargeterInfo;
        case FETCH_IRIS_S1_TARGETER_ERROR:
            return [];
        default:
            return state;
    }
};

irisS1TargeterReducer.reducerName = 'irisS1TargeterData';

export default irisS1TargeterReducer;
