export const OPT_IN_POST_REQUEST = 'OPT_IN_POST_REQUEST';
export const OPT_IN_POST_SUCCESS = 'OPT_IN_POST_SUCCESS';
export const OPT_IN_POST_ERROR = 'OPT_IN_POST_ERROR';
export const GET_STORES_GET_SUCCESS = 'GET_STORES_GET_SUCCESS';
export const GET_STORES_GET_ERROR = 'GET_STORES_GET_ERROR';
export const CLEAR_STORE = 'CLEAR_STORE';
export const GET_LAT_LONG_GET_REQUEST = 'GET_LAT_LONG_GET_REQUEST';
export const GET_STORE_BY_STORE_ID_REQUEST = 'GET_STORE_BY_STORE_ID_REQUEST';
export const GET_STORE_BY_STORE_ID_SUCCESS = 'GET_STORE_BY_STORE_ID_SUCCESS';
export const GET_STORE_BY_STORE_ID_SUCCESS_CHECKOUT = 'GET_STORE_BY_STORE_ID_SUCCESS_CHECKOUT';
export const UPDATE_ITEM_COUNT = 'UPDATE_ITEM_COUNT';
export const RESET_ITEM_COUNT = 'RESET_ITEM_COUNT';
