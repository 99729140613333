import ecoRebatesActionTypes from '../actionTypes/EcoRebatesActionTypes';

const defaultState = {};

function EcoRebatesReducer(state = defaultState, action) {
    const { payload } = action;
    switch (action.type) {
        case ecoRebatesActionTypes.SITE_ECOREBATES_GET_SUCCESS:
            return payload;
        case ecoRebatesActionTypes.SITE_ECOREBATES_MODAL_OPEN:
            return { ...state, ...payload };
        case ecoRebatesActionTypes.SITE_ECOREBATES_MODAL_CLOSE:
            return { ...state, ...payload };
        case ecoRebatesActionTypes.SITE_ECOREBATES_RESET:
            return defaultState;
        default:
            return state;
    }
}

// EcoRebatesReducer.reducerName = 'rebateInfo';

export default EcoRebatesReducer;
