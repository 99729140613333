import { SAVEDITEMS_GET_SUCCESS } from '../actionTypes/SavedItemActionTypes';

/* istanbul ignore next */

function SavedItemsReducer(state = {}, action) {
    /* istanbul ignore next */
    switch (action.type) {
        case SAVEDITEMS_GET_SUCCESS: {
            const { savedItemsList } = action;
            return savedItemsList;
        }

        default:
            return state;
    }
}

SavedItemsReducer.reducerName = 'savedItems';
export default SavedItemsReducer;
