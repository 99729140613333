import { STICKYTIMER_GET_REQUEST } from '../actionTypes/StickyTimerActionTypes';

const getStickyTimerDataAction = (urlParams) => ({
    type: STICKYTIMER_GET_REQUEST,
    urlParams,
});

export default {
    getStickyTimerDataAction,
};
