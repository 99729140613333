import { takeLatest, put } from 'redux-saga/effects';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import * as AccountActionTypes from '../actionTypes/AccountActionTypes';
import {
    DP_USER_NAME,
    ACCOUNT_ID,
    DP_REWARDS_STATUS,
    DP_USER_STATE,
    DP_REWARDS_CERTS,
    DP_SAVED_ITEMS_COUNT,
} from '../common/Constants';

export function* getUserDetails() {
    const userProfileInfo = {};
    userProfileInfo.firstName = TokenProvider.get(DP_USER_NAME);
    userProfileInfo.accountId = TokenProvider.get(ACCOUNT_ID);
    userProfileInfo.rewardsStatus = TokenProvider.get(DP_REWARDS_STATUS);
    userProfileInfo.userState = TokenProvider.get(DP_USER_STATE);
    userProfileInfo.totalCerts = TokenProvider.get(DP_REWARDS_CERTS);
    userProfileInfo.savedItemsCount = TokenProvider.get(DP_SAVED_ITEMS_COUNT);

    if (!userProfileInfo.savedItemsCount && userProfileInfo.savedItemsCount !== '0') {
        userProfileInfo.savedItemsCount = 0;
        TokenProvider.set(DP_SAVED_ITEMS_COUNT, userProfileInfo.savedItemsCount.toString());
    }

    yield put({ type: AccountActionTypes.GET_SITE_USER_PROFILE_SUCCESS, userProfileInfo });
}

const watchAccountSaga = function* watchAccountSaga() {
    yield takeLatest(AccountActionTypes.GET_SITE_USER_PROFILE, getUserDetails);
};
watchAccountSaga.sagaName = 'watchAccountSaga';

export default watchAccountSaga;
