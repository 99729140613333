import React from 'react';
import Success from 'yoda-core-components/lib/assets/svg/Success.svg';
import Error from 'yoda-core-components/lib/assets/svg/Error.svg';
import Warning from 'yoda-core-components/lib/assets/svg/Warning.svg';
import Information from 'yoda-core-components/lib/assets/svg/Information.svg';

export const messagesLevels = {
    page: 'page',
    section: 'section',
    inline: 'inline',
    popup: 'popup',
};

export const levelUiMapping = {
    page: 'toast',
    section: 'box',
    inline: 'inline',
};

export const messageTypes = {
    success: 'success',
    information: 'information',
    warning: 'warning',
    error: 'error',
};

export const messageTypeIconMapping = {
    success: <Success />,
    information: <Information />,
    warning: <Warning />,
    error: <Error />,
};
