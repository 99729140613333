import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import YDTIcon from 'yoda-core-components/lib/components/Icon';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import { setValue } from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import noop from 'lodash/noop';
import HambBack from '../../assets/svg/backArrow2022.svg';
import HambCloseNew from '../../assets/svg/closeIconNew.svg';
import { getIrisCategories } from '../../actions/HamburgerAction';
import {
    triggerNavigationClick,
    triggerBopisDefaultStoreChange,
    triggerBopisPickupStoreChange,
} from '../../actions/AnalyticsAction';
import { customStepUp, openRewardsPromotionLogin } from '../../actions/SignInSliderActions';
import {
    findStores,
    setFindStoreSliderProps,
    openSlidePanel,
    selectStore,
} from '../../actions/FindStoresAction';
import storeNavigationActions from '../../actions/DepartmentVisualNavigationAction';
import * as styles from './Hamburger.css';
import DropdownDefault from './DropdownDefault';
import RootCategoryTemplate from './RootCategoryTemplate';
import AccordianTemplate from './AccordianLayout';
import FindaStoreSelector from './FindaStoreSelector';
import SelectaStore from './SelectaStore';
import FindAStorePageLazy from '../FindAStorePage/FindAStorePageLazy';
import withReducerSaga from '../../hoc/ReducerRegistry/withReducerSaga';
import IrisHamburgerReducer from '../../reducers/IrisHambergurReducer';
import { hamburgerPreferencesDeptSelector } from '../../selectors/ContextSelector';
import { selectIsAccessibleV2Enabled } from '../../selectors/accessibleSelector';
import StoreInfo from '../StoreInfo/StoreInfo';
import { isDepartmentPage, isMarketingPage } from '../../helpers/Utils/pageType';
import ProductConstants from '../../common/ProductConstants';

const cx = classNames.bind(styles);
const verticalSlideStyles = dt([
    'fixed',
    'sm:z-[999]',
    'top-0',
    'bottom-0',
    'sm:left-[-2000rem]',
    'w-full',
    'h-full',
    'block',
    'sm:pt-0',
]);

export class Hamburger extends Component {
    static propTypes = {
        pageName: PropTypes.string,
        actions: PropTypes.object,
        hambergurMenu: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        deviceType: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        accounts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        selectedStore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        hamburgerZeroLevel: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isDepartmentAPILocalStore: PropTypes.bool,
        hostname: PropTypes.string,
        disableStoreInfoFromHeader: PropTypes.bool,
        enableExternalComponent: PropTypes.bool,
        enableHybridHamburger: PropTypes.bool,
        slideOpen: PropTypes.bool,
        toggleSlideState: PropTypes.func,
        departmentTitle: PropTypes.bool,
        enableNewHamburgerTitle: PropTypes.bool,
        disableGeoLocatedStoreInfo: PropTypes.bool,
        isAccessibleV2Enabled: PropTypes.bool,
        preferences: PropTypes.object,
        disableStoreNameFromHeader: PropTypes.bool,
        isNative: PropTypes.bool,
        isFragment: PropTypes.bool,
        triggerStoreSelectionSlider: PropTypes.func,
        getPreferredStore: PropTypes.func,
        enableHeaderSigninUpdate: PropTypes.bool,
    };

    static defaultProps = {
        pageName: 'HOME',
        actions: {},
        hambergurMenu: {},
        deviceType: {},
        hamburgerZeroLevel: {},
        isDepartmentAPILocalStore: false,
        hostname: '',
        disableStoreInfoFromHeader: false,
        disableStoreNameFromHeader: false,
        enableExternalComponent: false,
        departmentTitle: '',
        enableNewHamburgerTitle: false,
        accounts: {
            userProfile: {
                firstName: null,
                accountId: null,
                rewardsStatus: null,
                userState: '0',
                totalCerts: null,
            },
        },
        selectedStore: {
            isGeoStore: false,
            storeDetails: {
                name: '',
            },
        },
        enableHybridHamburger: false,
        slideOpen: false,
        toggleSlideState: {},
        disableGeoLocatedStoreInfo: false,
        isAccessibleV2Enabled: false,
        preferences: {},
        isNative: false,
        isFragment: false,
        triggerStoreSelectionSlider: noop,
        getPreferredStore: () => {},
        enableHeaderSigninUpdate: false,
    };

    /** *
     * yodaHmenuOpen - to decide menu should be in open/close state when navigating
     * yodaHmenuAllnids - stores all nids, exceptions if the link is XGN
     * yodaHmenuAllItemids - stores all itemids, exceptions if the link is XGN. Not requred for all nids
     * yodaHmenuType - if user clicks the XGN links then current Nid stores with yodaHmenuType
     */

    /* eslint-disable react/sort-comp */
    storage = {
        yodaHmenuOpen: 'yodaHmenuOpen',
        yodaHmenuAllnids: 'yodaHmenuAllnids',
        yodaHmenuAllItemids: 'yodaHmenuAllItemids',
        yodaHmenuType: 'yodaHmenuType',
        yodaHmenuTitle: 'yodaHmenuTitle',
    };

    /** Parent Nid for back button scenario. Gets from Hover API It will fetch it from urlprams initially */
    allNids = [];

    /** Track all nids on traversal from navigation pannel and put it to nid. It will fetch it from urlprams initially */
    currentNid = '';

    /** Track individual item id which user visited */
    allItemIds = [];

    /** On Hover api response take departmentid and store it to currentItemId It will fetch it from currentitem id */
    currentItemId = '';

    /** *
     * It will helps to find the current template
     * level 0:  default
     * level 1: card layout
     * level 2: accordian layout
     */
    templateSelection = 0;

    /** it will be false initially and will be set to true after the first api calls happen */
    firstTimeLoadCompleted = false;

    /** Complete Title for HAMB MENU */
    allTitles = [];

    currentTitle = 'MENU';

    previousTitle = '';

    unmount = null;

    callExpire = null;

    // selected accordian title - stored as yodaHmenuType
    accordianSelect = '';

    // Used to reset accordianSelect - MNPDPYODA-8437
    previousL2Nid = '';

    /* istanbul ignore next */
    static clearSliderLockOnPageLoad() {
        /* istanbul ignore next */
        if (!__SERVER__) {
            if (document.body.classList.contains('scrollLock')) {
                document.body.classList.remove('scrollLock');
            }
        }
    }

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.closeMenu = this.closeMenu.bind(this);
        this.menuClick = this.menuClick.bind(this);
        this.urlChanged = this.urlChanged.bind(this);
        this.analyticsForDefault = this.analyticsForDefault.bind(this);
        this.signOut = this.signOut.bind(this);
        this.catSelectedPushToNid = this.catSelectedPushToNid.bind(this);
        this.goBack = this.goBack.bind(this);
        this.openSlider = this.openSlider.bind(this);
        this.goToPageViewAll = this.goToPageViewAll.bind(this);
        this.resetOnTimer = this.resetOnTimer.bind(this);
        this.handleBrowserBack = this.handleBrowserBack.bind(this);

        this.state = {
            currentMenuItems: null,
            loader: false,
            fullLoader: false,
        };
    }

    componentWillMount() {
        const { actions, isNative } = this.props;
        if (isNative) {
            const param = { pageType: 'HOME' };
            actions?.getIrisGlobalNavigationAction(param);
            actions?.getIrisCategories(param);
        }
    }

    componentDidMount() {
        /** If your page is not falls under /g patern then reset the menu * */
        /* istanbul ignore next */
        if (!this.findNidFromUrl(location?.href)) {
            this?.resetMenu();
        }
        Hamburger.clearSliderLockOnPageLoad();
        this.urlChanged();
        /* istanbul ignore next */
        this.unmount = getBrowserHistory().listen(() => this.urlChanged());
        window.addEventListener('popstate', this.handleBrowserBack);
    }

    componentWillUnmount() {
        this?.unmount();
        window.removeEventListener('popstate', this.handleBrowserBack);
    }

    handleBrowserBack() {
        this.closeMenu();
        this?.removeCommonStorageState();
    }

    urlChanged() {
        this?.urlChangedPreserveStorage();
        this?.removeCommonStorageState();
    }

    urlChangedPreserveStorage() {
        this.currentNid = '';
        this.currentTitle = 'MENU';
        this.currentItemId = '';
        this.allNids = [];
        this.allTitles = [];
        this.allItemIds = [];
        this?.findNidsWithItemIds();
        this?.findCurrentNidandItemId();
        this?.callHoverorRootApi(); // this will move to onclick from zerothh level
        this?.hambOpenorClose(); // for tablet
    }

    /** For tablet. Will help to decide the satte of Menu (close/ Open) */
    hambOpenorClose() {
        const {
            storage: { yodaHmenuOpen },
            props: { slideOpen, hostname },
            toggleMenu,
        } = this;
        if (LocalStorage.getData(yodaHmenuOpen, true) && !slideOpen) {
            toggleMenu();
            LocalStorage.removeData(yodaHmenuOpen, true, hostname);
        }
    }

    /** Function will check thhe localstorage is available if yes then pull the data from localstorage or else take it from url params */
    findNidsWithItemIds() {
        const {
            yodaHmenuAllnids,
            yodaHmenuAllItemids,
            yodaHmenuTitle,
            yodaHmenuType,
        } = this.storage;
        const localNids = JSON.parse(LocalStorage.getData(yodaHmenuAllnids, true));
        const localItemIds = JSON.parse(LocalStorage.getData(yodaHmenuAllItemids, true));
        const localTitle = JSON.parse(LocalStorage.getData(yodaHmenuTitle, true));
        let isNid = false;
        /* istanbul ignore next */
        if (localNids) {
            this.allNids = localNids;
            this.allItemIds = localItemIds || [];
            this.allTitles = localTitle || [];
            this.accordianSelect = LocalStorage.getData(yodaHmenuType, true);
            isNid = true;
        }

        return isNid;
    }

    /** this logic will be irrelevent for mobile only scenario but ignoring it to keep consistancy between tablet and mobile */
    findCurrentNidandItemId() {
        this.currentNid = this.allNids.pop() || '';
        this.currentItemId = this.allItemIds.pop();
        this.currentTitle = this.allTitles.pop() || 'MENU';
        this.previousMenuTitle();
    }

    getHamburgerParams() {
        return {
            pageType: this?.props?.pageName,
        };
    }

    /** if we have nid present then load hover api or else load category api */
    callHoverorRootApi() {
        const {
            currentNid,
            props: { actions, hamburgerZeroLevel },
        } = this;
        this.setState({ currentMenuItems: null });

        if (
            currentNid &&
            currentNid !== 'base' &&
            currentNid !== 'root' &&
            currentNid !== 'findastore' &&
            currentNid !== 'selectstore' &&
            currentNid !== 'storeinfo'
        ) {
            this.templateSelection = 2;
            // MNPDPYODA-8437 - Brands category is expended by default without clicking on brands from Level 2
            /* istanbul ignore next */
            if (this.previousL2Nid && this.previousL2Nid !== currentNid) {
                this.accordianSelect = '';
            }
            this.previousL2Nid = currentNid;
            this?.setStateBasedOnCurrentSelection();
            this.setState({ loader: false });
            this?.timerOnandReset();
        } else if (currentNid === 'root') {
            this.templateSelection = 1;
            const hamburgerParams = this.getHamburgerParams();
            actions?.getIrisCategories(hamburgerParams);
            clearTimeout(this.callExpire);
            this.setState({ loader: true });
            this.timerOnandReset();
            this?.removeCommonStorageState();
        } else if (currentNid === 'findastore') {
            this.templateSelection = 3;
            this?.removeCommonStorageState();
        } else if (currentNid === 'selectstore') {
            this.templateSelection = 4;
            this?.removeCommonStorageState();
        } else if (currentNid === 'storeinfo') {
            this.templateSelection = 5;
            this?.removeCommonStorageState();
        } else {
            this.templateSelection = 0;
            this.setState({ currentMenuItems: hamburgerZeroLevel });
        }
    }

    setStateBasedOnCurrentSelection() {
        const {
            currentNid,
            props: { hambergurMenu, actions },
            getHamburgerParams,
        } = this;
        /* istanbul ignore next */
        if (isEmpty(hambergurMenu) || !currentNid) {
            const hamburgerParams = getHamburgerParams();
            actions?.getIrisCategories(hamburgerParams);
        } else {
            this.setState({
                loader: false,
                currentMenuItems: hambergurMenu[currentNid],
            });
        }
    }

    /** It will close the loader and reset the menu to zero level if any api failed or any issue in the hamburger respose. */
    timerOnandReset() {
        /* istanbul ignore next */
        this.callExpire = setTimeout(this.resetOnTimer, 10000, this);
    }

    resetOnTimer() {
        if (this?.state?.loader) {
            console.warn(
                'Hamburger:: something wrong! check api response. Defaulting the menu level to zero'
            );
            this.goBack();
            this.setState({ loader: false });
        }
    }

    /** close the loader and set the props as state so that it will rerender and having control */
    componentWillReceiveProps(nextPropsDefault) {
        const nextProps = nextPropsDefault?.hambergurMenu?.menu
            ? nextPropsDefault?.hambergurMenu?.menu
            : nextPropsDefault?.hambergurMenu;
        /* istanbul ignore next */
        if (
            this?.currentNid &&
            this.currentNid !== 'base' &&
            this.currentNid !== 'root' &&
            this.currentNid !== 'findastore' &&
            this.currentNid !== 'selectstore'
        ) {
            this.setState({ loader: false, currentMenuItems: nextProps[this?.currentNid] });
        } else {
            this.setState({ loader: false, currentMenuItems: nextProps });
        }
    }

    signOut() {
        this.analyticsForDefault('signout');
        /* clear DPOrder and AccountID to avoid accountrelated call from header in sessionTimoutpage */
        setValue('ACCOUNT_ID', '', true);
        Cookies.remove('DPOrder');
        return '/sessiontimeout'; // redirect through Link component
    }

    /** It will helps to select the layout based on the layout settings done before API calls happen * */
    pickLayout(currentItem) {
        const {
            props: {
                accounts,
                departmentTitle,
                deviceType,
                disableGeoLocatedStoreInfo,
                disableStoreInfoFromHeader,
                disableStoreNameFromHeader,
                enableExternalComponent,
                enableHybridHamburger,
                hambergurMenu,
                hamburgerZeroLevel,
                hostname,
                isAccessibleV2Enabled,
                isNative,
                preferences,
                selectedStore,
                isFragment,
                triggerStoreSelectionSlider,
                getPreferredStore,
                enableHeaderSigninUpdate,
            },
            state: { loader },
        } = this;

        const locationServiceStoreInfo = getPreferredStore();
        const { userProfile = '' } = accounts || {};
        const { meta = '' } = hambergurMenu || {};
        let layoutSelected = null;

        if (currentItem && this?.templateSelection === 0) {
            layoutSelected = DropdownDefault(
                hamburgerZeroLevel,
                this.catSelectedPushToNid,
                userProfile,
                selectedStore,
                this.signOut,
                this.analyticsForDefault,
                enableHybridHamburger,
                hambergurMenu,
                deviceType,
                loader,
                disableStoreInfoFromHeader,
                enableExternalComponent,
                this.openSlider,
                departmentTitle,
                disableGeoLocatedStoreInfo,
                disableStoreNameFromHeader,
                isAccessibleV2Enabled,
                preferences,
                this.closeMenu,
                isNative,
                locationServiceStoreInfo,
                isFragment,
                triggerStoreSelectionSlider,
                enableHeaderSigninUpdate
            );
        } else if (currentItem && this?.templateSelection === 1) {
            LocalStorage.removeData(this?.storage?.yodaHmenuType, true, hostname);
            layoutSelected = RootCategoryTemplate(
                currentItem,
                this.catSelectedPushToNid,
                deviceType,
                loader,
                false,
                '',
                preferences
            );
        } else if (currentItem && this?.templateSelection === 2) {
            layoutSelected = AccordianTemplate(
                currentItem,
                this.catSelectedPushToNid,
                meta,
                this.goToPageViewAll,
                loader,
                this.accordianSelect
            );
        } else if (this?.templateSelection === 3) {
            layoutSelected = FindaStoreSelector(
                userProfile,
                selectedStore,
                this.catSelectedPushToNid,
                this.analyticsForDefault
            );
        } else if (this?.templateSelection === 4) {
            layoutSelected = SelectaStore(selectedStore);
        } else if (this?.templateSelection === 5) {
            const isUserLoggedIn = userProfile?.userState !== '0';
            layoutSelected = (
                <StoreInfo
                    locationStoreInfo={getPreferredStore()}
                    isUserLoggedIn={isUserLoggedIn}
                    triggerStoreSelectionSlider={triggerStoreSelectionSlider}
                    inHamburger
                />
            );
        }
        return layoutSelected;
    }

    goToPageViewAll(targUrl) {
        const {
            props: { hostname },
            storage: { yodaHmenuOpen, yodaHmenuType },
            currentNid,
            currentItemId,
            currentTitle,
        } = this;
        this.allNids.push(currentNid);
        this.allItemIds.push(currentItemId);
        this.allTitles.push(currentTitle);
        this?.analytics();
        LocalStorage.setData(yodaHmenuOpen, true, true, hostname);
        LocalStorage.setData(yodaHmenuType, currentNid, true, hostname);
        this?.navigationalDefaultValues();
        LocalStorage.removeData(yodaHmenuOpen, true, hostname);
        return targUrl; // redirect through Link component
    }

    /** menu show and hide */
    toggleMenu = () => {
        const { slideOpen, isDepartmentAPILocalStore, actions, toggleSlideState } = this.props;
        /* istanbul ignore next */
        if (!slideOpen && isDepartmentAPILocalStore) {
            const hamburgerParams = this?.getHamburgerParams();
            actions?.getIrisCategories(hamburgerParams);
        }
        toggleSlideState(!slideOpen);
    };

    /** changing the state of menu to close. copied from old yoda Hamburger */
    closeMenu() {
        const {
            props: { toggleSlideState, hostname },
            storage: { yodaHmenuOpen },
        } = this;
        toggleSlideState(false);
        LocalStorage.removeData(yodaHmenuOpen, true, hostname);
    }

    analytics(currentTitle, itemCLicked) {
        const {
            allTitles,
            props: { actions, isFragment, requestUrl },
        } = this;
        let localTitle = '';
        const reload = false;
        if (allTitles?.length) {
            allTitles?.forEach((oneTitle) => {
                if (oneTitle?.trim() !== 'MENU') {
                    localTitle = localTitle ? `${localTitle}:${oneTitle}` : oneTitle;
                }
            });
            if (currentTitle?.trim() !== 'MENU') {
                localTitle = localTitle
                    ? currentTitle === undefined
                        ? localTitle
                        : `${localTitle}:${currentTitle}`
                    : currentTitle;
            } else if (itemCLicked?.trim() !== 'MENU') {
                localTitle = localTitle
                    ? itemCLicked === undefined
                        ? localTitle
                        : `${localTitle}:${itemCLicked}`
                    : itemCLicked;
            }
        }
        // fix for analytics defect - MNPDPYODA-4767
        const eventLinkName = localTitle ? `top:${localTitle}` : `top:${itemCLicked}`;
        this.setAnalyticsFragmentC23(isFragment, requestUrl, eventLinkName);
        actions?.triggerNavigationClick({
            linkName: eventLinkName,
            isReload: reload,
        });
    }

    analyticsForDefault(localTitle) {
        const { actions, isFragment, requestUrl } = this.props;
        this.setAnalyticsFragmentC23(isFragment, requestUrl, localTitle);
        actions?.triggerNavigationClick({
            linkName: localTitle,
            isReload: false,
        });
    }

    setAnalyticsFragmentC23(isFragment, requestUrl, localTitle) {
        if (isFragment && requestUrl === '/v1/fragment/aggregate/shopDepartments') {
        }
        Cookies.save(ProductConstants.C23, localTitle);
    }

    findNidFromUrl(url) {
        this.isGalleryPageNotSearch = url?.indexOf('/g/') >= 0;
        this.shopDepartMenuUrl = url?.indexOf('/v1/fragment/aggregate/shopDepartments/') >= 0;
        const stayContext =
            url &&
            (this.isGalleryPageNotSearch ||
                isMarketingPage(url) ||
                isDepartmentPage(url) ||
                this.shopDepartMenuUrl);

        return stayContext;
    }

    resetMenu() {
        this.currentNid = '';
        this.accordianSelect = '';
        this.currentTitle = 'MENU';
        this.currentItemId = '';
        this.allNids = [];
        this.allTitles = [];
        this.allItemIds = [];
        this.resetMenuMobile();
    }

    resetMenuMobile() {
        this?.removeCommonStorageState();
    }

    /** nids and item ids will be appended once user click on an item. */
    catSelectedPushToNid(ev) {
        const {
            props: {
                enableExternalComponent,
                disableStoreInfoFromHeader,
                selectedStore: { storeDetails = {} },
            },
            state: { currentMenuItems },
        } = this;
        const e = ev?.target?.closest('button')?.dataset || ev;
        let newNid = e?.nid;
        /* istanbul ignore next */
        if (enableExternalComponent && disableStoreInfoFromHeader && e?.nid === 'findastore') {
            this?.analyticsForDefault('top:findastore');
            return '/findastore'; // redirect through Link component to /findastore
        }
        /* istanbul ignore next */
        if (e?.automationId === 'hamb-storeinfo-btn') newNid = 'storeinfo';

        if (e?.nid === 'findastore' && (!storeDetails || !storeDetails?.name)) {
            this?.analyticsForDefault('top:findastore');
            return '/findastore'; // redirect through Link component to /findastore
        }
        if (newNid && e?.haschild !== 'false') {
            if (
                this?.currentNid &&
                !(this?.currentNid === newNid && this?.currentItemId === e?.itemid)
            ) {
                this.allNids.push(this?.currentNid);
                this.allItemIds.push(this?.currentItemId);
                this.allTitles.push(this?.currentTitle);
            } else if (this?.templateSelection === 0) {
                this.allNids.push('base');
                this.allItemIds.push('base');
                this.allTitles.push(this?.currentTitle);
            }
            this.currentNid = newNid;
            this.currentItemId = e?.itemid;
            this.currentTitle = e?.name;
            this.previousMenuTitle();
            if (!e?.haschild) {
                this?.analyticsForDefault(`top:${currentMenuItems?.linkText}:${e?.name}`);
            }
            return this?.goToPage(e);
        }
        /** If the url does not contain /g then it is not been handled. remove all the cookie and go to that page. This will happen if url has direct pdp page links or external links */
        this.setState({ fullLoader: true });
        this?.resetMenu();
        this?.analytics(this.currentTitle, e.name);
        /* istanbul ignore next */
        const openInNewTab = e?.opennewtab ? JSON.parse(e?.opennewtab) : false;
        /* istanbul ignore next */
        if (e?.targetdurl) {
            return {
                url: e?.targetdurl, // redirect through <Link />
                openInNewTab,
            };
        }
        return false;
    }

    /* istanbul ignore next */
    navigationalDefaultValues() {
        const {
            props: { hostname },
            storage: { yodaHmenuAllnids, yodaHmenuAllItemids, yodaHmenuOpen, yodaHmenuTitle },
            allNids,
            allItemIds,
            allTitles,
        } = this;
        LocalStorage.setData(yodaHmenuAllnids, allNids, true, hostname);
        LocalStorage.setData(yodaHmenuAllItemids, allItemIds, true, hostname);
        LocalStorage.setData(yodaHmenuOpen, true, true, hostname);
        LocalStorage.setData(yodaHmenuTitle, allTitles, true, hostname);
    }

    /**
     * Mobile : set the current nid and item id and call the service if Nid is not diffrent
     * Tablet:  construct localstorage/ url.
     */
    goToPage(e = { pagetype: 'root' }) {
        const {
            props: { hostname },
            allNids,
            storage: { yodaHmenuType, yodaHmenuOpen },
        } = this;
        this?.resetMenuMobile();
        const needToFech = this?.isNidChanged();
        /** It is also checking the length because it is a requirement that we dont levels beyond 4. */
        if (e?.pagetype === 'XGN' || allNids?.length === 4) {
            LocalStorage.setData(yodaHmenuType, this.currentNid, true, hostname);
            this.navigationalDefaultValues();
            LocalStorage.removeData(yodaHmenuOpen, true, hostname); // this is to override hamb open if you are in gallery page
            /* istanbul ignore next */
            const openInNewTab = e?.opennewtab ? JSON.parse(e.opennewtab) : false;
            /* istanbul ignore next */
            if (e?.targetdurl) {
                this.urlChangedPreserveStorage();
                return {
                    url: e?.targetdurl, // redirect through <Link />
                    openInNewTab,
                };
            }
            return false; // tell Link component not to redirect
        }
        if (needToFech) {
            this?.callHoverorRootApi();
        }
        return false; // tell Link component not to redirect
    }

    /** this is to check for any NID change. If no change thhen no need to call the apicall only after push or pop operation has done. */
    isNidChanged() {
        const { allNids, currentNid } = this;
        let needFetch = false;
        if (
            (currentNid && allNids?.length <= 1) ||
            (currentNid && allNids?.length > 1 && currentNid !== allNids[allNids?.length - 2])
        ) {
            needFetch = true;
        }
        return needFetch;
    }

    previousMenuTitle() {
        this.previousTitle = this?.allTitles?.length
            ? this?.allTitles[this?.allTitles?.length - 1]
            : 'MENU';
    }

    /** Sign In Slider Implementation for Lefr Hamburger Menu */
    openSlider(nextUrl) {
        const { isFragment, actions, enableRewardsPromoLogin } = this.props;
        if (isFragment) {
            return true;
        }
        const customizeConfig = {
            skipOnAuthenticationSuccess: true,
            isHideAccountMenu: true,
            postAuthenticationUrl: nextUrl,
        };
        enableRewardsPromoLogin
            ? actions?.openRewardsPromotionLogin()
            : actions?.customStepUp(customizeConfig);
    }

    /** Back button Implementation. */
    goBack() {
        const {
            props: { hostname, enableHybridHamburger },
            storage: { yodaHmenuType },
            currentNid,
            allNids,
            allItemIds,
            allTitles,
        } = this;

        if (currentNid !== 'root' && currentNid !== 'base') {
            LocalStorage.setData(yodaHmenuType, currentNid, true, hostname);
        }
        // MNPDPYODA-6840
        // for hybrid menu, when going back from templateSelection === 2 to templateSelection === 0, need to do some resetting for templateSelection === 1
        /* istanbul ignore next */
        if (enableHybridHamburger) {
            LocalStorage.removeData(yodaHmenuType, true, hostname);
        }
        this.currentNid = allNids.pop();
        this.currentItemId = allItemIds.pop();
        this.currentTitle = allTitles.pop();
        this?.previousMenuTitle();
        this?.removeCommonStorageState();
        this?.goToPage();
    }

    removeCommonStorageState() {
        const {
            props: { hostname },
            storage: {
                yodaHmenuAllnids,
                yodaHmenuAllItemids,
                yodaHmenuOpen,
                yodaHmenuTitle,
                yodaHmenuType,
            },
        } = this;
        LocalStorage.removeData(yodaHmenuAllnids, true, hostname);
        LocalStorage.removeData(yodaHmenuAllItemids, true, hostname);
        LocalStorage.removeData(yodaHmenuOpen, true, hostname);
        LocalStorage.removeData(yodaHmenuTitle, true, hostname);
        LocalStorage.removeData(yodaHmenuType, true, hostname);
    }

    /** Menu Open, copied from old yoda Hamburger */
    menuClick = (ev) => {
        this.menuOpen = true;
        ev?.stopPropagation();

        /* istanbul ignore next */
        if (this?.templateSelection === 3) {
            FindAStorePageLazy.preload();
        }
    };

    loadIconandLoader(type) {
        let ico = '';
        /* istanbul ignore next */
        if (this?.state?.loader) {
            ico = <div className={cx('loadingCircle')} />;
        } else if (type === 1) {
            ico = (
                <YDTIcon className={cx('hambBack')} width="28px" height="28px">
                    <HambBack />
                </YDTIcon>
            );
        } else if (type === 2) {
            ico = (
                <YDTIcon className={cx('hambBack')} width="32px" height="32px">
                    <HambCloseNew />
                </YDTIcon>
            );
        }

        return ico;
    }

    /** It will help to decide which icon to show up (Close/ back button) */
    closeorBackButton() {
        if (__SERVER__) return '';
        const {
            accounts: { userProfile },
            enableNewHamburgerTitle,
            isNative,
        } = this.props;
        let prevTitle = this?.previousTitle;
        let curTitle = this?.currentTitle;
        /* istanbul ignore next */
        if (enableNewHamburgerTitle) {
            prevTitle = 'Back';
            const firstName = userProfile?.firstName; // for both warm and hot state, user firstname will be available. for cold state user data wont be available
            curTitle = firstName ? `Hi, ${firstName}` : 'Welcome';
        }

        const closeIcon = (
            <button type="button" onClick={this.closeMenu} data-automation-id="slider-button-close">
                <i
                    data-automation-id="hamburger-menu-close-icon"
                    className={dt(['sm:content-[""]', 'absolute', 'right-5', 'top-2'])}
                >
                    {this.loadIconandLoader(2)}
                </i>
            </button>
        );

        if (this?.allNids?.length) {
            return (
                <div className={cx('titleBar')}>
                    <button
                        type="button"
                        onClick={this.goBack}
                        data-automation-id="slider-button-back"
                    >
                        <i
                            data-automation-id="hamburger-menu-back-icon"
                            className={dt([
                                'content-[""]',
                                'absolute',
                                'text-black',
                                'left-5',
                                'sm:top-2.5',
                            ])}
                        >
                            {this.loadIconandLoader(1)}
                        </i>
                        <span
                            data-automation-id="hamburger-menu-back"
                            className={dt([
                                'pl-9',
                                'capitalize',
                                'font-open-sans-semibold',
                                'text-xLg',
                                'font-open-sans',
                                'leading-6',
                            ])}
                        >
                            {prevTitle}
                        </span>
                    </button>
                    {!isNative && closeIcon}
                </div>
            );
        }

        return (
            <div className={cx('titleBar', 'l1Menu')}>
                <span
                    data-automation-id="hamburger-menu-title"
                    className={dt([
                        'font-bold',
                        'text-xLg',
                        'leading-6',
                        'tracking-[-0.5px]',
                        'whitespace-nowrap',
                        'overflow-hidden',
                        'text-ellipsis',
                        'w-11/12',
                        'float-left',
                    ])}
                >
                    {curTitle}
                </span>
                {closeIcon}
            </div>
        );
    }

    render() {
        const {
            props: { slideOpen, enableHybridHamburger },
            state: { fullLoader, currentMenuItems },
        } = this;

        const slideToggle = slideOpen
            ? `${verticalSlideStyles} ${cx('verticalSlide', 'visible')} ${dt(['sm:left-0'])}`
            : `${verticalSlideStyles} ${cx('verticalSlide')}`;
        const wrapperClass = `${dt([
            'sm:w-full',
            'md:w-[320px]',
            'h-full',
            'shadow-md',
            'block',
            'bg-white',
            'text-black',
        ])} ${cx('menuWrapper')}`;

        if (fullLoader) {
            return <Loader keepOverlay automationId="test-automation-loader-1" />;
        }

        return (
            <div
                data-automation-id="hamburgerBlockLink"
                tabIndex="-1"
                className={cx(slideToggle, 'newHeaderHamburger')}
                onClick={this.closeMenu}
            >
                {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                {slideOpen ? (
                    <div
                        tabIndex="-1"
                        className={wrapperClass}
                        onClick={this.menuClick}
                        id="menuWrapper"
                    >
                        {this?.templateSelection !== 0 ? (
                            <div
                                data-automation-id="slider-button-close"
                                id="slider-button-close"
                                className={`${dt([
                                    'block',
                                    'h-12',
                                    'w-full',
                                    'bg-white',
                                    'borderBottomSolid',
                                    'text-black',
                                    'px-6',
                                    'py-3',
                                    'box-border',
                                    'relative',
                                    'text-left',
                                ])} ${cx('menuWrapperClose', {
                                    hybridHamburger: enableHybridHamburger,
                                })}`}
                            >
                                {this.closeorBackButton()}
                            </div>
                        ) : null}
                        <div
                            className={dt(['bg-white', 'h-full'])}
                            data-automation-id="slider-data"
                            id="subMenuLevel1"
                        >
                            {/* Dropdown menu section */}
                            {this.pickLayout(currentMenuItems)}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIrisCategories,
            triggerNavigationClick,
            customStepUp,
            openRewardsPromotionLogin,
            ...storeNavigationActions,
            triggerBopisDefaultStoreChange,
            triggerBopisPickupStoreChange,
            findStores,
            setFindStoreSliderProps,
            openSlidePanel,
            selectStore,
        },
        dispatch
    ),
});

const mapStateToProps = (state) => {
    const {
        context: {
            featureFlags: {
                departmentTitle = false,
                disableGeoLocatedStoreInfo = false,
                disableStoreInfoFromHeader = false,
                disableStoreNameFromHeader = false,
                enableExternalComponent = false,
                enableHybridHamburger = false,
                enableNewHamburgerTitle = false,
                isDepartmentAPILocalStore = false,
                enableRewardsPromoLogin = false,
                enableHeaderSigninUpdate = false,
            } = {},
            preferences: { MarketingUrlChange = {}, defaultRadius = 25 },
            isFragment = false,
            deviceType = { isTablet: true },
            hostname = undefined,
            requestUrl = '',
        },
        irishambergurMenu = {},
        accounts = {
            userProfile: {
                firstName: null,
                accountId: null,
                rewardsStatus: null,
                userState: '0',
                totalCerts: null,
            },
        },
        selectedStore = {
            isGeoStore: false,
            storeDetails: {
                name: '',
            },
        },
    } = state;
    return {
        accounts,
        departmentTitle,
        deviceType,
        disableStoreInfoFromHeader,
        disableGeoLocatedStoreInfo,
        disableStoreNameFromHeader,
        enableHybridHamburger,
        enableNewHamburgerTitle,
        enableExternalComponent,
        enableRewardsPromoLogin,
        enableHeaderSigninUpdate,
        hambergurMenu: irishambergurMenu,
        hamburgerZeroLevel: hamburgerPreferencesDeptSelector(state),
        hostname,
        isDepartmentAPILocalStore,
        isAccessibleV2Enabled: selectIsAccessibleV2Enabled(state),
        isFragment,
        preferences: { MarketingUrlChange, defaultRadius },
        selectedStore,
        requestUrl,
    };
};

export default compose(
    withReducerSaga([IrisHamburgerReducer], []),
    connect(mapStateToProps, mapDispatchToProps)
)(Hamburger);
