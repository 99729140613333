import {
    OPT_IN_POST_REQUEST,
    GET_STORE_BY_STORE_ID_REQUEST,
    CLEAR_STORE,
} from '../actionTypes/OrderActionTypes';
import { GET_LAT_LONG_GET_REQUEST } from '../actionTypes/VendorActionTypes';

const getLatLong = (payload) => ({
    type: GET_LAT_LONG_GET_REQUEST,
    payload,
});

const optin = (payload) => ({
    type: OPT_IN_POST_REQUEST,
    payload,
});

/* istanbul ignore next */
const getStoreByStoreId = (payload) => ({
    type: GET_STORE_BY_STORE_ID_REQUEST,
    payload,
});
/* istanbul ignore next */
const clearStore = (payload) => ({
    type: CLEAR_STORE,
    payload,
});

export default {
    getLatLong,
    optin,
    clearStore,
    getStoreByStoreId,
};
