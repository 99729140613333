import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Button from 'yoda-core-components/lib/components/Button/Button';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import List from 'yoda-core-components/lib/components/List/List';
import StoreFastTruck from 'yoda-core-components/lib/assets/svg/StoreFastTruck.svg';
import configForList from 'yoda-core-components/lib/components/List/List/List.config';
import config from './StoreInfo.config';
import DateFormatter from '../../dates/DateFormatter/DateFormatter';
import { STORE_SERVICES, JCP_SALON_SIGNED, SALON_SYNDICATE_URL } from '../../common/Constants';
import { triggerNavigationClick } from '../../actions/AnalyticsAction';
import WeeklyAdsAction from '../../actions/WeeklyAdsAction';
import * as styles from './StoreInfo.css';
import {
    selectAkamaiGeoCookie,
    selectHeaderStoreHoverConfig,
    selectIsFragment,
    selectReqHeaders,
    selectHostName,
} from '../../selectors/ContextSelector';
import { getCookieOrLocalStorageData } from '../../helpers/Utils/utils';

const cx = classNames.bind(styles);

const StoreInfo = (props) => {
    const { locationStoreInfo, triggerStoreSelectionSlider, isUserLoggedIn, inHamburger } = props;

    const {
        storeName,
        street,
        city,
        state,
        zipcode,
        phone,
        timings,
        timingsOverrideMessage,
        storeId,
        services,
    } = locationStoreInfo;
    const reqHeaders = useSelector(selectReqHeaders);
    const akGeoCookieKey = useSelector(selectAkamaiGeoCookie);
    const akGeoCookie = !_isEmpty(getCookieOrLocalStorageData(reqHeaders, akGeoCookieKey)) || false;
    const dispatch = useDispatch();
    const headerStoreHoverConfig = useSelector(selectHeaderStoreHoverConfig);
    const isFragment = useSelector(selectIsFragment);
    const hostName = useSelector(selectHostName);
    const weeklyAdsMessages = useSelector((store) => store?.weeklyAdsMessages || []);
    useEffect(() => {
        /* istanbul ignore else */
        if (storeId || akGeoCookie) {
            // default store code/id for weekly ads to fetch referred from Marketing page which is Manhattan Mall (store id = 0174)
            const DEFAULT_WEEKLY_ADS_STORE_CODE = '0174';
            const weeklyAdsStoreCode = {
                storeCode: storeId || DEFAULT_WEEKLY_ADS_STORE_CODE,
            };
            dispatch(WeeklyAdsAction?.getWeeklyAdDetails(weeklyAdsStoreCode));
        }
    }, [storeId, akGeoCookie]);

    const sectionTitleStyles = dt(['text-black', 'text-left', 'font-open-sans-bold', 'text-base']);

    const {
        weeklyAdsDefaultImg,
        sameDayPickupLink,
        displaySDPU,
        storeInfo,
        viewMoreAdsLink,
    } = headerStoreHoverConfig;

    /* istanbul ignore next */
    const analyticsForNavigationClick = (localTitle) => {
        dispatch(
            triggerNavigationClick({
                linkName: localTitle,
                isReload: false,
            })
        );
    };

    /* istanbul ignore next */
    const weeklyAdClickHandler = () => analyticsForNavigationClick('top:weekly ad');
    /* istanbul ignore next */
    const storeServicesClickHandler = () => analyticsForNavigationClick('top:store service');

    // below method formates day & time from the timing object from API
    const storeTimingsRenderer = (timing) => {
        const storeTime = timing?.time ? timing.time : '';
        const storeOpenDay = timing?.days ? timing.days : '';

        let time = storeTime.replace(/:00/g, '');
        time = time.replace(/-/g, ' - ');

        const days = storeOpenDay.replace(/-/g, ' - ');
        return `${days}: ${time}`;
    };

    const linkStyles = `${dt(['text-sm', 'sm:leading-[1.43]', 'text-black'])} ${cx(
        'storeLinkBtn'
    )}`;

    const selectStoreInfo = () => {
        const storeDetailInfoWrapStyles = `${cx('storeDetailInfoWrap')} ${
            inHamburger ? dt(['bg-light-white', 'px-6']) : dt(['px-0'])
        } ${dt(['pb-4', 'pt-4'])}`;

        const storeNameStyles = `${cx('storeName')} ${sectionTitleStyles} ${dt([
            'w-3/4',
            'inline-block',
            'sm:pr-[5px]',
            'leading-5',
        ])}`;

        const storeAddressInfoStyles = dt([
            'text-black',
            'block',
            'text-left',
            'font-open-sans-regular',
            'text-small',
        ]);
        const storePhoneStyles = `${cx('storePhone')} ${storeAddressInfoStyles} ${dt(['pb-4'])}`;
        const storeTimesWrapperStyles = dt([
            'text-left',
            'font-open-sans-regular',
            'text-sm',
            'leading-normal',
        ]);
        const timingsOverrideMessageStyles = dt([
            'text-left',
            'my-1',
            'text-gray-70',
            'ont-open-sans-normal',
            'text-sm',
            'leading-normal',
        ]);
        const storeCloseInfoStyles = dt([
            'font-open-sans-regular',
            'text-xs',
            'text-black',
            'pt-2',
            'pr-5',
            'pb-2.5',
            'pl-2',
            'inline-block',
        ]);
        const sdpuInfoWrapStyles = dt(['text-black', 'pl-6', 'font-open-sans-regular', 'text-sm']);

        return (
            <article className={storeDetailInfoWrapStyles}>
                <div className={`${cx('storeDetails')} ${dt(['sm:mb-2', 'text-left'])}`}>
                    {storeName && <p className={storeNameStyles}>{storeName}</p>}
                    <div
                        className={`${cx('findAStoreLinkWrap')} ${dt([
                            'w-1/4',
                            'float-right',
                            'text-right',
                        ])}`}
                    >
                        {!isFragment && (
                            <Button
                                size="Sm"
                                type="linkbutton"
                                linkUrl="javascript:void(0)"
                                buttonType="Link"
                                className={linkStyles}
                                onClick={triggerStoreSelectionSlider}
                            >
                                <span className={dt(['text-small'])}>Change</span>
                            </Button>
                        )}
                    </div>
                </div>
                <address>
                    {
                        <span className={storeAddressInfoStyles}>
                            {street && `${street}, `}
                            {city && `${city}, `}
                            {state && `${state} `}
                            {zipcode && `${zipcode} `}
                        </span>
                    }
                    {
                        phone && (
                            <span className={storePhoneStyles} x-ms-format-detection="none">
                                {phone}
                            </span>
                        ) // x-ms-format-detection is to overide phone no. property on IE
                    }
                </address>
                {!timingsOverrideMessage ? (
                    <div className={storeTimesWrapperStyles}>
                        <List
                            direction={configForList?.direction?.VERTICAL}
                            spacing={configForList?.spacing?.NONE}
                            itemSpacing={configForList?.spacing?.NONE}
                            datasource={timings}
                            childRenderer={storeTimingsRenderer}
                            listBodyClass={cx('storeTime')}
                            listStyleClass={cx('ulStoreTimeWrap')}
                        />
                    </div>
                ) : (
                    <div className={timingsOverrideMessageStyles}>{timingsOverrideMessage}</div>
                )}
                <div className={`${cx('deliveryInfoWrap')} ${dt(['bg-white', 'text-left'])}`}>
                    {timingsOverrideMessage && !displaySDPU && storeInfo && (
                        <span className={storeCloseInfoStyles}>{storeInfo}</span>
                    )}
                    {displaySDPU && (
                        <div>
                            <a
                                href={sameDayPickupLink}
                                className={`${cx('deliveryInfoLink')} ${dt(['block'])}`}
                            >
                                <div
                                    className={dt([
                                        'flex',
                                        'items-center',
                                        'pt-2',
                                        'pr-5',
                                        'pb-2.5',
                                        'pl-2',
                                    ])}
                                >
                                    <Icon
                                        automationId="at-icon-store-fast-truck"
                                        iconType="svg"
                                        width="63px"
                                        height="36px"
                                        viewBox="0 0 63 36"
                                        className={cx('store-fast-truck')}
                                    >
                                        <StoreFastTruck />
                                    </Icon>
                                    <p className={sdpuInfoWrapStyles}>
                                        <span className={`${cx('sdpuInfo')} ${dt(['block'])}`}>
                                            Order before <b>3pm</b> to get
                                        </span>
                                        <b>
                                            <span
                                                className={`${cx('sdpuFreeText')} ${dt([
                                                    'text-brand-red',
                                                    'pr-1',
                                                ])}`}
                                            >
                                                FREE
                                            </span>
                                            same day pickup
                                        </b>
                                    </p>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
            </article>
        );
    };

    const storeServices = () => {
        const storeServicesWrapperStyles = `${dt([
            'pb-4',
            'border-solid',
            'border-t',
            'border-b-0',
            'border-l-0',
            'border-r-0',
            'border-gray-15',
        ])} ${inHamburger ? dt(['px-6']) : dt(['px-0'])}`;

        const storeServiceSectionTitleStyles = `${sectionTitleStyles} ${dt(['my-4'])}`;
        const storeServiceListWrapperStyles = dt([
            'flex',
            'gap-5',
            'flex-col',
            'justify-start',
            'p-0',
        ]);
        const storeServiceItemStyles = dt(['flex', 'h-14', 'gap-4', 'flex-row', 'justify-start']);
        const storeServiceInfoStyles = dt(['flex', 'flex-col', 'gap-y-1']);
        const storeServiceImageStyle = dt(['w-14', 'h-14', 'rounded-full', 'bg-gray-25']);
        const storeServiceTitleStyles = dt([
            'h-5',
            'text-sm',
            'font-semibold',
            'text-left',
            'text-black',
        ]);
        const appointmentLinkStyle = dt(['underline', 'h-5', 'text-sm', 'text-black']);

        const storeDisplayServiceList = [];

        if (storeId && services) {
            services.forEach((service) => {
                const validStoreService = config?.storeOptions.find(
                    (option) => option.key === service
                );

                if (validStoreService) storeDisplayServiceList.push(validStoreService);
            });
        }

        if (!storeDisplayServiceList.length) return null;

        const storeInfoList = storeDisplayServiceList.map((item) => {
            const { link = '', text = '', label = '', key = '', svgIcon = '' } = item;
            if (key === 'salon' && isFragment) {
                item.link = `https://${hostName}${SALON_SYNDICATE_URL}`;
            }

            return (
                <li className={cx('storeService-item')}>
                    <a
                        className={`${storeServiceItemStyles} ${cx('storeServiceLink')}`}
                        href={key === 'salon' && isUserLoggedIn ? JCP_SALON_SIGNED : link}
                        onClick={storeServicesClickHandler}
                    >
                        <div>
                            <Icon
                                className={cx(storeServiceImageStyle)}
                                iconType="svg"
                                automationId="service-store-icon"
                            >
                                {svgIcon}
                            </Icon>
                        </div>
                        <div className={storeServiceInfoStyles}>
                            <div className={storeServiceTitleStyles}>{text}</div>
                            <span className={appointmentLinkStyle}>{label}</span>
                        </div>
                    </a>
                </li>
            );
        });

        return (
            <article className={storeServicesWrapperStyles}>
                <p className={storeServiceSectionTitleStyles}> {STORE_SERVICES} </p>
                <ul className={storeServiceListWrapperStyles}>{storeInfoList} </ul>
            </article>
        );
    };

    const displayWeeklyAds = () => {
        /**
         * Accepts a date string as params and returns true if its valid Ads
         */
        const isValidWeeklyAd = (startDate, endDate) => {
            const startTime = new Date(startDate).getTime();
            const endTime = new Date(endDate).getTime();
            const sysTime = new Date().getTime();
            return endTime - sysTime > 0 && sysTime - startTime > 0;
        };

        let weeklyAdsData = [];
        const weeklyAdsMsgLength = weeklyAdsMessages.length;
        /* istanbul ignore else */
        if (!weeklyAdsMsgLength) {
            return false;
        }
        const viewAllAdsLink = weeklyAdsMsgLength > 1 && viewMoreAdsLink;
        const weeklyAdsFilterData = weeklyAdsMessages.filter((item) =>
            isValidWeeklyAd(item.available_from, item.available_to)
        );

        const weeklyAdsFilterDataLength = weeklyAdsFilterData.length;
        if (!weeklyAdsFilterDataLength) {
            return false;
        }

        weeklyAdsFilterDataLength > 1
            ? (weeklyAdsData = weeklyAdsFilterData.slice(0, 1))
            : (weeklyAdsData = weeklyAdsFilterData);

        const weekyAdsWrapStyles = `${dt([
            'pb-4',
            'border-t',
            'border-b-0',
            'border-l-0',
            'border-r-0',
            'border-solid',
            'border-gray-15',
        ])} ${cx('weekyAdsWrap')} ${inHamburger ? dt(['px-6']) : dt(['px-0'])}`;

        const weekyAdsTitleStyles = `${sectionTitleStyles} ${dt(['my-4'])}`;
        const weeklyAdsListStyles = dt(['flex', 'flex-col', 'gap-y-4']);
        const weeklyAdsImgWrapStyles = dt([
            'sm:max-w-[128px]',
            'sm:h-[100px]',
            'overflow-y-hidden',
            'self-center',
            'w-[128px]',
            'basis-2/5',
        ]);
        const weeklyAdsTimesStyles = dt([
            'text-gray-70',
            'pt-1',
            'font-open-sans-regular',
            'text-xs',
        ]);

        const weeklyAdsList =
            weeklyAdsData.length &&
            weeklyAdsData.map((item) => {
                const {
                    valid_from = '',
                    valid_to = '',
                    external_display_name,
                    first_page_thumbnail_400h_url = weeklyAdsDefaultImg,
                } = item;
                const validFrom = valid_from && DateFormatter.format('MMM Do', valid_from, true);
                const validTo = (valid_to && DateFormatter.format('MMM Do', valid_to, true)) || '';
                const displayName = external_display_name;
                const weeklyAdImg = first_page_thumbnail_400h_url;
                return (
                    <li>
                        <a
                            href={item.deep_link}
                            className={cx('weeklyAdsPageLink')}
                            onClick={weeklyAdClickHandler}
                        >
                            <div
                                className={`${cx('weeklyAdsTile')} ${dt(['flex', 'items-center'])}`}
                            >
                                {weeklyAdImg && (
                                    <div className={weeklyAdsImgWrapStyles}>
                                        <img
                                            src={weeklyAdImg}
                                            className={dt(['w-full', 'h-auto'])}
                                            alt={displayName}
                                        />
                                    </div>
                                )}
                                <div
                                    className={`${cx('weeklyAdsInfoWrap')} ${dt([
                                        'sm:w-[60%]',
                                        'pl-3',
                                        'text-left',
                                    ])}`}
                                >
                                    {displayName && (
                                        <p
                                            className={dt([
                                                'text-black',
                                                'ont-open-sans-regular',
                                                'text-base',
                                            ])}
                                        >
                                            {displayName}
                                        </p>
                                    )}
                                    {validFrom && validTo && (
                                        <span className={weeklyAdsTimesStyles}>
                                            {`Valid ${validFrom} - ${validTo}`}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </a>
                    </li>
                );
            });
        return (
            <article className={weekyAdsWrapStyles}>
                <div className={dt(['flex', 'items-center', 'justify-between'])}>
                    <p className={weekyAdsTitleStyles}>Featured Weekly Ad</p>
                    {viewAllAdsLink && (
                        <div className={dt(['w-1/4', 'float-right', 'text-right'])}>
                            <Button
                                size="Sm"
                                type="linkbutton"
                                linkUrl={viewAllAdsLink}
                                buttonType="Link"
                                className={linkStyles}
                            >
                                <span className={dt(['text-small'])}>View All</span>
                            </Button>
                        </div>
                    )}
                </div>
                <ul className={weeklyAdsListStyles}>{weeklyAdsList}</ul>
            </article>
        );
    };

    const StoreInfoWrapperStyles = `${cx('storeInfoWrapper')} ${
        inHamburger ? dt(['sm:h-[calc(100%-48px)]', 'overflow-y-auto']) : dt(['px-4'])
    }`;

    return (
        <article className={StoreInfoWrapperStyles}>
            {selectStoreInfo()}
            {storeServices()}
            {displayWeeklyAds()}
        </article>
    );
};

StoreInfo.defaultProps = {
    locationStoreInfo: {},
    isUserLoggedIn: false,
    triggerStoreSelectionSlider: () => null,
    inHamburger: false,
};

StoreInfo.propTypes = {
    locationStoreInfo: PropTypes.object,
    isUserLoggedIn: PropTypes.bool,
    triggerStoreSelectionSlider: PropTypes.func,
    inHamburger: PropTypes.bool,
};

export default StoreInfo;
