import { PROMOTIONALBANNERDATA_GET_SUCCESS } from '../actionTypes/PromotionalBannerActionTypes';

const removeEmptyKeyValues = (offerData = [], deviceType = {}) => {
    const contentDate = new Date(offerData.contentExpiry);
    const currentDate = new Date();
    let offerDataObject = {};
    if (contentDate >= currentDate) {
        const { isTablet, isMobile } = deviceType;
        let deviceTypeToRenderOfferData = 'large';
        if (isMobile) {
            deviceTypeToRenderOfferData = 'small';
        } else if (isTablet) {
            deviceTypeToRenderOfferData = 'medium';
        }
        const offerDataForDevice = offerData[deviceTypeToRenderOfferData];
        offerDataObject = offerDataForDevice.filter((offerItem) => offerItem.promoText);
    }
    return offerDataObject;
};

function OfferDetailsReducer(state = [], action) {
    let promotionalBannerData = state;
    switch (action.type) {
        case PROMOTIONALBANNERDATA_GET_SUCCESS:
            if (action.promotionalBannerData.data) {
                promotionalBannerData = removeEmptyKeyValues(
                    action.promotionalBannerData.data,
                    action.params.deviceType
                );
            }
            return {
                content: promotionalBannerData,
                isReady: true,
            };
        default:
            return state;
    }
}

OfferDetailsReducer.reducerName = 'promotionalBannerData';
export default OfferDetailsReducer;
