import _isEmpty from 'lodash/isEmpty';
import { apiErrorLog } from '../../common/Constants';

const logApiErrors = (apiName, response = {}, data = []) => {
    const errObj = {};
    let logError;

    if (response.status && response.status !== 200) {
        logError = apiErrorLog.statusCodeNot200;
        errObj.errorType = `${apiName}_${logError.type}`;
        errObj.errorDescription = `${apiName} ${logError.description}: ${response.status}`;
    } else if (response.status && response.status === 200 && _isEmpty(data)) {
        logError = apiErrorLog.emptyResponseData;
        errObj.errorType = `${apiName}_${logError.type}`;
        errObj.errorDescription = `${apiName} ${logError.description}`;
    } else if (!response.status && _isEmpty(data)) {
        logError = apiErrorLog.genericError;
        errObj.errorType = `${apiName}_${logError.type}`;
        errObj.errorDescription = `${apiName} ${logError.description}`;
    }
    return errObj;
};

export default logApiErrors;
