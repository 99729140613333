import {
    GET_ACCESS_TOKEN_REQUEST,
    SET_TOKEN_INFO,
    SET_SERVER_COOKIES,
    CLEAR_TOKEN_INFO,
} from '../actionTypes/AccessTokenProviderActionTypes';

const getAccessToken = (payload) => ({
    type: GET_ACCESS_TOKEN_REQUEST,
    payload,
});

const setTokenInfo = (payload) => ({
    type: SET_TOKEN_INFO,
    payload,
});

const setServerCookies = (payload) => ({
    type: SET_SERVER_COOKIES,
    payload,
});

const clearTokenInfo = () => ({
    type: CLEAR_TOKEN_INFO,
});

export default {
    getAccessToken,
    setTokenInfo,
    clearTokenInfo,
    setServerCookies,
};
