import { CM_PAGE_LOAD_SUCCESS, CM_FALLBACK_TO_IRIS } from '../actionTypes/CMPageActionTypes';

const initialState = {
    isIrisFallBackEnabled: false,
};

const cmReducer = function cmReducer(state = initialState, action) {
    switch (action.type) {
        case CM_PAGE_LOAD_SUCCESS:
            return { ...state, ...action.cmDataResponse };
        case CM_FALLBACK_TO_IRIS:
            return { ...state, isIrisFallBackEnabled: true };
        default:
            return state;
    }
};

cmReducer.reducerName = 'cmData';

export default cmReducer;
