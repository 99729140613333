const SHORT_MONTH_TOKENS = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec';
const FULL_MONTH_TOKENS =
    'January_February_March_April_May_June_July_August_September_October_November_December';

const ORDINAL_SUFFIX = ['th', 'st', 'nd', 'rd'];

export const IS0_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/; // YYYY-MM-DD

export const getLocalDateString = (dateString) =>
    typeof dateString === 'string' ? dateString.replace(IS0_DATE_REGEX, '$1/$2/$3') : dateString;

export const FORMAT_TOKENS = {
    WEEK_DAY_SPLIT: 'Wd,',
    SHORT_MONTH: 'MMM',
    FULL_MONTH: 'MMMM',
    ORDINAL_DATE: 'Do',
    MONTH: 'MM',
    DATE: 'DD',
    SAME_DAY: 'Today',
    NEXT_DAY: 'Tomorrow',
    DEFAULT: 'Default',
};

export const DAY = 24 * 60 * 60 * 1000;

const getDayWithOrdinal = (day) => {
    const remainder = day % 100;
    return (
        day +
        (ORDINAL_SUFFIX[(remainder - 20) % 10] || ORDINAL_SUFFIX[remainder] || ORDINAL_SUFFIX[0])
    );
};

const getDateFormatted = (token, date, dateString) => {
    const formatter = {
        [FORMAT_TOKENS.WEEK_DAY_SPLIT]: () =>
            `${date.toLocaleDateString('en-US', { weekday: 'long' })},`,
        [FORMAT_TOKENS.SHORT_MONTH]: () => SHORT_MONTH_TOKENS.split('_')[date.getMonth()],
        [FORMAT_TOKENS.FULL_MONTH]: () => FULL_MONTH_TOKENS.split('_')[date.getMonth()],
        [FORMAT_TOKENS.MONTH]: () => `0${date.getMonth() + 1}`.slice(-2),
        [FORMAT_TOKENS.ORDINAL_DATE]: () => getDayWithOrdinal(date.getDate()),
        [FORMAT_TOKENS.DATE]: () => `0${date.getDate()}`.slice(-2),
        [FORMAT_TOKENS.SAME_DAY]: () => FORMAT_TOKENS.SAME_DAY,
        [FORMAT_TOKENS.NEXT_DAY]: () => FORMAT_TOKENS.NEXT_DAY,
        [FORMAT_TOKENS.DEFAULT]: () => dateString,
    };

    return (formatter[token] || formatter[FORMAT_TOKENS.DEFAULT])();
};

const composeDate = (date, dateString) => (formatted, formatter, index) =>
    `${formatted}${index && formatted ? ' ' : ''}${getDateFormatted(formatter, date, dateString)}`;

const DateFormatter = () => ({
    format: (token, dateString, isSystemTimeZone) => {
        const localDateString = getLocalDateString(dateString);
        let date = '';
        if (isSystemTimeZone) {
            date = new Date(localDateString);
        } else {
            const splittedArray = typeof dateString === 'string' ? dateString.split(' ') : '';
            date = new Date(`${splittedArray[1]} ${splittedArray[2]} ${splittedArray[5]}`);
        }
        let formatter = token;

        if (isNaN(date)) {
            formatter = FORMAT_TOKENS.DEFAULT;
            console.warn('Invalid date', dateString);
        }

        if (!formatter) {
            const today = new Date();
            const tomorrow = new Date(today.getTime() + DAY);

            switch (true) {
                case today.toDateString() === date.toDateString():
                    formatter = FORMAT_TOKENS.SAME_DAY;
                    break;
                case tomorrow.toDateString() === date.toDateString():
                    formatter = FORMAT_TOKENS.NEXT_DAY;
                    break;
                default:
                    formatter = `${FORMAT_TOKENS.FULL_MONTH} ${FORMAT_TOKENS.ORDINAL_DATE}`;
            }
        }

        return formatter.split(' ').reduce(composeDate(date, dateString), '');
    },
    /**
     *  This helper can be improved based on the requirement
     */
    formatWithOptions: (dateString, separator = '/') => {
        let month = dateString.getMonth() + 1;
        let day = dateString.getDate();
        const year = dateString.getFullYear();

        if (month < 10) month = `0${month}`;
        if (day < 10) day = `0${day}`;
        return [month, day, year].join(`${separator}`);
    },
});

export default DateFormatter();
