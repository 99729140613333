import {
    LOAD_USER_STORE_LOCATION_INFO,
    UPDATE_USER_STORE_LOCATION_INFO,
    UPDATE_CART_STORE,
} from '../actionTypes/LocationServiceActionTypes';

export const loadUserStoreLocationInfo = (shouldRefreshStore = false) => ({
    type: LOAD_USER_STORE_LOCATION_INFO,
    shouldRefreshStore,
});

export const updateUserStoreLocationInfo = (storeDetails) => ({
    type: UPDATE_USER_STORE_LOCATION_INFO,
    storeDetails,
});

export const updateStoreOnCartAction = (storeId) => ({
    type: UPDATE_CART_STORE,
    storeId,
});
