import { all, takeLatest, put, call } from 'redux-saga/effects';
import { getList, createList, saveToList, addSavedItems } from 'yoda-interfaces/lib/MyList/MyList';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import get from 'lodash/get';
import FSaga from './FactorySaga';
import * as ActionTypes from '../actionTypes/SavedListToolTip';

export function* getListSaga(action) {
    try {
        // yield call(getList, action.payload.accountId);
        const listItems = yield call(FSaga, getList, action.payload, undefined, false);

        yield put({ type: ActionTypes.GET_SITE_LIST_SUCCESS, listItems });
    } catch (error) {
        yield put({
            type: ActionTypes.GET_SITE_LIST_ERROR,
            error,
        });
    }
}

export function* getPerticularListBasedOnIdSaga(action) {
    try {
        const products = yield call(getList, action.payload);

        yield put({ type: ActionTypes.SITE_GETLIST_BASEDON_ID_SUCCESS, products });
    } catch (error) {
        yield put({
            type: ActionTypes.SITE_GETLIST_BASEDON_ID_ERROR,
            error,
        });
    }
}

function sortByName(userList) {
    const [first, ...rdata] = userList;
    rdata.sort(({ name: a }, { name: b }) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });
    return [first, ...rdata];
}

export function* createListSaga(action) {
    try {
        // yield call(getList, action.payload.accountId);
        const list = yield call(FSaga, createList, action.payload, undefined, false);
        yield put({ type: ActionTypes.CREATENEW_SITE_LIST_SUCCESS, list: list.response });

        if (list.status === 201 || (list.response && list.response.status === 201)) {
            // Add new list to Token Provider
            try {
                let userList = JSON.parse(TokenProvider.get('DP_USER_LIST'));
                userList.push({
                    id: list.response.data.id,
                    name: list.response.data.name,
                });
                userList = sortByName(userList);
                TokenProvider.set('DP_USER_LIST', JSON.stringify(userList));
                TokenProvider.set(
                    'DP_USER_NEW_MY_LIST',
                    JSON.stringify({ id: list.response.data.id })
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('Some error');
            }
            yield all([
                put({ type: ActionTypes.CREATE_SITE_LIST_CLICK_EVENT, list }),
                put({ type: ActionTypes.GET_MYLIST }),
            ]);
        } else if (
            list.data.errorCode ||
            (list.response && list.response.data && list.response.data.errorCode)
        ) {
            const errorData = {
                error: [
                    {
                        errorDescription: list.data.errorMessage || list.response.data.errorMessage,
                        errorID: list.data.errorCode || list.response.data.errorCode,
                    },
                ],
            };
            yield put({ type: ActionTypes.ADD_SITE_TO_LIST_ERROR_EVENT, errorData });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.CREATENEW_SITE_LIST_ERROR,
            error,
        });
    }
}

export function* movetoListSaga(action) {
    try {
        // yield call(getList, action.payload.accountId);
        const moveAction = {};
        moveAction.type = action.type;
        moveAction.payload = action.payload;
        /*
            Has to send accountid and accesstoken, since factorysaga
            has issues
        */
        const movedToListResponse = yield call(FSaga, saveToList, moveAction, undefined, false);
        const statusCode =
            get(movedToListResponse, 'status') || get(movedToListResponse, 'response.status');
        yield all([
            put({
                type: ActionTypes.MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS,
                movedToListResponse: movedToListResponse.response,
            }),
            put({ type: ActionTypes.GET_MYLIST }),
        ]);

        if (
            movedToListResponse.status === 204 ||
            (movedToListResponse.response && movedToListResponse.response.status === 204)
        ) {
            const analyticsData = {
                listType: '',
                product: [
                    {
                        productInfo: {
                            productPPID: action.payload.savedItemId,
                        },
                    },
                ],
            };
            if (action.payload.params.value === 'myFavorites') {
                analyticsData.listType = 'my fav';
            } else {
                analyticsData.listType = 'custom';
            }
            // yield put({ type: ActionTypes.ADD_SITE_TO_LIST_EVENT, analyticsData });
        } else if ('data' in movedToListResponse) {
            const payload = [
                {
                    errorID:
                        movedToListResponse.data.errorCode ||
                        movedToListResponse.response.data.errorCode,
                    errorDescription:
                        movedToListResponse.data.errorMessage ||
                        movedToListResponse.response.data.errorMessage,
                },
            ];
            yield put({ type: ActionTypes.SITE_MOVETOLIST_ERROR_EVENT, payload });
        } else if (!movedToListResponse.isSuccess && statusCode !== 412) {
            /** To render Uh-oh broken page */
            const payload = {
                isSuccess: false,
                error: {
                    errorCode: get(
                        movedToListResponse,
                        'data.errorCode',
                        'SRV_DOWN_STREAM_API_DOWN_ERROR'
                    ),
                    errorMessage: get(movedToListResponse, 'data.errorMessage', ''),
                    statusCode,
                },
            };
            yield put({ type: ActionTypes.SITE_MOVETOLIST_ERROR_EVENT, payload });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.MOVETO_SITE_NEW_LIST_ERROR,
            error,
        });
    }
}

export function* addToListSaga(action) {
    try {
        const movedToListResponse = yield call(
            FSaga,
            addSavedItems,
            action.payload,
            undefined,
            false
        );
        if (
            movedToListResponse.status === 201 ||
            (movedToListResponse.response && movedToListResponse.response.status === 201)
        ) {
            const analyticsData = {
                listType: '',
                product: [
                    {
                        productInfo: {
                            productPPID: action.payload.payload.pdpPayload[0].product.id,
                        },
                    },
                ],
                itemsSavedForLater: action.analyticsData.totalItemsSavedForLater,
            };
            if (action.payload.listId === 'myFavorites') {
                analyticsData.listType = 'my fav';
            } else {
                analyticsData.listType = 'custom';
            }
            yield put({ type: ActionTypes.ADD_SITE_TO_LIST_EVENT, analyticsData });
        } else if (
            movedToListResponse?.response?.data?.errorCode === 'SRV_ITEMCOUNT_EXCEED' ||
            movedToListResponse?.data?.errorCode === 'SRV_ITEMCOUNT_EXCEED' ||
            movedToListResponse?.response?.data?.errorCode === 'SRV_ITEM_ALREADY_EXISTS'
        ) {
            const errorData = {
                error: [
                    {
                        errorID: movedToListResponse.response.data.errorCode,
                        errorDescription: movedToListResponse.response.data.errorMessage,
                    },
                ],
            };
            yield put({ type: ActionTypes.ADD_SITE_TO_LIST_ERROR_EVENT, errorData });
        }
        const { payload } = action;
        const newListId = payload.listId;
        payload.payload = { listId: newListId, params: { field: 'id,name,totalItems' } };
        yield all([
            put({
                type: ActionTypes.MOVETO_SITE_NEWPRODUCT_LIST_SUCCESS,
                movedToListResponse: movedToListResponse.response,
            }),
            put({ type: ActionTypes.SITE_GETLIST_BASEDON_ID, payload }),
            put({ type: ActionTypes.GET_MYLIST }),
        ]);
    } catch (error) {
        yield put({
            type: ActionTypes.ADD_SITE_ITEM_TO_LIST_ERROR,
            error,
        });
    }
}

const watchMTLToolTipSaga = function* watchMTLToolTipSaga() {
    yield takeLatest(ActionTypes.GET_SITE_LIST, getListSaga);
    yield takeLatest(ActionTypes.CREATE_SITE_NEW_LIST, createListSaga);
    yield takeLatest(ActionTypes.MOVETO_SITE_NEW_LIST, movetoListSaga);
    yield takeLatest(ActionTypes.ADD_SITE_ITEM_TO_LIST, addToListSaga);
    yield takeLatest(ActionTypes.SITE_GETLIST_BASEDON_ID, getPerticularListBasedOnIdSaga);
};
watchMTLToolTipSaga.sagaName = 'watchMTLToolTipSaga';

export default watchMTLToolTipSaga;
