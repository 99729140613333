import FooterWrapper from '../../components/YodaFooter/FooterLazy';
import HeaderWrapper from '../../components/YodaHeader/Header';
import HeaderV3Wrapper from '../../components/YodaHeader/HeaderV3';
import SignInSliderLazy from '../../components/SignInSlider/SignInSliderConnectLazy';
import FindStoresLazy from '../../components/FindStores/FindStoresLazy';
import ShopDepartments from '../Wrappers/DepartmentWrapper';

const components = {
    footer: FooterWrapper,
    header: HeaderWrapper,
    headerV3: HeaderV3Wrapper,
    signInSlider: SignInSliderLazy,
    findStoresSlider: FindStoresLazy,
    shopDepartments: ShopDepartments,
};

export default components;
