import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _defaultTo from 'lodash/defaultTo';
import ReducerRegistryAction from '../../actions/ReducerSagaRegistryAction';

const withReducerSaga = (initialReducers = [], initialSagas = []) => WrappedComponent => { //eslint-disable-line
    class ConnectedReducerSagaComponent extends Component {
        static propTypes = {
            $actions: PropTypes.object,
        };

        static defaultProps = {
            $actions: {},
        };

        /* istanbul ignore next */
        constructor(props) {
            super(props);

            const requestedBy = _uniqueId(WrappedComponent.displayName);

            this.state = {
                reducers: _defaultTo(initialReducers, []).map((reducer) => ({
                    name: reducer.reducerName,
                    reducer,
                    requestedBy,
                })),
                sagas: _defaultTo(initialSagas, []).map((saga) => ({
                    name: saga.sagaName,
                    saga,
                    requestedBy,
                })),
            };

            this.toggleReducers = this.toggleReducers.bind(this);
            this.toggleSagas = this.toggleSagas.bind(this);
        }

        componentWillMount() {
            const { reducers, sagas } = this.state;
            this.toggleReducers(reducers);
            this.toggleSagas(sagas);
        }

        componentWillUnmount() {
            const { reducers, sagas } = this.state;
            this.toggleReducers(reducers, false);
            this.toggleSagas(sagas, false);
        }

        toggleReducers(reducers, inject = true) {
            if (inject) {
                this.props.$actions.injectReducer(reducers);
            } else {
                this.props.$actions.rejectReducer(reducers);
            }
        }

        toggleSagas(sagas, inject = true) {
            sagas.forEach((asyncSaga) =>
                inject
                    ? this.props.$actions.injectSaga(asyncSaga)
                    : this.props.$actions.rejectSaga(asyncSaga)
            );
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => ({
        $actions: bindActionCreators({ ...ReducerRegistryAction }, dispatch),
    });

    return connect(null, mapDispatchToProps)(ConnectedReducerSagaComponent);
};

export default withReducerSaga;
