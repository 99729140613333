import { GET_SITE_USER_PROFILE_SUCCESS } from '../actionTypes/AccountActionTypes';

function AccountReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_SITE_USER_PROFILE_SUCCESS: {
            const userProfile = action.userProfileInfo;
            return { ...state, userProfile };
        }

        default:
            return state;
    }
}

AccountReducer.reducerName = 'accounts';
export default AccountReducer;
