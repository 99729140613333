import digitalDataActionTypes from '../actionTypes/DigitalDataActionTypes';

const digitalDataReducer = function digitalDataReducer(state = {}, action) {
    switch (action.type) {
        case digitalDataActionTypes.SNAPSHOT_DIGITAL_DATA:
            return { ...window.digitalData };
        default:
            return state;
    }
};

digitalDataReducer.reducerName = 'digitalData';

export default digitalDataReducer;
