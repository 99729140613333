import { IRIS_GLOBAL_NAVIGATION_GET_REQUEST } from '../actionTypes/DepartmentVisualNavigationActionTypes';

const getIrisGlobalNavigationAction = (pageName) => ({
    type: IRIS_GLOBAL_NAVIGATION_GET_REQUEST,
    pageName,
});

export default {
    getIrisGlobalNavigationAction,
};
