import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import get from 'lodash/get';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Menu from '../../assets/svg/newHambIcon.svg';
import MenuIconNew from '../../assets/svg/newHambIcon.svg';
import IrisHamburgerLazy from '../IrisHamburger/HeaderChunksAndFooterLazy';

const menuLinkStyles = dt([
    'block',
    'cursor-pointer',
    'mx-auto',
    'relative',
    'rounded-none',
    'text-black',
]);

const iconWrapperStyles = dt(['inline-block', 'align-middle', `min-h-[22px]`, `pt-[2px]`]);

export class HamburgerPlaceHolder extends Component {
    static propTypes = {
        customIcon: PropTypes.node,
        automationId: PropTypes.string,
        enableHamburgerMenuLabel: PropTypes.bool,
        displayHamburgerSlider: PropTypes.func,
        HamburgerSlider: PropTypes.bool,
        triggerStoreSelectionSlider: PropTypes.func,
        getPreferredStore: PropTypes.func,
    };

    static defaultProps = {
        customIcon: '',
        automationId: '',
        enableHamburgerMenuLabel: false,
        displayHamburgerSlider: noop,
        HamburgerSlider: false,
        triggerStoreSelectionSlider: noop,
        getPreferredStore: () => {},
    };

    state = {
        slideOpen: get(this.props, 'HamburgerSlider.isHamburgerSliderOpen', false),
        title: 'MENU',
    };

    constructor(props) {
        super(props);
        this.headerOverlay = null;
    }

    componentDidMount() {
        this.headerOverlay = document.getElementById('headerOverlay');
    }

    /* ugly code need to redo in r2 */
    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        const {
            HamburgerSlider: { isHamburgerSliderOpen: prevIsSliderOpen },
        } = prevProps;
        const {
            HamburgerSlider: { isHamburgerSliderOpen: isSliderOpen },
        } = this.props;
        const { slideOpen } = this.state;
        /** when slider is closed externally - remove the styling */
        if (prevIsSliderOpen && !isSliderOpen && slideOpen) {
            this.setState({ slideOpen: false });
            this.addOrRemoveStyling(false);
        }
    }

    addOrRemoveStyling = (menuOpenStatus) => {
        const root = document.getElementsByTagName('html')[0];
        if (menuOpenStatus) {
            root.classList.add('blockContentScroll', 'scrollBlock', 'hamburgerScrollBlock');
            if (this.headerOverlay) this.headerOverlay.style.display = 'block';
        } else {
            root.classList.remove('blockContentScroll', 'scrollBlock', 'hamburgerScrollBlock');
            if (this.headerOverlay) this.headerOverlay.style.display = 'none';
        }
    };

    /** *
     * Slider-scrollLock, copied from old yoda Hamburger menu to make menu fit in the layout
     */
    toggleSlideState = (menuOpenStatus) => {
        this.addOrRemoveStyling(menuOpenStatus);
        this.setState({
            slideOpen: menuOpenStatus,
        });
        this.props.displayHamburgerSlider(menuOpenStatus);
    };

    toggleMenu = () => {
        this.toggleSlideState(!this.state.slideOpen);
    };

    render() {
        const { customIcon, automationId, enableHamburgerMenuLabel } = this.props;
        const { isHamburgerSliderOpen: slideOpen } = this.props.HamburgerSlider;

        const HamburgerMenuIcon = enableHamburgerMenuLabel ? (
            <span>
                <Icon
                    iconType="svg"
                    automationId="test-automation-icon"
                    width="40px"
                    height="40px"
                    viewBox="0 0 40 40"
                >
                    <MenuIconNew />
                </Icon>
            </span>
        ) : (
            <Icon
                iconType="svg"
                automationId="test-automation-icon"
                width="40px"
                height="40px"
                viewBox="0 0 40 40"
            >
                <Menu />
            </Icon>
        );

        const toggleMenu = this.props.customIcon ? (
            <button onClick={this.toggleMenu}>{customIcon}</button>
        ) : (
            <button
                data-automation-id="jcp-menu"
                className={menuLinkStyles}
                onClick={this.toggleMenu}
            >
                {HamburgerMenuIcon}
            </button>
        );

        return (
            <div className={dt(['relative', 'z-10'])} data-automation-id={automationId}>
                <div className={iconWrapperStyles} data-automation-id="button-menu">
                    {toggleMenu}
                </div>
                {slideOpen && (
                    <IrisHamburgerLazy
                        enableHamburgerMenuLabel={enableHamburgerMenuLabel}
                        slideOpen={slideOpen}
                        toggleSlideState={this.toggleSlideState}
                        triggerStoreSelectionSlider={this.props.triggerStoreSelectionSlider}
                        getPreferredStore={this.props.getPreferredStore}
                    />
                )}
            </div>
        );
    }
}

export default HamburgerPlaceHolder;
