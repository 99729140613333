import actionTypes from '../actionTypes/ProductSearchActionTypes';

function ProductSearchDetailReducer(state = [], action) {
    switch (action.type) {
        default:
            return state;

        case actionTypes.PRODUCTSEARCHDETAIL_GET_SUCCESS:
            return { ...action.productSearchDetailResult };
    }
}

ProductSearchDetailReducer.reducerName = 'productSearchDetailResult';
export default ProductSearchDetailReducer;
