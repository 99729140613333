import { put, call, takeEvery, select, take } from 'redux-saga/effects';
import _get from 'lodash/get';
import { transformPriceForBrowse } from 'yoda-interfaces/lib/Product/PricingApi';

import { getRecommendationDataFromAdobe } from 'yoda-interfaces/lib/Product/RecommendationApi';
import {
    ADD_PRODUCT_CARTRIDGE_SLOT,
    ADD_CERTONA_LOADER_TO_LIST,
    CLEAR_CERTONA_OPTIONS,
    RESET_PRODUCT_CARTRIDGE_REDUCER,
    ENSIGHTEN_ERROR_EVENT,
    FETCH_DATA_FOR_RECOMMENDATION,
} from '../actionTypes/ProductCartridgeActionTypes';

import { selectPreferences, selectFeatureFlags } from '../selectors/ContextSelector';
import analyticsActions from '../actions/AnalyticsAction';
import CustomErrorLogger from '../helpers/CustomErrorLogger/CustomErrorLogger';

const recommendationLoaders = {};

/**
 * Get the data from store to verify loader status
 * @param {Object}  state(Application state)
 * @param {Object} payload(Component props)
 * @return {boolean}
 */
export const isRecommendationLoaderInvokedForPageType = (state, payload) => {
    const { pageType } = payload;
    if (payload && (payload.forceRefresh || payload.refreshCertona)) {
        return false;
    }
    return recommendationLoaders[pageType];
};

/**
 * Get the data from store to send data with recommendation call
 * @param {Object}  state(Application state)
 * @return {Object}
 */
export const getRecommendationProps = (state) => {
    const { productCartridge = {} } = state;
    const schemes = productCartridge.certonaOptions || [];
    return [...new Set(schemes)].join(';');
};

function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function* triggerEnsightenError(params) {
    const { errorType, errorDescription, pageType } = params.payload;
    yield put(
        CustomErrorLogger.triggerCustomErrorLog({
            errorType,
            errorDescription,
            pageType,
        })
    );
}

function fetchPriceFromRecommendation(
    schema,
    enableCouponInfoPrice,
    enableEligibleCouponInfoPrice
) {
    schema.items.forEach((item) => {
        item.pricing = item.displayJSON || {};
        item.priceDetails = transformPriceForBrowse(
            item,
            enableCouponInfoPrice,
            enableEligibleCouponInfoPrice
        );
        delete item.pricing;
        if (item.displayJSON) {
            delete item.displayJSON;
        }
    });

    schema.isPriceLoaded = true;
    return { [schema.scheme]: schema };
}

function* swapTheMappedKey(response) {
    if (response.schemes.length > 0) {
        const preferences = yield select(selectPreferences);
        const map = _get(preferences, 'recommendations.adobeMap', {});
        map &&
            response.schemes.map((scheme) => {
                const key = Object.keys(map).find((k) => map[k] === scheme.scheme);
                if (key) {
                    scheme.scheme = key;
                }
                return scheme;
            });
        return response;
    }
    return null;
}

const getAdobeMappedSlot = (preferences, pageType = '', schemes) => {
    const pageTypeList = _get(preferences, 'recommendations.schemes', {});
    const schemeList = (schemes || pageTypeList[pageType.toLowerCase()] || '').split(';');
    const mappedSchemes = [];
    schemeList.forEach((scheme) => {
        const map = _get(preferences, 'recommendations.adobeMap', {});
        mappedSchemes.push(map[scheme] || scheme);
    });
    return mappedSchemes.join(';');
};

/**
 * Get data from recommendation
 * @param {Object} initialData
 */
function* fetchDataForRecommendation(initialData) {
    try {
        // Check in store to ensure recommendation loader is invoked or not
        let loaderTriggered = yield select(
            isRecommendationLoaderInvokedForPageType,
            initialData.payload
        );

        // Fetching the feature flags and preferences.
        const featureFlags = yield select(selectFeatureFlags);
        const preferences = yield select(selectPreferences);
        const {
            cartridgeLazyLoad,
            enableCouponInfoPrice,
            enableEligibleCouponInfoPrice = true,
            enableMboxEntityParamsInCart = false,
        } = featureFlags;

        // To cancel the request if loader is already trigerred
        while (!loaderTriggered) {
            let certonProps = yield select(getRecommendationProps);
            if (certonProps !== '') {
                initialData.payload.attributes.schemes = certonProps;
                yield put({ type: CLEAR_CERTONA_OPTIONS });
            }
            const { loader, slotId, attributes = {}, pageType } = initialData.payload;
            loader && (recommendationLoaders[pageType] = true);
            yield put({ type: ADD_CERTONA_LOADER_TO_LIST, initialData });
            loaderTriggered = true;
            let responseData = { schemes: [] };
            // Pass flag to update entity params in cart if featureFlag enabled
            if (enableMboxEntityParamsInCart) {
                initialData.payload.enableMboxEntityParamsInCart = enableMboxEntityParamsInCart;
            }
            // Each loader should have unique constant name to trigger respective watcher saga
            // attributes.schemes
            initialData.payload.attributes.schemes = getAdobeMappedSlot(
                preferences,
                pageType,
                attributes.schemes
            );
            responseData = yield call(getRecommendationDataFromAdobe, initialData);
            if (slotId.includes('_rr')) responseData = yield swapTheMappedKey(responseData);
            let readSchemes = 0;
            let nodisplaySchemes = [];
            const displayedStrategies = [];
            if (responseData.schemes.length === 0) {
                // PDP & Gallery are passing slotId instead of schema
                // to handle, added below logic
                certonProps = certonProps || slotId;
                const errorDetails = {
                    errorType: 'certona',
                    errorDescription: `${certonProps} missing`,
                    pageType: initialData.payload.pageType,
                };
                yield put(CustomErrorLogger.triggerCustomErrorLog(errorDetails));
                nodisplaySchemes = certonProps
                    .split(';')
                    .map((scheme) => ({ scheme, display: 'no' }));
            }
            // Loop through all schemes and fetch price details for each product in scheme
            while (responseData.schemes.length > readSchemes) {
                const currentScheme = responseData.schemes[readSchemes];
                const { display } = currentScheme;
                // const { scheme, display } = currentScheme;
                let slotData;
                readSchemes += 1;

                if (display === 'yes') {
                    displayedStrategies.push(
                        currentScheme.explanation || 'No strategy name available'
                    );
                    // Creating header key for Product cartridge Header
                    currentScheme.header = currentScheme.explanation;

                    slotData = fetchPriceFromRecommendation(
                        currentScheme,
                        enableCouponInfoPrice,
                        enableEligibleCouponInfoPrice
                    );
                    yield put({ type: ADD_PRODUCT_CARTRIDGE_SLOT, slotData });

                    /**
                     * Making it configurable so as to switch off in case not required
                     */
                    if (cartridgeLazyLoad) {
                        const sleepSeconds = (preferences && preferences.sleepCartridge) || 0;
                        yield sleep(readSchemes * sleepSeconds); // sleeping for the second one after other
                    }
                } else {
                    currentScheme.items = [];
                    nodisplaySchemes.push(currentScheme);
                }
                if (responseData.schemes.length === readSchemes && displayedStrategies.length > 0) {
                    yield put(
                        analyticsActions.productRecommendationAnalytics({
                            productStrategy: displayedStrategies.join('|'),
                        })
                    );
                }
            }
            if (nodisplaySchemes.length > 0) {
                const slotData = {};
                let errorDescriptionText = '';
                nodisplaySchemes.forEach((currentScheme) => {
                    const { scheme } = currentScheme;
                    errorDescriptionText += `${scheme};`;
                    slotData[scheme] = currentScheme;
                });
                yield put(
                    CustomErrorLogger.triggerCustomErrorLog({
                        errorType: 'certona',
                        errorDescription: `${errorDescriptionText} missing`,
                        pageType: initialData.payload.pageType,
                    })
                );
                yield put({ type: ADD_PRODUCT_CARTRIDGE_SLOT, slotData });
            }
        }
    } catch (error) {
        yield put({ type: 'PRODUCT_ERROR', error });
    }
}

function* resetProductCartridgeReducer() {
    yield put({ type: RESET_PRODUCT_CARTRIDGE_REDUCER });
}

export const watchEnsightenErrorRequest = function* watchEnsightenErrorRequest() {
    yield takeEvery(ENSIGHTEN_ERROR_EVENT, triggerEnsightenError);
};

watchEnsightenErrorRequest.sagaName = 'triggerEnsightenError';

const watchProductListForRecommendation = function* watchProductListFromAdobe() {
    yield takeEvery(FETCH_DATA_FOR_RECOMMENDATION, fetchDataForRecommendation);
};

watchProductListForRecommendation.sagaName = 'fetchDataForRecommendation';

export const watchResetProductList = function* watchResetProductList() {
    yield take(RESET_PRODUCT_CARTRIDGE_REDUCER, resetProductCartridgeReducer);
};

watchResetProductList.sagaName = 'resetProductCartridgeReducer';

export default watchProductListForRecommendation;
