import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import {
    applyAdjustment,
    getOrderDetails,
    removeAdjustment,
    getAdjustmentDiscounts,
} from 'yoda-interfaces/lib/Order/OrderApi';
import { getCouponListAurora } from 'yoda-interfaces/lib/Coupon/CouponApi';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import * as messagesActions from '../actions/MessagesAction';
import { messageTypes } from '../components/Messages/Messages.config';
import analyticsActions from '../actions/AnalyticsAction';
import * as AdjustmentActionTypes from '../actionTypes/AdjustmentActionTypes';
import AnalyticsActionTypes from '../actionTypes/AnalyticsActionTypes';
import LoadingAction from '../actions/LoadingAction';
import {
    COUPON_APPLIED_SUCCESS,
    couponErrorMessages,
    COUPON_DRAWER_ERROR_MESSAGES_COUPON_REQUEST_ERROR,
    COUPON_DRAWER_ERROR_MESSAGES_COUPON_CODE_EXPIRED,
    COUPON_DRAWER_ERROR_MESSAGES_SRV_COUPON_EXPIRED_ERR,
    COUPON_DRAWER_ERROR_MESSAGES_SERIAL_NUMBER_REQ_MESSAGE,
    COUPON_DRAWER_ERROR_MESSAGES_SRV_PROMOCODE_INVALID,
    COUPON_DRAWER_ERROR_MESSAGES_IRIS_COUPON_NO_CART_ITEMS,
    COUPON_DRAWER_ERROR_MESSAGES_SRV_REWARD_TOKEN_MISSING,
} from '../common/Constants';
import { selectFeatureFlags } from '../selectors/ContextSelector';
import getAppliedCode from '../selectors/CouponSelector';
import Config from '../components/CouponCard/CouponConfig';
import FactorySaga from './FactorySaga';

function* applyAdjustmentsSaga(action) {
    const { couponAnimation, selectedCouponCode = '' } = action.payload;
    const couponDrawerErrorMessages = {
        COUPON_REQUEST_ERROR: COUPON_DRAWER_ERROR_MESSAGES_COUPON_REQUEST_ERROR,
        COUPON_CODE_EXPIRED: COUPON_DRAWER_ERROR_MESSAGES_COUPON_CODE_EXPIRED,
        SRV_COUPON_EXPIRED_ERR: COUPON_DRAWER_ERROR_MESSAGES_SRV_COUPON_EXPIRED_ERR,
        SERIAL_NUMBER_REQ_MESSAGE: COUPON_DRAWER_ERROR_MESSAGES_SERIAL_NUMBER_REQ_MESSAGE,
        SRV_PROMOCODE_INVALID: COUPON_DRAWER_ERROR_MESSAGES_SRV_PROMOCODE_INVALID,
        IRIS_COUPON_NO_CART_ITEMS: COUPON_DRAWER_ERROR_MESSAGES_IRIS_COUPON_NO_CART_ITEMS,
        SRV_REWARD_TOKEN_MISSING: COUPON_DRAWER_ERROR_MESSAGES_SRV_REWARD_TOKEN_MISSING,
    };
    const couponErrors = couponAnimation
        ? {
              ...couponErrorMessages,
              ...couponDrawerErrorMessages,
          }
        : couponErrorMessages;
    try {
        yield all([
            // Showing Loader
            couponAnimation
                ? put({
                      type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                      isInlineLoader: true,
                      selectedCouponCode,
                  })
                : put(LoadingAction.showLoader()),
            put(messagesActions.removeAllMessages({ section: Config.COUPON_POPUP_MESSAGES_ID })), // Clearing Pop Up Messages
        ]);

        const adjustmentMethod = action.payload.method;
        const adjustmentCategory = action.payload.category;
        const adjustmentSlot = action.payload.slot;

        delete action.payload.method;
        delete action.payload.category;
        delete action.payload.slot;

        let removeLoader = true; // Used for corner case scenario.

        const adjustmentResponse = yield call(FactorySaga, applyAdjustment, action);
        console.log('*** AdjustmentResponse ****', adjustmentResponse);
        if (!isEmpty(adjustmentResponse)) {
            let getAdjustmentData = null;
            let adjustmentDataItem = null;
            let errorCode = null;
            let errorMessage = null;
            const { status, statusText } = adjustmentResponse.response;

            console.log('** Adding consoles for Testing as we cannot add breakpoints in Sagas **');
            console.log('API Response status', status);
            console.log('API Response statusText', statusText);
            console.log('***********End*********');

            switch (status) {
                case 201:
                    {
                        const featureFlags = yield select(selectFeatureFlags);

                        // To indicate that the adjustments have updated recently.
                        yield put({
                            type: AdjustmentActionTypes.ADJUSTMENTS_UPDATED,
                            payload: {
                                result: true,
                                method: adjustmentMethod,
                                category: adjustmentCategory,
                                slot: adjustmentSlot,
                            },
                        });

                        const orderDetails = yield call(FactorySaga, getOrderDetails, action);
                        if (orderDetails && orderDetails.isSuccess) {
                            // Check the adjustments object in the response.
                            getAdjustmentData =
                                orderDetails.response.data &&
                                orderDetails.response.data.adjustments &&
                                orderDetails.response.data.adjustments.length > 0
                                    ? orderDetails.response.data.adjustments
                                    : null;
                            const getAnalysisData =
                                orderDetails.response.data &&
                                orderDetails.response.data.analyticsAttributes &&
                                orderDetails.response.data.analyticsAttributes.length > 0
                                    ? orderDetails.response.data.analyticsAttributes
                                    : null;

                            const getAdjustmentValues = {
                                value: action.payload.code,
                            };
                            // MCYODA-5346 => Aurora will not provide serialNumber if coupon is not serial restricted coupon.
                            // this check will make sure we only send serialNumber to compare in Lodash's FIND operation
                            // Find the coupon / reward that was just applied.
                            adjustmentDataItem = find(getAdjustmentData, getAdjustmentValues);
                            adjustmentResponse.response.data = adjustmentDataItem;
                            adjustmentResponse.response.data.analyticsAttributes = getAnalysisData;
                            if (
                                !isEmpty(adjustmentDataItem) &&
                                !isEmpty(adjustmentDataItem.message) &&
                                adjustmentDataItem.message.indexOf('has expired') !== -1
                            ) {
                                const code = adjustmentDataItem.value;
                                const { id } = adjustmentDataItem;
                                const payload = {
                                    status: AdjustmentActionTypes.ADJUSTMENT_EXPIRED,
                                    code,
                                    id,
                                };
                                yield put({
                                    type: AdjustmentActionTypes.ADJUSTMENT_EXPIRED,
                                    payload,
                                });
                            }
                            yield put({
                                type: AdjustmentActionTypes.ADJUSTMENTS_POST_SUCCESS,
                                adjustmentResponse,
                            });
                            let appliedAdjustmentsResponse = getAdjustmentData;

                            if (featureFlags.enableCouponSavings) {
                                // Params
                                const params = {
                                    payload: {
                                        priceFlag: true,
                                        marketing: null,
                                        unmerged: false,
                                    },
                                };
                                const bestCoupon = yield call(
                                    FactorySaga,
                                    getCouponListAurora,
                                    params
                                );
                                /** Best coupon code starts */
                                // once you get the response from API
                                if (bestCoupon && bestCoupon.isSuccess) {
                                    const couponGroup = get(bestCoupon, 'response.data.groups', {}); // getting the coupon Group

                                    const allCoupon = [];
                                    couponGroup.forEach((groupObject) => {
                                        allCoupon.push(
                                            ...groupObject.coupons.filter(
                                                (coupon) => coupon.totalDiscount > 0
                                            )
                                        );
                                    });

                                    const bestCoupons = allCoupon.map((couponSingle) => ({
                                        bestCoupon: couponSingle.bestCoupon,
                                        totalDiscount: couponSingle.totalDiscount,
                                        promoCode: couponSingle.code,
                                    }));

                                    // adding best coupon info
                                    if (bestCoupons) {
                                        appliedAdjustmentsResponse =
                                            appliedAdjustmentsResponse !== null
                                                ? appliedAdjustmentsResponse
                                                : {};
                                        appliedAdjustmentsResponse.bestCoupons = bestCoupons;
                                        // hasAdjustmentDispatch = true;
                                    }
                                } // end of best coupon API

                                /** Best Coupon Code ends */
                            }
                            yield all([
                                put({
                                    type: AdjustmentActionTypes.ADJUSTMENTS_GET_SUCCESS,
                                    appliedAdjustmentsResponse,
                                }),
                                put({
                                    type: AnalyticsActionTypes.UPDATE_ANALYTICS_CLICK_EVENT,
                                    payload: COUPON_APPLIED_SUCCESS,
                                }),
                            ]);
                        }
                    }
                    break;
                case 400:
                    {
                        errorCode = get(adjustmentResponse, 'response.data[0].errorCode');
                        errorMessage =
                            couponErrors[errorCode] ||
                            get(adjustmentResponse, 'response.data[0].errorMessage') ||
                            couponErrors.COUPON_REQUEST_ERROR;
                        const section = Config.COUPON_POPUP_MESSAGES_ID;

                        // const section = errorCode === 'SVC_ORD_ERR_COUPON_PROMO_INVALID' || 'SRV_PROMOCODE_INVALID' ?
                        // Config.COUPON_INLINE_MESSAGES_ID : Config.COUPON_POPUP_MESSAGES_ID;
                        yield all([
                            put({
                                type: AdjustmentActionTypes.ADJUSTMENTS_POST_ERROR,
                                adjustmentResponse,
                            }),
                            put(
                                analyticsActions.triggerFormError([
                                    {
                                        errorDescription: `${Config.TOF}|${errorMessage}`,
                                    },
                                ])
                            ),
                            put(
                                messagesActions.addMessage({
                                    section,
                                    message: {
                                        id: errorCode,
                                        title: errorMessage,
                                        type: messageTypes.error,
                                    },
                                })
                            ),
                        ]);
                    }
                    break;
                case 401:
                case 412: {
                    /** Corner Case Handling for Unauthorized Access
                     *  and Precondition failure breaking for now
                     *  will add additional code for Interminent Message
                     * */
                    const section = Config.COUPON_POPUP_MESSAGES_ID;
                    errorCode = get(adjustmentResponse, 'response.data[0].errorCode');
                    errorMessage =
                        get(adjustmentResponse, 'response.data[0].errorMessage') ||
                        couponErrors.COUPON_REQUEST_ERROR;

                    if (errorCode === 'SRV_GENERIC_ERROR') {
                        yield all([
                            put({
                                type: AdjustmentActionTypes.ADJUSTMENTS_POST_ERROR,
                                adjustmentResponse,
                            }),
                            put(
                                analyticsActions.triggerFormError([
                                    {
                                        errorDescription: `${Config.TOF}|${errorMessage}`,
                                    },
                                ])
                            ),
                            put(
                                messagesActions.addMessage({
                                    section,
                                    message: {
                                        id: errorCode,
                                        title: errorMessage,
                                        type: messageTypes.error,
                                    },
                                })
                            ),
                        ]);
                    } else if (!couponAnimation) {
                        // #TJ MPDPYODA-4502 for coupon drawer, loader has to be canceled all the time
                        removeLoader = false; // keep loader for more time
                    }
                    break;
                }
                case 404: {
                    const section = Config.COUPON_POPUP_MESSAGES_ID;
                    errorCode = get(adjustmentResponse, 'response.data[0].errorCode');
                    errorMessage =
                        get(adjustmentResponse, 'response.data[0].errorMessage') ||
                        couponErrors.COUPON_REQUEST_ERROR;

                    yield all([
                        put({
                            type: AdjustmentActionTypes.ADJUSTMENTS_POST_ERROR,
                            adjustmentResponse,
                        }),
                        put(
                            analyticsActions.triggerFormError([
                                {
                                    errorDescription: `${Config.TOF}|${errorMessage}`,
                                },
                            ])
                        ),
                        put(
                            messagesActions.addMessage({
                                section,
                                message: {
                                    id: errorCode,
                                    title: errorMessage,
                                    type: messageTypes.error,
                                },
                            })
                        ),
                    ]);

                    break;
                }
                default:
                    errorCode =
                        get(adjustmentResponse, 'response.data[0].errorCode') ||
                        status ||
                        couponErrors.COUPON_REQUEST_ERROR;
                    errorMessage =
                        get(adjustmentResponse, 'response.data[0].errorMessage') ||
                        statusText ||
                        couponErrors.COUPON_REQUEST_ERROR;
                    yield all([
                        put({
                            type: AdjustmentActionTypes.ADJUSTMENTS_POST_ERROR,
                            adjustmentResponse,
                        }),
                        put(
                            analyticsActions.triggerFormError([
                                {
                                    errorDescription: `${Config.TOF}|${couponErrors.COUPON_REQUEST_ERROR}`,
                                },
                            ])
                        ),
                        put(
                            messagesActions.addMessage({
                                section: Config.COUPON_POPUP_MESSAGES_ID,
                                message: {
                                    id: errorCode,
                                    title: couponErrors.COUPON_REQUEST_ERROR,
                                    type: messageTypes.error,
                                },
                            })
                        ),
                    ]);
                    break;
            }
        }

        if (removeLoader) {
            // hiding Loader
            couponAnimation
                ? yield put({
                      type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                      isInlineLoader: false,
                      selectedCouponCode,
                  })
                : yield put(LoadingAction.removeLoader());
        }
    } catch (error) {
        // hiding Loader
        yield all([
            couponAnimation
                ? put({
                      type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                      isInlineLoader: false,
                      selectedCouponCode,
                  })
                : put(LoadingAction.removeLoader()),
            put({
                type: AdjustmentActionTypes.ADJUSTMENTS_POST_ERROR,
                adjustmentResponse: {},
            }),
            put(
                analyticsActions.triggerFormError([
                    {
                        errorDescription: `${Config.TOF}|${couponErrors.COUPON_REQUEST_ERROR}`,
                    },
                ])
            ),
            put(
                messagesActions.addMessage({
                    section: Config.COUPON_POPUP_MESSAGES_ID,
                    message: {
                        id: error.errorCode,
                        title: couponErrors.COUPON_REQUEST_ERROR,
                        type: messageTypes.error,
                    },
                })
            ),
        ]);
    }
}

function* getAppliedAdjustments(action) {
    try {
        // Fetching the feature flags.
        const featureFlags = yield select(selectFeatureFlags);
        const drawerLoader = action.payload && action.payload.drawerLoader;
        if (drawerLoader) {
            // Fetching applied Code
            const appliedCode = yield select(getAppliedCode);
            if (appliedCode) {
                action.payload = {
                    code: appliedCode,
                    selectedCouponCode: appliedCode,
                    serialNumber: null,
                };
            }
        }

        // Showing Loader
        // #TJ MPDPYODA-4489 show a different loader for coupon drawer; hide it for now
        if (drawerLoader) {
            yield put(
                messagesActions.removeAllMessages({ section: Config.COUPON_POPUP_MESSAGES_ID })
            ); // Clearing Pop Up Messages
        } else {
            yield all([
                put(LoadingAction.showLoader()),
                put(
                    messagesActions.removeAllMessages({ section: Config.COUPON_POPUP_MESSAGES_ID })
                ), // Clearing Pop Up Messages
            ]);
        }

        let orderDetails = null;
        let bestCoupon = null;
        let appliedAdjustmentsResponse = null;
        let bagItems = null;
        // MCYODA-5346 => as aprt of Aurora launch, GET adjusmtnet is NOT going to return anything with
        // microservices in place. For work around we will make draft order GET call and process the adjustment
        // orderDetails = yield call(FactorySaga, getOrderDetails, action);

        // Using all side effect to execut simultaneously
        orderDetails = yield call(FactorySaga, getOrderDetails, action);
        // [orderDetails, bestCoupon] = yield all([
        //     call(FactorySaga, getOrderDetails, action),
        //     call(FactorySaga, getCouponListAurora, params),
        // ]);

        let hasAdjustmentDispatch = false; // to dispatch

        if (orderDetails && orderDetails.isSuccess) {
            appliedAdjustmentsResponse =
                orderDetails.response.data &&
                orderDetails.response.data.adjustments &&
                orderDetails.response.data.adjustments.length > 0
                    ? orderDetails.response.data.adjustments
                    : null;

            bagItems = orderDetails?.response?.data?.bag?.items;

            if (appliedAdjustmentsResponse) {
                const getAnalysisData =
                    orderDetails.response.data &&
                    orderDetails.response.data.analyticsAttributes &&
                    orderDetails.response.data.analyticsAttributes.length > 0
                        ? orderDetails.response.data.analyticsAttributes
                        : null;

                appliedAdjustmentsResponse.analyticsAttributes = getAnalysisData;
            } else {
                Cookies.remove('COUPON_STATE'); // MMPYODA-233 Clearing COUPON_STATE cookie when adjustments is an empty array.
            }

            hasAdjustmentDispatch = true;
        } // end of getting adjustments

        /**
         * Making sure the feature flag is true
         */
        if (featureFlags.enableCouponSavings) {
            // Params
            const params = {
                payload: {
                    priceFlag: true,
                    marketing: null,
                    unmerged: false,
                },
            };

            bestCoupon = yield call(FactorySaga, getCouponListAurora, params);
            /** Best coupon code starts */
            // once you get the response from API
            if (bestCoupon && bestCoupon.isSuccess) {
                const couponGroup = get(bestCoupon, 'response.data.groups', {}); // getting the coupon Group

                const allCoupon = [];
                couponGroup.forEach((groupObject) => {
                    allCoupon.push(
                        ...groupObject.coupons.filter((coupon) => coupon.totalDiscount > 0)
                    );
                });

                const bestCoupons = allCoupon.map((couponSingle) => ({
                    bestCoupon: couponSingle.bestCoupon,
                    totalDiscount: couponSingle.totalDiscount,
                    promoCode: couponSingle.code,
                }));

                // adding best coupon info
                if (bestCoupons) {
                    appliedAdjustmentsResponse =
                        appliedAdjustmentsResponse !== null ? appliedAdjustmentsResponse : {};
                    appliedAdjustmentsResponse.bestCoupons = bestCoupons;
                    hasAdjustmentDispatch = true;
                }
            } // end of best coupon API

            /** Best Coupon Code ends */
        }

        // dispatch either of them are true
        if (hasAdjustmentDispatch) {
            let postAdjustment = [];
            if (drawerLoader) {
                let value = action.payload.code;
                if (!value) {
                    value = get(appliedAdjustmentsResponse, '[0].value');
                }
                const getAdjustmentValues = {
                    value,
                };
                // MCYODA-5346 => Aurora will not provide serialNumber if coupon is not serial restricted coupon.
                // this check will make sure we only send serialNumber to compare in Lodash's FIND operation
                // Find the coupon / reward that was just applied.
                const adjustmentDataItemValue = find(
                    appliedAdjustmentsResponse,
                    getAdjustmentValues
                );
                const adjustmentResponse = {
                    response: {
                        data: adjustmentDataItemValue,
                    },
                    openDrawerCall: true,
                };
                postAdjustment = [
                    put({
                        type: AdjustmentActionTypes.ADJUSTMENTS_POST_SUCCESS,
                        adjustmentResponse,
                    }),
                ];
            }
            yield all([
                put({
                    type: AdjustmentActionTypes.ADJUSTMENTS_GET_SUCCESS,
                    appliedAdjustmentsResponse,
                }),
                ...postAdjustment,
            ]);
        }
        // end of dispatch

        // hiding Loader
        yield put(LoadingAction.removeLoader());
        yield put({
            type: AdjustmentActionTypes.ADJUSTMENTS_GET_SUCCESS_COUPONPAGE,
            appliedAdjustmentsResponse,
        });
    } catch (error) {
        yield all([
            put(LoadingAction.removeLoader()),
            put(
                analyticsActions.triggerFormError([
                    {
                        errorDescription: `${Config.TOF}|${couponErrorMessages.COUPON_REQUEST_ERROR}`,
                    },
                ])
            ),
            put(
                messagesActions.addMessage({
                    section: Config.COUPON_POPUP_MESSAGES_ID,
                    message: {
                        id: error.errorCode,
                        title: couponErrorMessages.COUPON_REQUEST_ERROR,
                        type: messageTypes.error,
                    },
                })
            ),
        ]);
    }
}

function* removeAdjustmentSaga(action) {
    try {
        yield all([
            // Showing Loader
            action.couponAnimation
                ? put({
                      type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                      isInlineLoader: true,
                      selectedCouponCode: action.selectedCouponCode,
                      isApplyLoader: false,
                  })
                : put(LoadingAction.showLoader()),
            put(messagesActions.removeAllMessages({ section: Config.COUPON_POPUP_MESSAGES_ID })), // Clearing Pop Up Messages
        ]);
        const adjustmentResponse = yield call(FactorySaga, removeAdjustment, action);

        if (!isEmpty(adjustmentResponse)) {
            if (adjustmentResponse.response.status === 204) {
                yield all([
                    put({
                        type: AdjustmentActionTypes.ADJUSTMENTS_BY_ID_DELETE_SUCCESS,
                        adjustmentResponse,
                    }),
                    put({
                        type: AnalyticsActionTypes.REMOVE_ANALYTICS_CLICK_EVENT,
                        payload: COUPON_APPLIED_SUCCESS,
                    }),
                    // To indicate that the adjustments have updated recently.
                    put({
                        type: AdjustmentActionTypes.ADJUSTMENTS_UPDATED,
                        payload: { result: true },
                    }),
                ]);
            }
        }

        // MCYODA-5346 => as aprt of Aurora launch, GET adjusmtnet is NOT going to return anything with
        // microservices in place. For work around we will make draft order GET call and process the adjustment
        const orderDetails = yield call(FactorySaga, getOrderDetails, {});
        if (orderDetails && orderDetails.isSuccess) {
            // Check the adjustments object in the response.
            let appliedAdjustmentsResponse =
                orderDetails.response.data && orderDetails.response.data.adjustments
                    ? orderDetails.response.data.adjustments
                    : [];
            const featureFlags = yield select(selectFeatureFlags);

            if (featureFlags.enableCouponSavings) {
                // Params
                const params = {
                    payload: {
                        priceFlag: true,
                        marketing: null,
                        unmerged: false,
                    },
                };

                const bestCoupon = yield call(FactorySaga, getCouponListAurora, params);
                /** Best coupon code starts */
                // once you get the response from API
                if (bestCoupon && bestCoupon.isSuccess) {
                    const couponGroup = get(bestCoupon, 'response.data.groups', {}); // getting the coupon Group

                    const allCoupon = [];
                    couponGroup.forEach((groupObject) => {
                        allCoupon.push(
                            ...groupObject.coupons.filter((coupon) => coupon.totalDiscount > 0)
                        );
                    });

                    const bestCoupons = allCoupon.map((couponSingle) => ({
                        bestCoupon: couponSingle.bestCoupon,
                        totalDiscount: couponSingle.totalDiscount,
                        promoCode: couponSingle.code,
                    }));
                    // adding best coupon info
                    if (bestCoupons) {
                        appliedAdjustmentsResponse =
                            appliedAdjustmentsResponse !== null ? appliedAdjustmentsResponse : {};
                        appliedAdjustmentsResponse.bestCoupons = bestCoupons;
                    }
                } // end of best coupon API

                /** Best Coupon Code ends */
            }
            yield put({
                type: AdjustmentActionTypes.ADJUSTMENTS_GET_SUCCESS,
                appliedAdjustmentsResponse,
            });
        }
        // hiding Loader
        action.couponAnimation
            ? yield put({
                  type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                  isInlineLoader: false,
                  selectedCouponCode: action.selectedCouponCode,
              })
            : yield put(LoadingAction.removeLoader());
    } catch (error) {
        // hiding Loader
        yield all([
            action.couponAnimation
                ? put({
                      type: AdjustmentActionTypes.ADJUSTMENTS_INLINE_LOADER,
                      isInlineLoader: false,
                      selectedCouponCode: action.selectedCouponCode,
                  })
                : put(LoadingAction.removeLoader()),
            put(
                analyticsActions.triggerFormError([
                    {
                        errorDescription: `${Config.TOF}|${couponErrorMessages.COUPON_REQUEST_ERROR}`,
                    },
                ])
            ),
            put(
                messagesActions.addMessage({
                    section: Config.COUPON_POPUP_MESSAGES_ID,
                    message: {
                        id: error.errorCode,
                        title: couponErrorMessages.COUPON_REQUEST_ERROR,
                        type: messageTypes.error,
                    },
                })
            ),
        ]);
    }
}

function* getDiscountAdjustments(action) {
    try {
        const discountsApplied = yield call(FactorySaga, getAdjustmentDiscounts, action);
        if (discountsApplied && discountsApplied.isSuccess) {
            const appliedAdjustmentsResponse =
                discountsApplied.response.data && discountsApplied.response.data.length > 0
                    ? discountsApplied.response.data
                    : [];
            const drawerLoader = action.payload && action.payload.drawerLoader;
            const adjustmentResponse = get(appliedAdjustmentsResponse, '[0]');
            let postAdjustment = [];
            if (drawerLoader && adjustmentResponse) {
                const postAdjustmentResponse = {
                    response: {
                        data: adjustmentResponse,
                    },
                    openDrawerCall: true,
                };
                postAdjustment = [
                    put({
                        type: AdjustmentActionTypes.ADJUSTMENTS_POST_SUCCESS,
                        adjustmentResponse: postAdjustmentResponse,
                    }),
                ];
            }
            yield all([
                put({
                    type: AdjustmentActionTypes.ADJUSTMENTS_GET_SUCCESS,
                    appliedAdjustmentsResponse,
                }),
                ...postAdjustment,
            ]);
        }
    } catch (error) {
        yield put({
            type: AdjustmentActionTypes.ADJUSTMENTS_GET_ERROR,
            error,
        });
    }
}

const watchApplyAdjustmentsRequest = function* watchApplyAdjustmentsRequest() {
    yield takeLatest(AdjustmentActionTypes.ADJUSTMENTS_POST_REQUEST, applyAdjustmentsSaga);
};
watchApplyAdjustmentsRequest.sagaName = 'watchApplyAdjustmentsRequest';

const watchgetAppliedAdjustmentsRequest = function* watchgetAppliedAdjustmentsRequest() {
    yield takeLatest(AdjustmentActionTypes.ADJUSTMENTS_GET_REQUEST, getAppliedAdjustments);
};
watchgetAppliedAdjustmentsRequest.sagaName = 'watchgetAppliedAdjustmentsRequest';

const watchgetEmptyAdjustmentsRequest = function* watchgetEmptyAdjustmentsRequest() {
    yield takeLatest(AdjustmentActionTypes.APPLIED_DISCOUNTS_GET_REQUEST, getDiscountAdjustments);
};
watchgetEmptyAdjustmentsRequest.sagaName = 'watchgetEmptyAdjustmentsRequest';

const watchRemoveAdjustmentsRequest = function* watchRemoveAdjustmentsRequest() {
    yield takeLatest(AdjustmentActionTypes.ADJUSTMENTS_BY_ID_DELETE_REQUEST, removeAdjustmentSaga);
};
watchRemoveAdjustmentsRequest.sagaName = 'watchRemoveAdjustmentsRequest';

export {
    watchApplyAdjustmentsRequest,
    watchgetAppliedAdjustmentsRequest,
    watchgetEmptyAdjustmentsRequest,
    watchRemoveAdjustmentsRequest,
};
