const trimCmComponents = (response) => {
    const { componentType = '', content = {} } = response;

    switch (componentType) {
        default:
            return response;
    }
};

export default trimCmComponents;
