import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames/bind';
import Button from 'yoda-core-components/lib/components/Button';
import customOmit from 'yoda-core-components/lib/helpers/Utils/Omit';
import AnalyticsAction from '../../actions/AnalyticsAction';
import * as styles from './Link.css';

const cx = classNames.bind(styles);
/**
 * Link component wiki: https://wiki.jcpenney.com/pages/viewpage.action?pageId=77573460
 * 1) Based on flag 'removeLinkPrefix', remove link domain from an absolute url;
 * 2) Based on flag 'enableLinkPrefix', prefix a relative url with hostname
 * 3) Redirect to the modified link or a different link specified
 */
export class Link extends Component {
    static propTypes = {
        actions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        className: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
        type: PropTypes.string,
        dangerouslySetInnerHTML: PropTypes.object,
        enableLinkPrefix: PropTypes.bool,
        linkPrefix: PropTypes.string,
        linkName: PropTypes.string,
        triggerAnalytics: PropTypes.bool,
        linkPrefixToRemove: PropTypes.array,
        removeLinkPrefix: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        href: '',
        onClick: null,
        children: {},
        type: null,
        enableLinkPrefix: false,
        linkPrefix: '',
        dangerouslySetInnerHTML: null,
        linkName: '',
        triggerAnalytics: false,
        linkPrefixToRemove: [],
        removeLinkPrefix: false, // this is updated as lowercase to fix this console error - React does not recognize the `removeLinkPrefix` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `removelinkprefix` instead.
    };

    shouldComponentUpdate(nextProps) {
        // Changed as per PBI raised on not updating Link on user state change
        // Ref: MNPDPYODA-8105
        // We never know what child comp (button/Button) needs
        // we cant put so much condition
        // it would break if new data-attr is added
        return !isEqual(nextProps, this.props);
    }

    onLinkClicked = (e) => {
        const { href, onClick, actions, linkName, triggerAnalytics } = this.props;
        let prefixedLink = this.prefixLink();
        let shouldRedirect = !!href;
        let openInNewTab = false;

        /* istanbul ignore next */
        if (triggerAnalytics && linkName.length > 0) {
            actions.triggerNavigationClick({
                linkName,
            });
        }
        /* istanbul ignore next */
        if (onClick) {
            const redirectObject = onClick(e);
            // if a link different from this.props.href is specified as the new redireciton url, use it
            if (redirectObject && typeof redirectObject === 'string') {
                prefixedLink = this.prefixLink(redirectObject);
            } else if (redirectObject && typeof redirectObject.url === 'string') {
                prefixedLink = this.prefixLink(redirectObject.url);
                // TODO: #TJ suppress onclick redirection when ctrl-clicking
                openInNewTab = redirectObject.openInNewTab;
            }

            shouldRedirect = !!redirectObject;
        }

        // if redirection is desired
        /* istanbul ignore next */
        if (shouldRedirect && prefixedLink && !openInNewTab) {
            window.location.assign(prefixedLink);
        } else if (shouldRedirect && prefixedLink && openInNewTab) {
            window.open(prefixedLink, '_blank');
        }
    };

    isLinkAbsolute = (link) =>
        // if link:
        // 1) starts with 'http' or
        // 2) starts with '//' or
        // 3) not 1) or 2), and doesn't start with '/', e.g. 'www.example.com' falls into this category
        // Note: 'm/test123' falls into 3) as expected (data issue)
        link.slice(0, 4) === 'http' || link.slice(0, 2) === '//' || link.slice(0, 1) !== '/';

    prefixLink = (originalLink) => {
        const {
            enableLinkPrefix,
            linkPrefix,
            removeLinkPrefix,
            linkPrefixToRemove,
            href,
        } = this.props;
        if (!originalLink) {
            originalLink = href;
        }
        let resultLink = originalLink;

        // remove prefix from absolute url
        /* istanbul ignore if */
        if (removeLinkPrefix && resultLink && this.isLinkAbsolute(resultLink)) {
            linkPrefixToRemove.forEach((prefixToRemove) => {
                const index = resultLink.indexOf(prefixToRemove);
                if (index > -1) {
                    resultLink = resultLink.slice(index + prefixToRemove.length);
                }
            });
        }

        // prefix relative url
        if (enableLinkPrefix && resultLink && !this.isLinkAbsolute(resultLink)) {
            // hostname in config looks like 'm-dt-test1.jcpenney.com' so should be prefixed with '//'
            resultLink = `//${linkPrefix}${resultLink}`;
        }

        return resultLink;
    };

    render() {
        const { className, children, type, ...props } = this.props;
        const extraProps = customOmit(props, [
            'triggerAnalytics',
            'enableLinkPrefix',
            'linkPrefix',
            'href',
            'onClick',
            'actions',
            'linkName',
            'linkPrefixToRemove',
            'dispatch',
        ]); // this is due to to fix this warning, MNPDPYODA - 10602-triggerAnalytics been removed React does not recognize the `triggerAnalytics` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `triggeranalytics` instead. If you accidentally passed it from a parent component, remove it from the DOM element

        switch (type) {
            case 'button':
                return (
                    <button
                        {...extraProps}
                        onClick={this.onLinkClicked}
                        className={cx('customLink', className)}
                    >
                        {!this.props.dangerouslySetInnerHTML ? children : null}
                    </button>
                );
            case 'Button':
                return (
                    <Button
                        {...extraProps}
                        onClick={this.onLinkClicked}
                        className={cx('customLink', className)}
                    >
                        {!this.props.dangerouslySetInnerHTML ? children : null}
                    </Button>
                );
            default:
                return (
                    <a
                        {...extraProps}
                        href={this.prefixLink() || 'javascript:void(0)'}
                        onClick={this.onLinkClicked}
                        className={cx('customLink', className)}
                    >
                        {!this.props.dangerouslySetInnerHTML ? children : null}
                    </a>
                );
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...AnalyticsAction }, dispatch),
});

const mapStateToProps = ({ context }) => {
    const enableLinkPrefix = get(context, 'featureFlags.enableLinkPrefix', false);
    const { linkPrefix, linkPrefixToRemove } = get(context, 'preferences', {
        linkPrefix: 'www.jcpenney.com',
        linkPrefixToRemove: ['www.jcpenney.com'],
    });

    return {
        enableLinkPrefix,
        linkPrefix,
        linkPrefixToRemove,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Link);
