import { OVERLAY_SHOW, OVERLAY_REMOVE } from '../actionTypes/OverlayActionTypes';

const showOverlay = () => ({
    type: OVERLAY_SHOW,
});

const removeOverlay = () => ({
    type: OVERLAY_REMOVE,
});

export default {
    showOverlay,
    removeOverlay,
};
