import React from 'react';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import LinkConnected from '../Link/Link';

const FindaStoreSelector = (
    selectedStore = {
        isGeoStore: false,
        storeDetails: {
            name: '',
        },
    },
    catSelectedPushToNid,
    analyticsForDefault
) => {
    let title = '';
    const analyticsForDefaultLocal = analyticsForDefault;
    /* istanbul ignore next */
    if (
        selectedStore?.isGeoStore &&
        selectedStore?.storeDetails &&
        selectedStore?.storeDetails?.name
    ) {
        title = 'Select My Store';
    } else if (selectedStore.storeDetails && selectedStore.storeDetails.name) {
        title = 'Change My Store';
    }

    /* istanbul ignore next */
    const goFindaStore = () => {
        analyticsForDefaultLocal('top:Select Store:findastore');
        return '/findastore'; // redirect through Link component to /findastore
    };

    return (
        <div className={dt(['pt-5'])}>
            <button
                data-itemid="root"
                onClick={catSelectedPushToNid}
                data-title="MENU"
                data-name={title}
                data-pagetype="selectstore"
                data-nid="selectstore"
                className={dt([
                    'w-11/12',
                    'bg-white',
                    'border',
                    'border-solid',
                    'border-gray-25',
                    'text-black',
                    'min-w-10',
                    'text-base',
                    'py-3',
                    'px-0',
                ])}
            >
                {title}
            </button>

            <LinkConnected
                type="Button"
                onClick={goFindaStore}
                data-itemid="root"
                data-title="MENU"
                data-name="Find a Store"
                data-pagetype="base"
                data-nid="root"
                buttonType="Primary"
                size="Lg"
                className={dt(['w-11/12', 'mt-2.5'])}
            >
                Find a Store
            </LinkConnected>
        </div>
    );
};

export default FindaStoreSelector;
