import ScriptLoader from 'yoda-core-components/lib/helpers/ScriptLoader/ScriptLoader';

function iovationLoader(src, config, onError, onSuccess) {
    const configProps = { src: config, async: false };
    const iovationProps = {
        src,
        async: false,
        onSuccess,
        onError,
    };
    /* istanbul ignore else */
    ScriptLoader.load(configProps);
    /* istanbul ignore else */
    ScriptLoader.load(iovationProps);
}

export default iovationLoader;
