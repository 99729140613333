const isCSSFeatureSupported = (feature) => {
    if (window.CSS && window.CSS.supports && window.CSS.supports(feature)) {
        return true;
    }
    return false;
};

export const enableFeatureInApp = (config) => {
    if (!__SERVER__) {
        const userAgent = navigator.userAgent || null;
        const { iosMinVersion = '', androidMinVersion = '' } = config;
        if (userAgent.indexOf('JCP_NATIVE_APPLICATION;WEB_VIEW')) {
            let userAgentSplit = '';
            let configVersionSplit = '';
            if (iosMinVersion && userAgent.indexOf('IOS_IPHONE') > 0) {
                userAgentSplit = userAgent.split('VERSION =');
                configVersionSplit = iosMinVersion.split('.');
            } else if (androidMinVersion && userAgent.indexOf('ANDROID') > 0) {
                userAgentSplit = userAgent.split('JCPenney-Android/');
                configVersionSplit = androidMinVersion.split('.');
            } else {
                return false;
            }
            const userAgentList =
                userAgentSplit && userAgentSplit[1] && userAgentSplit[1].split('/');
            const appVersion = userAgentList[0] && userAgentList[0].trim();
            const appVersionSplit = appVersion && appVersion.split('.');
            if (appVersion === iosMinVersion || appVersion === androidMinVersion) {
                return true;
            }
            // eslint-disable-next-line radix
            if (parseInt(appVersionSplit[0]) > parseInt(configVersionSplit[0])) {
                return true;
            }
            // eslint-disable-next-line radix
            if (parseInt(appVersionSplit[1]) > parseInt(configVersionSplit[1])) {
                return true;
            }
            // eslint-disable-next-line radix
            if (parseInt(appVersionSplit[2]) > parseInt(configVersionSplit[2])) {
                return true;
            }
        }
    }
    return false;
};

export default isCSSFeatureSupported;
