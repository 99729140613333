const PRODUCTDETAILS_GET_REQUEST = 'PRODUCTDETAILS_GET_REQUEST';
const PRODUCTDETAILS_GET_SUCCESS = 'PRODUCTDETAILS_GET_SUCCESS';
const PRODUCTDETAILS_RESET = 'PRODUCTDETAILS_RESET';
const PRODUCTDETAILS_GET_ERROR = 'PRODUCTDETAILS_GET_ERROR';
export default {
    PRODUCTDETAILS_GET_REQUEST,
    PRODUCTDETAILS_GET_SUCCESS,
    PRODUCTDETAILS_RESET,
    PRODUCTDETAILS_GET_ERROR,
};
