import { createSelector } from 'reselect';
import get from 'lodash/get';
import drop from 'lodash/drop';

export const defaults = {
    context: {},
    deviceType: {},
    featureFlags: {},
    preferences: {},
    messagesTexts: {},
    endpoints: {},
    reqHeaders: {},
    shouldRender: true,
    irisfallbackPreferences: {},
    seoConfig: {},
};

const defaultStoreConfig = {
    weeklyAdsDefaultImg: '',
    sameDayPickupLink: '/m/same-day-pickup',
    displaySDPU: false,
    storeInfo: '',
    viewMoreAdsLink: '/m/digital-books?pageId=pg40030000010',
    findAStoreLink: '/findastore',
};

export const defaultRecommendations = {
    adobeMap: {},
    adobeScheme: [],
    schemes: {},
};

// const getThresholdLimitForDeviceType = (preferences, deviceType) => {
//     const thresholdLimit = get(preferences, 'secondaryNavigationMenu.thresholdLimit', null);
//     let thresholdValue = 0;
//     if (thresholdLimit) {
//         if (deviceType.isMobile) {
//             thresholdValue = thresholdLimit.mobile;
//         } else if (deviceType.isTablet) {
//             thresholdValue = thresholdLimit.tablet;
//         } else {
//             thresholdValue = thresholdLimit.desktop;
//         }
//     }
//     return thresholdValue;
// };

export const selectContext = (state) => state.context || defaults.context;

export const selectBagItemCount = (state) => state.bagItemCount || 0;

export const isPreview = createSelector([selectContext], (context) =>
    get(context, 'isPreview', false)
);

export const selectPreferences = createSelector([selectContext], (context) =>
    get(context, 'preferences', defaults.preferences)
);
export const selectMessagesTexts = createSelector([selectContext], (context) =>
    get(context, 'messagesTexts', defaults.messagesTexts)
);
export const selectReqHeaders = createSelector([selectContext], (context) =>
    get(context, 'headers', defaults.reqHeaders)
);

export const selectRequestUrl = createSelector([selectContext], (context) =>
    get(context, 'requestUrl', '')
);

export const selectPreferencesMaxOrderQuantity = createSelector(
    [selectPreferences],
    (preferences) => get(preferences, 'maxOrderQuantity', defaults.maxOrderQuantity)
);

export const selectIrisFallbackPreferences = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'irisfallbackPreferences', defaults.irisfallbackPreferences)
);

export const selectSeoProdHostName = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'seoProdHostName', 'https://www.jcpenney.com')
);

export const selectAkamaiGeoCookie = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'akamaiGeoCookie', 'ak_geo')
);

export const selectAkamaiZipCookie = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'akamaiZipCookie', 'ak_zip')
);

export const selectAkamaiZipCookieTTL = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'akamaiGeoZipCookieTTL', 30)
);

export const selectFeatureFlags = createSelector(
    [selectContext],
    (context) => context.featureFlags || defaults.featureFlags
);

export const selectEnableAkamaiGeoZipCode = createSelector([selectFeatureFlags], (featureFlags) =>
    get(featureFlags, 'enableAkamaiGeoZipCode', true)
);

export const selectEnableAkgeoCookieSecure = createSelector([selectFeatureFlags], (featureFlags) =>
    get(featureFlags, 'enableAkgeoCookieSecure', false)
);

export const selectDeviceType = createSelector(
    [selectContext],
    (context) => context.deviceType || defaults.deviceType
);

export const selectDebugFlag = createSelector([selectContext], (context) =>
    get(context, 'debug.debugFlag', false)
);

export const selectShouldRender = createSelector([selectContext], (context) =>
    get(context, 'shouldRender', defaults.shouldRender)
);

export const selectSizeBasedOnDeviceType = createSelector([selectDeviceType], (deviceType) => {
    if (deviceType.isMobile) {
        return 'small';
    }
    if (deviceType.isTablet) {
        return 'medium';
    }
    return 'large';
});

export const selectDeviceName = createSelector([selectDeviceType], (deviceType) => {
    if (deviceType.isMobile) {
        return 'mobile';
    }
    if (deviceType.isTablet) {
        return 'tablet';
    }
    return 'desktop';
});

export const hamburgerPreferencesSelector = createSelector([selectContext], (context) => {
    const hybridHamburgerFlagSelector = createSelector([selectContext], (ctx) =>
        get(ctx, 'featureFlags.enableHybridHamburger', false)
    );
    const enableHybridHamburger = hybridHamburgerFlagSelector({ context });

    return enableHybridHamburger
        ? get(context, 'preferences.hybridHamburger', null)
        : get(context, 'preferences.hamburger', null);
});

export const hamburgerPreferencesDeptSelector = createSelector([selectContext], (context) => {
    const hybridHamburgerFlagSelector = createSelector([selectContext], (ctx) =>
        get(ctx, 'featureFlags.enableHybridHamburger', false)
    );
    const enableHybridHamburger = hybridHamburgerFlagSelector({ context });

    return enableHybridHamburger
        ? get(context, 'preferences.hybridDeptsHamburger', {})
        : get(context, 'preferences.hamburger', {});
});

export const selectMoreSecondaryNavigationMenuList = createSelector(
    [selectPreferences],
    (preferences) => {
        let moreSecondaryMenuOptions = [];
        const menuDisplayThresholdValue = get(
            preferences,
            'secondaryNavigationMenu.thresholdLimit',
            0
        );
        const secondaryMenuList = get(preferences, 'secondaryNavigationMenu.menuList', []);
        moreSecondaryMenuOptions = menuDisplayThresholdValue
            ? drop(secondaryMenuList, menuDisplayThresholdValue)
            : [];
        return moreSecondaryMenuOptions;
    }
);

export const selectApplyingToEmptyCart = createSelector(
    [selectFeatureFlags, selectBagItemCount],
    (featureFlags, bagItemCount) => featureFlags.Coupon_AllowApplyEmptyCart && bagItemCount <= 0
);

export const selectInteractiveVizNavCacheMins = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'interactiveVizNav.cacheMins', 30)
);

export const selectIsBot = createSelector(
    [selectDeviceName],
    (deviceType) => deviceType.isBot || false
);
export const selectHeaderStoreHoverConfig = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'headerStoreHoverConfig', defaultStoreConfig)
);

export const selectIsFragment = createSelector(
    [selectContext],
    (context) => context?.isFragment || false
);

export const selectHostName = createSelector(
    [selectContext],
    (context) => context?.hostname || 'www.jcpenney.com'
);

export const selectPreferencesNoImageFoundSrc = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'noImageFoundSrc', '')
);

export const selectIsNative = createSelector([selectContext], (context) =>
    get(context, 'isNative', false)
);

export const selectPreferencesRecommendations = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'recommendations', defaultRecommendations)
);

export const selectPreferencesUrgencyApiUrl = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'URGENCY_API_URL', '')
);

export const selectDefaultRadius = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'defaultRadius', 25)
);

export const selectSeoConfig = createSelector([selectPreferences], (preferences) =>
    get(preferences, 'seoConfig', defaults.seoConfig)
);
