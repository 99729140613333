import React from 'react';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import YDTIcon from 'yoda-core-components/lib/components/Icon';
import Image from 'yoda-core-components/lib/components/Image/Image';
import { replaceSubdomain } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import HambArrowRight from '../../assets/svg/chevron2022.svg';
import * as styles from './RootCategoryTemplate.css';
import Link from '../Link/Link';

const cx = classNames.bind(styles);

const oneRow = (
    item,
    catSelectedPushToNid,
    isLoader,
    itemKey,
    enableHybridHamburger = false,
    preferences
) => {
    const hasChild = !!(item.children && item.children.length);
    /* istanbul ignore next */
    const targUrl = item.link && item.link.url ? item.link.url : '';
    /* istanbul ignore next */
    const imgURL = item.image && item.image.url ? item.image.url : '';

    const hrStyles = enableHybridHamburger
        ? dt(['border-b-0', 'pb-0'])
        : dt(['pb-1.5', 'borderBottomSolid']);
    const menuListItemLinkStyles = enableHybridHamburger
        ? dt(['w-full', 'text-normal', 'text-black', 'text-left', 'px-2', 'sm:py-2', 'h-auto'])
        : dt(['w-full', 'text-normal', 'text-black', 'text-left', 'p-1', 'sm:pl-2.5', 'h-auto']);
    const menuListItemNameStyle = enableHybridHamburger
        ? dt([
              'pl-3',
              'inline-block',
              'align-middle',
              'mx-0',
              'sm:my-2.5',
              'sm:w-[calc(100%-85px)]',
          ])
        : dt(['pl-3']);
    const rightArrow = enableHybridHamburger
        ? dt(['mx-0', 'float-right', 'py-4', 'pr-0'])
        : dt(['float-right', 'pr-3']);
    return (
        <li className={hrStyles} key={`hamb-root-${item.linkText}`}>
            <Link
                type="button"
                onClick={catSelectedPushToNid}
                data-targetdurl={targUrl}
                data-hasChild={hasChild}
                data-pagetype="root"
                data-name={item.linkText}
                data-title="shop departments "
                data-nid={itemKey}
                data-automation-id="dropdown-item-button"
                className={menuListItemLinkStyles}
                disabled={isLoader}
            >
                <div className={dt(['w-14', 'inline-block', 'align-middle', 'rounded-half'])}>
                    {imgURL && (
                        <Image
                            src={replaceSubdomain(imgURL, preferences)}
                            alt={item.linkText}
                            imgClass={dt(['rounded-half'])}
                            automationId="hamburger-image"
                        />
                    )}
                </div>
                <span
                    className={`${menuListItemNameStyle} ${cx(
                        styles.menuListItemName,
                        item.styleClass
                    )}`}
                    aria-label={item.linkText}
                >
                    {item.linkText}
                </span>
                <i className={rightArrow}>
                    {_get(item, 'children', []).length > 0 && (
                        <YDTIcon width="24px" height="24px">
                            <HambArrowRight />
                        </YDTIcon>
                    )}
                </i>
            </Link>
        </li>
    );
};

const renderMidSection = (items, catSelectedPushToNid, isLoader, preferences) => {
    const fullDom = [];
    const itemArray = Object.keys(items);
    itemArray.forEach((itemKey) => {
        fullDom.push(oneRow(items[itemKey], catSelectedPushToNid, isLoader, itemKey, preferences));
    });
    return fullDom;
};

const RootCategoryTemplate = (
    depts,
    catSelectedPushToNid,
    isLoader,
    enableHybridHamburger = false,
    preferences
) => {
    const rootWrapperStyles = enableHybridHamburger
        ? dt(['mt-0', 'pt-2', 'border-t-0', 'h-auto', 'w-full', 'text-left', 'relative', 'block'])
        : dt([
              'w-full',
              'pt-2',
              'text-left',
              'relative',
              'sm:h-[calc(100%-40px)]',
              'block',
              'px-4',
          ]);
    const dropdownMenuWrapperStyles = enableHybridHamburger
        ? dt(['h-full', 'overflow-y-visible', 'touch-auto', 'px-4'])
        : dt(['h-full', 'overflow-y-auto', 'touch-auto', 'px-4']);
    return (
        <nav
            className={`${rootWrapperStyles} ${cx('dropdownMenu', 'newHeaderRootCategory', {
                hybridHamburger: enableHybridHamburger && Object.keys(depts).length,
            })}`}
        >
            {/* <p className={dt(['font-bold', 'text-sm', 'leading-4', 'text-black', 'pt-4', 'px-3', 'pb-2', 'w-full'])} id="deptTitle">
                {deptTitle}
            </p> */}
            <ul className={dropdownMenuWrapperStyles} id="subMenuLevel1">
                {renderMidSection(depts, catSelectedPushToNid, isLoader, preferences)}{' '}
            </ul>
        </nav>
    );
};
export default RootCategoryTemplate;
