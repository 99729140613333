import { IRIS_PAGE_LOAD_SUCCESS } from '../actionTypes/IrisActionTypes';

const irisReducer = function irisReducer(state = {}, action) {
    switch (action.type) {
        case IRIS_PAGE_LOAD_SUCCESS:
            return action.irisData;
        default:
            return state;
    }
};

irisReducer.reducerName = 'irisData';

export default irisReducer;
