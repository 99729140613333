import {
    SET_TOKEN_INFO,
    SET_SERVER_COOKIES,
    CLEAR_TOKEN_INFO,
} from '../actionTypes/AccessTokenProviderActionTypes';

function AccessTokenProviderReducer(state = {}, action) {
    switch (action.type) {
        case SET_TOKEN_INFO: {
            return { ...state, ...action.payload };
        }

        case SET_SERVER_COOKIES: {
            const cookieList = state.cookieList ? state.cookieList : [];
            cookieList.push(action.payload);
            return { ...state, cookieList };
        }

        case CLEAR_TOKEN_INFO: {
            return {};
        }

        default:
            return state;
    }
}

AccessTokenProviderReducer.reducerName = 'tokenInfo';
export default AccessTokenProviderReducer;
