import React from 'react';
import { string } from 'prop-types';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';

const dtWrapper = dt([
    'bg-white',
    'border-2',
    'border-gray-40',
    'border-r-0',
    'border-solid',
    'fixed',
    'flex',
    'font-open-sans-semibold',
    'items-center',
    'justify-center',
    'px-1',
    'py-2',
    'rounded-l-md',
    'text-small',
    'text-link-hover',
    'hidden',
    'z-[10]',
    'h-12',
    'right-0',
    'top-1/2',
    'w-9',
    'mt-4',
    'sm:!z-[100]',
]);

const ThirdPartyServiceButton = ({ name }) => {
    const tpsConfig = {
        button: {
            className: dt(['capitalize', 'p-2', '-rotate-90']),
        },
        wrapper: {
            className: `${dtWrapper} ${'third-party-service'}`,
            id: `tps-${name}-button`,
        },
    };

    return (
        <div {...tpsConfig.wrapper}>
            <button {...tpsConfig.button} type="button">
                {name}
            </button>
        </div>
    );
};

ThirdPartyServiceButton.propTypes = {
    name: string.isRequired,
};

export default ThirdPartyServiceButton;
