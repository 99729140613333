import AnalyticsActionTypes from '../../actionTypes/AnalyticsActionTypes';

/**
 Helper to log custom error logs
 */

class CustomErrorLogHelper {
    getFeatureflag = () => {
        const getContext =
            (!__SERVER__ && window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.context) || {};
        const featureFlags = getContext.featureFlags || {};
        return featureFlags && featureFlags.enableCustomErroLogs;
    };

    triggerCustomErrorLog = ({
        errorType = '',
        errorDescription = '',
        errorData = {},
        pageType = '',
    }) => {
        if (this.getFeatureflag()) {
            return {
                type: AnalyticsActionTypes.LOG_CUSTOM_ERRORS,
                errorDetails: {
                    error: {
                        userMetadata: navigator.userAgent,
                        type: errorType,
                        name: errorData.name || errorType,
                        message: errorData.message,
                        stack: errorData.stack,
                        componentStack: errorData.componentStack,
                        vendor: !!errorData.vendor,
                    },
                    errorType,
                    errorDescription,
                    pageType,
                },
            };
        }

        return { type: 'NOERROR' };
    };
}

export default new CustomErrorLogHelper();
