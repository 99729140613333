import * as SignInSliderActionTypes from '../actionTypes/SignInSliderActionTypes';

// TODO: Modify openSignInSlider to use one single config objject instead of multiple params
/**
 * @param createAccountFlag <Boolean> Tells whether to directly open createaccount or not
 * @param nextUrl <String> Url to which site has to be redirected after authentication success
 * @param isLogout <Boolean> Tells whether to directly open logout slider or not
 * @param openForgotPasswordSlider <Boolean> Tells whether to directly open forgot password form or not
 */
export const openSignInSlider = (
    createAccountFlag,
    nextUrl,
    isLogout = false,
    openForgotPasswordSlider = false
) => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_OPEN,
    payload: {
        openCreateAccount: createAccountFlag,
        postAuthenticationUrl: nextUrl,
        openLogoutSlider: isLogout,
        openForgotPasswordSlider,
    },
});

/* To be called from saga when openSignInSlider is accessed from other
   than actual pages like (/signin, /createaccount, /forgotpassword)
   to open slider
*/
export const signInSliderMounted = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_MOUNTED,
});

// To be called to close the slider with transition
export const initiateSignInSliderClose = (source) => ({
    type: SignInSliderActionTypes.INITIATE_SIGNIN_SLIDER_CLOSE,
    source,
});

// To be called only after the slider closes to unmount SignInSlider from Layout
export const unmountSignInSlider = () => ({
    type: SignInSliderActionTypes.UNMOUNT_SIGNIN_SLIDER,
});

export const openAccountMenuPage = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_ACCOUNT_MENU,
});

export const openCreateAccountPage = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_CREATE_ACCOUNT,
});

export const openRewardsPromotionLogin = () => ({
    type: SignInSliderActionTypes.OPEN_REWARDS_PROMOTIONAL_LOGIN,
});

// Called when ForgotPassword is clicked from signin slider to navigate to forgot password form
export const openForgotPasswordResetPage = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_PASSWORD_RESET,
});

export const resetSignInSlider = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_RESET,
});

export const hideWelcomeToolTip = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_HIDE_WELCOME_TOOL_TIP,
});

// Called to perform logout and show logout tooltip
// TODO: Modifiy to use it as general tooltip in header
export const showLogoutToolTip = () => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_SHOW_LOGOUT_TOOL_TIP,
});

export const logoutApiRequest = (callback) => ({
    type: SignInSliderActionTypes.SIGNIN_SLIDER_LOGOUT_API_REQUEST,
    payload: {
        callback,
    },
});

export const customStepUp = (
    customizeConfig = {},
    { onAuthentication, onClose, onSkip } = {},
    prepopulatedForm = {}
) => ({
    type: SignInSliderActionTypes.CUSTOM_STEPUP_SIGNIN_SLIDER,
    payload: {
        customizeConfig,
        customCallbacks: {
            onAuthentication,
            onClose,
            onSkip,
        },
        prepopulatedForm,
    },
});

export const skipAndContinue = () => ({
    type: SignInSliderActionTypes.CUSTOM_STEPUP_SIGNIN_SLIDER_SKIP,
});

export const lnlPageEmailFocus = (payload) => ({
    type: SignInSliderActionTypes.LNL_PAGE_EMAIL_FOCUS,
    payload,
});

export const enrollRewardlink = (payload) => ({
    type: SignInSliderActionTypes.NEW_ENROLL_REWARD_LINK,
    payload,
});

export const clearEnrollRewardLinkError = () => ({
    type: SignInSliderActionTypes.CLEAR_NEW_ENROLL_REWARD_LINK_ERROR,
});
