import {
    BRIGHTEDGE_AUTOPILOT_GET_SUCCESS,
    BRIGHTEDGE_AUTOPILOT_GET_ERROR,
} from '../actionTypes/BrightEdgeActionTypes';

export const defaultState = {};

export const BrightEdgeAutopilotReducer = function BrightEdgeAutopilotReducer(
    state = defaultState,
    action
) {
    switch (action.type) {
        case BRIGHTEDGE_AUTOPILOT_GET_SUCCESS:
            return action.brightEdgeData;
        case BRIGHTEDGE_AUTOPILOT_GET_ERROR:
            return defaultState;
        default:
            return state;
    }
};

BrightEdgeAutopilotReducer.reducerName = 'brightEdgeData';

export default BrightEdgeAutopilotReducer;
