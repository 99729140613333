import { ADJUSTMENTS_UPDATED } from '../actionTypes/AdjustmentActionTypes';

function UpdatedAdjustmentReducer(state = false, action) {
    switch (action.type) {
        case ADJUSTMENTS_UPDATED:
            return action.payload;
        default:
            return state;
    }
}

UpdatedAdjustmentReducer.reducerName = 'isAdjustmentsUpdated';
export default UpdatedAdjustmentReducer;
