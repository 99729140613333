import { OPT_IN_POST_SUCCESS, OPT_IN_POST_ERROR } from '../actionTypes/OrderActionTypes';

const optInReducer = function optInReducer(state = {}, action = {}) {
    switch (action.type) {
        case OPT_IN_POST_ERROR:
        case OPT_IN_POST_SUCCESS:
            return action.status;
        default:
            return state;
    }
};

optInReducer.reducerName = 'optin';

export default optInReducer;
