/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getStoresByLatLong, fetchStores } from 'yoda-interfaces/lib/Common/LocationServiceApi';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import makeQuery from 'yoda-core-components/lib/navigation/query/makeQuery';
import {
    addStore,
    getStoreForLoggedInUser,
    updateStore,
} from 'yoda-interfaces/lib/Account/AccountApi';
import { updateCartStore, getStoreForCart } from 'yoda-interfaces/lib/Cart/CartApi';
import {
    LOAD_USER_STORE_LOCATION_INFO,
    LOAD_USER_STORE_LOCATION_INFO_ERROR,
    SET_USER_STORE_LOCATION_INFO,
    UPDATE_USER_STORE_LOCATION_INFO,
    UPDATE_CART_STORE,
} from '../actionTypes/LocationServiceActionTypes';
import {
    CONTINUE_PROCESSING_APP_FAV_STORE,
    SAVE_NATIVE_FAVORITE_STORE,
} from '../actionTypes/NativeAppActionTypes';
import { getCookieOrLocalStorageData } from '../helpers/Utils/utils';
import {
    selectContext,
    selectAkamaiGeoCookie,
    selectEnableAkgeoCookieSecure,
    selectReqHeaders,
    selectPreferences,
    selectFeatureFlags,
} from '../selectors/ContextSelector';
import {
    ACCOUNT_ID,
    LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
    LOCATION_SERVICE_COOKIES_AKAMAI_STORE,
    STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_CART,
    STORE_CLICK_STREAM_LOGS_UPDATE_USER_PREFFERED_STORE,
    STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_LOGGED_IN_USER,
    STORE_CLICK_STREAM_LOGS_LAT_LONG,
    ITEM_COUNT,
} from '../common/Constants';
import FactorySaga from './FactorySaga';
import User from '../helpers/User/User';
import { isCheckoutPage, isPDPPage } from '../helpers/Utils/pageType';
import { isPLAPage } from '../helpers/Utils/PLAHelper';
import { updateStoreOnCartAction } from '../actions/LocationServiceAction';
import logClientLogs from '../actions/ClientLoggerAction';
import { getAppFavStore } from '../actions/NativeAppAction';
import { getSdpuStoreData, setSdpuLocalStorage } from '../actions/SdpuStoreInfoAction';

const GEO_LOC_PERMISSION_DENIED = 1;
const GEO_LOC_POSITION_UNAVAILABLE = 2;
const GEO_LOC_TIMEOUT = 3;
/**
 * @param  {} storeData={}
 */
function getRequiredStoreData(storeData = {}) {
    const {
        curbside,
        distance = '',
        id = '',
        inputZipCode = '',
        name = '',
        phone = '',
        zip = '',
    } = storeData;
    const curbsidePickupIndicator = storeData.curbsidePickupIndicator || !_isEmpty(curbside);
    const {
        street = '',
        city = '',
        state = '',
        timings,
        timingsOverrideMessage = '',
        services,
    } = storeData;
    return {
        curbsidePickupIndicator,
        city,
        distance,
        inputZipCode,
        phone,
        state,
        storeId: id,
        storeName: name,
        street,
        timings,
        timingsOverrideMessage,
        zipcode: zip,
        services,
    };
}

const getLocationServiceStore = (state) => state.locationServiceReducer;

function* triggerStoreLogs(store, apiDetails) {
    const { enableStoreClickStreamLogs = true } = yield select(selectFeatureFlags) || {};
    if (store && (_isEmpty(store.city) || _isEmpty(store.state)) && enableStoreClickStreamLogs) {
        yield put(logClientLogs.logError(apiDetails, JSON.stringify(store)));
    }
    return null;
}

function* getCookieData(key) {
    const reqHeaders = yield select(selectReqHeaders);
    return getCookieOrLocalStorageData(reqHeaders, key);
}

function* saveDataToCookie({ cookieKey, data }) {
    const preferences = yield select(selectPreferences);
    const cookieTtl = preferences.storeCookieTTL || 259200;
    const serviceStatus = 'services' in data;
    if (!serviceStatus && cookieKey === LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE) {
        try {
            const response = yield call(fetchStores, data.storeId);
            const devReact = response?.data?.stores[0].services;
            const serviceList = { services: devReact };
            const storeData = { ...data, ...serviceList };
            Cookies.save(
                LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
                JSON.stringify(storeData),
                cookieTtl / (60 * 24)
            );
            yield put({
                type: SET_USER_STORE_LOCATION_INFO,
                data: storeData,
            });
            return true;
        } catch (error) {
            yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error });
        }
    } else {
        Cookies.save(cookieKey, JSON.stringify(data), cookieTtl / (60 * 24));
        Cookies.save('geoLocStoreId', data.storeId, cookieTtl / (60 * 24));
    }
    return null;
}

/**
 * @param  {response, formattedData, userStore}
 * response - {} - Browse API response
 * formattedData - {} - Store details
 * Boolean - If yes then data will be stored in userPreferredStore cookie else akamaiStore cookie
 */
function* saveStoreDetails({ response, formattedData, userStore }) {
    const storesData =
        response && response.status === 200 && _get(response, 'data.stores[0]', null);
    if (storesData && response && response.inputZipCode) {
        storesData.inputZipCode = response.inputZipCode;
    }
    const dataToStore = storesData || formattedData;
    const cookieToStore = userStore
        ? LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE
        : LOCATION_SERVICE_COOKIES_AKAMAI_STORE;

    if (dataToStore) {
        const requiredData = getRequiredStoreData(dataToStore);
        if (__SERVER__) {
            requiredData.saveToCookie = true;
        } else {
            dataToStore.inputZipCode = dataToStore.inputZipCode
                ? dataToStore.inputZipCode
                : dataToStore.zip;
            yield call(saveDataToCookie, {
                cookieKey: cookieToStore,
                data: requiredData,
            });
        }
        yield put({ type: SET_USER_STORE_LOCATION_INFO, data: requiredData });
    } else {
        yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error: 'No stores available' });
    }
}
/**
 * @param {Load AkamaiStore cookie value with custom location} customLatLong
 * customLatLong - Currently this functionality is being used by gallery if Akamai fails to set the store.
 */
export function* loadAkamaiStore(customLatLong, userStore = false) {
    const preferences = yield select(selectPreferences);
    const akGeoCookie = yield select(selectAkamaiGeoCookie);
    const enableAkGeoCookieSecure = yield select(selectEnableAkgeoCookieSecure);
    let userLatLong = customLatLong;
    if (!customLatLong && !enableAkGeoCookieSecure) {
        userLatLong = yield call(getCookieData, akGeoCookie);
    }
    let payload = {};

    if (userLatLong || enableAkGeoCookieSecure) {
        const radius = _get(preferences, 'storeRadius', 30);
        const latLong = userLatLong && userLatLong.split(',');
        const [latitudeVal = '', longitudeVal = ''] = latLong || [];
        payload = {
            pageSize: 1,
            limit: 5,
            radius,
            xClientSource: 'SLIDER',
        };
        if (latitudeVal && longitudeVal) {
            payload.latitude = latitudeVal;
            payload.longitude = longitudeVal;
        }
        // this is to hit origin(stores api) when http and secure enable to address akgeo cookie sync issue .. once data stores in userprefcookie this api call wont happen
        if (enableAkGeoCookieSecure && (_isEmpty(latitudeVal) || _isEmpty(longitudeVal))) {
            payload.SVCVER = 'latest';
        }
        const response = yield call(getStoresByLatLong, payload);
        const responseData = response && response.data;
        if (responseData) {
            response.inputZipCode = _get(responseData, 'meta.inputZipCode', '');
            yield triggerStoreLogs(
                _get(response, 'data.stores[0]', null),
                STORE_CLICK_STREAM_LOGS_LAT_LONG
            );
            if (!_isEmpty(responseData.stores)) {
                if (userStore) {
                    yield call(saveStoreDetails, { response, userStore: true });
                } else {
                    yield call(saveStoreDetails, { response });
                }
            }
        }
    }
}

function* saveNativeFavoriteStore(storeData = '{}', hasUserChangedFavStore = true) {
    try {
        if (!_isEmpty(storeData) && hasUserChangedFavStore) {
            const formattedData = typeof storeData === 'string' ? JSON.parse(storeData) : storeData;
            // added for validation. Will be removed later
            console.log('parsed data::', formattedData);
            const dataToStore = getRequiredStoreData(formattedData);
            // added for validation. Will be removed later
            console.log('Coookie dataToStore::', dataToStore);
            if (__SERVER__) {
                dataToStore.saveToCookie = true;
            } else {
                yield call(saveDataToCookie, {
                    cookieKey: LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
                    data: dataToStore,
                });
            }
            yield put({ type: SET_USER_STORE_LOCATION_INFO, data: dataToStore });
        } else {
            yield call(setUserStoreLocationInfoFromCookie);
        }
    } catch (error) {
        yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error });
    }
}

function* setUserStoreLocationInfoFromCookie() {
    try {
        const userStoreCookie = yield call(
            getCookieData,
            LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE
        );
        if (!_isEmpty(userStoreCookie)) {
            const store = JSON.parse(userStoreCookie) || {};
            yield put({
                type: SET_USER_STORE_LOCATION_INFO,
                data: store,
            });
        } else {
            yield call(loadAkamaiStore, null, true);
        }
    } catch (error) {
        yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error });
    }
}

function* updateNativeFavouriteStore(data) {
    try {
        const favStoreData = _get(data, 'payload', {});
        const storeObj = _get(favStoreData, 'Store', '{}');
        // added for validation. Will be removed later
        console.log('store data::', favStoreData);
        if (favStoreData && storeObj) {
            yield call(saveNativeFavoriteStore, storeObj, favStoreData.hasUserChangedFavStore);
        } else {
            yield call(setUserStoreLocationInfoFromCookie);
        }
    } catch (error) {
        yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error });
    }
}

function* fetchSynchronizedStoreLocation(data) {
    try {
        let store = '';
        let storeSaved = false;
        const context = yield select(selectContext);
        const featureFlags = yield select(selectFeatureFlags);
        const isFragment = _get(context, 'isFragment', false);
        const userStoreCookie = yield call(
            getCookieData,
            LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE
        );
        store = userStoreCookie;
        // get app favoritestore
        const isNative = _get(context, 'isNative', false);
        if (isNative) {
            const pathName = _get(window, 'location.pathname', '');
            if (window.webkit && isCheckoutPage(pathName)) {
                yield call(setUserStoreLocationInfoFromCookie);
            } else {
                yield put(getAppFavStore());
            }
        } else {
            let isPDP = false;
            let plaStoreID = '';
            let isPLAPDPPage = false;
            let shouldFetchStore = true;
            if (!__SERVER__) {
                const pathName = _get(window, 'location.pathname', '');
                const queryString = _get(window, 'location.search', '');
                isPDP = isPDPPage(pathName);
                const query = makeQuery(queryString);
                plaStoreID = _get(query, 'storeId', '');
                isPLAPDPPage = isPLAPage(query, 'google', featureFlags);
                shouldFetchStore = !(isPLAPDPPage && !_isEmpty(plaStoreID) && isPDP);
            }
            if (shouldFetchStore) {
                // This function will be invoked both on Server and Client so adding a check for store information.
                // eslint-disable-next-line no-lonely-if
                if (_isEmpty(store) || data.shouldRefreshStore) {
                    const accountId = TokenProvider.get(ACCOUNT_ID);
                    if (accountId && !isFragment) {
                        // call cart api and store response to User Preferred store
                        const {
                            isSuccess = false,
                            response: {
                                data = {},
                                data: {
                                    storeDetails: {
                                        city = '',
                                        state = '',
                                        curbsideAvailable = '',
                                        timingsOverrideMessage = '',
                                        distance = '',
                                        phone = '',
                                        storeId = '',
                                        storeName = '',
                                        storeTimings = [],
                                        street = '',
                                        zipcode = '',
                                        services = [],
                                    } = {},
                                } = {},
                            } = {},
                        } = yield call(FactorySaga, getStoreForCart, {
                            payload: { xClientSource: 'SLIDER' },
                        });
                        if (isSuccess && Object.keys(data).length) {
                            const preferences = yield select(selectPreferences);
                            const curbsidePickupService = _get(
                                preferences,
                                'curbsidePickupInfo.curbsidePickupService',
                                ''
                            );
                            const curbsidePickupIndicator = services.some(
                                (item) =>
                                    item.toString().toLowerCase() ===
                                    curbsidePickupService.toString().toLowerCase()
                            );
                            store = {
                                city,
                                curbsideAvailable,
                                distance,
                                inputZipCode: zipcode,
                                phone,
                                state,
                                storeId,
                                storeName,
                                street,
                                timings: storeTimings,
                                timingsOverrideMessage,
                                zipcode,
                                curbsidePickupIndicator,
                                services,
                            };
                            yield triggerStoreLogs(
                                store,
                                STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_CART
                            );
                        } else if (User.isUserLoggedIn(true)) {
                            const logggedInResponse = yield call(getStoreInfoForLoggedInUser);
                            if (
                                logggedInResponse &&
                                logggedInResponse.isSuccess &&
                                Object.keys(logggedInResponse.store).length
                            ) {
                                store = logggedInResponse.store;
                                yield triggerStoreLogs(
                                    store,
                                    STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_LOGGED_IN_USER
                                );
                            } else {
                                // set store from ak_geo and then check for geo location, is user allows set from geolocation
                                yield call(setStoreFromAkamaiOrGeo);
                                storeSaved = true;
                            }
                        } else if (!__SERVER__) {
                            // set store from ak_geo and then check for geo location, is user allows set from geolocation
                            yield call(setStoreFromAkamaiOrGeo);
                            storeSaved = true;
                        }
                    } else if (User.isUserLoggedIn(true) && !isFragment) {
                        const logggedInResponse = yield call(getStoreInfoForLoggedInUser);
                        if (
                            logggedInResponse &&
                            logggedInResponse.isSuccess &&
                            Object.keys(logggedInResponse.store).length
                        ) {
                            store = logggedInResponse.store;
                            yield triggerStoreLogs(
                                store,
                                STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_LOGGED_IN_USER
                            );
                        } else {
                            // set store from ak_geo and then check for geo location, is user allows set from geolocation
                            yield call(setStoreFromAkamaiOrGeo);
                            storeSaved = true;
                        }
                    } else if (!__SERVER__) {
                        // set store from ak_geo and then check for geo location, is user allows set from geolocation
                        yield call(setStoreFromAkamaiOrGeo);
                        storeSaved = true;
                    }
                    if (!storeSaved) {
                        const stateSaved = yield call(saveDataToCookie, {
                            cookieKey: LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
                            data: store,
                        });
                        if (!stateSaved) {
                            yield put({
                                type: SET_USER_STORE_LOCATION_INFO,
                                data: store,
                            });
                        }
                    }
                } else {
                    store = JSON.parse(store) || {};
                    const serviceStatus = 'services' in store;
                    if (!serviceStatus) {
                        const response = yield call(fetchStores, store.storeId);
                        const devReact = response?.data?.stores[0].services;
                        const serviceList = { services: devReact };
                        const storeData = { ...store, ...serviceList };
                        yield call(saveDataToCookie, {
                            cookieKey: LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
                            data: storeData,
                        });
                        yield put({
                            type: SET_USER_STORE_LOCATION_INFO,
                            data: storeData,
                        });
                    } else {
                        yield put({
                            type: SET_USER_STORE_LOCATION_INFO,
                            data: store,
                        });
                    }
                }
            }
        }
    } catch (error) {
        const { code = 0 } = error || {};
        if (
            !(
                code === GEO_LOC_PERMISSION_DENIED ||
                code === GEO_LOC_TIMEOUT ||
                code === GEO_LOC_POSITION_UNAVAILABLE
            )
        ) {
            yield put({ type: LOAD_USER_STORE_LOCATION_INFO_ERROR, error });
        }
    }
}

function* setStoreFromAkamaiOrGeo() {
    yield call(loadAkamaiStore, null, true);
    const position = yield call(getCoords);
    if (position && position.coords) {
        const { latitude, longitude } = position.coords;
        const customLatLong = `${latitude},${longitude}`;
        yield call(loadAkamaiStore, customLatLong, true);
    }
}

function* getStoreInfoForLoggedInUser() {
    let store = {};
    const {
        isSuccess = false,
        response: {
            data = {},
            data: {
                id: storeId = '',
                name: storeName = '',
                phone = '',
                timings = [],
                distance = '',
                timingsOverrideMessage = '',
                services = [],
                address = {},
            } = {},
        } = {},
    } = yield call(FactorySaga, getStoreForLoggedInUser, {});
    const curbsideAvailable = services.some((item) => item === 'Curbside Pickup');
    if (isSuccess && Object.keys(data).length) {
        store = {
            curbsideAvailable,
            city: address.city,
            distance,
            inputZipCode: address.zip,
            phone,
            state: address.state,
            storeId,
            storeName,
            street: address.lineOne,
            timings,
            timingsOverrideMessage,
            zipcode: address.zip,
            services,
        };
        return { isSuccess: true, store };
    }
    return { isSuccess: false, store };
}

const getCoords = async () =>
    new Promise((resolve, reject) => {
        // eslint-disable-line
        navigator.geolocation.getCurrentPosition(
            (location) => resolve(location),
            (error) => reject(error)
        );
    });

function* updateUserStoreLocationInfo(data) {
    const { id = null, name = '', zip = null } = data.storeDetails;
    if (id && name && zip) {
        yield call(saveStoreDetails, { formattedData: data.storeDetails, userStore: true });
    } else if (id) {
        const response = yield call(fetchStores, id);
        yield call(saveStoreDetails, { response, userStore: true });
    } else {
        yield put({
            type: LOAD_USER_STORE_LOCATION_INFO_ERROR,
            error: 'Error while updating store location',
        });
    }
}

function* updateStoreOnCart({ storeId }) {
    const cartParams = {
        payload: {
            isUpdateCartStore: true,
            deliveryOptions: {
                storeId,
            },
        },
    };

    const { enablePreSourcing = true } = yield select(selectFeatureFlags) || {};
    if (enablePreSourcing) {
        cartParams.payload.xClientSource = 'SLIDER';
    }

    yield call(FactorySaga, updateCartStore, cartParams);
}

function* updateUserPreferredStoreInfo(data) {
    const { id = null, name = '', zip = '', curbsidePickupIndicator = false } = data.storeDetails;
    if (id && name) {
        const previouslySelectedStore = yield select(getLocationServiceStore);
        if (previouslySelectedStore.id !== id) {
            yield call(saveStoreDetails, { formattedData: data.storeDetails, userStore: true });
            yield all([
                put(
                    getSdpuStoreData({
                        storeId: id,
                        storeName: name,
                        zipCode: zip,
                        curbsidePickupIndicator,
                    })
                ),
                put(setSdpuLocalStorage()),
            ]);
            yield triggerStoreLogs(
                data.storeDetails,
                STORE_CLICK_STREAM_LOGS_UPDATE_USER_PREFFERED_STORE
            );
            const accountId = TokenProvider.get(ACCOUNT_ID);
            if (accountId) {
                const cartItemCount = Number(yield call(getCookieData, ITEM_COUNT));
                if (cartItemCount > 0) {
                    yield put(updateStoreOnCartAction(id));
                }
            }
            if (User.isUserLoggedIn(true)) {
                const camUpdateParams = {
                    payload: {
                        isDefault: true,
                        storeId: id,
                    },
                };
                const { enablePreSourcing = true } = yield select(selectFeatureFlags) || {};
                if (enablePreSourcing) {
                    camUpdateParams.payload.xClientSource = 'SLIDER';
                }
                const { isSuccess = false } = yield call(FactorySaga, updateStore, camUpdateParams);
                if (!isSuccess) {
                    const camAddStoreParams = {
                        payload: {
                            isDefault: true,
                            id,
                        },
                    };
                    yield call(FactorySaga, addStore, camAddStoreParams);
                }
            }
        }
    } else {
        yield put({
            type: LOAD_USER_STORE_LOCATION_INFO_ERROR,
            error: 'Error while updating store location',
        });
    }
}

export const watchUserStoreLocationInfo = function* watchUserStoreLocationInfo() {
    yield takeLatest(LOAD_USER_STORE_LOCATION_INFO, fetchSynchronizedStoreLocation);
};

watchUserStoreLocationInfo.sagaName = 'locationServiceSaga';

export const watchUpdateNativeFavouriteStore = function* watchUpdateNativeFavouriteStore() {
    yield takeLatest(CONTINUE_PROCESSING_APP_FAV_STORE, updateNativeFavouriteStore);
};

watchUpdateNativeFavouriteStore.sagaName = 'updateNativeFavouriteStore';

export const watchSaveNativeFavoriteStore = function* watchSaveNativeFavoriteStore() {
    yield takeLatest(SAVE_NATIVE_FAVORITE_STORE, saveNativeFavoriteStore);
};

watchSaveNativeFavoriteStore.sagaName = 'saveNativeFavoriteStore';

export const watchUpdateUserStoreLocationInfo = function* watchUpdateUserStoreLocationInfo() {
    yield takeLatest(UPDATE_USER_STORE_LOCATION_INFO, updateUserPreferredStoreInfo);
    yield takeLatest(UPDATE_CART_STORE, updateStoreOnCart);
};

watchUpdateUserStoreLocationInfo.sagaName = 'updateUserStoreLocationSaga';
