export const FIND_STORES_REQUEST = 'FIND_STORES_REQUEST';
export const FIND_STORES_SUCCESS = 'FIND_STORES_SUCCESS';
export const FIND_STORES_ERROR = 'FIND_STORES_ERROR';
export const FIND_STORES_INVALID_INPUT = 'FIND_STORES_INVALID_INPUT';
export const FIND_MORE_STORES_REQUEST = 'FIND_MORE_STORES_REQUEST';
export const FIND_MORE_STORES_SUCCESS = 'FIND_MORE_STORES_SUCCESS';
export const FIND_MORE_STORES_ERROR = 'FIND_MORE_STORES_ERROR';
export const PUT_SELECTED_STORE_REQUEST = 'PUT_SELECTED_STORE_REQUEST';
export const PUT_SELECTED_STORE_SUCCESS = 'PUT_SELECTED_STORE_SUCCESS';
export const PUT_SELECTED_STORE_ERROR = 'PUT_SELECTED_STORE_ERROR';
export const OPEN_FIND_STORES_SLIDE_PANEL = 'OPEN_FIND_STORES_SLIDE_PANEL';
export const CLOSE_FIND_STORES_SLIDE_PANEL = 'CLOSE_FIND_STORES_SLIDE_PANEL';
export const SET_AVAILABLE_FILTER = 'SET_AVAILABLE_FILTER';
export const OPEN_FIND_STORES_SUB_SLIDE_PANEL = 'OPEN_FIND_STORES_SUB_SLIDE_PANEL';
export const CLOSE_FIND_STORES_SUB_SLIDE_PANEL = 'CLOSE_FIND_STORES_SUB_SLIDE_PANEL';
export const RESET_FIND_STORES = 'RESET_FIND_STORES';
export const SET_FIND_STORE_SLIDER_PROPS = 'SET_FIND_STORE_SLIDER_PROPS';
