import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import _difference from 'lodash/difference';
import _isEmpty from 'lodash/isEmpty';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import HambArrowLeft from 'yoda-core-components/lib/assets/svg/HambArrowLeft.svg';
import DesktopMenu from 'yoda-core-components/lib/assets/svg/DesktopMenu.svg';
import LocationFill from 'yoda-core-components/lib/assets/svg/LocationFill.svg';
import IconSelectedStore from 'yoda-core-components/lib/assets/svg/IconSelectedStore.svg';
import List from 'yoda-core-components/lib/components/List/List';
import Button from 'yoda-core-components/lib/components/Button/Button';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import SiteComponent from '../SiteComponent/SiteComponent';
import YodaTooltip from '../YodaTooltip/YodaTooltip';
import AnalyticsAction from '../../actions/AnalyticsAction';
import storeNavigationActions from '../../actions/DepartmentVisualNavigationAction';
import OverlayAction from '../../actions/OverlayAction';
import * as styles from './SecondaryNavigationPanel.css';
import config from './Header.config';
import SelectaStore from '../IrisHamburger/SelectaStore';
import LinkFarmHandler from '../LinkFarmHandler/LinkFarmHandler';
import FindAStorePageLazy from '../FindAStorePage/FindAStorePageLazy';
import withReducerSaga from '../../hoc/ReducerRegistry/withReducerSaga';
import ShowShopDepartmentReducer from '../../reducers/ShowShopDepartmentReducer';
import { selectMoreSecondaryNavigationMenuList } from '../../selectors/ContextSelector';
import ShopDepartmentMenuLazy from './HeaderChunksAndFooterLazy';
import Link from '../Link/Link';
import shopDeptActions from '../../actions/ShowShopDepartmentAction';
import getNavigationData from './GetNavigationData';
import FlyoutMenu from './FlyoutMenu';

const cx = classNames.bind(styles);

const secondaryMenuStyle = dt([
    'bg-white',
    'smOnly:min-h-[auto]',
    'sm:z-[8]',
    'relative',
    'border-b-0',
    'pt-2',
]);

/* istanbul-ignore-next */
export class SecondaryNavigationPanel extends SiteComponent {
    static propTypes = {
        selectedStore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        actions: PropTypes.object,
        shopDepartmentMenuInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        secondaryNavigationMenu: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        deviceType: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        disableStoreInfoFromHeader: PropTypes.bool,
        enableExternalComponent: PropTypes.bool,
        disableFindStore: PropTypes.bool,
        secNavMainClass: PropTypes.string,
        enableRenderBotDept: PropTypes.bool,
        disableGeoLocatedStoreInfo: PropTypes.bool,
        moreSecondaryNavMenuList: PropTypes.arrayOf(PropTypes.object),
        enableFlyoutMenu: PropTypes.bool,
    };

    static defaultProps = {
        selectedStore: {
            isGeoStore: false,
            storeDetails: {
                name: '',
            },
        },
        actions: {},
        shopDepartmentMenuInfo: [],
        secondaryNavigationMenu: {},
        deviceType: {},
        disableStoreInfoFromHeader: false,
        enableExternalComponent: false,
        disableFindStore: false,
        showShopDepartment: false,
        secNavMainClass: '',
        enableRenderBotDept: false,
        disableGeoLocatedStoreInfo: false,
        moreSecondaryNavMenuList: [],
        enableFlyoutMenu: false,
    };

    /* istanbul ignore next */
    static clearSliderLockOnPageLoad() {
        if (!__SERVER__) {
            /* istanbul ignore next */
            if (document.body.classList.contains('scrollLock')) {
                /* istanbul ignore next */
                document.body.classList.remove('scrollLock');
            }
        }
    }

    static contextTypes = {
        isFusionEnabled: PropTypes.bool,
    };

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.renderFindAStoreDetails = this.renderFindAStoreDetails.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.saveAnalyticsData = this.saveAnalyticsData.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
        this.timeout = null;
        this.mouseInMainDiv = false;
        this.state = {
            displayvericalSlider: false,
            openSlider: false,
            hoverDepartmentData: '',
            lastBindedDept: '',
            hoverDept: '',
            hoverCategory: '',
            showBotDepartment: false,
            isFlipped: [],
            moreMenuOnclick: false,
        };
    }

    componentDidMount() {
        ShopDepartmentMenuLazy.preload();
        SecondaryNavigationPanel.clearSliderLockOnPageLoad();
    }

    displayDropDown = true;

    displayMoreSecondaryNavMenuList = false;

    closeMenu() {
        this.selectStoreInfo(false);
        this.setState({ displayvericalSlider: false });
        this.setState({ openSlider: false });
    }

    toggleMenu(eventName) {
        this.selectStoreInfo(!this.state.openSlider, eventName);
    }

    /* istanbul ignore next */
    preloadFindAStore = (isDropDownOpen) => {
        if (isDropDownOpen) {
            FindAStorePageLazy.preload();
        }
    };

    goFindaStore = (isDropDownClick) => {
        if (isDropDownClick === true) {
            this.saveAnalyticsData('top:select store:findastore', true);
        } else {
            this.saveAnalyticsData('top:findastore', true);
        }
        return '/findastore'; // redirect through Link component to /findastore
    };

    selectStoreInfo = (menuOpenStatus, eventName) => {
        if (menuOpenStatus) {
            document.body.classList.add('scrollLock');
        } else {
            document.body.classList.remove('scrollLock');
        }
        this.setState({ openSlider: true });
        this.setState({ displayvericalSlider: true });
        /* istanbul ignore next */
        if (eventName) {
            this.saveAnalyticsData(`top:select store:${eventName}`, true);
        }
    };

    FindaStoreSelector = () => {
        let title = 'Change My Store';
        if (
            this.props.selectedStore.isGeoStore &&
            this.props.selectedStore.storeDetails &&
            this.props.selectedStore.storeDetails.name
        ) {
            title = 'Select My Store';
        }
        const eventName = title.toLowerCase();
        return (
            <div className={cx('store-tooltip')}>
                <Button
                    onClick={() => this.toggleMenu(eventName)}
                    type="button"
                    automationId="test-automation-btn-0"
                    buttonType="Primary"
                    size="Lg"
                    className={cx('store-btn', 'toggleStore')}
                >
                    {' '}
                    {title}{' '}
                </Button>
                <Link
                    type="Button"
                    onClick={() => this.goFindaStore(true)}
                    data-automation-id="test-automation-btn-0"
                    buttonType="Primary"
                    size="Lg"
                    className={cx('store-btn', 'findStore')}
                >
                    Find a Store
                </Link>
            </div>
        );
    };

    saveAnalyticsData(title, isInternalUrl) {
        /* istanbul ignore next */
        this.props.actions.triggerNavigationClick({
            linkName: title,
            isReload: !isInternalUrl,
        });
    }

    /* istanbul ignore next */
    navigateurl = (title, link, event, parentMenu = null, isInternalUrl = true, analyticData) => {
        event.preventDefault();
        const eventName = analyticData ? analyticData.join(':') : title;
        const eventData = parentMenu ? `sec:${parentMenu}:${eventName}` : `sec:${eventName}`;
        this.saveAnalyticsData(eventData, isInternalUrl);
        if (!_isEmpty(link)) {
            window.location.assign(link);
            return false;
        }
        return true; // redirect through Link component
    };

    resetMenu = () => {
        this.props.actions.removeOverlay();
        this.props.actions.hideShopDepartment();
        document.removeEventListener('touchend', this.onSyndicateClicked);
    };

    /* istanbul ignore next */
    displayItemOnTouch = (event) => {
        // syndicate mobile/tablet only
        if (this.props.singleFlyoutSyndicateExperience) {
            event.preventDefault(); // will prevent navigateUrl() as well
            // MNPDPYODA-7259
            this.displayItem(event);
            this.navigateurl('shopDepartments', '', event);

            // MNPDPYODA-6866
            document.addEventListener('touchend', this.onSyndicateClicked);
        }
    };

    displayItem = (event) => {
        const currentElemetOrPrevElement = event.target;
        const menuName = currentElemetOrPrevElement.textContent;
        this.resetMenu();
        if (menuName && menuName.toLowerCase() === 'shop departments') {
            this.props.actions.showOverlay();
            this.props.actions.showShopDepartment();
        } else {
            this.props.actions.removeOverlay();
            this.props.actions.hideShopDepartment();
        }
    };

    /* istanbul ignore next */
    onSyndicateClicked = (event) => {
        // if not clicking on "shop department" or single flyout
        if (!event.target.closest('#subMenuLevel1')) {
            this.resetMenu();
        }
    };

    renderStoreDataWithGeo() {
        return (
            <YodaTooltip
                tooltipBodyClassName={cx('storeTooltipWrapper')}
                renderTooltipContents={this.renderStoreData()}
                tooltipContentClass={cx('storeTooltipText')}
                triggerEventName="click"
                direction="Bottomright"
                tooltipPlacement="Right"
                tooltipType="button"
                callBackFun={this.preloadFindAStore}
            >
                {this.renderFindAStoreDetails()}
            </YodaTooltip>
        );
    }

    renderFindAStoreDetails() {
        return (
            <div className={cx('findStoreWrapper')}>
                <div className={cx('store-iconWrapper')}>
                    {this.title === 'My Store' ? (
                        <Icon
                            iconType="svg"
                            className={cx('selected-store-icon')}
                            viewBox="0 0 48 48"
                            pathClassName="accountFillColor"
                        >
                            <IconSelectedStore />
                        </Icon>
                    ) : (
                        <Icon
                            iconType="svg"
                            className={cx('store-icon')}
                            viewBox="0 0 48 48"
                            pathClassName="accountFillColor"
                        >
                            <LocationFill />
                        </Icon>
                    )}
                </div>
                <div className={cx('store-Text')}>
                    <div className={cx('findTitle')}>{this.title}</div>
                    <div className={cx('findText')} title="Change or update your local store">
                        {this.storeText}
                        <span className={cx('storeDropDownIcon')} />
                    </div>
                </div>
            </div>
        );
    }

    renderFindStoreButton() {
        return (
            <Link
                type="Button"
                onClick={this.goFindaStore}
                data-automation-id="test-automation-btn-0"
                buttonType="Text"
                className={cx('findStoreWrapper')}
            >
                <div className={cx('store-iconWrapper')}>
                    <Icon
                        iconType="svg"
                        className={cx('store-icon')}
                        viewBox="0 0 48 48"
                        pathClassName="accountFillColor"
                    >
                        <LocationFill />
                    </Icon>
                </div>
                <div className={cx('store-Text')}>
                    <div className={cx('findTitle')}>{this.title}</div>
                    <div className={cx('findText')} title="Change or update your local store">
                        {this.storeText}
                        <span className={cx('storeDropDownIcon')} />
                    </div>
                </div>
            </Link>
        );
    }

    /* istanbul ignore next */
    renderStoreInfo() {
        if (this.props.enableExternalComponent && this.props.disableStoreInfoFromHeader) {
            this.title = ' ';
            this.storeText = 'Find a Store';
            this.displayDropDown = false;
        } else if (
            this.props.selectedStore.isGeoStore &&
            this.props.selectedStore.storeDetails &&
            this.props.selectedStore.storeDetails.name
        ) {
            this.title = 'Store Nearest You';
            this.storeText = this.props.selectedStore.storeDetails.name;
            this.displayDropDown = true;
        } else if (
            this.props.selectedStore.storeDetails &&
            (this.props.selectedStore.storeDetails.name ||
                this.props.selectedStore.storeDetails.length > 0)
        ) {
            this.title = 'My Store';
            this.storeText =
                this.props.selectedStore.storeDetails.name ||
                this.props.selectedStore.storeDetails[0].name;
            this.displayDropDown = true;
        } else if (
            this.props.selectedStore.userStores &&
            this.props.selectedStore.userStores.length > 0
        ) {
            this.title = 'My Store';
            let store = {};
            if (
                this.props.selectedStore.userStores[0] &&
                this.props.selectedStore.userStores[0].name
            ) {
                store = this.props.selectedStore.userStores;
            } else {
                store = JSON.parse(this.props.selectedStore.userStores);
            }
            this.storeText = store[0].name;
            this.displayDropDown = true;
        } else {
            this.renderFindStoreInfo();
        }
    }

    renderFindStoreInfo() {
        this.title = 'Near You';
        this.storeText = 'Find a Store';
        this.displayDropDown = false;
    }

    renderStoreData() {
        return this.FindaStoreSelector();
    }

    /* istanbul ignore next */
    onClickMoreMenu = () => {
        this.setState({ moreMenuOnclick: !this.state.moreMenuOnclick });
    };

    /* istanbul ignore next */
    mainDivMouseEnter = () => {
        this.mouseInMainDiv = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    };

    mainDivMouseLeave = () => {
        this.mouseInMainDiv = false;
        /* istanbul ignore next */
        this.timeout = setTimeout(() => {
            if (!this.mouseInMainDiv) {
                this.resetMenu();
            }
        }, this.props.menuCloseDelay);
    };

    showBotDepartmentMenu = () => {
        this.setState({
            showBotDepartment: true,
        });
    };

    hideBotDepartmentMenu = () => {
        this.setState({
            showBotDepartment: false,
        });
    };

    /* istanbul ignore next */
    onClick = (index) => {
        const prevIsFlipped = this.state.isFlipped;
        const newIsFlipped = [...prevIsFlipped];
        newIsFlipped[index] = !newIsFlipped[index];
        this.setState({
            isFlipped: [...newIsFlipped],
        });
    };

    renderMenu = (item, index) => {
        const { isDesktop } = this.props.deviceType;
        let arrowClassName = cx('arrowIcon', 'topArrowIcon');
        if (!isDesktop) {
            arrowClassName = this.state.isFlipped[index]
                ? cx('arrowIcon', 'bottomArrowIcon')
                : cx('topArrowIcon');
        }
        let activityMapId = 'sec:subMenuLevel2';
        let parentActivityMapId = 'sec:subMenuLevel1';
        if (item.showMoreMenuItems) {
            activityMapId = 'sec:more:subMenuLevel1';
            parentActivityMapId = '';
        }
        if (
            (item.subMenu && item.subMenu.length > 0) ||
            (this.displayMoreSecondaryNavMenuList && item.showMoreMenuItems)
        ) {
            return (
                <YodaTooltip
                    tooltipBodyClassName={cx(
                        'secNavTooltipWrapper',
                        isDesktop ? 'linkWrapper' : 'buttonWrapper'
                    )}
                    renderTooltipContents={this.renderSubMenu(item)}
                    tooltipTextClass={cx('toolTipBtn')}
                    tooltipContentClass={cx('secNavTooltipText')}
                    triggerEventName={isDesktop ? 'mouseover' : 'click'}
                    direction="Bottomright"
                    tooltipPlacement="Right"
                    callBackFun={
                        /* istanbul ignore next */
                        () => this.onClick(index)
                    }
                    onClickHandler={
                        /* istanbul ignore next */
                        (event) => this.navigateurl(item.name, item.link, event)
                    }
                    activityMapId={activityMapId}
                    parentActivityMapId={parentActivityMapId}
                    tooltipType={isDesktop && item.link ? 'link' : 'button'}
                    linkName={item.link}
                >
                    {this.renderSecondaryNavSubMenu(item, arrowClassName)}
                </YodaTooltip>
            );
        }
        if (!item.subMenu || item.subMenu.length === 0) {
            return (
                <div id="sec:subMenuLevel1">
                    <Link
                        href={item.link}
                        className={cx('links')}
                        onClick={
                            /* istanbul ignore next */
                            (event) => this.navigateurl(item.name, '', event)
                        }
                    >
                        {item.name}
                    </Link>
                </div>
            );
        }
        return null;
    };

    renderSecondaryNavSubMenu(item, arrowClassName) {
        return (
            <div className={cx('rendernavList')}>
                {item.name}
                <span data-automation-id="arrow-drop-icon" className={arrowClassName} />
            </div>
        );
    }

    renderSubMenu = (item) => {
        let parentName = item.name;
        let dataSource = item.subMenu;
        if (item.showMoreMenuItems) {
            dataSource = this.props.moreSecondaryNavMenuList;
            parentName = 'more';
        }
        const listItemRenderer = (dataItem) => (
            <Link
                href={dataItem.link}
                className={cx('links')}
                onClick={(event) => this.navigateurl(dataItem.name, '', event, parentName)}
            >
                {dataItem.name}
            </Link>
        );
        return (
            <List
                direction="Vertical"
                datasource={dataSource}
                childRenderer={listItemRenderer}
                listStyleClass={cx('listBlock')}
                itemStyleClass={cx('listItem')}
                listBodyClass={cx('secNavBodyClass')}
                spacing="None"
                automationId="tooltip-automation-content"
            />
        );
    };

    renderSecondaryNavList = (source) => (
        <List
            datasource={source}
            direction="Horizontal"
            listBodyClass={cx('listBody')}
            listStyleClass={cx('listBlock')}
            itemStyleClass={cx('listItem')}
            childRenderer={this.renderMenu}
            spacing="None"
            automationId="at-error-helpfullinks-renderer"
        />
    );

    renderFindAStore() {
        const {
            deviceType: { isDesktop, isBot },
            showShopDepartment,
            disableFindStore,
            secondaryNavigationMenu,
            moreSecondaryNavMenuList,
            enableRenderBotDept,
        } = this.props;
        /* istanbul ignore next */
        const mainStyle = showShopDepartment
            ? cx('shopDepartmentsBlock', 'mainNavSelected')
            : cx('shopDepartmentsBlock');
        /* istanbul ignore next */
        const overlay = showShopDepartment ? cx('overlay', 'active') : cx('overlay');
        let source = secondaryNavigationMenu.menuList || [];
        const moreItemsToDisplay = _difference(
            secondaryNavigationMenu.menuList,
            moreSecondaryNavMenuList
        );
        /* istanbul ignore else */
        if (moreItemsToDisplay.length > 1 && moreSecondaryNavMenuList.length > 1) {
            source = moreItemsToDisplay;
            this.displayMoreSecondaryNavMenuList = true;
            source.push({
                name: 'More',
                showMoreMenuItems: true,
            });
        }

        return (
            <div className={cx('secNavFindStoreBlock')}>
                <div
                    className={cx('LeftNavigationWrapper')}
                    data-automation-id="leftNavigationWrapper"
                    id="leftNavigationWrapper"
                >
                    {isDesktop && !(enableRenderBotDept && isBot) && (
                        <div
                            className={mainStyle}
                            data-automation-id="subMenuLevel1"
                            onMouseEnter={this.mainDivMouseEnter}
                            onMouseLeave={this.mainDivMouseLeave}
                            id="subMenuLevel1"
                        >
                            {/* mainNavSelected for the selected Class  Eg: Shop Dept => Block  */}
                            <div className={overlay} onMouseOver={this.overlayMouseOver} />
                            <button
                                onMouseOver={this.displayItem}
                                onTouchEnd={this.displayItemOnTouch}
                                id="shopDepartmentBtn"
                                className={cx('shopdepartments')}
                                data-automation-id="shopdepartments"
                                onClick={(event) => {
                                    this.navigateurl('shopDepartments', '', event);
                                }}
                            >
                                <Icon
                                    iconType="svg"
                                    className={cx('shopDepartmentsSvg')}
                                    automationId="desktop hamburger Icon"
                                    width="40px"
                                    height="40px"
                                    viewBox="0 0 40 40"
                                    pathClassName={cx('shopDeptHamburgerIcon')}
                                >
                                    <DesktopMenu />
                                </Icon>
                                <span className={cx('shopDeptText')}>Shop Departments</span>
                            </button>
                            {
                                /* istanbul ignore next */
                                showShopDepartment && <ShopDepartmentMenuLazy />
                            }
                        </div>
                    )}
                    {enableRenderBotDept && isBot && isDesktop && (
                        <div className={cx('botMenuBlock')}>
                            <button
                                onMouseOver={this.showBotDepartmentMenu}
                                onMouseLeave={this.hideBotDepartmentMenu}
                                id="botDepartmentBtn"
                                className={cx('shopdepartments')}
                                data-automation-id="shopdepartments"
                            >
                                SHOP DEPARTMENTS
                            </button>
                        </div>
                    )}
                    {source && (
                        <div className={cx('config-links')} data-automation-id="secondaryNav">
                            {this.renderSecondaryNavList(source)}
                        </div>
                    )}
                </div>
                {isDesktop &&
                    !disableFindStore &&
                    (this.displayDropDown
                        ? this.renderStoreDataWithGeo()
                        : this.renderFindStoreButton())}
            </div>
        );
    }

    /* To render all menu for BOT to cwarl */
    /* istanbul ignore next */
    displayAllMenuForBot = () => {
        const {
            shopDepartmentMenuInfo,
            deviceType: { isBot },
        } = this.props;
        const { showBotDepartment } = this.state;
        const links = [];
        const botDeptClass = showBotDepartment
            ? cx('visualNavGridBot')
            : cx('visualNavGridBot', 'notShown');

        if (isBot && shopDepartmentMenuInfo && shopDepartmentMenuInfo.length > 0) {
            shopDepartmentMenuInfo.forEach((deptL1) => {
                const deptL1Links = [];
                deptL1Links.push(
                    <h3>
                        <a className={cx('deptTitleBot')} href={deptL1.links}>
                            {deptL1.title}
                        </a>
                    </h3>
                );
                deptL1.departments.forEach((category) => {
                    deptL1Links.push(
                        <div className={cx('botLinkFarmList')}>
                            <LinkFarmHandler
                                category={category.key}
                                items={category.categories}
                                categoryLink={category.links}
                            />
                        </div>
                    );
                });
                links.push(
                    <div className={botDeptClass} id={deptL1.title}>
                        {deptL1Links}
                    </div>
                );
            });
        }

        return links;
    };

    /* istanbul ignore next */
    displayFlyoutAllMenuForBot = () => {
        const {
            shopDepartmentMenuInfo,
            deviceType: { isBot },
        } = this.props;
        const links = [];

        if (isBot && shopDepartmentMenuInfo && shopDepartmentMenuInfo.length > 0) {
            shopDepartmentMenuInfo.forEach((deptL1) => {
                const deptL1Links = [];
                deptL1Links.push(
                    <h3>
                        <a className={cx('deptTitleBot')} href={deptL1.links}>
                            {deptL1.title}
                        </a>
                    </h3>
                );
                deptL1.subDepartments.forEach((subcategories) => {
                    deptL1Links.push(
                        <h3>
                            <a className={cx('subDeptTitleBot')} href={subcategories.links}>
                                {subcategories.name}
                            </a>
                        </h3>
                    );
                });
                deptL1.departments.forEach((category) => {
                    deptL1Links.push(
                        <div className={cx('botLinkFarmList')}>
                            <LinkFarmHandler
                                category={category.key}
                                items={category.categories}
                                categoryLink={category.links}
                            />
                        </div>
                    );
                });
                links.push(
                    <div className={cx('visualNavGridBot', 'notShown')} id={deptL1.title}>
                        {deptL1Links}
                    </div>
                );
            });
        }

        return links;
    };

    render() {
        /* istanbul ignore next */
        if (this.props.enableFlyoutMenu) {
            const {
                deviceType: { isBot = false },
                enableRenderBotDept,
            } = this.props;

            return (
                <div
                    data-automation-id="secondaryMenu"
                    className={`${secondaryMenuStyle} ${cx(
                        'secondaryMenu',
                        'newHeader',
                        'flyoutMenu'
                    )}`}
                >
                    <div className={cx('containerWidth')}>
                        <FlyoutMenu />
                    </div>
                    {
                        /* istanbul ignore next */
                        enableRenderBotDept && isBot && this.displayFlyoutAllMenuForBot()
                    }
                </div>
            );
        }
        const {
            disableFindStore,
            deviceType: { isBot },
            enableRenderBotDept,
            disableGeoLocatedStoreInfo,
        } = this.props;
        !disableFindStore &&
            (disableGeoLocatedStoreInfo ? this.renderFindStoreInfo() : this.renderStoreInfo());

        const headerBack = this.context.isFusionEnabled ? 'slideBack' : '';
        const { openSlider, displayvericalSlider } = this.state;
        const verticalSlideClassName = openSlider
            ? cx('verticalslide', 'displayVerticalSlide', headerBack)
            : cx(headerBack, 'verticalslide');
        const changeStoreWrapperClassName = displayvericalSlider
            ? cx('changeStoreWrapper')
            : cx('hideStoreWrapper');

        return (
            <div
                className={cx(
                    'secondaryMenu',
                    'newHeader',
                    this.props.secNavMainClass || '',
                    openSlider ? 'secondaryMenuOpen' : ''
                )}
            >
                <div className={cx('containerWidth')}>
                    {this.renderFindAStore()}
                    <div className={cx('verticalmenu-wrapper')}>
                        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                        <div className={changeStoreWrapperClassName} onClick={this.closeMenu} />
                        <div className={verticalSlideClassName}>
                            <div className={cx('sliderHeader')}>
                                <Button
                                    type="button"
                                    onClick={this.closeMenu}
                                    automationId="test-automation-btn-0"
                                    buttonType="Text"
                                    className={cx('leftArrowWrapper')}
                                >
                                    <Icon
                                        className={cx('left-arrow')}
                                        iconType="svg"
                                        width="22px"
                                        height="22px"
                                        viewBox="0 0 30 20"
                                    >
                                        <HambArrowLeft />
                                    </Icon>
                                    Back
                                </Button>
                            </div>
                            <div className={cx('slider-data')}>
                                {this.state.openSlider
                                    ? SelectaStore(this.props.selectedStore)
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    /* istanbul ignore next */
                    enableRenderBotDept && isBot && this.displayAllMenuForBot()
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            ...AnalyticsAction,
            ...storeNavigationActions,
            ...OverlayAction,
            ...shopDeptActions,
        },
        dispatch
    ),
});

// const mapStateToProps = ({ accounts, selectedStore, context, IrisGlobalNavigation }) => {
const mapStateToProps = (state) => {
    const { selectedStore, context, IrisGlobalNavigation = [], showShopDepartment = false } = state;
    const enableFlyoutMenu = _get(context, 'featureFlags.enableFlyoutMenu', false);
    const disableStoreInfoFromHeader = _get(
        context,
        'featureFlags.disableStoreInfoFromHeader',
        false
    );
    const enableExternalComponent = _get(context, 'featureFlags.enableExternalComponent', false);
    const disableFindStore = _get(context, 'featureFlags.disableFindStore', false);
    const disableHeaderSecondaryNav = _get(
        context,
        'featureFlags.disableHeaderSecondaryNav',
        false
    );
    const secondaryMenu =
        context && context.preferences
            ? context.preferences.secondaryNavigationMenu
            : config.secondaryNavigationMenu;
    const secondaryNavigationMenu = disableHeaderSecondaryNav ? [] : secondaryMenu;
    const enableRenderBotDept = _get(context, 'featureFlags.enableRenderBotDept', false);
    const menuCloseDelay = _get(context, 'preferences.singleFlyer.menuCloseDelay', 1000);
    const disableGeoLocatedStoreInfo = _get(
        context,
        'featureFlags.disableGeoLocatedStoreInfo',
        false
    );
    let departmentVisualNav = [];
    departmentVisualNav = IrisGlobalNavigation;
    /* istanbul ignore else */
    if (departmentVisualNav.length === 0) {
        departmentVisualNav = getNavigationData(_get(context, 'preferences.departments', []));
    }
    const moreSecondaryNavMenuList = selectMoreSecondaryNavigationMenuList(state);

    return {
        disableStoreInfoFromHeader,
        enableExternalComponent,
        disableFindStore,
        shopDepartmentMenuInfo: departmentVisualNav,
        selectedStore: selectedStore || {
            isGeoStore: false,
            storeDetails: {
                name: '',
            },
        },
        secondaryNavigationMenu, // need to remove else part once config chages are done in AWS
        menuCloseDelay,
        showShopDepartment,
        enableRenderBotDept,
        moreSecondaryNavMenuList,
        disableGeoLocatedStoreInfo,
        enableFlyoutMenu,
    };
};

export default compose(
    withReducerSaga([ShowShopDepartmentReducer]),
    connect(mapStateToProps, mapDispatchToProps)
)(SecondaryNavigationPanel);
