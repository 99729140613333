import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LinkFarm from '../LinkFarm/LinkFarm';
import analyticsAction from '../../actions/AnalyticsAction';

class LinkFarmHandler extends PureComponent {
    constructor() {
        super();
        this.handleEvent = this.handleEvent.bind(this);
    }

    handleEvent(logEvent) {
        this.props.actions.triggerNavigationClick(logEvent);
    }

    render() {
        const { category, items, automationId, categoryLink } = this.props;
        return (
            <LinkFarm
                triggerNavigationClick={this.handleEvent}
                category={category}
                items={items}
                automationId={automationId}
                categoryLink={categoryLink}
            />
        );
    }
}

LinkFarmHandler.defaultProps = {
    category: '',
    items: [],
    automationId: '',
    isBot: false,
    categoryLink: '',
};

LinkFarmHandler.propTypes = {
    category: PropTypes.string,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    actions: PropTypes.object.isRequired,
    automationId: PropTypes.string,
    categoryLink: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...analyticsAction }, dispatch),
});

export default connect(null, mapDispatchToProps)(LinkFarmHandler);
