import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import orderActions from '../../actions/OrderAction';
import actions from '../../actions/RegionZoneActions';
import { COOKIE_PRICE_ZONE } from '../../common/Constants';

/*
    Regional pricing is determined by lat/long and it persisted for one day now. Meaning
    region pricing would not change for a day till user clears his/her cookie.

    Store does not impact region. It is the actual user lat long

    We fallback to Akamai lat long in case user location could not be accessed
*/

export class FetchRegion extends PureComponent {
    static defaultProps = {
        regionZonefromLocation: null,
        enableRegionPricing: null,
    };

    static propTypes = {
        actions: PropTypes.object.isRequired,
        regionZonefromLocation: PropTypes.object, //eslint-disable-line
    };

    componentDidMount() {
        this.resetRegion();
    }

    componentWillReceiveProps(nextProps) {
        this.props.actions.setRegion(nextProps.regionZonefromLocation.regionZonefromLocation);
    }

    resetRegion() {
        this.props.actions.setRegion(-1);
        Cookies.remove(COOKIE_PRICE_ZONE);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (store) => ({
    regionZonefromLocation: store.regionZonefromLocation,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...actions, ...orderActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FetchRegion);
