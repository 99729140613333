import productSearchActionTypes from '../actionTypes/ProductSearchActionTypes';

const getProductSearchAction = (params, regionZone, version) => ({
    type: productSearchActionTypes.PRODUCTSEARCH_GET_REQUEST,
    params,
    regionZone,
    version,
});

const resetProductSearchResults = (value = []) => ({
    type: productSearchActionTypes.RESET_PRODUCTSEARCH_RESULTS,
    value,
});

const getBRProductSearchAction = (params, version) => ({
    type: productSearchActionTypes.PRODUCTSEARCH_GET_REQUEST_BR,
    params,
    version,
});

const resetBRProductSearchResults = (value = []) => ({
    type: productSearchActionTypes.RESET_PRODUCTSEARCH_RESULTS_BR,
    value,
});

export default {
    getProductSearchAction,
    resetProductSearchResults,
    getBRProductSearchAction,
    resetBRProductSearchResults,
};
