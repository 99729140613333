import get from 'lodash/get';

const getPageType = () =>
    !__SERVER__ ? get(window, 'digitalData.page.pageInfo.pageType', '') : '';

export const isPDPPage = (pageUrl = '') => {
    return pageUrl.includes('/p/');
};

export const isCheckoutPage = (pageUrl = '') => {
    return pageUrl.includes('/checkout');
};

export const isGalleryPage = (pageUrl = '') => {
    const validGalleryRoutes = ['/s', '/g/'];
    return validGalleryRoutes.some((route) => pageUrl.includes(route));
};
export const isCart = (pageUrl = '') => {
    return pageUrl.includes('/cart');
};

export const isCategoryPage = (pageUrl = '') => {
    return pageUrl.includes('/g');
};

export const isSearchPage = (pageUrl = '') => {
    return pageUrl.includes('/s');
};

export const isCartPage = (pageUrl = '') => {
    return pageUrl.includes('/cart');
};

export const isDepartmentPage = (pageUrl = '') => {
    return pageUrl.includes('/d/');
};

export const isMarketingPage = (pageUrl = '') => {
    return pageUrl.includes('/m/') && !pageUrl.includes('/N-mpi6e5');
};

export default getPageType;
