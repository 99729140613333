const INJECT_REDUCER = 'INJECT_REDUCER';
const REJECT_REDUCER = 'REJECT_REDUCER';
const INJECT_SAGA = 'INJECT_SAGA';
const REJECT_SAGA = 'REJECT_SAGA';

export default {
    INJECT_REDUCER,
    REJECT_REDUCER,
    INJECT_SAGA,
    REJECT_SAGA,
};
