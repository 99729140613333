import find from 'lodash/find';
import get from 'lodash/get';
import ProductPricingActionTypes from '../actionTypes/ProductPricingActionTypes';

const {
    PRODUCT_PRICING_GET_REQUEST,
    PRODUCT_PRICING_FALLBACK_GET_REQUEST,
    PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST,
    PRODUCT_PRICING_GET_SUCCESS,
    SET_BUNDLE_PRODUCT_PRICING,
    UPDATE_PRODUCT_PRICING,
} = ProductPricingActionTypes;

const getProductPricingAction = (payload) => ({
    type: PRODUCT_PRICING_GET_REQUEST,
    payload,
});

const getProductPricingFallbackAction = (payload) => ({
    type: PRODUCT_PRICING_FALLBACK_GET_REQUEST,
    payload,
});

const getProductPricingWithCustomOptionsAction = (payload) => ({
    type: PRODUCT_PRICING_WITH_CUSTOM_OPTIONS_GET_REQUEST,
    payload,
});

const clearProductPricingAction = (sourceMiniPDP) => ({
    type: PRODUCT_PRICING_GET_SUCCESS,
    pricing: {},
    sourceMiniPDP,
});

const setBundleProductPricingAction = (payload) => ({
    type: SET_BUNDLE_PRODUCT_PRICING,
    payload,
});

const updateSelectedStoreAvailability = (
    selectedSkuId,
    { items = [], id: locationId },
    { deliveryOptions: prevDeliveryOptions = {} },
    sourceMiniPDP,
    status = 'AVAILABLE'
) => {
    const availableStore = find(
        items,
        ({ skuId, inventory: { pickUpStatus } }) =>
            skuId === selectedSkuId && pickUpStatus === status
    );
    const bopusDelivery = {
        bopus: {
            locationIds: [
                {
                    locationId,
                    availabilityStatus: availableStore ? 'AH' : 'NA',
                    availableQuantity: get(availableStore, 'inventory.pickUpQuantity'),
                },
            ],
        },
    };
    const deliveryOptions = { ...prevDeliveryOptions, ...bopusDelivery };

    const payload = {
        promise: {
            skuDeliveryOptions: [
                {
                    deliveryOptions,
                },
            ],
        },
        sourceMiniPDP,
    };

    return {
        type: UPDATE_PRODUCT_PRICING,
        payload,
    };
};

export default {
    getProductPricingAction,
    getProductPricingWithCustomOptionsAction,
    clearProductPricingAction,
    getProductPricingFallbackAction,
    setBundleProductPricingAction,
    updateSelectedStoreAvailability,
};
