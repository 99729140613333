import React from 'react';
import universal from 'react-universal-component';
import universalImport from 'babel-plugin-universal-import/universalImport';
import path from 'path';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { timeout } from '../../lazy/lazyConstants';

export default universal(
    universalImport({
        chunkName: () => 'FindAStoreRouteLazy',
        path: () => path.join(__dirname, './FindAStoreRoute'),
        resolve: () => require.resolveWeak('./FindAStoreRoute'),
        load: () =>
            Promise.all([
                import(/* webpackChunkName: "FindAStoreRouteLazy" */ './FindAStoreRoute'),
            ]).then((proms) => proms[0]),
    }),
    {
        loading: () => <Loader keepOverlay loaderClass={dt(['absolute'])} />,
        error: () => null,
        timeout,
    }
);
