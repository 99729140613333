import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import { CARTPAGE_LAST_VISIT_TIME } from '../../common/Constants';

export const setLastCartVisitTime = () => {
    const currTime = new Date();
    LocalStorage.setData(CARTPAGE_LAST_VISIT_TIME, currTime, true);
};

export const getLastCartVisitTime = () =>
    JSON.parse(LocalStorage.getData(CARTPAGE_LAST_VISIT_TIME, true));

export const removeLastCartVisitTime = () =>
    LocalStorage.removeData(CARTPAGE_LAST_VISIT_TIME, true);
