import _forEach from 'lodash/forEach';

const getNavigationData = (preferencesDept) => {
    const deptsArray = [];
    _forEach(preferencesDept, (value) => {
        let arrObj = {};
        const departments = [];
        const subDepartments = [];
        const { linkText: title = '' } = value;
        const { image: { url: image = '' } = {} } = value;
        const { link: { url: links = '' } = {} } = value;
        arrObj = {
            title,
            image,
            links,
            departments,
            subDepartments,
        };
        deptsArray.push(arrObj);
    });
    return deptsArray;
};

export const fetchVizNavIrisDepartmentData = (irisMenuData = []) => {
    const deptsArray = [];
    const itemArray = Object.keys(irisMenuData);
    _forEach(itemArray, (value) => {
        const { linkText: title = '' } = irisMenuData[value];
        const { image: { url: image = '' } = {} } = irisMenuData[value];
        const { link: { url: links = '' } = {} } = irisMenuData[value];
        deptsArray.push({
            title,
            image,
            links,
        });
    });
    return deptsArray;
};

export default getNavigationData;
