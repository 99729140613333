import { takeLatest, call, put, select } from 'redux-saga/effects';
import { addItemToCart } from 'yoda-interfaces/lib/Cart/CartApi';
import FactorySaga from './FactorySaga';
import * as messagesActions from '../actions/MessagesAction';
import loadingActions from '../actions/LoadingAction';
import { X_CLIENT_SOURCE_MINI_PDP } from '../common/Constants';
import { ADD_TO_CART_MESSAGES_ID } from '../components/AddToCart/AddToCart.config';
import addToCartActionTypes from '../actionTypes/AddToCartActionTypes';
import AnalyticsAction from '../actions/AnalyticsAction';
import { getDisableBYDeliveryDateFlag } from '../selectors/byDeliveryDateSelector';

const getGenericApiErrorMessages = (state) => state.context.messagesTexts.GenericApiErrorMessages;
let apiCounter = 0;

function* AddToCartSaga(action) {
    try {
        yield put(
            messagesActions.removeAllMessages({
                section: ADD_TO_CART_MESSAGES_ID,
                sourceMiniPDP: action.sourceMiniPDP,
            })
        );
        const GenericApiErrorMessages = yield select(getGenericApiErrorMessages);
        const addItemAction = {};
        const disableBYDeliveryDate = yield select(getDisableBYDeliveryDateFlag);
        addItemAction.payload = {
            ...action.payload.productDetail,
            xClientSource:
                action.sourceMiniPDP && !disableBYDeliveryDate ? X_CLIENT_SOURCE_MINI_PDP : null,
        };
        addItemAction.type = action.type;
        apiCounter++; // eslint-disable-line
        const cartSuccess = yield call(FactorySaga, addItemToCart, addItemAction);
        if (cartSuccess.isSuccess) {
            cartSuccess.ppId = action.payload.productDetail.data[0].ppId;
            cartSuccess.skuId = action.payload.productDetail.data[0].skuId;
            apiCounter = 0;
            cartSuccess.from = action.from;
            yield put({ type: addToCartActionTypes.ADDTOCART_SITE_REQUEST_SUCCESS, cartSuccess });
        } else if (cartSuccess.response && cartSuccess.response.status !== 401) {
            const { errorCode, errorMessage } =
                cartSuccess.response.data &&
                Array.isArray(cartSuccess.response.data.data) &&
                Array.isArray(cartSuccess.response.data.data[0].errors)
                    ? cartSuccess.response.data.data[0].errors[0]
                    : cartSuccess.response.data;
            cartSuccess.ppId = action.payload.productDetail.data[0].ppId;
            cartSuccess.skuId = action.payload.productDetail.data[0].skuId;
            yield put({
                type: addToCartActionTypes.ADDTOCART_SITE_REQUEST_ERROR,
                error: cartSuccess,
            });
            if (
                cartSuccess.response.status !== 412 ||
                errorCode !== 'INITIATE_CHECKOUT_AGAIN' ||
                apiCounter === 2
            ) {
                const errorTitle = errorMessage || GenericApiErrorMessages.apiErrorMessage;
                yield put(loadingActions.removeLoader());
                yield put(
                    messagesActions.addMessage({
                        section: ADD_TO_CART_MESSAGES_ID,
                        message: {
                            id: errorCode,
                            title: errorTitle,
                        },
                        sourceMiniPDP: action.sourceMiniPDP,
                    })
                );
                yield put(AnalyticsAction.triggerFormError([{ errorDescription: errorTitle }]));
            }
        }
    } catch (error) {
        const GenericApiErrorMessages = yield select(getGenericApiErrorMessages);
        yield put(loadingActions.removeLoader());
        yield put({ type: addToCartActionTypes.ADDTOCART_SITE_REQUEST_ERROR, error });
        const errorTitle = error.errorMessage || GenericApiErrorMessages.apiErrorMessage;
        yield put(
            messagesActions.addMessage({
                section: ADD_TO_CART_MESSAGES_ID,
                message: {
                    id: error.errorCode,
                    title: errorTitle,
                },
                sourceMiniPDP: action.sourceMiniPDP,
            })
        );
        yield put(AnalyticsAction.triggerFormError([{ errorDescription: errorTitle }]));
    }
}

const watchAddToCartSaga = function* watchAddToCartSaga() {
    yield takeLatest(addToCartActionTypes.ADDTOCART_SITE_REQUEST, AddToCartSaga);
};
watchAddToCartSaga.sagaName = 'watchAddToCartSaga';

export default watchAddToCartSaga;
