const ADDTOCART_SITE_REQUEST = 'ADDTOCART_SITE_REQUEST';
const ADDTOCART_SITE_REQUEST_ERROR = 'ADDTOCART_SITE_REQUEST_ERROR';
const ADDTOCART_SITE_REQUEST_SUCCESS = 'ADDTOCART_SITE_REQUEST_SUCCESS';
const ADDTOCART_SITE_RESET = 'ADDTOCART_SITE_RESET';
const ADDTOCART_SITE_KEEPSHOPPING_ONCLICK = 'ADDTOCART_SITE_KEEPSHOPPING_ONCLICK';
const MYLIST_CART_ADD_ANALYTICS = 'MYLIST_CART_ADD_ANALYTICS';
const REVIEVE_ADDTOCART_REQUEST = 'REVIEVE_ADDTOCART_REQUEST';
const REVIEVE_ADDTOCART_RESPONSE = 'REVIEVE_ADDTOCART_RESPONSE';
const REVIEVE_ADDTOCART_API_ERROR = 'REVIEVE_ADDTOCART_API_ERROR';
const REVIEVE_ADDTOCART_RESET = 'REVIEVE_ADDTOCART_RESET';
const ADDTOCART_DETAILS_GET_REQUEST = 'ADDTOCART_DETAILS_GET_REQUEST';

export default {
    ADDTOCART_SITE_REQUEST,
    ADDTOCART_SITE_REQUEST_ERROR,
    ADDTOCART_SITE_REQUEST_SUCCESS,
    ADDTOCART_SITE_KEEPSHOPPING_ONCLICK,
    MYLIST_CART_ADD_ANALYTICS,
    ADDTOCART_SITE_RESET,
    REVIEVE_ADDTOCART_REQUEST,
    REVIEVE_ADDTOCART_RESPONSE,
    REVIEVE_ADDTOCART_API_ERROR,
    REVIEVE_ADDTOCART_RESET,
    ADDTOCART_DETAILS_GET_REQUEST,
};
