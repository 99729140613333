import { APPLY_VENDOR_COUPON } from '../actionTypes/OtherVendorActionTypes';

const applyOtherCoupon = (payload, callback) => ({
    type: APPLY_VENDOR_COUPON,
    payload,
    callback,
});

export default {
    applyOtherCoupon,
};
